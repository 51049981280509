
<div class="w-100" xLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
    <div ngClass.gt-sm="w-25"></div>
    <div ngClass.xs="p-lr" ngClass.sm="p-lr-2" class="p-t-3 full-height-container" fxFlex fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">
        <div class="w-100" *ngFor="let dataElement of data; let i = index">
            <h2 fxLayout="column" fxLayoutAlign="center center" *ngIf="dataElement.type === 'title'">{{dataElement.content}}</h2>
            <h3 fxLayout="row" fxLayoutAlign="start center" *ngIf="dataElement.type === 'heading'">{{dataElement.content}}</h3>
            <p *ngIf="dataElement.type === 'passage'">{{dataElement.content}}</p>
            <p class="m-l" *ngIf="dataElement.type === 'passage-m-l'">{{dataElement.content}}</p>
            <div class="m-tb w-100" *ngIf="dataElement.type === 'link'">
                <a href="{{dataElement.path}}" target="_blank">{{dataElement.title}}</a>
            </div>
        </div>
    </div>
    <div ngClass.gt-sm="w-25"></div>
</div>
