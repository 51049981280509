<div class="additional-tools-element">
    <div class="ep-single-form p-b" *ngIf="isEditMode() && rect" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
        <div class="ep-form-control-container" fxLayout="row" fxLayoutAlign="center center">
            <div class="w-100" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px">
                <div class="ep-form-control-container">
                    <div class="ep-form-control">
                        <span class="additional-tools-span">Breite ({{units}})</span>
                        <input type="number" [(ngModel)]="rect.width" (blur)="onBlur()" (focus)="onFocus()"
                        (ngModelChange)="updateWidth()">
                    </div>
                </div>
                <div class="ep-form-control-container">
                    <div class="ep-form-control">
                        <span class="additional-tools-span">Höhe ({{units}})</span>
                        <input type="number" [(ngModel)]="rect.height" (blur)="onBlur()" (focus)="onFocus()"
                        (ngModelChange)="updateHeight()">
                    </div>
                </div>
            </div>
            <div>
                <button mat-icon-button [ngClass]="!sideRatioLocked ? 'disabled-ep': 'enabled-lock'" (click)="toggleSideRatioLock()">
                    <mat-icon svgIcon="link-solid"></mat-icon>
                </button>
            </div>
        </div>
        <form [formGroup]="percentageForm" (submit)="submitPercentage()" class="ep-form-control-container" fxLayout="row" fxLayoutAlign="center center">
            <div class="w-100" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px">
                <div class="w-100">
                    <div class="ep-form-control">
                        <span class="additional-tools-span">Breite %</span>
                        <input formControlName="width" type="number" (ngModelChange)="sideRatioLocked && initPercentageForm(percentageForm.value.width)" (blur)="onBlur()" (focus)="onFocus()">
                    </div>
                </div>
                <div class="ep-form-control-container">
                    <div class="ep-form-control">
                        <span class="additional-tools-span">Höhe %</span>
                        <input formControlName="height" type="number" (ngModelChange)="sideRatioLocked && initPercentageForm(percentageForm.value.height)" (blur)="onBlur()" (focus)="onFocus()">
                    </div>
                </div>
            </div>
            <div>
                <button mat-icon-button (click)="submitPercentage()">
                    OK
                </button>
            </div>
        </form>
        <div class="ep-form-control-container" fxLayout="row" fxLayoutAlign="center center">
            <div class="w-100">
                <div class="ep-form-control">
                    <span class="additional-tools-span">Rotation °</span>
                    <input type="number" [(ngModel)]="rect.rotation" (blur)="onBlur()" (focus)="onFocus()"
                    (ngModelChange)="updateRotation()">
                </div>
            </div>
            <div fxFlex="40px"></div>
        </div>
    </div>
    <app-common-properties *ngIf="isInsertMode()"></app-common-properties>
    <app-fill-tools *ngIf="isInsertMode()"></app-fill-tools>
</div>