import { BrowserModule } from '@angular/platform-browser';
import { importProvidersFrom, NgModule} from '@angular/core';
import { MaterialModule } from "./shared/material.module";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { StyleguideComponent } from "./components";
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InfoDialogComponent } from './components/dialogs/info-dialog/info-dialog.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EditorComponent } from './components/editor/editor.component';
import { NavComponent } from './components/nav/nav.component'
import { HorizontalControlsComponent } from './components/controls/horizontal-controls/horizontal-controls.component';
import { VerticalControlsComponent } from './components/controls/vertical-controls/vertical-controls.component';
import { JwtInterceptor } from "./helpers";
import { MockBackendProvider } from './helpers/mock-backend/mock-backend.service';
import { FileImportDialogComponent } from './components/dialogs/file-import-dialog/file-import-dialog.component';
import { FileControlsComponent } from './components/controls/horizontal-controls/file-controls/file-controls.component';
import { GeomPrimitivesControlsComponent } from './components/controls/vertical-controls/geom-primitives-controls/geom-primitives-controls.component';
import { ViewerControlsComponent } from './components/controls/horizontal-controls/viewer-controls/viewer-controls.component';
import { VertViewerControlsComponent } from './components/controls/vertical-controls/viewer-controls/viewer-controls.component';
import { PrintControlsComponent } from './components/controls/horizontal-controls/print-controls/print-controls.component';
import { EditingControlsComponent } from './components/controls/horizontal-controls/editing-controls/editing-controls.component';
import { VertEditingControlsComponent } from './components/controls/vertical-controls/editing-controls/editing-controls.component';
import { InsertingControlsComponent } from './components/controls/vertical-controls/inserting-controls/inserting-controls.component';
import { AdditionalToolsComponent } from './components/additional-tools/additional-tools.component';
import { LineToolsComponent } from './components/additional-tools/line-tools/line-tools.component';
import { LayerToolsComponent } from './components/additional-tools/layer-tools/layer-tools.component';
import { InputDialogComponent } from './components/dialogs/input-dialog/input-dialog.component';
import { GroupToolsComponent } from './components/additional-tools/group-tools/group-tools.component';
import { ColorSketchModule } from 'ngx-color/sketch';
import { RectToolsComponent } from './components/additional-tools/rect-tools/rect-tools.component';
import { CircleToolsComponent } from './components/additional-tools/circle-tools/circle-tools.component';
import { ArcToolsComponent } from './components/additional-tools/arc-tools/arc-tools.component';
import { CommonPropertiesComponent } from './components/additional-tools/common-properties/common-properties.component';
import { EditingToolsComponent } from './components/additional-tools/editing-tools/editing-tools.component';
import { EditingCommonPropertiesToolsComponent } from './components/additional-tools/editing-common-properties-tools/editing-common-properties-tools.component';
import { FillToolsComponent } from './components/additional-tools/fill-tools/fill-tools.component';
import { SymbolLibraryToolsComponent } from './components/additional-tools/symbol-library-tools/symbol-library-tools.component';
import { TextToolsComponent } from './components/additional-tools/text-tools/text-tools.component';
import { ConfirmationDialogComponent } from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { LineIconToolsComponent } from './components/additional-tools/line-icon-tools/line-icon-tools.component';
import { LineControlPointsToolsComponent } from './components/additional-tools/line-control-points-tools/line-control-points-tools.component';
import { ObjectsOrderToolsComponent } from './components/additional-tools/objects-order-tools/objects-order-tools.component';
import { GraphicObjectsComponent } from './components/additional-tools/graphic-objects/graphic-objects.component';
import { LoginComponent } from './components/authorization/login/login.component';
import { RegistrationComponent } from './components/authorization/registration/registration.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ActivationCodeInputComponent } from './components/authorization/activation-code-input/activation-code-input.component';
import { ProjectsListComponent } from './components/projects/projects-list/projects-list.component';
import { ProjectDataComponent } from './components/projects/project-data/project-data.component';
import { ControlMessagesComponent } from './components/notifications/control-messages/control-messages.component';
import { FillColorsToolsComponent } from './components/additional-tools/fill-colors-tools/fill-colors-tools.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SpecialArrowToolsComponent } from './components/additional-tools/special-arrow-tools/special-arrow-tools.component';
import { PrintSetupDialogComponent } from './components/dialogs/print-setup-dialog/print-setup-dialog.component';
import { WallOpeningsComponent } from './components/additional-tools/wall-openings/wall-openings.component';
import { AccountActivationComponent } from './components/authorization/account-activation/account-activation.component';
import { ExportSetupDialogComponent } from './components/dialogs/export-setup-dialog/export-setup-dialog.component';
import { LegendComponent } from './components/additional-tools/legend/legend.component';
import { PlanCloseDialogComponent } from './components/dialogs/plan-close-dialog/plan-close-dialog.component';
import { NotificationComponent } from './components/notification/notification.component';
import { LicenseAgreementComponent } from './components/license-agreement/license-agreement.component';
import { VideoComponent } from './components/video/video.component';
import { TutorialComponent } from './components/tutorial/tutorial.component';
import { FaqComponent } from './components/faq/faq.component';
import { DocumentationComponent } from './components/documentation/documentation.component';
import { FooterComponent } from './components/footer/footer.component';
import { ProjectChoiceComponent } from './components/dialogs/project-choice/project-choice.component';
import { WallToolsComponent } from './components/additional-tools/wall-tools/wall-tools.component';
import { ContactComponent } from './components/contact/contact.component';
import { ForgotPasswordComponent } from './components/authorization/forgot-password/forgot-password.component';
import { GridSettingsComponent } from './components/dialogs/grid-settings/grid-settings.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ImageDialogComponent } from './components/dialogs/image-dialog/image-dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { KnowledgeComponent } from './components/knowledge/knowledge.component';
import { FrameToolsComponent } from './components/additional-tools/frame-tools/frame-tools.component';
import { FocusRemover } from './helpers/focus-remover.directive';
import { ImageCropperComponent } from 'ngx-image-cropper';

@NgModule({ declarations: [
        AppComponent,
        StyleguideComponent,
        InfoDialogComponent,
        EditorComponent,
        NavComponent,
        HorizontalControlsComponent,
        VerticalControlsComponent,
        FileImportDialogComponent,
        FileControlsComponent,
        GeomPrimitivesControlsComponent,
        ViewerControlsComponent,
        PrintControlsComponent,
        EditingControlsComponent,
        VertViewerControlsComponent,
        VertEditingControlsComponent,
        InsertingControlsComponent,
        AdditionalToolsComponent,
        LineToolsComponent,
        LayerToolsComponent,
        InputDialogComponent,
        GroupToolsComponent,
        RectToolsComponent,
        CircleToolsComponent,
        ArcToolsComponent,
        CommonPropertiesComponent,
        EditingToolsComponent,
        EditingCommonPropertiesToolsComponent,
        FillToolsComponent,
        SymbolLibraryToolsComponent,
        TextToolsComponent,
        ConfirmationDialogComponent,
        PrintSetupDialogComponent,
        LineIconToolsComponent,
        LineControlPointsToolsComponent,
        ObjectsOrderToolsComponent,
        GraphicObjectsComponent,
        LoginComponent,
        RegistrationComponent,
        ProfileComponent,
        ActivationCodeInputComponent,
        ProjectsListComponent,
        ProjectDataComponent,
        ControlMessagesComponent,
        FillColorsToolsComponent,
        SettingsComponent,
        SpecialArrowToolsComponent,
        WallOpeningsComponent,
        AccountActivationComponent,
        ExportSetupDialogComponent,
        LegendComponent,
        PlanCloseDialogComponent,
        NotificationComponent,
        LicenseAgreementComponent,
        VideoComponent,
        TutorialComponent,
        FaqComponent,
        DocumentationComponent,
        FooterComponent,
        ProjectChoiceComponent,
        WallToolsComponent,
        ContactComponent,
        ForgotPasswordComponent,
        GridSettingsComponent,
        ImageDialogComponent,
        KnowledgeComponent,
        FrameToolsComponent,
        FocusRemover
    ],
    bootstrap: [AppComponent],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        FontAwesomeModule,
        ColorSketchModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        PdfViewerModule,
        ImageCropperComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        MockBackendProvider,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

