import { Component, OnInit } from '@angular/core';
import { EditorControl } from 'src/app/models/controls';
import { ControlsService, DataService, ViewerOperationsService, ViewerService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { AdditionalToolsService } from 'src/app/services/status-and-control-services/additional-tools/additional-tools.service';
import { GridSettingsComponent } from 'src/app/components/dialogs/grid-settings/grid-settings.component';
import { MatDialog } from '@angular/material/dialog';
import { CanvasDimensionsService } from 'src/app/services/status-and-control-services/canvas-dimensions/canvas-dimensions.service';
import { Point } from 'src/app/models';
import { NotificationService } from 'src/app/services/status-and-control-services/notification/notification.service';
import { ModesService } from 'src/app/services/status-and-control-services/modes/modes.service';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { ControlActiveSettingEnum } from 'src/app/models/enums/control-active-setting.enum';

@Component({
  selector: 'app-hor-viewer-controls',
  templateUrl: './viewer-controls.component.html',
  styleUrls: ['./viewer-controls.component.scss']
})
export class ViewerControlsComponent implements OnInit {
  currentScale: number;
  //scaleSubscription: Subscription;
  scaleWithMouseSubscription: Subscription;
  controlItems: Array<EditorControl>
  constructor(
    private controlsService: ControlsService,
    private viewerService: ViewerService,
    private viewerOperationsService: ViewerOperationsService,
    private dataService: DataService,
    private additionalToolsService: AdditionalToolsService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private modesService: ModesService
  ) {
   /*  this.currentScale = this.viewerService.scale * 100;
    this.scaleSubscription = this.viewerService.scale$.subscribe(
      scale => {
        this.currentScale = scale * 100
      }
    );
    this.scaleWithMouseSubscription = this.viewerService.scaleWithMouse$.subscribe(
      data => {
        this.currentScale = data ? data.scale * 100 : this.currentScale;
      }
    );  */
    this.controlItems = this.controlsService.controls.horizontalControls.viewerControlsList;
  }

  ngOnInit(): void {
  }

  onButtonClick(controlsItem: EditorControl) {
    switch(controlsItem.id) {
      case 2: this.scaleUp(); break;
      case 3: this.scaleDown(); break;
      case 4: {
        this.viewerOperationsService.translation = new Point(0,0);
        this.viewerOperationsService.translated = false;
        this.viewerService.setScale(1); break
      };
      case 11: this.toggleRuler(); break;
      case 7: this.manageGridSettings(); break;
      case 18: {
        this.toggleAdditionalTools("layers");
        break;
      }
      default: return
      }
  }

  fitGeometryInViewport() {
    this.viewerOperationsService.resetFittingParams();
    this.viewerOperationsService.fitGeometryInViewport(this.dataService.geometricalData);
    this.dataService.emitChanges();
  }

  variableFromComponent(controlItem: EditorControl) {
    return this[controlItem.varName];
  }

  scaleUp() {
    if(this.modesService.mode === ActiveMode.moveMode) {
      this.viewerService.scaleUp();
    }
    else {
      this.notificationService.openNotification("CHANGE_MODE");
    }
  }

  scaleDown() {
    if(this.modesService.mode === ActiveMode.moveMode) {
      this.viewerService.scaleDown();
    }
    else {
      this.notificationService.openNotification("CHANGE_MODE");
    }
  }

  toggleAdditionalTools(componentName) {
    this.additionalToolsService.toggleAdditionalTools(componentName);
  }

  toggleRuler() {
    this.viewerService.toggleRuler();
  }

  manageGridSettings() {
    let dialogRefPass = this.dialog.open(GridSettingsComponent);
    dialogRefPass.afterClosed().subscribe(result => {
      if(result) {
        this.viewerService.setScale(this.viewerService.scale);
      }
    });
  }

  controlActive(activeOn) {
    let result = this.viewerOperationsService.controlActive(activeOn);
    return result;
  }

}
