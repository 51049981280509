import { Component, OnInit } from '@angular/core';
import { EventsPropagationService } from 'src/app/services/status-and-control-services/events-propagation/events-propagation.service';
import { ModesService } from 'src/app/services/status-and-control-services/modes/modes.service';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { ColorService, DataService, ViewerOperationsService } from 'src/app/services';
import { EPImage } from 'src/app/models/geometries/ep-image.model';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { InputDialogComponent } from '../../dialogs/input-dialog/input-dialog.component';
import { Color, Point } from 'src/app/models';
import { CanvasDimensionsService } from 'src/app/services/status-and-control-services/canvas-dimensions/canvas-dimensions.service';
import { GeometricType } from 'src/app/models/enums/geometric-types.enum';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit {
  description: string;
  columns: number;
  columnWidth: number;
  geometry: any;
  geometrySubscription: Subscription;
  legendElements: Array<EPImage>;
  strokeColor: string;
  frameColors: Array<string>;
  withFrame: boolean;
  northArrow: boolean;
  iconWidth: number;

  constructor(
    private eventsPropagationService: EventsPropagationService,
    private modesService: ModesService,
    private dataService: DataService,
    private colorService: ColorService,
    public dialog: MatDialog,
    public viewerOperationsService: ViewerOperationsService,
    public canvasDimensionsService: CanvasDimensionsService

  ) {
    this.description = "Legende";
    this.columnWidth = 120;
    this.iconWidth = 50;
    this.columns = 2;
    this.withFrame = false;
    this.northArrow = false;
    this.geometry = this.dataService.getGeometricalData();
    this.legendElements = new Array<EPImage>();
    this.frameColors = this.colorService.legendFrameColors.map(a => a.colorHex);
    this.geometrySubscription = this.dataService.geometricalData$.subscribe( data => {
      this.getLegendElements();
    })
  }

  ngOnInit(): void {
  }

  onBlur() {
    this.eventsPropagationService.enableEvents();
  }

  getLegendElements() {
    let legendData: any = this.dataService.getGeometricalDataFromLayer("Legende");
    if(legendData && legendData[0] && legendData[0].geometricType === GeometricType.Legend) {
      this.legendElements = legendData[0].images;
      this.columnWidth = legendData[0].columnWidth;
      this.columns = legendData[0].columns;
      this.description = legendData[0].title;
      this.withFrame = legendData[0].withFrame;
    }
  }

  toggleFrame() {
    this.withFrame = !this.withFrame;
    if(this.withFrame) {
      this.strokeColor = "#008e5e";
    }
    else {
      this.strokeColor = null;
    }
  }
  toggleNorthArrow() {
    this.northArrow = !this.northArrow;
  }

  isInsertMode(): boolean {
    return this.modesService.mode === ActiveMode.insertMode;
  }

  isEditMode(): boolean {
    return this.modesService.mode === ActiveMode.editMode;
  }

  onFocus() {
    this.eventsPropagationService.disableEvents();
  }

  generateLegend() {
    let origin = new Point(
      this.viewerOperationsService.translation.x + this.canvasDimensionsService.dimensions.width/3,
      this.viewerOperationsService.translation.y + this.canvasDimensionsService.dimensions.height/4
    );
    let legendData = {
      origin: origin,
      title: this.description,
      columns: this.columns,
      columnWidth: this.columnWidth,
      iconWidth: this.iconWidth,
      withFrame: this.withFrame,
      northArrow: this.northArrow
    }
    let stroke = new Color(this.strokeColor);
    this.dataService.createSpecialLayer("legend",legendData,stroke).subscribe(data => {});
  }

  rearrangeLegendElements(event) {
    this.dataService.rearrangeLegendImages(event)
  }

  updateCurrentFrameColor(event) {
    this.strokeColor = event.color.hex;
  }

  editLegendElementTitle(element,index) {
      let dialogRefPass = this.dialog.open(InputDialogComponent, {
        data: {
          value: element.title,
          valueName: "Element name",
          buttonText: "Änderungen speichern",
          withFrame: this.withFrame
        }
      },);
      dialogRefPass.afterClosed().subscribe(result => {
        if(result && result.elementName) {
          let legendData: any = this.dataService.getGeometricalDataFromLayer("Legende");
          if(legendData && legendData[0]) {
            legendData[0].images[index].title = result.elementName;
            this.dataService.updateLegend(legendData[0]);
          }
        }
      });
  }

  removeLegendElement(index) {
    let legendData: any = this.dataService.getGeometricalDataFromLayer("Legende");
    if(legendData && legendData[0]) {
      legendData[0].images.splice(index,1);
      this.dataService.updateLegend(legendData[0]);
    }
  }

}
