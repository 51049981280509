import { Injectable } from '@angular/core';
import { DataService } from '../../operational-services/data/data.service';
import { LayerService } from '../../operational-services/layer/layer.service';
import { Extrema } from 'src/app/models/extrema.model';
import { Rectangle } from 'src/app/models/geometries/rectangle.model';
import { ColorService } from '../../operational-services/color/color.service';
import { ColorEnum } from 'src/app/models/enums/color.enum';
import { GeometricType } from 'src/app/models/enums/geometric-types.enum';
import { Geometry } from 'src/app/models/geometries/geometry.model';
import { Point } from 'src/app/models/point.model';

@Injectable({
  providedIn: 'root'
})
export class SelectionService {
  activeElement: string;
  draggingImage: any;
  selectingRect: Rectangle;
  constructor(
    private dataService: DataService,
    private layerService: LayerService,
    private  colorService: ColorService
  ) {
    this.activeElement = "";
    this.draggingImage = null;
    this.selectingRect = null;
  }

  selectedElementsExist(): boolean {
    return this.dataService.selectedElementsInLayerByNameExist(this.layerService.activeLayer);
  }

  multipleObjectsSelected(): boolean {
    return this.dataService.multipleObjectsInLayerByNameSelected(this.layerService.activeLayer);
  }

  selectedArc() {
    if(!this.multipleObjectsSelected()) {
      return this.dataService.selectedType(this.layerService.activeLayer,GeometricType.Arc);
    }
    else {
      return null
    }
  }

  selectedCircle() {
    if(!this.multipleObjectsSelected()) {
      return this.dataService.selectedType(this.layerService.activeLayer,GeometricType.Circle);
    }
    else {
      return null
    }
  }

  selectedText() {
    if(!this.multipleObjectsSelected()) {
      return this.dataService.selectedType(this.layerService.activeLayer,GeometricType.Text);
    }
    else {
      return null
    }
  }

  selectedRect() {
    if(!this.multipleObjectsSelected()) {
      return this.dataService.selectedType(this.layerService.activeLayer,GeometricType.Rectangle);
    }
    else {
      return null
    }
  }

  elementsWithFillSelected(): boolean {
    return this.dataService.elementsWithFillSelected(this.layerService.activeLayer);
  }

  setActiveElement(name: string) {
    this.activeElement = name;
  }

  addElementToSelection(groupMode: boolean = true) {
    this.dataService.addElementToSelection(this.layerService.activeLayer, this.activeElement, groupMode);
  }

  resetSelection() {
    this.dataService.resetSelection(this.layerService.activeLayer);
    this.selectingRect = null;
  }

  resetActiveElement() {
    this.dataService.resetIntersection(this.layerService.activeLayer,this.activeElement);
    this.activeElement = "";
  }

  selectionIncludesGeometry(name: string) {
    let layerIndex = this.dataService.findLayerIndex(this.layerService.activeLayer);
    let geometryIndex = this.dataService.findGeometryIndexInLayerByName(this.layerService.activeLayer, name)
    if (layerIndex > -1 && geometryIndex > -1) {
      return this.dataService.geometricalData[layerIndex].geometry[geometryIndex].selected;
    }
    else {
      return false;
    }
  }

  removeActiveElementFromSelection() {
    this.dataService.removeActiveElementFromSelection(this.layerService.activeLayer, this.activeElement)
  }

  getSelectedRotatableGeometry() {
    return this.dataService.rotatableGeometryInSelection(this.layerService.activeLayer)
  }

  oneElementOfLineTypeSelected() {
    return this.dataService.oneElementOfLineTypeSelectedInLayer(this.layerService.activeLayer)
  }

  oneElementOfOpenLineTypeSelected() {
    return this.dataService.oneElementOfOpenLineTypeSelectedInLayer(this.layerService.activeLayer)
  }

  singleLineSelected() {
    return this.dataService.singleLineSelectedInLayer(this.layerService.activeLayer)
  }

  oneTextSelected() {
    return this.dataService.singleTextSelectedInLayer(this.layerService.activeLayer)
  }

  getSingleSelectedGeometry(): Geometry {
    if(!this.multipleObjectsSelected()) {
      return this.dataService.getSingleSelectedGeometryFromLayer(this.layerService.activeLayer);
    }
  }

  getSelectionRect() {
     let selectionExtrema: Extrema = this.dataService.getSelectionExtrema(this.layerService.activeLayer);
     let selctedRotatable: Rectangle = this.getSelectedRotatableGeometry();
     let multiple: boolean = this.multipleObjectsSelected();
     if(selectionExtrema && (selectionExtrema.minX || selectionExtrema.maxX)) {
       let selectionRect: Rectangle = 
        new Rectangle({
          origin: {
            x: (selctedRotatable &&!multiple) ? selctedRotatable.origin.x : selectionExtrema.minX,
            y: (selctedRotatable &&!multiple) ? selctedRotatable.origin.y : selectionExtrema.minY
          },
          width: (selctedRotatable &&!multiple) ? selctedRotatable.width : selectionExtrema.maxX - selectionExtrema.minX,
          height: (selctedRotatable &&!multiple) ? selctedRotatable.height : selectionExtrema.maxY - selectionExtrema.minY,
          strokeColor: this.colorService.hexToRgb(ColorEnum.selectionFrameColor),
        })
        selectionRect.rotation = (selctedRotatable && !multiple) ? selctedRotatable.rotation : 0;
        return selectionRect;
     }
     else {
      return null;
     }
  }

  startRectSelection(mousePosition: Point) {
    this.selectingRect = new Rectangle({ origin: {...mousePosition}, width: 0, height: 0, geometryProperties: {strokeColor: ColorEnum.selectionFrameColor}});
  }

  dragRectSelection(mousePosition: Point) {
    this.selectingRect.width = mousePosition.x - this.selectingRect.origin.x;
    this.selectingRect.height = mousePosition.y - this.selectingRect.origin.y;
  }

  selectWithRect(shiftKey: boolean) {
    if(this.selectingRect.width < 0) {
      this.selectingRect.origin.x += this.selectingRect.width;
      this.selectingRect.width *= -1; 
    }
    if(this.selectingRect.height < 0) {
      this.selectingRect.origin.y += this.selectingRect.height;
      this.selectingRect.height *= -1; 
    }
    this.dataService.selectInsideBoundaries(this.layerService.activeLayer, this.selectingRect, shiftKey);
  }

  canRectSelect() {
    return this.selectingRect && this.selectingRect.width && this.selectingRect.height;
  }

  setDraggingImage(imagePath: string, size, title) {
    this.draggingImage = {path: imagePath, size: size , title: title};
  }

  resetDraggingImage() {
    this.draggingImage = null;
  }

  getGeometricalDataFromActiveLayer(limit: number = null) {
    return this.dataService.getGeometricalDataFromLayer(this.layerService.activeLayer,limit);
  }
}
