import { Address } from './address.model';

export class Client {
    clientName: string;
    builderName: string;
    responsibleName: string;
    address: Address;

    constructor() {
        this.address = new Address();
    }
}