import { Injectable } from '@angular/core';

interface CanvasDimensions {
  width: number;
  height: number
}
@Injectable({
  providedIn: 'root'
})
export class CanvasDimensionsService {
  private canvasDimensions: CanvasDimensions;
  constructor() {
    this.setCanvasDimensions();
  }

  setCanvasDimensions() {
    this.canvasDimensions = {
      width: window.innerWidth - 60,
      height: window.innerHeight - 130
    };
  }

  get dimensions(): CanvasDimensions {
    return this.canvasDimensions;
  }

  scaledCanvasDimensions(scale: number): CanvasDimensions {
    return { width: ((this.dimensions.width + 60 )*scale) - 60, height: ((this.dimensions.height + 130)*scale) - 130};
  }
}
