import { Rectangle } from './rectangle.model';
import { Point } from '../point.model';
import { ColorEnum } from '../enums/color.enum';
import { EPImage } from './ep-image.model';
import { VertexPosition } from '../position.model';

export class Legend extends Rectangle {
    columns: number;
    images: Array<EPImage>;
    title: string;
    columnWidth: number;
    iconWidth: number;
    withFrame: boolean;
    forExport: any[];
    titleOrigin: Point;
    fontSize: number;

    constructor(data) {
        super(data);
        this.images = new Array<EPImage>();
        this.images = data.images;
        this.title = data.title ? data.title : "";
        this.columns = data.columns ? data.columns : 1;
        this.fontSize = 12;
        this.geometryProperties.thickness = 2;
        this.columnWidth = data.columnWidth;
        this.iconWidth = data.iconWidth;
        this.withFrame = data.withFrame;
        this.forExport = new Array<any>();
    }

    override draw(context: CanvasRenderingContext2D, scale, color, canvas, fillColor = null) {
        let elementsInOneColumn = this.countElementsInOneColumn();
        this.forExport = new Array<any>();
        for(let i = 0; i < this.images.length; i += elementsInOneColumn) {
            for(let j = i; j < i + elementsInOneColumn && j < this.images.length; j++) {
                let imageObj = new Image();
                imageObj.src = this.images[j].imagePath;
                context.save();
                let proportionalImageHeight = this.iconWidth/this.images[j].width * this.images[j].height;
                let mult = 1;
                if(proportionalImageHeight > this.iconWidth) {
                    mult = this.iconWidth/proportionalImageHeight;
                    proportionalImageHeight = this.iconWidth;
                }
                let translation = new Point(this.origin.x + i/elementsInOneColumn * this.columnWidth, this.origin.y + this.fontSize*4   + (this.iconWidth*1.4 + this.fontSize*2)*(j - i));
                this.images[j].origin = new VertexPosition({x: translation.x + this.columnWidth/2 - this.images[j].width/2, y: translation.y + this.iconWidth/2 - this.images[j].height/2});
                this.images[j].width = this.iconWidth*mult;
                this.images[j].height = proportionalImageHeight;
                context.translate(translation.x * scale, translation.y * scale);
                context.drawImage(imageObj, this.columnWidth/2 - this.images[j].width/2, this.iconWidth/2 - this.images[j].height/2, this.images[j].width, this.images[j].height);
                context.fillStyle = ColorEnum.fontDefault;
                context.font = this.fontSize.toString() + "px Arial";
                context.textAlign = 'left';
                this.wrapText(context, this.images[j].title, (0) * scale/2, (this.iconWidth * 1.3) * scale, (this.columnWidth/1.1) * scale, this.fontSize * scale, translation);
                context.restore();
            }
        }

        if(this.withFrame) {
            super.draw(context, scale, color, canvas, fillColor);
            /* context.setTransform(1, 0, 0, 1, this.origin.x * scale + this.width/2*scale, this.origin.y * scale + this.height/2*scale);
            context.beginPath();
            context.rect(-this.width/2*scale, -this.height/2*scale, this.width * scale, this.height * scale);
            context.strokeStyle = color ? color : ColorEnum.default;
            context.stroke();
            context.restore(); */
        }

        context.save();
        context.fillStyle = ColorEnum.fontDefault;
        context.font = Math.round(this.fontSize*1.7).toString() + "px Arial";
        context.textAlign = 'center';
        var metrics = context.measureText(this.title);
        context.translate(this.origin.x * scale, this.origin.y * scale);
        context.fillText(this.title, this.width/2, this.fontSize*2);
        this.titleOrigin = new Point(this.origin.x + this.width/2 - metrics.width/2, this.origin.y + this.fontSize);
        context.restore();
    }

    wrapText(context, text, x, y, maxWidth, lineHeight, translation) {
        let initailY = y;
        var words = text.split(' ');
        var line = '';

        for(var n = 0; n < words.length; n++) {
          var testLine = line + words[n] + ' ';
          var metrics = context.measureText(testLine);
          var testWidth = metrics.width;
          if (testWidth > maxWidth && n > 0) {
              var lineWidth = context.measureText(line).width;
            this.forExport.push({pos: {x: translation.x + x - lineWidth/2 + this.columnWidth/2, y: translation.y + y - 10 }, text: line, width: testWidth});
            context.fillText(line, x - lineWidth/2 + this.columnWidth/2 , y);
            line = words[n] + ' ';
            y += lineHeight;
          }
          else {
            line = testLine;
          }
        }
        var metrics = context.measureText(line);
        var testWidth = metrics.width;
        this.forExport.push({pos: {x: translation.x + x - testWidth/2 + this.columnWidth/2, y: translation.y + y - 10 }, text: line});
        context.fillText(line, x - testWidth/2 + this.columnWidth/2 , y);
        if(initailY < y) {
            return y;
        }
    }

    countElementsInOneColumn() {
        if(this.images.length < this.columns) {
            return 1;
        }
        else {
            let elementsInColumn = this.images.length / this.columns;
            var intElementsInColumn = Math.round(elementsInColumn);
            return intElementsInColumn;
        }
    }

    override scale(mult: Point, selectionRect: Rectangle,scaleText: any = false) {
        super.scale(mult, selectionRect);
        this.iconWidth *= mult.x;
        this.columnWidth *= mult.x;
        if(scaleText) {
            //this.geometryProperties.thickness *= mult.x;
            this.fontSize = this.fontSize*mult.x;
        }
    }

}
