import { Vertex } from '../vertex.model';
import { GeometryProperties } from './geometry-properties.model';
import { Polyline } from './polyline.model';

export class Polyligon extends Polyline {
    override entityName: string;
    override geometricType: number;
    override layerName: string;
    override geometryProperties: GeometryProperties;

    override vertexes: Array<Vertex>;

    constructor(data) {
        super(data);
        this.isClosed = true;
    }

    override draw(context: CanvasRenderingContext2D, scale, color, canvas, fillColor = null) {
        super.draw(context, scale, color, canvas, fillColor);
        if(fillColor) {
            context.save();
            context.fillStyle = fillColor;
            context.fill();
            context.restore();
        }
    }
}
