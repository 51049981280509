<div class="additional-tools-element" *ngIf="selectionExists()">
    <app-text-tools></app-text-tools>
    <app-arc-tools></app-arc-tools>
    <app-circle-tools></app-circle-tools>
    <app-rect-tools></app-rect-tools>
    <app-line-control-points-tools></app-line-control-points-tools>
    <app-line-icon-tools></app-line-icon-tools>
    <app-editing-common-properties-tools></app-editing-common-properties-tools>
    <div *ngIf="elementsWithFillSelected()">
        <app-fill-tools ></app-fill-tools>
    </div>
</div>