
import { VertexPosition } from "./position.model"

export class Vertex {
    position: VertexPosition;
    startWidth?: number;
    endWidth?: number;
    bulge?: number

    constructor(data) {
        Object.keys(data).forEach(key => {
            if(key === "Position") {
                this.position = new VertexPosition(data[key])
            }
            else {
                this[key] = data[key]
            }
        })
    }
}