<div class="w-100" xLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
    <div ngClass.gt-sm="w-25"></div>
    <div ngClass.xs="p-lr" ngClass.sm="p-lr-2" class="p-t-3 full-height-container" fxFlex fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px">
        <div fxLayout="column" fxLayoutAlign="start center" class="w-100" *ngFor="let dataElement of data; let i = index">
            <h2 fxLayout="column" fxLayoutAlign="center center" *ngIf="dataElement.type === 'title'">{{dataElement.content}}</h2>
            <h3 class="w-50" *ngIf="dataElement.type === 'heading'">{{dataElement.content}}</h3>
            <div class="w-50" *ngIf="dataElement.type === 'link_section'" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px">
                <a *ngFor="let linkElement of dataElement.contents" href="{{linkElement.link}}" class="ep-link p-b" download="{{linkElement.name}}.{{linkElement.type}}">{{linkElement.name}}</a>
            </div>
        </div>
    </div>
    <div ngClass.gt-sm="w-25"></div>
</div>