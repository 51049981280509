import { EditorControl } from './editor-control.model';

export class VerticalControls {
    geomPrimitivesControlsList:Array <EditorControl>;
    viewerControlsList: Array <EditorControl>;
    insertControlsList: Array <EditorControl>;
    editingControlsList: Array <EditorControl>

    constructor() {
        this.geomPrimitivesControlsList= new Array <EditorControl>();
        this.viewerControlsList= new Array <EditorControl>();
        this.insertControlsList= new Array <EditorControl>();
        this.editingControlsList= new Array <EditorControl>();
    }
}