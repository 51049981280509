import { Injectable } from '@angular/core';
import { Layer, Point, VertexPosition, Vertex, Polyline, Color } from 'src/app/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { GeometryFactory } from 'src/app/models/geometries/geometry.factory.model';
import { GeometricType } from 'src/app/models/enums/geometric-types.enum';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { Extrema } from 'src/app/models/extrema.model';
import { LineControlsEditingMode } from 'src/app/models/enums/line-controls-editing-mode.enum';
import { Line } from 'src/app/models/geometries/line.model';
import { Geometry } from 'src/app/models/geometries/geometry.model';
import { WallOpening } from 'src/app/models/geometries/wall-opening.model';
import { EPImage } from 'src/app/models/geometries/ep-image.model';
import { NotificationService } from '../../status-and-control-services/notification/notification.service';
import { CanvasDimensionsService } from '../../status-and-control-services/canvas-dimensions/canvas-dimensions.service';
import { Rectangle } from 'src/app/models/geometries/rectangle.model';
import * as doorTypesList from "../../../../assets/controls-lists/door-type-list.json";
import { Arc } from 'src/app/models/geometries/arc.model';
import { Circle } from 'src/app/models/geometries/circle.model';
import { Legend } from 'src/app/models/geometries/legend.model';
import { Frame } from 'src/app/models/geometries/frame.model';
import { ColorService } from '../color/color.service';
import { LineIconEnum } from 'src/app/models/enums/line-icon.enum';
import { EPText } from 'src/app/models/geometries/text.model';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  selectedVertex: number;
  selectedVertex$: Observable<number>;
  private _selectedVertexSource: BehaviorSubject<number>;
  geometricalData: Array<Layer>;
  deletedEntityNames: Array<string>;
  geometricalData$: Observable<Array<Layer>>;
  private _geometricalDataSource: BehaviorSubject<Array<Layer>>;
  geometryBuffer: Array<Geometry>;
  selection: boolean = false;

  newOrUpdatedEntities: Array<Geometry>;


  constructor(
    private notificationService: NotificationService,
    private canvasDimensionsService: CanvasDimensionsService,
    private colorService: ColorService
  ) {
    this.selectedVertex = null;
    this.initializeGeometryDataLists();
    this._selectedVertexSource = new BehaviorSubject(this.selectedVertex);
    this.selectedVertex$ = this._selectedVertexSource.asObservable();
    this._geometricalDataSource = new BehaviorSubject(this.geometricalData);
    this.geometricalData$ = this._geometricalDataSource.asObservable();
  }

  public get layerNames(): string[] {
    return this.geometricalData.map(layer => layer.layerName);
  }

  initializeGeometryDataLists(exceptions: string[] = null) {
    if(exceptions && exceptions.length) {
      for(let i = 0; i < this.geometricalData.length; i++) {
        if(!exceptions.includes(this.geometricalData[i].layerName)){
          this.geometricalData.splice(i,1);
          i--;
        }
      }
    }
    else {
      this.geometricalData = new Array<Layer>(0);
    }
    this.deletedEntityNames = new Array<string>(0);
    this.newOrUpdatedEntities = new Array<Geometry>(0);
  }

  initGeometricalData(exceptions: string[] = null) {
    this.initializeGeometryDataLists(exceptions);
    this.emitChanges();
  }

  setGeometricalData(data) {
    this.geometricalData = data;
    this._geometricalDataSource.next(this.geometricalData);
  }

  initGeometricalDataFromResponse(layers, preview = false, drawingToMap = null) {
    let amount = 0;
    let perLayer = [];
    let existingDrawing = drawingToMap ? drawingToMap.geometry : null;
    let obs: Observable<any> = new Observable(observer => {
      let geometryFactory = new GeometryFactory();
      if(layers && layers.length) {
      layers.forEach(element => {
        let newLayer = new Layer();
        let layerAmount = 0;
        newLayer.layerName = (typeof element !== "string") ? element.layerName : element;
        Object.keys(element).forEach(key => {
          if(!preview) {
            if(key !== "layerName") {
              element[key].forEach(geometryData => {
                if(geometryData.geometricEntities && geometryData.geometricEntities.length > 0) {
                  geometryData.geometricEntities.forEach(geometricEntity => {
                    let overridingGeom = null
                    if(drawingToMap) {
                      let layerIndexInLocalArray = this.findLayerIndexInArray(existingDrawing,element.layerName);
                      if(layerIndexInLocalArray > -1) {
                        let geomIndex = this.findGeometryIndexInLayer(existingDrawing[layerIndexInLocalArray],geometricEntity.entityTag.replace("tag_",""));
                        if(geomIndex > -1 && (existingDrawing[layerIndexInLocalArray].geometry[geomIndex].geometricType === GeometricType.Text || existingDrawing[layerIndexInLocalArray].geometry[geomIndex].geometricType === GeometricType.Legend)) {
                          overridingGeom = existingDrawing[layerIndexInLocalArray].geometry[geomIndex];
                          this.removeEntityToNewUpdateArray(geometricEntity);
                          this.deletedEntityNames.push(geometricEntity.entityName);
                          geometricEntity = overridingGeom;
                        }
                      }
                    }
                    if(geometricEntity.isFilledColor && geometricEntity.color) {
                      geometricEntity.fillColor = geometricEntity.color;
                    }
                    else if(geometricEntity.color){
                      geometricEntity.strokeColor = geometricEntity.color;
                    }
                    let newGeometry = geometryFactory.createGeometry(geometricEntity);

                    if(newGeometry && (newGeometry.geometricType === GeometricType.Image ? this.isValidGeometry(newGeometry,newGeometry.title) : true)) {
                      newGeometry.mirrorToXAxis(0);
                    if(newGeometry && newGeometry.geometricType !== GeometricType.Line) {
                      if(overridingGeom) {
                        if(newGeometry.geometricType === GeometricType.Text || newGeometry.geometricType === GeometricType.Legend) {
                          newGeometry.scale({x:drawingToMap.scale,y:drawingToMap.scale},null,true);
                        }
                        newGeometry.mirrorToXAxis(0);
                      }
                      newLayer.geometry.push(newGeometry);
                      layerAmount++;
                      amount++;
                    }
                    else if(newGeometry && this.isValidGeometry(newGeometry)) {
                      newLayer.geometry.push(newGeometry);
                      layerAmount++;
                      amount++;
                    }
                    }
                  });
                }
              });
            }
          }
        })
        this.moveElementsToForeground(newLayer.geometry);
        let overridinglayerIndex = this.findLayerIndex(newLayer.layerName);
        if(overridinglayerIndex > -1) {
          this.geometricalData[overridinglayerIndex] = newLayer;
        }
        else {
          this.geometricalData.push(newLayer);
        }
        perLayer.push(layerAmount);
      });
      let max = perLayer.length ? perLayer.reduce((a, b)=>Math.max(a, b)) : 0;
      observer.next({data: this.geometricalData, amount: {perLayer: max, total: amount}});
      }
      else{
        observer.next(null);
      }
    })
    return obs;
  }

  moveElementsToForeground(layer: Geometry[]) {
    layer.sort((a, b) => ((a.geometricType === GeometricType.Text || a.geometricType === GeometricType.Image || a.geometricType === GeometricType.Arc) ? 1 : (b.geometricType === GeometricType.Text || b.geometricType === GeometricType.Image || b.geometricType === GeometricType.Arc) ? -1 : 0));
  };


  initGeometricalDataFromDataBase(layers) {
    let obs: Observable<any> = new Observable(observer => {
      this.initializeGeometryDataLists();
      let geometryFactory = new GeometryFactory();
      if(layers && layers.length) {
      layers.forEach(element => {
        let newLayer = new Layer();
        Object.keys(element).forEach(key => {
          newLayer.layerName = element.layerName;
          if(key === "geometry") {
            element[key].forEach(geometricEntity => {
                  if(geometricEntity.color) {
                    geometricEntity.strokeColor = geometricEntity.color;
                  }
                  let newGeometry = geometryFactory.createGeometry(geometricEntity);
                  if(newGeometry && newGeometry.geometricType !== GeometricType.Line) {
                    newLayer.geometry.push(newGeometry);
                    //this.setEntityToNewUpdateArray(newGeometry);
                  }
                  else if(newGeometry && this.isValidGeometry(newGeometry)) {
                    newLayer.geometry.push(newGeometry);
                    //this.setEntityToNewUpdateArray(newGeometry);
                  }
                });
          }
          else {
            newLayer[key] = element[key];
          }
        })
        this.geometricalData.push(newLayer)
      });
      observer.next(this.geometricalData);
      }
    })
    return obs;
  }

  getGeometricalData(): Array<Layer> {
    return this.geometricalData;
  }

  geometryExists() {
    for(let layer of this.geometricalData) {
      if(layer.geometry && layer.geometry.length) return true
    }
    return false;
  }

  setEntityToNewUpdateArray(newGeometry){
    let index = this.newOrUpdatedEntities.findIndex(x=> x.entityName === newGeometry.entityName);
    if(index > -1){
      this.newOrUpdatedEntities.splice(index, 1);
    }
    this.newOrUpdatedEntities.push(newGeometry);
  }

  removeEntityToNewUpdateArray(newGeometry){
    let index = this.newOrUpdatedEntities.findIndex(x=> x.entityName === newGeometry.entityName);
    if(index > -1){
      this.newOrUpdatedEntities.splice(index, 1);
    }
  }

  addGeometryToLayer(layerName, geometryItem, drawingMode, drawn=false) {
    let obs: Observable<any> = new Observable(observer => {
      let geometryFactory = new GeometryFactory();
      let index = this.findLayerIndex(layerName);
      let newGeometry = geometryFactory.createGeometry({...geometryItem, geometricType: drawingMode},drawn);
      if(newGeometry && this.isValidGeometry(newGeometry,newGeometry.title)) {
        if(this.geometricalData[index].layerName) {
          newGeometry.layerName = this.geometricalData[index].layerName;
          newGeometry.entityName = !newGeometry.entityName ? this.newName() : newGeometry.entityName;
          newGeometry.entityTag = "tag_" + newGeometry.entityName;
          newGeometry.selected = true;
          this.geometricalData[index].geometry.push(newGeometry);
          this.setEntityToNewUpdateArray(newGeometry);
          observer.next(newGeometry.entityName);
        }
        else {
          observer.error();
        }
      }
      this.emitChanges();


    });
    return obs;
}

  isValidGeometry(geometry,title=null) {
    let dimensions = this.canvasDimensionsService.dimensions;
    switch(geometry.geometricType) {
      case GeometricType.Rectangle: {
        if(!geometry.width || !geometry.height) return;
        this.validateGeometry(geometry);
        return true;
      }
      case GeometricType.Image: {
        if(title && title === "previewimage") {
          return true;
        }
        else if(!geometry.imagePath || !this.validImagePath(geometry.imagePath)) {
          return false;
        }
        else {
          if(!geometry.width || !geometry.height) return;
          if(geometry.width > dimensions.width) {
            let ratio = geometry.width/geometry.height;
            geometry.width = dimensions.width/2;
            geometry.height = geometry.width/ratio;
          }
          if(geometry.height > dimensions.height) {
            let ratio = geometry.height/geometry.width;
            geometry.height = dimensions.height/2;
            geometry.width = geometry.height/ratio;
          }
          this.validateGeometry(geometry);
          return true;
        }
      }
      case GeometricType.Arrow: {
        if(!geometry.width || !geometry.height) return;
        this.validateGeometry(geometry);
        return true;
      }
      case GeometricType.Line: {
        if(geometry.startPoint.x === geometry.endPoint.x && geometry.startPoint.y === geometry.endPoint.y) return;
        return true;
      }
      case GeometricType.LwPolyline || GeometricType.Polygon || GeometricType.Polyline: {
        this.validateGeometry(geometry);
        return true;
      }
      case GeometricType.Circle: {
        if(!geometry.radius) return;
        else return true;
      }
      default: return true;
    }
  }

  validImagePath(path: string) {
    let validPaths = ['assets/symbols/', 'assets/images/', 'base64'];
    for(let i = 0; i < validPaths.length; i++) {
      if(path.includes(validPaths[i])) return true;
    }
    return false;
  }

  eliminateDuplicateVertexes(geometry) {
    if(
      (geometry.vertexes[geometry.vertexes.length-1].position.x === geometry.vertexes[geometry.vertexes.length-2].position.x &&
      geometry.vertexes[geometry.vertexes.length-1].position.y === geometry.vertexes[geometry.vertexes.length-2].position.y) ||
      (
        geometry.vertexes[geometry.vertexes.length-1].position.x === geometry.vertexes[0].position.x &&
        geometry.vertexes[geometry.vertexes.length-1].position.y === geometry.vertexes[0].position.y
      ) ||
      (
        (Math.abs(geometry.vertexes[geometry.vertexes.length-1].position.x - geometry.vertexes[geometry.vertexes.length-2].position.x) < 10 ) &&
        (Math.abs(geometry.vertexes[geometry.vertexes.length-1].position.y - geometry.vertexes[geometry.vertexes.length-2].position.y) < 10 )
      )
    ) {
      geometry.vertexes.pop();
      this.eliminateDuplicateVertexes(geometry);
    }
  }

  validateGeometry(geometry) {
    switch(geometry.geometricType) {
      case GeometricType.Rectangle: {
        if(geometry.geometricType === GeometricType.Rectangle) {
          if(geometry.width < 0) {
            geometry.origin.x += geometry.width;
            geometry.width *= -1;
          }
          if(geometry.height < 0) {
            geometry.origin.y += geometry.height;
            geometry.height *= -1;
          }
        }
        return;
      }
      case GeometricType.Arrow: {
        if(geometry.width < 0) {
          geometry.rotation = 180;
          geometry.origin.x += geometry.width;
          geometry.width *= -1;
        }
        if(geometry.height < 0) {
          geometry.origin.y += geometry.height;
          geometry.height *= -1;
        }
        return;
      }
      case GeometricType.LwPolyline || GeometricType.Polygon || GeometricType.Polyline: {
        this.eliminateDuplicateVertexes(geometry);
        return;
      }
      default: return;
    }
  }

  createLayer(layerName: string) {
    let newLayer = new Layer();
    newLayer.layerName = layerName;
    this.geometricalData.push(newLayer);
    this.emitChanges();
  }

  generateReversedIndex(index: number) {
    return this.geometricalData.length - 1 - index;
  }

  generateReversedIndexInLayer(layerIndex: number, geomIndex: number) {
    return this.geometricalData[layerIndex].geometry.length - 1 - geomIndex;
  }

  findLayerIndex(layerName: string) {
    let index = this.findLayerIndexInArray(this.geometricalData, layerName);
    return index;
  }

  findLayerIndexInArray(array: Layer[], layerName: string) {
    let index = array.findIndex(layer => layer.layerName === layerName);
    return index;
  }

  findGeometryIndexInLayer(layer: Layer, geometryName: string) {
    let index = layer.geometry.findIndex(geometry => geometry.entityName === geometryName);
    return index;
  }

  findGeometryIndexInLayerByName(layerName: string, geometryName: string) {
    let layerIndex = this.findLayerIndex(layerName);
    let geometryIndex = -1;
    if(layerIndex > -1) {
      geometryIndex = this.findGeometryIndexInLayer(this.geometricalData[layerIndex],geometryName)
    }
    return geometryIndex;
  }

  removeLayerByName(layerName: string, toRecreate: boolean = false) {
    let index = this.findLayerIndex(layerName);
    if(index > -1) {
      if(this.geometricalData[index].geometry.length) {
        this.geometricalData[index].geometry.forEach(geomItem => {
          this.deletedEntityNames.push(geomItem.entityName);
          this.removeEntityToNewUpdateArray(geomItem);
        })
      }
      this.geometricalData.splice(index,1);
      this.emitChanges();
    }
  }

  rearrangeLayers(event: CdkDragDrop<Array<string>>) {
    moveItemInArray(this.geometricalData, this.generateReversedIndex(event.previousIndex), this.generateReversedIndex(event.currentIndex));
    this.emitChanges();
  }

  selectedElementsInLayerExist(layerIndex: number) {
    if(layerIndex > -1) {
      let i = 0;
      while(i < this.geometricalData[layerIndex].geometry.length) {
        if(this.geometricalData[layerIndex].geometry[i].selected) {
          return true;
        }
        i++;
      }
    }
    return false;
  }

  multipleObjectsInLayerByNameSelected(layerName: string) {
    let layerIndex = this.findLayerIndex(layerName);
    let multiple = 0;
    if(layerIndex > -1) {
      let i = 0;
      while(i < this.geometricalData[layerIndex].geometry.length) {
        if(this.geometricalData[layerIndex].geometry[i].selected) {
          multiple += 1;
          if(multiple > 1) {
            return true;
          }
        }
        i++;
      }
    }
    return false;
  }

  selectedElementsInLayerByNameExist(layerName: string) {
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      return this.selectedElementsInLayerExist(layerIndex);
    }
    else {
      return false;
    }
  }

  elementsWithFillSelected(layerName) {
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      return this.selectedElementsWithFillInLayerExist(layerIndex);
    }
    else {
      return false;
    }
  }

  selectedType(layerName, type) {
    let arc = null
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      this.geometricalData[layerIndex].geometry.forEach(geometryItem => {
        if(geometryItem.selected && geometryItem.geometricType === type) {
          arc = {...geometryItem};
        }
      })
    }
    return arc;
  }

  oneElementOfLineTypeSelectedInLayer(layerName) {
    let singleLine = 0;
    let line = null;
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      let i = 0;
      while(i < this.geometricalData[layerIndex].geometry.length) {
        if(this.geometricalData[layerIndex].geometry[i].selected && this.isLineType(this.geometricalData[layerIndex].geometry[i].geometricType)) {
          line = this.geometricalData[layerIndex].geometry[i];
          singleLine++;
          if(singleLine > 1) {
            return null;
          }
        }
        else if(this.geometricalData[layerIndex].geometry[i].selected && !this.isLineType(this.geometricalData[layerIndex].geometry[i].geometricType)) {
          return null;
        }
        i++;
      }
    }
    return line;
  }

  oneElementOfOpenLineTypeSelectedInLayer(layerName) {
    let singleLine = 0;
    let line = null;
    if(!this.multipleObjectsInLayerByNameSelected(layerName)) {
      let layerIndex = this.findLayerIndex(layerName);
      if(layerIndex > -1) {
        this.geometricalData[layerIndex].geometry.forEach(geometryItem => {
          if(geometryItem.selected && this.isOpenLineType(geometryItem.geometricType)) {
            line = geometryItem;
            singleLine++;
          }
        })
      }
      return singleLine === 1 ? line : null;
    }
  }

  singleLineSelectedInLayer(layerName) {
    let singleLine = 0;
    let line = null;
    if(!this.multipleObjectsInLayerByNameSelected(layerName)) {
      let layerIndex = this.findLayerIndex(layerName);
      if(layerIndex > -1) {
        this.geometricalData[layerIndex].geometry.forEach(geometryItem => {
          if(geometryItem.selected && this.isSimpleLine(geometryItem.geometricType)) {
            line = geometryItem;
            singleLine++;
          }
        })
      }
      return singleLine === 1 ? line : null;
    }
  }

  singleTextSelectedInLayer(layerName) {
    let singleLine = 0;
    let text = null;
    if(!this.multipleObjectsInLayerByNameSelected(layerName)) {
      let layerIndex = this.findLayerIndex(layerName);
      if(layerIndex > -1) {
        this.geometricalData[layerIndex].geometry.forEach(geometryItem => {
          if(geometryItem.selected && this.isText(geometryItem.geometricType)) {
            text = geometryItem;
            singleLine++;
          }
        })
      }
      return singleLine === 1 ? text : null;
    }
  }

  selectedElementsWithFillInLayerExist(layerIndex: number) {
    if(layerIndex > -1) {
      let i = 0;
      while(i < this.geometricalData[layerIndex].geometry.length) {
        if(
          this.geometricalData[layerIndex].geometry[i].selected && this.elementWithFill(this.geometricalData[layerIndex].geometry[i].geometricType)) {
            return true;
          }
        i++
      }
    }
    return false;
  }

  emitChanges() {
    this._geometricalDataSource.next(this.geometricalData);
  }

  removeSelectedElementsFromLayer(layerName: string) {
    let layerIndex = this.findLayerIndex(layerName);
    this.geometricalData[layerIndex].geometry.forEach(geometryItem => {
      if(
        geometryItem.selected) {
          this.deletedEntityNames.push(geometryItem.entityName);
          this.removeEntityToNewUpdateArray(geometryItem);
        }
    })
    this.geometricalData[layerIndex].geometry = this.geometricalData[layerIndex].geometry.filter(({ selected }) => !selected);
    this.emitChanges();
  }

  mouseIntersectsGeometryControlPoint(layerName: string, mousePos: Point,context,canvas,scale) {
    let index = this.findLayerIndex(layerName);
    if(index > -1) {
      let i = 0;
      while(i < this.geometricalData[index].geometry.length) {
        let foundPoint = this.geometricalData[index].geometry[i].findControlToSnap(mousePos,context,canvas,scale);
        if(foundPoint) {
          return foundPoint;
        }
        i++;
      }
      return null;
    }
    else {
      return null;
    }
  }

  updatePosiitonOfSelectedElementsInLayer(layerName: string, offset) {
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      this.geometricalData[layerIndex].geometry.forEach((geometryItem) => {
        if(geometryItem.selected) {
          geometryItem.move(offset);
          this.setEntityToNewUpdateArray(geometryItem);
        }
      })
    }
    this.emitChanges();
  }

  updateSizeOfSelectedElementsInLayer(layerName:string, selectionRect: Rectangle, resizeData, intersectedControl,allControls=false) {
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      let multiplier = new Point();
      multiplier.x = intersectedControl.multiplierSettings === "x" ? 1 : (selectionRect.width - resizeData.offset.x)/selectionRect.width;
      multiplier.y = intersectedControl.multiplierSettings === "y" ? 1 : (selectionRect.height - resizeData.offset.y)/selectionRect.height;
      this.geometricalData[layerIndex].geometry.forEach((geometryItem) => {
        if(geometryItem.selected) {
          if(intersectedControl.actions && intersectedControl.actions.length && allControls) {
            intersectedControl.actions.forEach(action => {
              this.performResizeAction(geometryItem,intersectedControl,resizeData,action)
            })
          }
          else {
            geometryItem.scale(multiplier,selectionRect);
          }
          this.setEntityToNewUpdateArray(geometryItem);
          /* if (this.toMoveOrigin(intersectedControl.name)) {
            resizeData.offset = {
              x: intersectedControl.multiplierSettings === "x" ? 0 : resizeData.offset.x,
              y: intersectedControl.multiplierSettings === "y" ? 0 : resizeData.offset.y
            }
            geometryItem.move(resizeData.offset);
          } */
          //geometryItem.scale(multiplier,selectionRect);
        }
      })
    }
    this.emitChanges();
  }

  performResizeAction(geometricItem, intersectedControl, resizeData, action: string) {
    switch(action) {
      case "originX": {
        let orX = geometricItem.origin.x;
        geometricItem.origin.x = resizeData.mousePos.x;
        break;
      }
      case "originY": {
        let orY = geometricItem.origin.y;
        geometricItem.origin.y = resizeData.mousePos.y;
        break;
      }
      case "width": {
        let mult = (geometricItem.width - resizeData.offset.x)/geometricItem.width;
        mult = intersectedControl.name.includes("left") ? 1/mult : mult;
        geometricItem.scale({x: mult,y:1},true);
        break;
      }
      case "height": {
        let mult = (geometricItem.height - resizeData.offset.y)/geometricItem.height;
        mult = (intersectedControl.name.includes("Top") || intersectedControl.name.includes("top")) ? 1/mult : mult;
        geometricItem.scale({x: 1,y:mult},true);
        break;
      }
    }
  }

  mirrorToYAxisSelectedElementsInLayer(layerName:string, selectionRect: Rectangle) {
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      let referenceCoordinateX = selectionRect.origin.x + selectionRect.width/2;
      this.geometricalData[layerIndex].geometry.forEach((geometryItem) => {
        if(geometryItem.selected) {
          geometryItem.mirrorToYAxis(referenceCoordinateX);
          this.setEntityToNewUpdateArray(geometryItem);
        }
      })
    }
    this.emitChanges();
  }

  mirrorToXAxisSelectedElementsInLayer(layerName:string, selectionRect: Rectangle) {
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      let referenceCoordinateY = selectionRect.origin.y + selectionRect.height/2;
      let single = !this.multipleObjectsInLayerByNameSelected(layerName);
      this.geometricalData[layerIndex].geometry.forEach((geometryItem) => {
        if(geometryItem.selected) {
          if(single && this.rotatableGeometryInSelection(layerName)) {
            referenceCoordinateY -= selectionRect.height;
          }
          geometryItem.mirrorToXAxis(referenceCoordinateY);
          this.setEntityToNewUpdateArray(geometryItem);
        }
      })
    }
    this.emitChanges();
  }

  rotateSelectedElementsInLayer(layerName:string, selectionRect: Rectangle, mousePos: Point, scale: number) {
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      let rectCenter = new Point(selectionRect.origin.x+selectionRect.width/2,selectionRect.origin.y+selectionRect.height/2)
      let angle: number = this.calculateAngle(mousePos, scale, rectCenter);
      this.geometricalData[layerIndex].geometry.forEach((geometryItem) => {
        if(geometryItem.selected && this.rotatableGeometry(geometryItem.geometricType)) {
          let differenceToRightAngle = Math.abs(angle%90);
          if(differenceToRightAngle < 3) {
            angle += differenceToRightAngle * (angle > 0 ? -1 : 1);
          }
          else if(differenceToRightAngle > 87) {
            angle += (90 - differenceToRightAngle) * (angle > 0 ? 1 : -1);
          }
          geometryItem.rotation = angle;
        }
      })
    }
    this.emitChanges();
  }

  rotatableGeometry(geometricType: number) {
    let isRotatable =
      geometricType === GeometricType.Rectangle ||
      geometricType === GeometricType.Image ||
      geometricType === GeometricType.Arrow ||
      geometricType === GeometricType.Text;
    return isRotatable;
  }

  isLineType(geometricType: number): boolean {
    return geometricType === GeometricType.Polygon || this.isOpenLineType(geometricType);
  }

  isOpenLineType(geometricType: number): boolean {
    return geometricType === GeometricType.Line || geometricType === GeometricType.LwPolyline || geometricType === GeometricType.Polyline;
  }

  isMultiVertexLineType(geometricType: number): boolean {
    return geometricType === GeometricType.Polygon || geometricType === GeometricType.LwPolyline || geometricType === GeometricType.Polyline;
  }

  isSimpleLine(geometricType: number): boolean {
    return geometricType === GeometricType.Line;
  }

  isText(geometricType: number): boolean {
    return geometricType === GeometricType.Text;
  }

  isCircularGeometry(geometricType: number): boolean {
    return geometricType === GeometricType.Circle ||
    geometricType === GeometricType.Arc;

  }

  elementWithFill(geometricType: number) {
    let isWithFill =
      geometricType === GeometricType.Rectangle ||
      geometricType === GeometricType.Polygon ||
      geometricType === GeometricType.Circle ||
      geometricType === GeometricType.Arrow;
    return isWithFill;
  }

  geometryWithText(geometricType: number) {
    let isWithText =
      geometricType === GeometricType.Frame ||
      geometricType === GeometricType.Text ||
      geometricType === GeometricType.Legend;
    return isWithText;
  }

  rotatableGeometryInSelection(layerName:string) {
    if(!this.multipleObjectsInLayerByNameSelected(layerName)) {
      let foundRotatable = null;
      let layerIndex = this.findLayerIndex(layerName);
      if(layerIndex > -1) {
        this.geometricalData[layerIndex].geometry.forEach((geometryItem) => {
          if(geometryItem.selected && this.rotatableGeometry(geometryItem.geometricType)) {
            foundRotatable = geometryItem;
          }
        })
      }
      return foundRotatable;
    }
  }

  calculateAngle(mousePos: Point, scale: number, selectionRectCenter: Point) {
    let dy = mousePos.y/scale - selectionRectCenter.y;
    let dx = mousePos.x/scale - selectionRectCenter.x;
    let angle = Math.atan((dy)/(dx)) - Math.PI*1.5;
    if (dx < 0) angle += Math.PI;
    else if (dy < 0) angle += Math.PI*2;
    let degree = Math.floor(angle * 180/Math.PI);
    return degree;
  }

  updateGeometryGroupInfo(layerName: string, groupName: string) {
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      this.geometricalData[layerIndex].geometry.forEach(geometryItem => {
        if(geometryItem.selected) {
          if(groupName) {
            geometryItem.groupId = groupName;
          }
          else {
            delete geometryItem.groupId;
          }
          this.setEntityToNewUpdateArray(geometryItem);
        }
      })
    }
    this.emitChanges();
  }

  addElementToSelection(layerName: string, geometryName: string, groupMode: boolean = true) {
    let layerIndex = this.findLayerIndex(layerName);
    let geometryIndex = this.findGeometryIndexInLayer(this.geometricalData[layerIndex],geometryName);
    if(layerIndex > -1 && geometryIndex > -1) {
      this.geometricalData[layerIndex].geometry[geometryIndex].selected = true;
      this.selection = true;
      if(this.geometricalData[layerIndex].geometry[geometryIndex].groupId && groupMode) {
        this.selectGroup(layerIndex,this.geometricalData[layerIndex].geometry[geometryIndex].groupId);
      }
      this.emitChanges();
    }
  }

  selectInsideBoundaries(layerName: string, selectingRect: Rectangle, shiftKey: boolean) {
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      this.geometricalData[layerIndex].geometry.forEach(geometryItem => {
        let extrema = geometryItem.extrema();
        if(this.geometryInsideBoundaries(extrema,selectingRect.extrema())) {
          geometryItem.selected = true;
        }
        else if(!shiftKey) {
          delete geometryItem.selected;
        }
      })
      this.emitChanges();
    }
  }

  geometryInsideBoundaries(extrema: Extrema, boundaries: Extrema) {
    if(
      extrema.maxX > boundaries.maxX ||
      extrema.minX < boundaries.minX ||
      extrema.maxY > boundaries.maxY ||
      extrema.minY < boundaries.minY
    ) {
      return false;
    }
    else {
      return true;
    }
  }

  selectGroup(layerIndex: number, groupId) {
    this.geometricalData[layerIndex].geometry.forEach(geometryItem => {
      if(geometryItem.groupId && geometryItem.groupId === groupId) {
        geometryItem.selected = true;
      }
    })
  }

  removeActiveElementFromSelection(layerName: string, geometryName: string) {
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      let geometryIndex = this.findGeometryIndexInLayer(this.geometricalData[layerIndex],geometryName);
      if(geometryIndex > -1) {
        delete this.geometricalData[layerIndex].geometry[geometryIndex].selected;
        this.emitChanges();
      }
    }
  }

  resetSelection(layerName: string) {
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      let i = 0;
      while(i < this.geometricalData[layerIndex].geometry.length) {
        delete this.geometricalData[layerIndex].geometry[i].selected;
        delete this.geometricalData[layerIndex].geometry[i].intersectedAfterVertexIndex;
        i++;
      }
      this.selection = false;
      this.emitChanges();
    }
  }

  resetIntersection(layerName: string, elementName: string) {
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      let geometryIndex = this.findGeometryIndexInLayer(this.geometricalData[layerIndex],elementName);
      if(geometryIndex > -1) {
        delete this.geometricalData[layerIndex].geometry[geometryIndex].intersectedAfterVertexIndex;
        this.emitChanges();
      }
    }
  }

  updatePropertyOfSelection(layerName: string, propertyName: string, value) {
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      this.geometricalData[layerIndex].geometry.forEach((geometryItem:any) => {
        if(geometryItem.selected) {
          if(
            (value || value === 0) &&
            (geometryItem[propertyName] || geometryItem[propertyName] === 0)
          ) {
            geometryItem[propertyName] = value;
          }
          else if(
            geometryItem.geometricType !== GeometricType.Line &&
            geometryItem.vertexes &&
            geometryItem.vertexes.length &&
            (value && value.x && value.y)
          ) {
            geometryItem.vertexes[propertyName].position = value;
          }
          else if(
            geometryItem.geometricType === GeometricType.Line &&
            (propertyName === "startIcon" || propertyName === "endIcon")
          ) {
            geometryItem[propertyName] = value;
          }
          else if(
            ((value || value === 0) &&
            (propertyName === "openings" ||
            propertyName === "thickness" ||
            propertyName === "lineType"))
          ) {
            geometryItem.geometryProperties[propertyName] = value;
          }
          else if(propertyName === "strokeColor") {
            geometryItem.geometryProperties[propertyName] = new Color(value);
          }
          else if(
            value &&
            propertyName === "fillColor" &&
            this.elementWithFill(geometryItem.geometricType)
          ){
            geometryItem.geometryProperties[propertyName] = new Color(value);
          }
          else if(
            !value &&
            propertyName === "fillColor"
          ) {
            delete geometryItem.geometryProperties[propertyName];
          }
          this.setEntityToNewUpdateArray(geometryItem);
        }
      })
    }
    this.emitChanges();
  }

  updateTextPropertyOfSelection(layerName: string, propertyName: string, value) {
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      this.geometricalData[layerIndex].geometry.forEach((geometryItem:any) => {
        if(geometryItem.selected) {
          if(propertyName === "strokeColor") {
            geometryItem.geometryProperties[propertyName] = new Color(value);
          }
          else if(propertyName === "fillColor") {
            if(value) {
              geometryItem.geometryProperties[propertyName] = new Color(value);
            }
            else {
              delete geometryItem.geometryProperties[propertyName];
            }
          }
          else {
            geometryItem[propertyName] = value;
          }
          this.setEntityToNewUpdateArray(geometryItem);
        }
      })
    }
    this.emitChanges();
  }

  getSelectionExtrema(layerName, tolerance: number = null) {
    let selectionExtrema = new Extrema();
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      this.geometricalData[layerIndex].geometry.forEach((geometryItem) => {
        if(geometryItem.selected) {
          let geomExtrema: Extrema = geometryItem.extrema();
          if(selectionExtrema.minX > geomExtrema.minX || !selectionExtrema.minX) selectionExtrema.minX = geomExtrema.minX;
          if(selectionExtrema.minY > geomExtrema.minY || !selectionExtrema.minY) selectionExtrema.minY = geomExtrema.minY;
          if(selectionExtrema.maxX < geomExtrema.maxX || !selectionExtrema.maxX) selectionExtrema.maxX = geomExtrema.maxX;
          if(selectionExtrema.maxY < geomExtrema.maxY || !selectionExtrema.maxY) selectionExtrema.maxY = geomExtrema.maxY;
        }
      })
      if(tolerance) {
        selectionExtrema.minX = selectionExtrema.minX ? Math.round(selectionExtrema.minX - tolerance) : null;
        selectionExtrema.minY = selectionExtrema.minY ? Math.round(selectionExtrema.minY - tolerance) : null;
        selectionExtrema.maxX = selectionExtrema.maxX ? Math.round(selectionExtrema.maxX + tolerance) : null;
        selectionExtrema.maxY = selectionExtrema.maxY ? Math.round(selectionExtrema.maxY + tolerance) : null;
      }
      else {
        selectionExtrema.minX = Math.round(selectionExtrema.minX);
        selectionExtrema.minY = Math.round(selectionExtrema.minY);
        selectionExtrema.maxX = Math.round(selectionExtrema.maxX);
        selectionExtrema.maxY = Math.round(selectionExtrema.maxY);
      }
    }
    return selectionExtrema;
  }

  getSingleSelectedGeometryFromLayer(layerName: string) {
    let singleSelected = null;
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      this.geometricalData[layerIndex].geometry.forEach((geometryItem) => {
        if(geometryItem.selected) {
          singleSelected = {...geometryItem};
        }
      })
    }
    return singleSelected;
  }

  copySelectedElementsInLayer(layerName) {
    let geometryFactory = new GeometryFactory();
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      this.geometricalData[layerIndex].geometry.forEach((geometryItem) => {
        if(geometryItem.selected) {
          delete geometryItem.selected;
          let geomBuff: any = {...geometryItem};
          let newGeometry = null;
          if(this.isMultiVertexLineType(geometryItem.geometricType)) {
            let vertexes = this.deepCopyVertexes(geomBuff.vertexes);
            newGeometry = new Polyline({...geomBuff, vertexes: vertexes});
          }
          else {
            newGeometry = geometryFactory.createGeometry({...geomBuff});
          }
          if(newGeometry) {
            newGeometry.entityName = this.newName();
            newGeometry.selected = true;
            newGeometry.move({x:-20,y:20});
            if(geometryItem.rotation) {
              newGeometry.rotation = geometryItem.rotation;
            }
            this.geometricalData[layerIndex].geometry.push(newGeometry);
            this.setEntityToNewUpdateArray(newGeometry);
          }
        }
      })
    }
    this.emitChanges();
  }

  deepCopyVertexes(vertexesBuff) {
    let newVertexes = new Array<Vertex>();
    vertexesBuff.forEach(vertex => {
      newVertexes.push(new Vertex({...vertex}))
    })
    newVertexes.forEach(vertex => {
      vertex.position = new VertexPosition({...vertex.position});
    })
    return newVertexes;
  }

  performBufferAction(layerName, bufferAction) {
    let geometryFactory = new GeometryFactory();
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      switch(bufferAction) {
        case "copy": {
          this.geometryBuffer = new Array<Geometry>();
          this.geometricalData[layerIndex].geometry.forEach((geometryItem) => {
            if(geometryItem.selected) {
              let newGeometry = geometryFactory.createGeometry({...geometryItem});
              if(newGeometry) {
                if(geometryItem.rotation) {
                  newGeometry.rotation = geometryItem.rotation;
                }
                delete newGeometry.layerName;
                this.geometryBuffer.push(newGeometry);
              }
            }
          })
          this.notificationService.openNotification("copy_success")
          break;
        }
        case "cut": {
          this.geometryBuffer = new Array<Geometry>();
          this.geometricalData[layerIndex].geometry.forEach((geometryItem) => {
            if(geometryItem.selected) {
              let newGeometry = geometryFactory.createGeometry({...geometryItem});
              if(newGeometry) {
                if(geometryItem.rotation) {
                  newGeometry.rotation = geometryItem.rotation;
                }
                delete newGeometry.layerName;
                this.geometryBuffer.push(newGeometry);
              }
            }
          })
          this.geometricalData[layerIndex].geometry = this.geometricalData[layerIndex].geometry.filter(({ selected }) => !selected);
          this.notificationService.openNotification("cut_success")
          break;
        }
        case "paste": {
          if(this.geometryBuffer && this.geometryBuffer.length) {
            this.geometryBuffer.forEach(geometryItem => {
              geometryItem.layerName = this.geometricalData[layerIndex].layerName;
              geometryItem.entityName = this.newName();
              geometryItem.move({x:-20,y:20});
              geometryItem.selected = true;
              this.geometricalData[layerIndex].geometry.push(geometryItem);
              this.setEntityToNewUpdateArray(geometryItem);
            })
          }
          this.notificationService.openNotification("paste_success")
          break;
        }
      }
      this.emitChanges();
    }
  }

  updateLineControls(layerName: string, mode: LineControlsEditingMode, point: Point, indexToInsert=null) {
    let geometryFactory = new GeometryFactory();
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1 && this.oneElementOfLineTypeSelectedInLayer(layerName)) {

      this.geometricalData[layerIndex].geometry.forEach((geometryItem,index) => {
        if(geometryItem.selected) {
          if(mode === LineControlsEditingMode.cutLines && geometryItem.geometricType === GeometricType.Line) {
            let firstLine = new Line({...geometryItem});
            firstLine.endPoint = new VertexPosition(point);
            firstLine.entityName = this.newName();
            firstLine.selected = true;
            firstLine.geometryProperties = {...geometryItem.geometryProperties};
            let secondLine = new Line({...geometryItem});
            secondLine.startPoint = new VertexPosition(point);
            secondLine.entityName = this.newName();
            secondLine.selected = true;
            secondLine.geometryProperties = {...geometryItem.geometryProperties};
            this.geometricalData[layerIndex].geometry.splice(index,1);
            this.geometricalData[layerIndex].geometry.splice(index,0,firstLine);
            this.geometricalData[layerIndex].geometry.splice(index,0,secondLine);
            this.setEntityToNewUpdateArray(firstLine);
            this.setEntityToNewUpdateArray(secondLine);
            this.removeEntityToNewUpdateArray(geometryItem);
          }
          else if(mode === LineControlsEditingMode.addControls && geometryItem.geometricType === GeometricType.Line) {
            let geomBuff = new Line({...geometryItem});
            let vertexes = new Array<Vertex>();
            vertexes[0] = new Vertex({Position:geomBuff.startPoint});
            vertexes[1] = new Vertex({Position:point});
            vertexes[2] = new Vertex({Position:geomBuff.endPoint});
            delete geomBuff.startPoint;
            delete geomBuff.endPoint;
            geomBuff.geometricType = GeometricType.Polyline;
            let newPolyline = geometryFactory.createGeometry({...geomBuff,vertexes:vertexes,selected:true});
            newPolyline.geometryProperties = {...geometryItem.geometryProperties};
            this.geometricalData[layerIndex].geometry.splice(index,1);
            this.geometricalData[layerIndex].geometry.splice(index,0,newPolyline);
            this.setEntityToNewUpdateArray(newPolyline);
            this.removeEntityToNewUpdateArray(geomBuff);
          }
          else if(
            mode === LineControlsEditingMode.addControls && this.isMultiVertexLineType(geometryItem.geometricType)
            ) {
              let geomBuff = new Polyline({...geometryItem});
              let newVertex = new Vertex({Position:point});
              geomBuff.vertexes.splice((indexToInsert ? indexToInsert : geomBuff.intersectedAfterVertexIndex)+1,0,newVertex);
              geomBuff.geometryProperties = {...geometryItem.geometryProperties};
              if(geomBuff.openings && geomBuff.openings.length) {
                geomBuff.openings.forEach(element => {
                  if(element.afterVertexIndex >= geomBuff.intersectedAfterVertexIndex) element.afterVertexIndex++;
                });
              }
              geomBuff.resetIntersectedPoints();
              this.geometricalData[layerIndex].geometry.splice(index,1,geomBuff);
              this.setEntityToNewUpdateArray(geomBuff);
          }
          else if(
            mode === LineControlsEditingMode.cutLines && this.isMultiVertexLineType(geometryItem.geometricType)) {
              let geomBuff = new Polyline({...geometryItem, isClosed: false});
              let devidingIndex = geomBuff.intersectedAfterVertexIndex;
              let firstVertexesPart = [...geomBuff.vertexes].slice(0,devidingIndex+1 - geomBuff.vertexes.length);
              firstVertexesPart.push(new Vertex({Position:point}));
              let firstPolyline = new Polyline({...geomBuff,vertexes: firstVertexesPart});
              firstPolyline.entityName = this.newName();
              firstPolyline.geometryProperties = {...geometryItem.geometryProperties};
              let secondVertexesPart = [...geomBuff.vertexes].slice(devidingIndex+1,geomBuff.vertexes.length);
              secondVertexesPart.splice(0,0,new Vertex({Position:point}));
              if(geometryItem.geometricType === GeometricType.Polygon) {
                secondVertexesPart.push(new Vertex({Position:{...firstVertexesPart[0].position}}));
              }
              let secondPolyline = new Polyline({...geomBuff,vertexes:secondVertexesPart});
              secondPolyline.entityName = this.newName();
              secondPolyline.geometryProperties = {...geometryItem.geometryProperties};
              if(geomBuff.openings && geomBuff.openings.length) {
                let firstOpeningsBuff = new Array<WallOpening>();
                let secondOpeningsBuff = new Array<WallOpening>();
                geomBuff.openings.forEach(element => {
                  if(element.afterVertexIndex >= geomBuff.intersectedAfterVertexIndex) {
                    element.afterVertexIndex -= firstPolyline.vertexes.length-2;
                    secondOpeningsBuff.push(element);
                  }
                  else {
                    firstOpeningsBuff.push(element);
                  }
                });
                firstPolyline.openings = firstOpeningsBuff;
                secondPolyline.openings = secondOpeningsBuff;
              }
              geomBuff.resetIntersectedPoints();
              this.geometricalData[layerIndex].geometry.splice(index,1);
              this.geometricalData[layerIndex].geometry.splice(index,0,firstPolyline);
              this.geometricalData[layerIndex].geometry.splice(index,0,secondPolyline);
              this.setEntityToNewUpdateArray(firstPolyline);
              this.setEntityToNewUpdateArray(secondPolyline);
              this.removeEntityToNewUpdateArray(geomBuff);
          }
        }
      })
    }
    this.emitChanges();
  }

  updateWallOpenings(layerName: string, wallOpening) {
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1 && this.oneElementOfLineTypeSelectedInLayer(layerName)) {
      this.geometricalData[layerIndex].geometry.forEach((geometryItem: any,index) => {
        if(geometryItem.selected) {
          if(geometryItem.openings) {
            let existingOpeningIndex = geometryItem.openings.findIndex(opening => opening.afterVertexIndex === wallOpening.afterVertexIndex);
            if(existingOpeningIndex > -1) {
              if(geometryItem.openings[existingOpeningIndex].type === wallOpening.type) {
                geometryItem.openings.splice(existingOpeningIndex,1);
                if(!geometryItem.openings.length) {
                  delete geometryItem.openings;
                }
              }
              else {
                geometryItem.openings[existingOpeningIndex] = wallOpening;
              }
            }
            else {
              if(geometryItem.openings && geometryItem.openings.length) {
                geometryItem.openings.forEach(opening => {
                  opening.afterVertexIndex += (wallOpening.afterVertexIndex < opening.afterVertexIndex) ? 1 : 0;
                });
              }
              let openingsBuff = [...geometryItem.openings];
              openingsBuff.push(wallOpening);
              openingsBuff = openingsBuff.sort((a,b) => a.afterVertexIndex - b.afterVertexIndex);
              geometryItem.openings = openingsBuff;
            }
          }
          else {
            geometryItem.openings = new Array<WallOpening>();
            geometryItem.openings.push(wallOpening);
          }
        }
      })
    }
    this.emitChanges();
  }


  moveSelectionToForeground(layerName: string) {
    this.changeSelectionOrder(layerName, 1)
  }

  moveSelectionToBackground(layerName: string) {
    this.changeSelectionOrder(layerName, 2)
  }

  moveSelectionOneStepToForeground(layerName: string) {
    this.changeSelectionOrderOneStep(layerName, 1)
  }

  moveSelectionOneStepToBackground(layerName: string) {
    this.changeSelectionOrderOneStep(layerName, 2)
  }

  changeSelectionOrderOneStep(layerName: string, direction:number) {
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      for(let index = 0;index<this.geometricalData[layerIndex].geometry.length;index++) {
        if(this.geometricalData[layerIndex].geometry[index].selected) {
          if(direction === 1 && index !== this.geometricalData[layerIndex].geometry.length-1) {
            this.geometricalData[layerIndex].geometry.splice(index+2,0,this.geometricalData[layerIndex].geometry[index]);
            this.geometricalData[layerIndex].geometry.splice(index,1);
            break;
          }
          else if(direction === 2 && index !== 0) {
            this.geometricalData[layerIndex].geometry.splice(index-1,0,this.geometricalData[layerIndex].geometry[index]);
            this.geometricalData[layerIndex].geometry.splice(index+1,1);
            break;
          }
        }
      }
      this.emitChanges();
    }
  }

  changeSelectionOrder(layerName: string, direction:number) {
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      let geomBuff = new Array<Geometry>();
      for(let index = 0;index<this.geometricalData[layerIndex].geometry.length;index++) {
        if(this.geometricalData[layerIndex].geometry[index].selected) {
          geomBuff.push(this.geometricalData[layerIndex].geometry[index]);
          this.geometricalData[layerIndex].geometry.splice(index,1);
          index--;
        }
      }
      this.geometricalData[layerIndex].geometry = direction == 1 ? this.geometricalData[layerIndex].geometry.concat(geomBuff) : geomBuff.concat(this.geometricalData[layerIndex].geometry);
      this.emitChanges();
    }
  }

  getGeometricalDataFromLayer(layerName: string, limit: number = null) {
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      return limit ? (this.geometricalData[layerIndex].geometry.length < limit ? this.geometricalData[layerIndex].geometry : null) : this.geometricalData[layerIndex].geometry;
    }
  }

  rearrangeGeometriesInLayer(layerName: string, event: CdkDragDrop<Array<Geometry>>) {
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      moveItemInArray(this.geometricalData[layerIndex].geometry, this.generateReversedIndexInLayer(layerIndex, event.previousIndex), this.generateReversedIndexInLayer(layerIndex, event.currentIndex));
      this.emitChanges();
    }
  }

  createSpecialLayer(LayerName: string, dataSettings, strokeColor: Color, images = null): Observable<boolean> {
    switch(LayerName) {
      case "legend": {
        return this.createLegend(dataSettings,strokeColor,images);
      }
      case "frame": {
        return this.createFrame(dataSettings,strokeColor,images);
      }
      default: return
      }
  }

  createFrame(frameDataSettings, strokeColor: Color, images = null): Observable<boolean>{
    let obs: Observable<boolean> = new Observable(observer => {
      if(!this.layerByNameExists("Rahmen")) {
        this.createLayer("Rahmen");
        let frameData = {
          origin: {x: frameDataSettings.origin.x, y: frameDataSettings.origin.y},
          width: frameDataSettings.width,
          height: frameDataSettings.height,
          geometryProperties: {...frameDataSettings.geometryProperties, strokeColor: strokeColor},
          entityName: "PlanRahmen",
          geometricType: 13,
          layerName: "Rahmen",
          title: frameDataSettings.title,
          fontSize: frameDataSettings.fontSize,
          paperFormatLocked: frameDataSettings.paperFormatLocked,
        }
        this.addGeometryToLayer("Rahmen", frameData, 13).subscribe(data => {
          observer.next(true);
        });
      }
      else {
        this.removeLayerByName("Rahmen");
        this.createFrame(frameDataSettings, strokeColor, images).subscribe(data => {});
      }
    });
    return obs;
  }

  createLegend(legendDataSettings, strokeColor, images = null): Observable<boolean> {
    let obs: Observable<boolean> = new Observable(observer => {
      if(!this.layerByNameExists("Legende")) {
        this.createLayer("Legende");
        let imagesBuff = new Array<EPImage>();
        if(!images) {
          this.geometricalData.forEach(layer => {
            layer.geometry.forEach((geometryItem: EPImage) => {
              if (geometryItem.geometricType === GeometricType.Image && !this.imageAlreadyExistsInList(imagesBuff, geometryItem) && geometryItem.title) {
                let imageBuff = new EPImage(geometryItem);
                if(imageBuff.title !== "Nordpfeil" && imageBuff.title !== "previewimage") {
                  imagesBuff.push(imageBuff);
                }
              }
            })
          })
          if(legendDataSettings.northArrow) {
            let geometry = {
              origin: legendDataSettings.origin,
              title: "Nordpfeil",
              width: 40,
              height: 45,
              imagePath: "assets/images/north-arrow.svg",
            }
            let imageBuff = new EPImage(geometry);
            imagesBuff.push(imageBuff);
          }
          images = imagesBuff;
        }
        if(images < legendDataSettings.columns) legendDataSettings.columns = images.length;
        let legendDimensions = this.setupDimensions(images.length, legendDataSettings.columns, legendDataSettings.columnWidth, legendDataSettings.iconWidth);
        let legendData = {
          origin: legendDataSettings.origin,
          columnWidth: legendDataSettings.columnWidth,
          iconWidth: legendDataSettings.iconWidth,
          width: legendDimensions.width,
          height: legendDimensions.height,
          geometryProperties: {strokeColor: strokeColor},
          withFrame: legendDataSettings.withFrame,
          entityName: this.newName(),
          geometricType: 12,
          layerName: "Legende",
          images: images,
          title: legendDataSettings.title,
          columns: legendDimensions.columns
        }
        this.addGeometryToLayer("Legende", legendData, 12).subscribe(data => {
          observer.next(true);
        });
      }
      else {
        this.removeLayerByName("Legende",true);
        this.createLegend(legendDataSettings,strokeColor,images).subscribe(data => {});
      }
    });
    return obs;
  }

  imageAlreadyExistsInList(images: EPImage[],image: EPImage) {
    const result = images.filter((imageItem: EPImage) =>
      imageItem.title === image.title ||
      imageItem.imagePath === image.imagePath
    );
    return result.length > 0 ? true : false;
  }

  setupDimensions(imagesCount, columns, columnWidth, iconSize) {
    let elementsInOneColumn = this.countElementsInOneColumn(imagesCount, columns);
    let height = 48 + (elementsInOneColumn * (iconSize*1.4+24));
    if(imagesCount/elementsInOneColumn < columns) {
      columns = Math.round(imagesCount/elementsInOneColumn);
    }
    let width = columns * columnWidth;
    return {width: width, height: height, columns: columns};
  }

  countElementsInOneColumn(imagesCount, columns) {
    let elementsInColumn = imagesCount / columns;
    var intElementsInColumn = Math.round(elementsInColumn);
    if(imagesCount !== columns+1) {
      return intElementsInColumn > 0 ? intElementsInColumn : 1;
    }
    else {
      return 2;
    }
  }

  layerByNameExists(layerName: string) {
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex === -1) {
      return false;
    }
    else {
      return true;
    }
  }

  rearrangeLegendImages(event: CdkDragDrop<Array<string>>) {
    let layerIndex = this.findLayerIndex("Legende");
    if(layerIndex !== -1) {
      let legendBuff:any = this.geometricalData[layerIndex].geometry[0];
      moveItemInArray(legendBuff.images, event.previousIndex, event.currentIndex);
      this.createLegend(legendBuff,legendBuff.geometryProperties.strokeColor,legendBuff.images);
      this.emitChanges();
    }
  }

  updateLegend(legendData) {
    let layerIndex = this.findLayerIndex("Legende");
    if(layerIndex !== -1) {
      this.geometricalData[layerIndex].geometry[0] = legendData;
      this.emitChanges();
    }
  }

  insertNorthArrowIntoLayer(layerName: string, origin) {
    this.resetSelection(layerName);
    let layerIndex = this.findLayerIndex(layerName);
    if(layerIndex > -1) {
      let geometry = {
        title: "Nordpfeil",
        width: 70,
        height: 125,
        imagePath: "assets/images/north-arrow.svg",
        origin: origin
      }
      this.addGeometryToLayer(layerName, geometry, 8).subscribe(res=>{});
    }
    else {
      return false;
    }
  }

  mirrorAllToXAxis() {
    let obs: Observable<any> = new Observable(observer => {
      if(this.geometricalData.length) {
        for(let i = 0; i < this.geometricalData.length; i++) {
          if(this.geometricalData[i].geometry.length) {
            for(let j = 0; j < this.geometricalData[i].geometry.length; j++) {
              this.geometricalData[i].geometry[j].mirrorToXAxis(0);
              if(i === this.geometricalData.length - 1 && j === this.geometricalData[i].geometry.length -1) {
                observer.next(true);
              }
            }
          }
          else if(i === this.geometricalData.length - 1) {
            observer.next(true);
          }
        }
      }
      else observer.next(true);
    })
    return obs;
  }

  filterSpecialGeometries(mult: number = 1) {
    let obs: Observable<any> = new Observable(observer => {
      this.geometricalData.forEach((layer,index) => {
        this.filterSpecials(index,mult);
      })
      observer.next(true);
    })
    return obs;
  }

  filterSpecials(index: number, mult: number = 1) {
    let resultGeometries = new Array<Geometry>();
    let factory = new GeometryFactory();
    if(this.geometricalData[index].geometry.length) {
      this.geometricalData[index].geometry.forEach((geometryItem: any,geomIndex) => {
        if(this.elementWithFill(geometryItem.geometricType)) {
          if(geometryItem.geometryProperties.fillColor && geometryItem.geometryProperties.strokeColor && !this.colorService.isEqualRGB(geometryItem.geometryProperties.fillColor,geometryItem.geometryProperties.strokeColor)) {
            let fillGeom = factory.createGeometry({...geometryItem, vertexes: geometryItem.vertexes ? this.deepCopyVertexes(geometryItem.vertexes) : null, entityName :this.newName("rect_fill_"), geometryProperties: {...geometryItem.geometryProperties, strokeColor: geometryItem.geometryProperties.fillColor}});
            let strokeGeom = factory.createGeometry({...geometryItem, vertexes: geometryItem.vertexes ? this.deepCopyVertexes(geometryItem.vertexes) : null, entityName :this.newName("rect_stroke_"), geometryProperties: {...geometryItem.geometryProperties, fillColor: null}});
            resultGeometries.push(fillGeom);
            resultGeometries.push(strokeGeom);
            this.deletedEntityNames.push(geometryItem.entityName);
            this.removeEntityToNewUpdateArray(geometryItem);
          }
        }
        if(this.isMultiVertexLineType(geometryItem.geometricType)) {
          resultGeometries = this.filterOpenings(geometryItem,resultGeometries);
        }
        else if(this.isSimpleLine(geometryItem.geometricType) && ((geometryItem.endIcon && geometryItem.endIcon.type) || (geometryItem.startIcon && geometryItem.startIcon.type))) {
          let icons = this.constructLineIcons(geometryItem,mult);
          console.log(icons);
          resultGeometries = resultGeometries.concat(icons);
        }
      })

        for(let i = 0; i < this.geometricalData[index].geometry.length; i++) {
          if(this.deletedEntityNames.includes(this.geometricalData[index].geometry[i].entityName)) {
            this.geometricalData[index].geometry.splice(i,1);
            i--
          }
        }
        //layer.geometry.splice(geomIndex,1);
        resultGeometries.forEach(item => {
          this.geometricalData[index].geometry.push(item);
          this.setEntityToNewUpdateArray(item);
        })
    }
    else return;
  }

  constructLineIcons(line: Line, mult: number = 1) {
    let icons: Array<Geometry> = [];
    let factory = new GeometryFactory();
    if(line.startIcon) {
      let angle = line.getLineAngle("toEnd") / Math.PI * 180;
      if(line.startIcon.type === LineIconEnum.square) {
        let size = 10*line.startIcon.size*mult;
        let rect = factory.createGeometry({origin: new VertexPosition({x: line.startPoint.x - size/2, y: line.startPoint.y - size/2}), rotation: angle, entityName: this.newName("lineicon_"), layerName: line.layerName, geometricType: GeometricType.Rectangle, width: size, height: size, fillColor: {r:0,g:0,b:0}});
        icons.push(rect);
      }
      else if(line.startIcon.type === LineIconEnum.rect) {
        let size = 10*line.startIcon.size*mult;
        let rect = factory.createGeometry({origin: new VertexPosition({x: line.startPoint.x - size/2, y: line.startPoint.y - size}), rotation: angle, entityName: this.newName("lineicon_"), layerName: line.layerName, geometricType: GeometricType.Rectangle, width: size, height: size*2, fillColor: {r:0,g:0,b:0}});
        icons.push(rect);
      }
      else if(line.startIcon.type === LineIconEnum.circle) {
        let size = 6*line.startIcon.size*mult;
        let circle = factory.createGeometry({center: new VertexPosition({x: line.startPoint.x, y: line.startPoint.y}), entityName: this.newName("lineicon_"), layerName: line.layerName, geometricType: GeometricType.Circle, radius: size, fillColor: {r:0,g:0,b:0}});
        icons.push(circle);
      }
      else if(line.startIcon.type === LineIconEnum.diamond) {
        let size = 10*line.startIcon.size*mult;
        let rect = factory.createGeometry({origin: new VertexPosition({x: line.startPoint.x - size/2, y: line.startPoint.y - size/2}), rotation: angle+45, entityName: this.newName("lineicon_"), layerName: line.layerName, geometricType: GeometricType.Rectangle, width: size, height: size, fillColor: {r:0,g:0,b:0}});
        icons.push(rect);
      }
      else if(line.startIcon.type === LineIconEnum.arrow) {
        let angle = line.getLineAngle("toStart");
        let size = 10*line.startIcon.size*mult;
        let vertexes = [
          new Vertex({position: {x: line.startPoint.x - size * Math.cos(angle - Math.PI / 6), y: line.startPoint.y - size * Math.sin(angle - Math.PI / 6)}}),
          new Vertex({position: {x: line.startPoint.x, y: line.startPoint.y}}),
          new Vertex({position: {x: line.startPoint.x - size * Math.cos(angle + Math.PI / 6), y: line.startPoint.y - size * Math.sin(angle + Math.PI / 6)}}),
        ]
        let arrow = factory.createGeometry({entityName: this.newName("lineicon_"), layerName: line.layerName, geometricType: GeometricType.Polyline, vertexes: vertexes, strokeColor: line.geometryProperties.strokeColor, thickness: line.geometryProperties.thickness});
        icons.push(arrow);
      }
    }
    if(line.endIcon) {
      let angle = line.getLineAngle("toStart") / Math.PI * 180;
      if(line.endIcon.type === LineIconEnum.square) {
        let size = 10 * line.endIcon.size*mult;
        let rect = factory.createGeometry({origin: new VertexPosition({x: line.endPoint.x - size/2, y: line.endPoint.y - size/2}), rotation: angle, entityName: this.newName("lineicon_"), layerName: line.layerName, geometricType: GeometricType.Rectangle, width: size, height: size, fillColor: {r:0,g:0,b:0}});
        icons.push(rect);
      }
      else if(line.endIcon.type === LineIconEnum.rect) {
        let size = 10*line.endIcon.size*mult;
        let rect = factory.createGeometry({origin: new VertexPosition({x: line.endPoint.x - size/2, y: line.endPoint.y - size}), rotation: angle, entityName: this.newName("lineicon_"), layerName: line.layerName, geometricType: GeometricType.Rectangle, width: size, height: size*2, fillColor: {r:0,g:0,b:0}});
        icons.push(rect);
      }
      else if(line.endIcon.type === LineIconEnum.circle) {
        let size = 6*line.endIcon.size*mult;
        let circle = factory.createGeometry({center: new VertexPosition({x: line.endPoint.x, y: line.endPoint.y}), entityName: this.newName("lineicon_"), layerName: line.layerName, geometricType: GeometricType.Circle, radius: size, fillColor: {r:0,g:0,b:0}});
        icons.push(circle);
      }
      else if(line.endIcon.type === LineIconEnum.diamond) {
        let size = 10 * line.endIcon.size*mult;
        let rect = factory.createGeometry({origin: new VertexPosition({x: line.endPoint.x - size/2, y: line.endPoint.y - size/2}), rotation: angle+45, entityName: this.newName("lineicon_"), layerName: line.layerName, geometricType: GeometricType.Rectangle, width: size, height: size, fillColor: {r:0,g:0,b:0}});
        icons.push(rect);
      }
      else if(line.endIcon.type === LineIconEnum.arrow) {
        let angle = line.getLineAngle("toEnd");
        let size = 10*line.endIcon.size*mult;
        let vertexes = [
          new Vertex({position: {x: line.endPoint.x - size * Math.cos(angle - Math.PI / 6), y: line.endPoint.y - size * Math.sin(angle - Math.PI / 6)}}),
          new Vertex({position: {x: line.endPoint.x, y: line.endPoint.y}}),
          new Vertex({position: {x: line.endPoint.x - size * Math.cos(angle + Math.PI / 6), y: line.endPoint.y - size * Math.sin(angle + Math.PI / 6)}}),
        ]
        let arrow = factory.createGeometry({entityName: this.newName("lineicon_"), layerName: line.layerName, geometricType: GeometricType.Polyline, vertexes: vertexes, strokeColor: line.geometryProperties.strokeColor, thickness: line.geometryProperties.thickness});
        icons.push(arrow);
      }
    }
    delete line.endIcon;
    delete line.startIcon;
    return icons;
  }

  filterOpenings(geometryItem, resultGeometries: Array<Geometry>) {
    let geomBuff = geometryItem as Polyline;
    if(geomBuff.openings && geomBuff.openings.length) {
      geomBuff.geometricType = GeometricType.Polyline;
      if(geometryItem.isClosed) {
        geomBuff.vertexes.push(new Vertex({Position:{...geomBuff.vertexes[0].position}}));
      }
      geomBuff.isClosed = false;
      geomBuff.openings.forEach((openingItem,index) => {
        if(geomBuff.openings.length > 1 && index < geomBuff.openings.length - 1) {
          let startDevidingIndex = index === 0 ? 0 : geomBuff.openings[index - 1].afterVertexIndex+1;
          let endDevidingIndex = openingItem.afterVertexIndex + 1 - geomBuff.vertexes.length;
          let vertexesPart = [...geomBuff.vertexes].slice(startDevidingIndex,endDevidingIndex);
          let newPolyline = new Polyline({...geomBuff,vertexes: vertexesPart,openings:null, geometryProperties: geometryItem.geometryProperties});
          newPolyline.entityName = this.newName("openings_");
          newPolyline.entityTag = newPolyline.entityName;
          resultGeometries.push(newPolyline);
        }
        else if(geomBuff.openings.length === 1) {
          let startDevidingIndex = 0;
          let endDevidingIndex = openingItem.afterVertexIndex + 1 - geomBuff.vertexes.length;
          let vertexesPart = [...geomBuff.vertexes].slice(startDevidingIndex,endDevidingIndex);
          let newPolyline = new Polyline({...geomBuff,vertexes: vertexesPart,openings:null, geometryProperties: geometryItem.geometryProperties});
          newPolyline.entityName = this.newName("openings_");
          newPolyline.entityTag = newPolyline.entityName;
          resultGeometries.push(newPolyline);
          startDevidingIndex = openingItem.afterVertexIndex + 1;
          endDevidingIndex = geomBuff.vertexes.length;
          vertexesPart = [...geomBuff.vertexes].slice(startDevidingIndex,endDevidingIndex);
          newPolyline = new Polyline({...geomBuff,vertexes: vertexesPart,openings:null, geometryProperties: geometryItem.geometryProperties});
          newPolyline.entityName = this.newName("openings_");
          newPolyline.entityTag = newPolyline.entityName;
          resultGeometries.push(newPolyline);
        }
        else {
          let startDevidingIndex = geomBuff.openings[index - 1].afterVertexIndex+1;
          let endDevidingIndex = openingItem.afterVertexIndex + 1 - geomBuff.vertexes.length;
          let vertexesPart = [...geomBuff.vertexes].slice(startDevidingIndex,endDevidingIndex);
          let newPolyline = new Polyline({...geomBuff,vertexes: vertexesPart,openings:null, geometryProperties: geometryItem.geometryProperties});
          newPolyline.entityName = this.newName("openings_");
          newPolyline.entityTag = newPolyline.entityName;
          resultGeometries.push(newPolyline);

          startDevidingIndex = openingItem.afterVertexIndex + 1;
          endDevidingIndex = geomBuff.vertexes.length;
          vertexesPart = [...geomBuff.vertexes].slice(startDevidingIndex,endDevidingIndex);
          newPolyline = new Polyline({...geomBuff,vertexes: vertexesPart,openings:null, geometryProperties: geometryItem.geometryProperties});
          newPolyline.entityName = this.newName("openings_");
          newPolyline.entityTag = newPolyline.entityName;
          resultGeometries.push(newPolyline);
        }
        //TODO: rectangle on the place of window not depicted correctly.
        if(openingItem.type !== 4) {
          let startPoint = new Point(geomBuff.vertexes[openingItem.afterVertexIndex].position.x, geomBuff.vertexes[openingItem.afterVertexIndex].position.y);
          let endPoint = new Point(geomBuff.vertexes[openingItem.afterVertexIndex+1].position.x, geomBuff.vertexes[openingItem.afterVertexIndex+1].position.y);
          let angle = Math.round(geomBuff.getLineAngle(startPoint, endPoint)/Math.PI*180);
          let distance = geomBuff.calculateDistance(startPoint, endPoint);
          let entityName = this.newName("openings_");
          if(openingItem.type === 3) {
            let height = (geomBuff.geometryProperties.thickness ? geomBuff.geometryProperties.thickness : 5);
            let origin = {
              x: (endPoint.x + startPoint.x)/2 - distance/2,
              y: (endPoint.y + startPoint.y)/2 - height/2,
            }
            let geometricType = 7;
            let rect = new Rectangle({origin,width: distance,height: height, entityName: entityName, geometricType: geometricType, rotation: angle, layerName: geomBuff.layerName, geometryProperties: {...geomBuff.geometryProperties, thickness: 1}});
            resultGeometries.push(rect);
          }
          else if(openingItem.type === 5) {
            let doorKinds: any = doorTypesList;
            let imageName = "";
            let distanceCenter = {
              x: (endPoint.x + startPoint.x)/2,
              y: (endPoint.y + startPoint.y)/2,
            }
            if(openingItem.doorKind) {
                imageName = doorKinds.default.doorTypes.find(element => element.type === openingItem.doorKind).icon;
                imageName = 'assets/images/doors/' + imageName + '.svg';
            }
            if(!openingItem.doorKind || openingItem.doorKind === 0) {
              let directionY = openingItem.invertY ? -1 : 1 ;
              let directionX = openingItem.invertX ? -1 : 1 ;
              let start = openingItem.invertX ? endPoint : startPoint;
              let end = openingItem.invertX ? startPoint : endPoint;
              let angleXInvertionOffset = openingItem.invertX ? -90 : 0;
              let angleYInvertionOffset = (openingItem.invertY && openingItem.invertX) ? -90 : (openingItem.invertY && !openingItem.invertX ? 90 : 0);
              let arc = new Arc({center: openingItem.invertX ? endPoint : startPoint, radius: distance, entityName: entityName, geometricType: 5, endAngle: angle + 180 + angleXInvertionOffset + angleYInvertionOffset, startAngle: angle + 90 + angleXInvertionOffset + angleYInvertionOffset, layerName: geomBuff.layerName, geometryProperties:{...geomBuff.geometryProperties, thickness: 1}});
              let lineName = this.newName("openings_");
              let line = new Line({startPoint: start, endPoint: this.rotatedCoordinate(end,start,-90 * directionY * directionX), entityName: lineName, geometricType: 6, rotation: angle, layerName: geomBuff.layerName, geometryProperties: {...geomBuff.geometryProperties, thickness: 1}});
              resultGeometries.push(arc);
              resultGeometries.push(line);
            }
            else if(openingItem.doorKind === 1 ) {
              let inversionApplies = (openingItem.invertX || openingItem.invertY) && !(openingItem.invertX && openingItem.invertY);
              let directionY = inversionApplies ? -1 : 1;
              let arc = new Arc({center: startPoint, radius: distance/2, entityName: entityName, geometricType: 5, endAngle: angle + (inversionApplies ? 180 : -90), startAngle: angle + (inversionApplies ? 90 : -180), layerName: geomBuff.layerName, geometryProperties: {...geomBuff.geometryProperties, thickness: 1}});
              let arcName2 = this.newName("openings_");
              let arc2 = new Arc({center: endPoint, radius: distance/2, entityName: arcName2, geometricType: 5, endAngle: angle + (inversionApplies ? 0 : 90), startAngle: angle + (inversionApplies ? -90 : 0), layerName: geomBuff.layerName, geometryProperties: {...geomBuff.geometryProperties, thickness: 1}});
              let lineName = this.newName("openings_");
              let line = new Line({startPoint: startPoint, endPoint: this.rotatedCoordinate(distanceCenter,startPoint, 90 * directionY), entityName: lineName, geometricType: 6, rotation: angle, layerName: geomBuff.layerName, geometryProperties: {...geomBuff.geometryProperties, thickness: 1}});
              let lineName2 = this.newName("openings_");
              let line2 = new Line({startPoint: endPoint, endPoint: this.rotatedCoordinate(distanceCenter,endPoint, 90 * directionY), entityName: lineName2, geometricType: 6, rotation: angle, layerName: geomBuff.layerName, geometryProperties: {...geomBuff.geometryProperties, thickness: 1}});

              resultGeometries.push(line2);
              resultGeometries.push(arc2);
              resultGeometries.push(arc);
              resultGeometries.push(line);
            }
            else if(openingItem.doorKind === 2 ) {
              let directionY = openingItem.invertY ? -1 : 1;

              let arc = new Arc({center: startPoint, radius: distance/2, entityName: entityName, geometricType: 5, endAngle: angle + (openingItem.invertY ?  -90 : 180), startAngle: angle + (openingItem.invertY ? -180 : 90), layerName: geomBuff.layerName, geometryProperties: {...geomBuff.geometryProperties, thickness: 1}});
              let arcName2 = this.newName("openings_");
              let arc2 = new Arc({center: endPoint, radius: distance/2, entityName: arcName2, geometricType: 5, endAngle: angle + (openingItem.invertY ? 0 : 90), startAngle: angle + (openingItem.invertY ? -90 : 0), layerName: geomBuff.layerName, geometryProperties: {...geomBuff.geometryProperties, thickness: 1}});

              let lineName = this.newName("openings_");
              let line = new Line({startPoint: startPoint, endPoint: this.rotatedCoordinate(distanceCenter,startPoint, -90 * directionY), entityName: lineName, geometricType: 6, rotation: angle, layerName: geomBuff.layerName, geometryProperties: {...geomBuff.geometryProperties, thickness: 1}});
              let lineName2 = this.newName("openings_");
              let line2 = new Line({startPoint: endPoint, endPoint: this.rotatedCoordinate(distanceCenter,endPoint, 90 * directionY), entityName: lineName2, geometricType: 6, rotation: angle, layerName: geomBuff.layerName, geometryProperties: {...geomBuff.geometryProperties, thickness: 1}});

              resultGeometries.push(line2);
              resultGeometries.push(arc2);
              resultGeometries.push(arc);
              resultGeometries.push(line);
            }
            else if(openingItem.doorKind === 3) {
              let circle = new Circle({center: distanceCenter,radius: distance/2, entityName: entityName, geometricType: 5, layerName: geomBuff.layerName});

              let lineName = this.newName("openings_");
              let line = new Line({startPoint: this.rotatedCoordinate(startPoint,distanceCenter, -45), endPoint: this.rotatedCoordinate(endPoint, distanceCenter, -45), entityName: lineName, geometricType: 6, rotation: angle, layerName: geomBuff.layerName, geometryProperties: {...geomBuff.geometryProperties, thickness: 1}});
              let lineName2 = this.newName("openings_");
              let line2 = new Line({startPoint: this.rotatedCoordinate(startPoint,distanceCenter, 45), endPoint: this.rotatedCoordinate(endPoint, distanceCenter, 45), entityName: lineName2, geometricType: 6, rotation: angle, layerName: geomBuff.layerName, geometryProperties: {...geomBuff.geometryProperties, thickness: 1}});

              resultGeometries.push(circle);
              resultGeometries.push(line);
              resultGeometries.push(line2);
            }
            else if(openingItem.doorKind === 4 ) {
              let start = openingItem.invertX ? endPoint : startPoint;
              let end = openingItem.invertX ? startPoint : endPoint;
              let arc = new Arc({center: start, radius: distance, entityName: entityName, geometricType: 5, endAngle: angle + (openingItem.invertX ?  90 : 270), startAngle: angle + (openingItem.invertX ? -90 : 90), layerName: geomBuff.layerName, geometryProperties: {...geomBuff.geometryProperties, thickness: 1}})
              let lineName = this.newName("openings_");
              let line = new Line({startPoint: this.rotatedCoordinate(end,start, -90), endPoint: this.rotatedCoordinate(end,start, 90), entityName: lineName, geometricType: 6, rotation: angle, layerName: geomBuff.layerName, geometryProperties: {...geomBuff.geometryProperties, thickness: 1}});

              resultGeometries.push(arc);
              resultGeometries.push(line);
            }
            else if(openingItem.doorKind === 5 ) {

              let arc = new Arc({center: startPoint,radius: distance/2, entityName: entityName, geometricType: 5, endAngle: angle - 90 , startAngle: angle + 90, layerName: geomBuff.layerName, geometryProperties: {...geomBuff.geometryProperties, thickness: 1}});
              let arcName2 = this.newName("openings_");
              let arc2 = new Arc({center: endPoint,radius: distance/2, entityName: arcName2, geometricType: 5, endAngle: angle+90, startAngle: angle -90, layerName: geomBuff.layerName, geometryProperties: {...geomBuff.geometryProperties, thickness: 1}});

              let lineName = this.newName("openings_");
              let line = new Line({startPoint: this.rotatedCoordinate(distanceCenter,startPoint, -90), endPoint: this.rotatedCoordinate(distanceCenter,startPoint, 90), entityName: lineName, geometricType: 6, rotation: angle, layerName: geomBuff.layerName, geometryProperties: {...geomBuff.geometryProperties, thickness: 1}});
              let lineName2 = this.newName("openings_");
              let line2 = new Line({startPoint: this.rotatedCoordinate(distanceCenter,endPoint, -90), endPoint: this.rotatedCoordinate(distanceCenter,endPoint, 90), entityName: lineName2, geometricType: 6, rotation: angle, layerName: geomBuff.layerName, geometryProperties: {...geomBuff.geometryProperties, thickness: 1}});

              resultGeometries.push(line2);
              resultGeometries.push(arc2);
              resultGeometries.push(arc);
              resultGeometries.push(line);
            }
          }
        }
      })
      this.deletedEntityNames.push(geometryItem.entityName);
      this.removeEntityToNewUpdateArray(geometryItem);
    }

    return resultGeometries;
  }

  rotatedCoordinate(coordinate: Point, referencePoint: Point,rotation: number): Point {
    let centerX = referencePoint.x;
    let centerY = referencePoint.y;
    let tempX = coordinate.x - centerX;
    let tempY = coordinate.y - centerY;
    let radRotation = (rotation) * Math.PI / 180
    let rotatedX = tempX * Math.cos(radRotation) - tempY * Math.sin(radRotation) + centerX;
    let rotatedY = tempX * Math.sin(radRotation) + tempY * Math.cos(radRotation) + centerY;

    return new Point(rotatedX,rotatedY);
  }

  prepareForLocalSave(): Layer[] {
    let res = [];
    this.geometricalData.forEach(l => {
      let filtered = {...l};
      filtered.geometry = l.geometry.filter(g => g.geometricType === GeometricType.Legend || g.geometricType === GeometricType.Text);
      res.push(filtered);
    })
    return res;
  }

  convertSpecial(planId: string, scale: number = 1) {
    let obs: Observable<any> = new Observable(observer => {
      let drawingPartToSave = this.prepareForLocalSave();
      let drawingToUpgrade = {planId: planId, geometry: drawingPartToSave, scale: scale};
      let drawings: any[] = JSON.parse(localStorage.getItem("editorData"));
      if(drawings) {
        let drawingIndex = drawings.findIndex(drawing => drawing.planId === planId);
        if((drawingIndex && drawingIndex > -1) || drawingIndex == 0) {
          drawings[drawingIndex] = drawingToUpgrade;
        }
        else {
          drawings.push(drawingToUpgrade);
        }
        localStorage.setItem("editorData", JSON.stringify(drawings));
      }
      else {
        localStorage.setItem("editorData", JSON.stringify([drawingToUpgrade]));
      }
      this.convertFrame().subscribe(() => {
        this.convertLegend().subscribe(() => {
          this.convertText().subscribe(() => {
            observer.next(true);
          })
        })
      })
    })
    return obs;
  }

  convertLegend() {
    let obs: Observable<any> = new Observable(observer => {
      let index = this.findLayerIndex("Legende")
      if(index > -1 && this.geometricalData[index].geometry.length) {
        for(let i = 0; i < this.geometricalData[index].geometry.length; i++) {
          if(this.geometricalData[index].geometry[i].geometricType === GeometricType.Legend) {

            let legend = this.geometricalData[index].geometry[i] as Legend;
            //legend.mirrorToXAxis(legend.origin.y+legend.height/2);

            let canvas = document.createElement('canvas');
                let ctx = canvas.getContext('2d');
                canvas.width = canvas.width;
                canvas.width = legend.width*4+12;
                canvas.height = legend.height*4+12;
                const canvasImg = new Image();
                let stroke = this.colorService.rgbToHex(legend.geometryProperties.strokeColor);
                ctx.lineWidth = 16;
                legend.scale({x:4,y:4},null,true);
                ctx.translate(-legend.origin.x+4,-legend.origin.y+4);
                legend.draw(ctx,1,stroke,canvas);
                let imagePath = canvas.toDataURL("image/png", 1.0);
                legend.scale({x:1/4,y:1/4},null,true);

                let image = new EPImage({...legend, imagePath: imagePath, geometricType: GeometricType.Image});
                image.entityName = legend.entityName;
                image.entityTag = legend.entityTag;
                image.title = "Legende";
                this.removeEntityToNewUpdateArray(this.geometricalData[index].geometry[i]);
                this.geometricalData[index].geometry.splice(i,1);
                this.geometricalData[index].geometry.push(image);
                this.setEntityToNewUpdateArray(image);
                if(i === this.geometricalData[index].geometry.length -1) {
                  observer.next(true);
                }
          }
          else if(i === this.geometricalData[index].geometry.length -1) {
            observer.next(true);
          }
        }
      }
      else {
        observer.next(true);

      }
    })
    return obs;
  }

  convertFrame() {
    let obs: Observable<any> = new Observable(observer => {
      let index = this.findLayerIndex("Rahmen")
      if(index > -1 && this.geometricalData[index].geometry.length) {
        for(let i = 0; i < this.geometricalData[index].geometry.length; i++) {
          if(this.geometricalData[index].geometry[i].geometricType === GeometricType.Frame) {

            let layerName = this.geometricalData[index].layerName;
            let frame = this.geometricalData[index].geometry[i] as Frame;

            let canvas = document.createElement('canvas');
                let ctx = canvas.getContext('2d');
                canvas.width = canvas.width;
                canvas.width = frame.width*4+12;
                canvas.height = frame.height*4+12;
                const canvasImg = new Image();
                let stroke = this.colorService.rgbToHex(frame.geometryProperties.strokeColor);
                ctx.lineWidth = 16;
                frame.scale({x:4,y:4},null,true);
                ctx.translate(-frame.origin.x+4,-frame.origin.y+4);
                frame.draw(ctx,1,stroke,canvas);
                let imagePath = canvas.toDataURL("image/png", 1.0);
                frame.scale({x:1/4,y:1/4},null,true);

                let image = new EPImage({...frame, imagePath: imagePath, geometricType: GeometricType.Image});
                image.entityName = frame.entityName;
                image.entityTag = frame.entityTag;
                image.title = "Rahmen";
                this.removeEntityToNewUpdateArray(this.geometricalData[index].geometry[i]);
                this.geometricalData[index].geometry.splice(i,1);
                this.geometricalData[index].geometry.push(image);
                this.setEntityToNewUpdateArray(image);
                if(i === this.geometricalData[index].geometry.length -1) {
                  observer.next(true);
                }

          }
          else if(i === this.geometricalData[index].geometry.length -1) {
            observer.next(true);
          }
        }
      }
      else {
        observer.next(true);

      }
    })
    return obs;
  }

  convertText() {
    let obs: Observable<any> = new Observable(observer => {
      this.geometricalData.forEach((layer,layerIndex) => {
        if(layer.geometry.length) {
          layer.geometry.forEach((geom,geomIndex) => {
            if(geom.geometricType === GeometricType.Text) {
              let textItem = geom as EPText;
              let canvas = document.createElement('canvas');
                let ctx = canvas.getContext('2d');
                canvas.width = canvas.width;
                canvas.width = textItem.width*2+12;
                canvas.height = textItem.height*2+12;
                const canvasImg = new Image();
                let stroke = this.colorService.rgbToHex(textItem.geometryProperties.strokeColor);
                let fill = textItem.geometryProperties.fillColor ? this.colorService.rgbToHex(textItem.geometryProperties.fillColor) : null;
                ctx.lineWidth = 8;
                textItem.scale({x:2,y:2},null,true);
                ctx.translate(-textItem.origin.x+4,-textItem.origin.y+4);
                textItem.draw(ctx,1,stroke,canvas,fill);
                let imagePath = canvas.toDataURL("image/png", 1.0);
                textItem.scale({x:1/2,y:1/2},null,true);

                let image = new EPImage({...textItem, imagePath: imagePath, geometricType: GeometricType.Image});
                image.entityName = textItem.entityName;
                image.entityTag = textItem.entityTag;
                image.title = textItem.text;
                this.removeEntityToNewUpdateArray(this.geometricalData[layerIndex].geometry[geomIndex]);
                this.geometricalData[layerIndex].geometry[geomIndex] = image;
                this.setEntityToNewUpdateArray(image);
                if(layerIndex === this.geometricalData.length - 1 && geomIndex === this.geometricalData[layerIndex].geometry.length - 1) {
                  observer.next(true);
                }
            }
            else if(layerIndex === this.geometricalData.length - 1 && geomIndex === this.geometricalData[layerIndex].geometry.length - 1) {
              observer.next(true);
            }
          })
        }
        else if(layerIndex === this.geometricalData.length - 1) {
          observer.next(true);
        }
      })
    })
    return obs;
  }

  newName(prefix: string = null) {
    return (prefix ? prefix : "") + Math.floor(Math.random() * Date.now()).toString();
  }

  cleanNewOrUpdatedEntitiesArray()
  {
    this.newOrUpdatedEntities = new Array<Geometry>(0);
    this.deletedEntityNames = new Array<string>(0);
  }
}
