<div class="additional-tools-element p-tb" *ngIf="(isInsertMode() && drawingService.drawingMode === 6) || (isEditMode() && singleLineSelected())">
    <span class="p-b">Icons</span>
    <mat-radio-group
        fxLayout="column"
        [(ngModel)]="iconSize">
        @for (size of sizes; track size; let index = $index) {
            <mat-radio-button [value]="size.sizeValue" (click)="changeSize(size)">
                <span class="small-text">{{ "lineIcons." + size.name | translate}}</span>
            </mat-radio-button>
        }
    </mat-radio-group>
    <div class="w-100">
        <mat-form-field class="w-100">
            <mat-label>Starticon</mat-label>
            <mat-select (selectionChange)="updateStartLineIcon($event)">
                <mat-option *ngFor="let lineIconItem of lineIconsList" [value]="lineIconItem.type" [matTooltip]="lineIconItem.name">
                    <mat-icon svgIcon="{{lineIconItem.icon}}"></mat-icon>
                    <span>{{lineIconItem.name}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="w-100">
        <mat-form-field class="w-100">
            <mat-label>Endicon</mat-label>
            <mat-select (selectionChange)="updateEndLineIcon($event)">
                <mat-option *ngFor="let lineIconItem of lineIconsList" [value]="lineIconItem.type" [matTooltip]="lineIconItem.name">
                    <mat-icon svgIcon="{{lineIconItem.icon}}"></mat-icon>
                    <span>{{lineIconItem.name}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>