import { Point } from '..';
import { Rectangle } from './rectangle.model';

export class Frame extends Rectangle {
    title: string;
    fontSize: number;
    paperFormatLocked: boolean;
    fitted: boolean;
    titleWidth: number;

    constructor(data) {
        super(data);
        this.title = data.title ? data.title : "";
        this.fontSize = data.fontSize ? data.fontSize : 20;
        this.paperFormatLocked = data.paperFormatLocked ? data.paperFormatLocked : false;
    }

    override draw(context: CanvasRenderingContext2D, scale, color, canvas, fillColor = null) {
        let fontSize = (this.fontSize*scale).toString();
        let fontStyle = "bold "+ fontSize + "px Arial";
        if(this.paperFormatLocked && !this.fitted) {
          this.fitToDINRatio();
        }
        context.save();
        context.fillStyle = this.geometryProperties.strokeColor.rgbToHex(this.geometryProperties.strokeColor);
        context.fillRect(this.origin.x * scale, this.origin.y * scale, this.width * scale, this.fontSize * 2 * scale);
        context.restore();
        context.save();

        context.font = fontStyle;
        context.textAlign = 'center';
        let textWidth = context.measureText(this.title).width;
        if(textWidth > this.width*scale) {
            this.origin.x += this.width/2;
            this.width = textWidth + 20;
            this.origin.x -= this.width/2;
        }
        context.restore();

        super.draw(context,scale,color,canvas);

        context.save();
        context.font = fontStyle;
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillStyle = "white";
        this.titleWidth = context.measureText(this.title).width;
        context.translate(this.origin.x * scale, this.origin.y * scale);
        context.fillText(this.title, this.width/2*scale, (this.fontSize)*scale);
        context.restore();
    }

    fitToDINRatio() {
      if(this.width > this.height) {
        let heightBuff = this.height;
        let formatHeight = this.width/1.41421;
        if(heightBuff > formatHeight) {
          this.width += 10;
          this.fitToDINRatio();
        }
        else {
          this.height = formatHeight;
          this.fitted = true;
        }
      }
      else {
        let widthBuff = this.width;
        let formatWidth = this.height/1.41421;
        if(widthBuff > formatWidth) {
          this.height += 10;
          this.fitToDINRatio();
        }
        else {
          this.width = formatWidth;
          this.fitted = true;
        }
      }
    }

    wrapText(context, text, x, y, maxWidth, lineHeight) {
        let initailY = y;
        var words = text.split(' ');
        var line = '';

        for(var n = 0; n < words.length; n++) {
          var testLine = line + words[n] + ' ';
          var metrics = context.measureText(testLine);
          var testWidth = metrics.width;
          if (testWidth > maxWidth && n > 0) {
            context.fillText(line, x, y);
            line = words[n] + ' ';
            y += lineHeight;
          }
          else {
            line = testLine;
          }
        }
        context.fillText(line, x, y);
        if(initailY < y) {
            return y;
        }
    }

    override scale(mult: Point, selectionRect: Rectangle, scaleText: any = false) {
      super.scale(mult, selectionRect);
      if(scaleText) {
        //this.geometryProperties.thickness *= mult.x;
        this.fontSize = Math.round(this.fontSize*mult.x);
      }
    }

}
