<div class="additional-tools-element">
    <p>{{ "legend.legend" | translate }}</p>
    <div class="p-tb" fxLayout="column">
        <mat-divider></mat-divider>
        <div class="m-tb ep-form-control-container">
            <div class="ep-form-control">
                <span>{{ "legend.description" | translate }}</span>
                <input type="text" [(ngModel)]="description" (blur)="onBlur()" (focus)="onFocus()">
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="m-tb ep-form-control-container">
            <div class="ep-form-control">
                <span>{{ "legend.columns" | translate }}</span>
                <input type="number" min="1" [(ngModel)]="columns" (blur)="onBlur()" (focus)="onFocus()">
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="m-tb ep-form-control-container">
            <div class="ep-form-control">
                <span>{{ "legend.columnWidth" | translate }} (px)</span>
                <input type="number" min="50" [(ngModel)]="columnWidth" (blur)="onBlur()" (focus)="onFocus()">
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="m-tb ep-form-control-container">
            <div class="ep-form-control">
                <span>{{ "legend.iconWidth" | translate }} (px)</span>
                <input type="number" min="40" [(ngModel)]="iconWidth" (blur)="onBlur()" (focus)="onFocus()">
            </div>
        </div>
        <mat-divider></mat-divider>
        <mat-checkbox class="m-tb" (click)="toggleFrame()" [checked]="withFrame">
            <span>
                {{ "legend.toggleFrame" | translate }}
            </span>
        </mat-checkbox>
        <mat-checkbox class="m-tb" (click)="toggleNorthArrow()" [checked]="northArrow">
            <span>
                {{ "legend.northArrow" | translate }}
            </span>
        </mat-checkbox>
        <color-sketch *ngIf="withFrame" [presetColors]="frameColors" [color]="strokeColor ? strokeColor : '#000000'" (onChangeComplete)="updateCurrentFrameColor($event)"></color-sketch>
        <mat-divider></mat-divider>
        <button mat-flat-button class="ep-btn-call" (click)="generateLegend()">{{ "legend.generateNew" | translate }}</button>

        <span class="m-t" *ngIf="legendElements && legendElements.length">{{ "legend.order" | translate }}</span>
        <div class="m-tb" cdkDropList (cdkDropListDropped)="rearrangeLegendElements($event)">
            <div *ngFor="let legendImage of legendElements; let i = index" cdkDrag fxLayout="row" fxLayoutAlign="start center">
              <div class="layer-drag-placeholder" *cdkDragPlaceholder></div>
              <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                  <button [matTooltip]="legendImage.title" mat-button fxFlex fxLayout="row" fxLayoutAlign="start center" (click)="editLegendElementTitle(legendImage,i)">
                      <span class="symbol-category-selection-trigger-text">{{legendImage.title}}</span>
                  </button>
                  <button mat-icon-button (click)="removeLegendElement(i)" matTooltip="Element entfernen">
                    <mat-icon svgIcon="trash-solid"></mat-icon> 
                </button>
              </div>
                <mat-divider></mat-divider>
            </div>
          </div>
    </div>
</div>