import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vertical-controls',
  templateUrl: './vertical-controls.component.html',
})
export class VerticalControlsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
  }

  stopEvent(e: MouseEvent) {
    e.stopPropagation();
  }
}
