import { Component, OnInit } from '@angular/core';
import { ColorEvent } from 'ngx-color';
import { GeometryPropertiesService } from 'src/app/services/operational-services/geometry-properties/geometry-properties.service';
import { ModesService } from 'src/app/services/status-and-control-services/modes/modes.service';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { DrawingService } from 'src/app/services/operational-services/drawing/drawing.service';
import { ColorEnum } from 'src/app/models/enums/color.enum';
import { DrawingMode } from 'src/app/models/enums/drawing-mode.enum';
import { ColorService } from 'src/app/services';

@Component({
  selector: 'app-fill-tools',
  templateUrl: './fill-tools.component.html',
  styleUrls: ['./fill-tools.component.scss']
})
export class FillToolsComponent implements OnInit {
  currentFillColor: string;
  presetColors: Array<string>;
  constructor(
    private geometryPropertiesService: GeometryPropertiesService,
    private modesService: ModesService,
    private drawingService: DrawingService,
    private colorService: ColorService
  ) {
    this.currentFillColor = this.modesService.mode === ActiveMode.insertMode ? this.geometryPropertiesService.activeFillColor : ColorEnum.default;
    this.presetColors = this.colorService.presetColors;
  }
  ngOnInit(): void {
  }

  updateFillColor(event: ColorEvent) {
    if(this.modesService.mode === ActiveMode.insertMode && this.drawingService.drawingMode === DrawingMode.polygon) {
      this.geometryPropertiesService.setActiveFillColor(event);
      this.geometryPropertiesService.setActiveStrokeColor(event);
      this.drawingService.setFillMode(true);
    }
    if(this.modesService.mode === ActiveMode.insertMode) {
      this.geometryPropertiesService.setActiveFillColor(event);
      this.drawingService.setFillMode(true);
    }
    else if(this.modesService.mode === ActiveMode.editMode) {
      let color = event.color.hex;
      this.geometryPropertiesService.updateFillColorOfSelection(color);
    }
    this.currentFillColor = event.color.hex;
  }

  resetFillSettings() {
    if(this.modesService.mode === ActiveMode.insertMode) {
      this.drawingService.setFillMode(false);
      this.geometryPropertiesService.resetActiveFillColor();
      this.currentFillColor = this.geometryPropertiesService.activeFillColor;
    }
    else if(this.modesService.mode === ActiveMode.editMode) {
      this.geometryPropertiesService.updateFillColorOfSelection(null);
    }
  }

}
