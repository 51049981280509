import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";

@Injectable({ providedIn: "root" })
export class ContactService {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  sendContactRequest(fileData): Observable<any> {
    let obs: Observable<any> = new Observable(observer => {
      this.httpClient
        .post(
          environment.requestUrl + "/api/contact",
          fileData)
        .subscribe(res => {
          observer.next(res);
        }, error => {
          console.log(error);
        });
    });
    return obs;
  }

}
