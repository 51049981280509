export class ProjectAdditionalData {
    fireSensorsNr: string;
    normId: string;
    buildingUsage: string;
    partnerInCase: string;
    contentsList: string;
    distributor: string;
    personel: string;
    workingHours: string;
    fireKeys: string;
    energySupplyTips: string;
    hazardAndTechnicsTips: string;
    dangerousSubstancesTip: string;
    technicalBuildingEquipment: string;
    buildingDescription: string;
    otherInformation: string;

    constructor() {}

}