<div class="additional-tools-element" *ngIf="isInsertMode()">
    <div class="w-100" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <button [ngClass]="{'bg-normal-greyblue': (selectedWallMode === 1)}" mat-icon-button matTooltip="Aussenwand" (click)="setWallMode(1)">
            <mat-icon svgIcon="aussendwand"></mat-icon>
        </button>
        <button [ngClass]="{'bg-normal-greyblue': (selectedWallMode === 2)}" mat-icon-button matTooltip="Innenwand" (click)="setWallMode(2)">
            <mat-icon svgIcon="innenwand"></mat-icon>
        </button>
        <button [ngClass]="{'bg-normal-greyblue': (selectedWallMode === 3)}" mat-icon-button matTooltip="Möbel" (click)="setWallMode(3)">
            <mat-icon svgIcon="moebel"></mat-icon>
        </button>
    </div>
    <div class="ep-form-control-container" fxLayout="row" fxLayoutAlign="center center">
        <div class="w-100">
            <div class="ep-form-control">
                <span class="additional-tools-span">Linienstärke</span>
                <input type="number" min={{minWallWidth}} [(ngModel)]="currentLineWidth" (blur)="onBlur()" (focus)="onFocus()">
            </div>
        </div>
        <div>
            <button mat-icon-button (click)="updateLineWidthFromModel(currentLineWidth)">
                OK
            </button>
        </div>
    </div>
</div>