import { Component, OnInit } from '@angular/core';
import { ControlsService, FileService, DataService, LayerService, TranslationService, ViewerOperationsService, ViewerService } from 'src/app/services';
import { EditorControl } from 'src/app/models/controls';
import { MatDialog } from '@angular/material/dialog';
import { FileImportDialogComponent } from 'src/app/components/dialogs/file-import-dialog/file-import-dialog.component';
import { ProjectService } from 'src/app/services/user/project/project.service';
import { PlanService } from 'src/app/services/user/plan/plan.service';
import { Router } from '@angular/router';
import { InfoDialogComponent } from 'src/app/components/dialogs/info-dialog/info-dialog.component';
import { ExportService } from 'src/app/services/operational-services/export/export.service';
import { PlanCloseDialogComponent } from 'src/app/components/dialogs/plan-close-dialog/plan-close-dialog.component';
import { Observable, of, Subscription } from 'rxjs';
import { NotificationService } from 'src/app/services/status-and-control-services/notification/notification.service';
import { ProjectChoiceComponent } from 'src/app/components/dialogs/project-choice/project-choice.component';
import { InputDialogComponent } from 'src/app/components/dialogs/input-dialog/input-dialog.component';
import { ProfileService } from 'src/app/services/user/profile/profile.service';
import { Layer } from 'src/app/models';
import { Geometry } from 'src/app/models/geometries/geometry.model';
import { ModesService } from 'src/app/services/status-and-control-services/modes/modes.service';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { ControlActiveSettingEnum } from 'src/app/models/enums/control-active-setting.enum';

@Component({
  selector: 'app-file-controls',
  templateUrl: './file-controls.component.html',
  styleUrls: ['./file-controls.component.scss']
})
export class FileControlsComponent implements OnInit {
  controlItems: Array<EditorControl>;
  loading: boolean;
  demoVersion: boolean;
  loadingSubscription: Subscription;
  editing: boolean;

  constructor(
    private controlsService: ControlsService,
    private dialog: MatDialog,
    private dataService: DataService,
    private layerService: LayerService,
    private projectService: ProjectService,
    private planService: PlanService,
    private router: Router,
    private translationService: TranslationService,
    private exportService: ExportService,
    private notificationService: NotificationService,
    private profileService: ProfileService,
    private viewerOperationsService: ViewerOperationsService,
    private viewerService: ViewerService,
    private fileService: FileService,
    private modesService: ModesService
  ) {
    this.profileService.getMyProfile().subscribe((res:any) => {
      this.demoVersion = res.licenseStatus === 2;
    })
    this.controlItems = this.controlsService.controls.horizontalControls.fileControlsList;
    this.editing = this.viewerOperationsService.editing;
    this.loadingSubscription = this.viewerService.loading$
    .subscribe(status => {
      this.loading = status;
    });
  }

  ngOnInit(): void {
  }

  onButtonClick(controlsItem: EditorControl) {
    switch(controlsItem.id) {
      case 1: return this.savePlan().subscribe(res=>{});
      case 10: return this.openImportFileDialog();
      case 11: return this.startExporting();
      case 12: return this.closePlan();
      default: return
      }
  }

  variableFromComponent(controlItem: EditorControl) {
    return this[controlItem.varName];
  }

  startExporting() {
    this.exportService.emitExportEvent("exportsetup");
  }

  openImportFileDialog() {
    this.dialog.open(FileImportDialogComponent,{
      data: {
        actionTitle: "selectCAD",
        actionSubtitle: "selectCADSubtitle"

      }
    });
  }

  savePlan(mode: number = null): Observable<any> {
    let obs: Observable<any> = new Observable(observer => {
      this.viewerService._loadingSource.next(true);
      let projectId = this.projectService.activeProjectId;
      if(!projectId || projectId === "sampleProject" ) {
        let dialogRefPass = this.dialog.open(ProjectChoiceComponent);
        dialogRefPass.afterClosed().subscribe(resultProjectId => {
          if(resultProjectId) {
            let dialogRefPass = this.dialog.open(InputDialogComponent, {
              data: {
                value: "Musterplan",
                valueName: "Plan-Name",
                buttonText: "Name speichern"
              }
            });
            dialogRefPass.afterClosed().subscribe(resultPlanNameResponse => {
              if(resultPlanNameResponse) {
                this.createNewPlan(resultProjectId, resultPlanNameResponse.planName).subscribe(result => {
                  if(result) {
                    this.notificationService.openNotification("save_plan_success");
                    this.viewerService._loadingSource.next(false);
                    this.planService.recentlySaved = true;
                    observer.next(true);
                  }
                });
              }
            })
          }
          else {
            observer.next(false)
            this.viewerService._loadingSource.next(false);
          }
        })
      }
      else if(projectId && this.planService.activePlanId) {
        this.dataService.convertSpecial(this.planService.activePlanId).subscribe(res => {
          this.viewerOperationsService.scaleGeometry(this.dataService.geometricalData,1/this.viewerOperationsService.viewerMultiplier,false);
          this.dataService.geometricalData.forEach((layer: Layer, indexLayer: number) => {
            layer.geometry.forEach((geometryItem: Geometry, indexGeometryItem: number) => {
              if(geometryItem.title && geometryItem.title === 'previewimage') {
                layer.geometry.splice(indexGeometryItem,1);
              }
            });
          });
          this.dataService.filterSpecialGeometries(1/this.viewerOperationsService.viewerMultiplier).subscribe(res => {
            this.dataService.mirrorAllToXAxis().subscribe(res => {
              this.planService.savePlanAction(this.projectService.activeProjectId,
                this.dataService.deletedEntityNames,
                this.fileService.dxfFilePath,
                this.dataService.newOrUpdatedEntities,
                this.dataService.layerNames,
                true).subscribe(() => {
                this.notificationService.openNotification("save_plan_success");
                this.viewerService._loadingSource.next(false);
                this.viewerService._loadingSource.next(false);
                observer.next(true);
                if(!mode) {
                  this.dataService.mirrorAllToXAxis().subscribe(res => {
                    this.viewerOperationsService.scaleGeometry(this.dataService.geometricalData,this.viewerOperationsService.viewerMultiplier,false);
                  })
                }
              });
            })
          })

        },
        error => {
          this.notificationService.openNotification("save_plan_error");
          this.viewerService._loadingSource.next(false);
        });
      }
      else {
        this.viewerService._loadingSource.next(false);
        let infoString = this.translationService.translateSimpleToken("NO_ACTIVE_DRAWING");
        let errorTitle = this.translationService.translateSimpleToken("ERROR");
        this.dialog.open(InfoDialogComponent, {data: {kind: errorTitle, serviceErrorCode: infoString}});
      }
    })
    return obs;
  }

  createNewPlan(projectId,planName) {
    let obs: Observable<any> = new Observable(observer => {
      this.viewerService._loadingSource.next(true);
      let planData = {
          drawingName: planName,
          projectId: projectId
        }
        this.planService.createPlan(planData).subscribe(response => {
          this.projectService.getMyProjects().subscribe((projectsResponse: any)  => {
            this.dataService.convertSpecial(this.planService.activePlanId).subscribe(() => {
              this.viewerOperationsService.scaleGeometry(this.dataService.geometricalData,1/this.viewerOperationsService.viewerMultiplier,false);
              this.dataService.geometricalData.forEach((layer, indexLayer: number) => {
                layer.geometry.forEach((geometryItem, indexGeometryItem: number) => {
                  if(geometryItem.title && geometryItem.title === 'previewimage') {
                    layer.geometry.splice(indexGeometryItem,1);
                  }
                });
              });
              this.dataService.filterSpecialGeometries(1/this.viewerOperationsService.viewerMultiplier).subscribe(() => {
                  this.dataService.mirrorAllToXAxis().subscribe(() => {
                    let createdPlan = projectsResponse.data.projects
                    .find(projectItem => projectItem.id === projectId).projectDrawings
                    .find(drawingItem => drawingItem.drawingName === planData.drawingName);
                    this.planService.activePlanId = createdPlan.id;
                    this.projectService.activeProjectId = projectId;
                    this.planService.drawingName = createdPlan.drawingName;
                    this.planService.savePlanAction(
                      projectId,
                      this.dataService.deletedEntityNames,
                      this.fileService.dxfFilePath,
                      this.dataService.newOrUpdatedEntities,
                      this.dataService.layerNames,
                      this.viewerOperationsService.editing).subscribe(() => {
                        if(this.viewerOperationsService.editing) {
                          this.dataService.mirrorAllToXAxis().subscribe(() => {
                            this.viewerOperationsService.scaleGeometry(this.dataService.geometricalData,1/this.viewerOperationsService.viewerMultiplier,false);
                            this.planService.recentlySaved = true;
                          })
                        }
                        observer.next(true);
                        this.notificationService.openNotification("save_plan_success");
                        this.viewerService._loadingSource.next(false);
                    },
                    error => {
                      this.notificationService.openNotification("save_plan_error");
                      this.viewerService._loadingSource.next(false);
                    });
                  })
                })
              })
          },
          error => {
            observer.error();
          })
        });
    })

    return obs;
  }

  closePlan() {
    if(!this.planService.recentlySaved) {
      let dialogRefPass = this.dialog.open(PlanCloseDialogComponent);
      dialogRefPass.afterClosed().subscribe(result => {
        if(result) {
          if(result === "save") {
            this.savePlan(1).subscribe(res => {
              if(res) {
                this.layerService.initLayersFromDataBase(null).subscribe(res=>{});
                this.router.navigate(['/projects']);
              }
            });
          }
          else if(result === "dismiss")  {
            this.layerService.initLayersFromDataBase(null).subscribe(res=>{});
            this.router.navigate(['/projects']);
          }
        }
      })
    }
    else {
      this.layerService.initLayersFromDataBase(null).subscribe(res=>{});
      this.router.navigate(['/projects']);
    }
  }

  controlActive(controlItem) {
    let result = this.viewerOperationsService.controlActive(controlItem.activeOn);
    return result && controlItem.id === 1 ? this.dataService.geometryExists() : true;
  }

}
