<div class="print-dialog" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutGap="20px">
        <div>
            <mat-form-field>
                <mat-label>{{"export.fileFormat" | translate }}</mat-label>
                <mat-select (selectionChange)="updateExportFileFormat($event.value)" [(ngModel)]="selectedValue">
                    <mat-option *ngFor="let exportFileFormat of exportFileFormats" [value]="exportFileFormat">{{exportFileFormat}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="50px">
        <button mat-flat-button class="ep-btn-call" (click)="closeDialog(selectedValue)">{{ "export.export" | translate }}</button>
        <button mat-flat-button (click)="closeDialog(false)">{{ "cancel" | translate }}</button>    
    </div>
</div>