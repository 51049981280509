import { Component, OnInit } from '@angular/core';
import { EventsPropagationService } from 'src/app/services/status-and-control-services/events-propagation/events-propagation.service';
import { GeometryPropertiesService } from 'src/app/services/operational-services/geometry-properties/geometry-properties.service';
import { ColorEvent } from 'ngx-color';
import { MatSelectChange } from '@angular/material/select';
import { ModesService } from 'src/app/services/status-and-control-services/modes/modes.service';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { DrawingService } from 'src/app/services/operational-services/drawing/drawing.service';
import { Subscription } from 'rxjs';
import { TextService } from 'src/app/services/operational-services/text/text.service';
import { SelectionService } from 'src/app/services/status-and-control-services/selection/selection.service';
import { DrawingMode } from 'src/app/models/enums/drawing-mode.enum';
import { ColorService } from 'src/app/services';

@Component({
  selector: 'app-text-tools',
  templateUrl: './text-tools.component.html',
  styleUrls: ['./text-tools.component.scss']
})
export class TextToolsComponent implements OnInit {
  modeSubscription: Subscription;
  fontSize: number;
  strokeColor: string;
  fillColor: string;
  currentMode: ActiveMode;
  fontStyles: Array<string>;
  fontStyle: string;
  text: string;
  textAlignVariants: Array<string>;
  textAlign: string;
  fontFamily: string;
  fontFamilies: Array<string>;
  verticalAlign: string;
  verticalAlignVariants: Array<string>;
  predifinedFormats: Array<any>;
  lineHeight: number;
  presetColors: Array<string>;
  frameColors: string[];

  constructor(
    private eventsPropagationService: EventsPropagationService,
    private geometryPropertiesService: GeometryPropertiesService,
    private textService: TextService,
    private modesService: ModesService,
    private drawingService: DrawingService,
    private selectionService: SelectionService,
    private colorService: ColorService
  ) {
    this.fontFamilies = this.textService.fontFamilies;
    this.textAlignVariants = this.textService.textAlignVariants;
    this.fontStyles = this.textService.fontStyles;
    this.fontStyle = this.textService.fontStyle;
    this.verticalAlignVariants = this.textService.verticalAlignVariants;
    this.strokeColor = this.geometryPropertiesService.activeStrokeColor;
    this.fillColor = this.geometryPropertiesService.activeFillColor;
    this.predifinedFormats = this.textService.predifinedFormats;
    const text = this.isEditMode() ? this.selectedText() : null;
    this.frameColors = this.colorService.defaultColors.map(a => a.colorHex);
    if(text) {
      this.initTextProperties(text);
    }
    else {
      this.initTextProperties();
    }
    this.subscribeToModes();
    this.presetColors = this.colorService.presetColors;

  }

  initTextProperties(data = null) {
    this.text = data && data.text ? data.text : "";
    this.fontFamily = data && data.fontFamily ? data.fontFamily : this.textService.fontFamily;
    this.textAlign = data && data.fontFamily ? data.textAlign : this.textService.textAlign;
    this.verticalAlign = data && data.verticalAlign ? data.verticalAlign : this.textService.verticalAlign;
    this.fontSize = data && data.fontSize ? data.fontSize : this.textService.fontSize;
    this.fontStyle = data && data.fontStyle ? data.fontStyle : this.textService.fontStyle;
    this.lineHeight = data && data.lineHeight ? data.lineHeight : this.textService.lineHeight;
  }

  subscribeToModes() {
    this.modeSubscription = this.modesService.activeMode$.subscribe(
      mode => {
        this.currentMode = mode;
      }
    )
  }

  ngOnInit(): void {
  }

  isEditMode(): boolean {
    return this.modesService.mode === ActiveMode.editMode;
  }

  isTextInsertMode(): boolean {
    return (this.modesService.mode === ActiveMode.insertMode && this.drawingService.drawingMode === DrawingMode.text);
  }

  onBlur() {
    this.eventsPropagationService.enableEvents();
  }

  onFocus() {
    this.eventsPropagationService.disableEvents();
  }

  ngOnDestroy() {
    this.modeSubscription.unsubscribe();
    this.onBlur();
  }

  setMode(event: MatSelectChange) {
    event.value === 2 ? this.setInsertMode() : this.setEditMode();
  }

  setPredefinedFormat(event: MatSelectChange) {
    event.value.properties.forEach(property => {
      if(this[property.name]) {
        this[property.name] = property.value;
      }
      this.updateParameter(property.value,property.name)
    });
  }

  updateParameter(event, parameterName: string) {
    this.textService.updateParameter((typeof event === 'number' || typeof event === 'string')? event : event.value, parameterName);
  }

  setInsertMode() {
    this.modesService.setMode(ActiveMode.insertMode);
    this.drawingService.setDrawingMode(10);
  }

  setEditMode() {
    this.modesService.setMode(ActiveMode.editMode);
  }

  updateActiveStrokeColor(event: ColorEvent) {
    this.geometryPropertiesService.setActiveStrokeColor(event);
    this.textService.updateParameter(event.color.hex, "strokeColor");
  }

  resetFillSettings() {
    this.updateActiveFillColor({color:{hex:""}});
    this.fillColor = "";

  }

  updateActiveFillColor(event) {
    this.geometryPropertiesService.setActiveFillColor(event);
    this.textService.updateParameter(event.color.hex, "fillColor");
    this.fillColor = event.color.hex;
  }

  selectedText() {
    return this.selectionService.selectedText();
  }

}
