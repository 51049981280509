<div class="bg-light w-100 p-tb">
    <div ngClass.gt-sm="p-lr-4" class="w-100" fxLayout="column" fxLayoutAlign="center center">
      <div class="w-100" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center" fxLayoutAlign="center center">
        <div ngClass.gt-sm="w-40" ngClass.sm="w-60" ngClass.xs="w-80" fxLayout="column" class="p-b-2">
          <div fxFlex.xs="20px"></div>
          <h2>Wir sind gerne für Sie da!</h2>       
          <h4>
              KUNDENSERVICE
          </h4>
          <small class="p-b">Bei Fragen zu unserem Service und Produktangebot sowie zur Bestellung erreichen Sie uns unter:</small>   
          <small>(08233) 38 11 23 </small> 
          <small>Mo - Do 8.00 - 17.00 Uhr</small> 
          <small>Fr 8.00 - 15.00 Uhr</small>
          <a href="mailto:service@forum-verlag.com" target="_blank">service&#64;forum-verlag.com</a>
          <h4>
              TECHNISCHE HOTLINE
          </h4>
          <small class="p-b">Bei Fragen zur Installation und Anwendung unserer digitalen Produkte hilft Ihnen unser technischer Support:</small>   
          <small>(08233) 38 11 12</small> 
          <small>Mo - Fr  8.00 - 15.00 Uhr </small>
        </div>
        <div ngClass.gt-sm="w-40" ngClass.sm="w-60" ngClass.xs="w-80" fxLayout="column" fxLayoutAlign="center start" class="p-tb-2" fxLayoutGap="10px">
            <a routerLink="/documentation">Dokumente</a>
            <a routerLink="/faq">FAQ</a>
            <a routerLink="/contact">Kontakt</a>
            <a href="https://www.forum-verlag.com/impressum" target="_blank">Impressum</a>
            <a href="https://www.forum-verlag.com/datenschutz" target="_blank">Datenschutz</a>
            <a href="https://www.forum-verlag.com/agb-und-lizenzbedingungen" target="_blank">AGB- und Lizenzbedingungen</a>
        </div>
      </div>
      <div fxLayout="row" class="p-b" fxLayoutAlign="center center">
        <small fxLayoutAlign="center center">
          Alle Rechte vorbehalten © FORUM VERLAG HERKERT GMBH
        </small>
      </div>
      <div>
        <small>
          Powered by &nbsp; <a href="https://technicsatelier.com/" target="_blank">technicsatelier GmbH</a>
        </small>
      </div>
      <div>
        <small>
          Mit freundlicher Unterstützung von: Christopher Biffar, Biffar Quality Solutions GmbH, &nbsp; <a href="https://biffar-ac.de/" target="_blank">www.biffar-ac.de</a>
        </small>
      </div>
    </div>
  </div>