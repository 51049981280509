import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from 'src/app/services';
import { ProfileService } from 'src/app/services/user/profile/profile.service';
import { InfoDialogComponent } from '../../dialogs/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-activation-code-input',
  templateUrl: './activation-code-input.component.html',
  styleUrls: ['./activation-code-input.component.scss']
})
export class ActivationCodeInputComponent implements OnInit {
  code: string;
  constructor(
    private profileService: ProfileService,
    private translationService: TranslationService,
    private router: Router,
    public dialog: MatDialog,

  ) {
    this.code = "";
  }

  ngOnInit(): void {
  }

  activateLicense() {
    this.profileService.requestMyProfile().subscribe((profileResponse: any) => {
      if(profileResponse && profileResponse.data && profileResponse.data.isActivated) {
        let licenseData = {
          licenseCode : this.code,
          email: profileResponse.data.email
          }
        this.profileService.setLicense(licenseData).subscribe(response => {
          if(!response.data && response.serviceErrorCode === 9) {
            let response = this.translationService.translateAPIResponse({serviceErrorCode: 9, kind: "ERROR"});
            this.dialog.open(InfoDialogComponent,{data: response});
          }
          else if(response) {
            this.router.navigate(["/projects"]);
            let response = this.translationService.translateAPIResponse({serviceErrorCode: 24, kind: "SUCCESS"});
            this.dialog.open(InfoDialogComponent,{data: response});
          }
        })
      }
      else {
        let response = this.translationService.translateAPIResponse({serviceErrorCode: 23, kind: "ERROR"});
        this.dialog.open(InfoDialogComponent,{data: response});
      }
    })
  }

}
