import { Geometry } from './geometry.model';
import { GeometricType } from '../enums/geometric-types.enum';
import { Circle } from './circle.model';
import { LwPolyline } from './lw-polyline.model';
import { Arc } from './arc.model';
import { Line } from './line.model';
import { Polyline } from './polyline.model';
import { Rectangle } from './rectangle.model';
import { EPImage } from './ep-image.model';
import { Polyligon } from './polygon.model';
import { Arrow } from './arrow.model';
import { Legend } from './legend.model';
import { EPText } from './text.model';
import { Frame } from './frame.model';
import { Vertex } from '../vertex.model';
import { VertexPosition } from '../position.model';

export class GeometryFactory {
    createGeometry(data,drawn=false): Geometry {
        if (data.geometricType === GeometricType.Circle) {
            return new Circle(data);
        } else if(
            data.geometricType === GeometricType.LwPolyline) {
                if(data.vertexes) {
                    if(data.fillColor) {
                        data.geometricType = GeometricType.Polygon;
                        return new Polyligon(data);
                    }
                    else {
                        data.geometricType = GeometricType.LwPolyline;
                        return new LwPolyline(data);
                    }
                }
        } else if(data.geometricType === GeometricType.Polyline) {
                if(data.vertexes) {
                    if(data.fillColor) {
                        data.geometricType = GeometricType.Polygon;
                        return new Polyligon(data);
                    }
                    else {
                        return new Polyline(data);
                    }
                }
        } else if(data.geometricType === GeometricType.Line) {
            return new Line(data);
        } else if(data.geometricType === GeometricType.Arc) {
            return new Arc(data);
        } else if(data.geometricType === GeometricType.Rectangle) {
            return new Rectangle(data);
        } else if(data.geometricType === GeometricType.Image) {
            return new EPImage(data);
        } else if(data.geometricType === GeometricType.Polygon) {
            if(data.vertexes) {
                return new Polyligon(data);
            }
        } else if(data.geometricType === GeometricType.Solid) {
            data.geometricType = GeometricType.Polygon;
            data.vertexes = this.defineSolidVertices(data);
            if(data.vertexes) {
                return new Polyligon(data);
            }
        } else if(data.geometricType === GeometricType.Arrow) {
            return new Arrow(data);
        } else if(data.geometricType === GeometricType.Frame) {
            return new Frame(data);
        } else if(data.geometricType === GeometricType.Legend) {
            return new Legend(data);
        } else if(data.geometricType === GeometricType.Text) {
            return new EPText(data);
        }
         else {
            return;
        }
    }

    defineSolidVertices(solid) {
        let vertexes = new Array<Vertex>();
        solid.firstCorner.position = new VertexPosition(solid.firstCorner);
        solid.secondCorner.position = new VertexPosition(solid.secondCorner);
        solid.thirdCorner.position = new VertexPosition(solid.thirdCorner);
        solid.fourthCorner.position = new VertexPosition(solid.fourthCorner);

        vertexes.push(new Vertex({...solid, ...solid.firstCorner}));
        vertexes.push(new Vertex({...solid, ...solid.thirdCorner}));
        vertexes.push(new Vertex({...solid, ...solid.fourthCorner}));
        vertexes.push(new Vertex({...solid, ...solid.secondCorner}));
        return vertexes;
    }
}
