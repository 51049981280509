<div class="full-height-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="40px">
  <div></div>
  <form class="ep-single-form" [formGroup]="contactForm" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
      <h3>Kontakt</h3>
      <span>Bitte teilen Sie Ihr Anliegen mit uns.</span>
      <div class="w-100">
          <div class="ep-form-control-container">
              <div class="ep-form-control">
                  <span>Anrede</span>
                  <select formControlName="salutation">
                    <option value=1>Herr</option>
                    <option value=2>Frau</option>
                    <option value=3>Divers</option>
                </select>           
              </div>
          </div>    
          <mat-error *ngIf="contactForm?.get('salutation').touched">
              <control-messages [control]="{controlProperties: contactForm?.get('salutation'), controlName: 'salutation'}">
              </control-messages>
          </mat-error>
      </div>
      <div class="ep-form-control-container">
          <div class="ep-form-control">
              <span>Vorname</span>
              <input type="text" formControlName="firstName">
          </div>
          <mat-error *ngIf="contactForm?.get('firstName').touched">
              <control-messages [control]="{controlProperties: contactForm?.get('firstName'), controlName: 'firstName'}">
              </control-messages>
          </mat-error>
      </div>
      <div class="ep-form-control-container">
          <div class="ep-form-control">
              <span>Nachname</span>
              <input type="text" formControlName="lastName">
          </div>
          <mat-error *ngIf="contactForm?.get('lastName').touched">
              <control-messages [control]="{controlProperties: contactForm?.get('lastName'), controlName: 'lastName'}">
              </control-messages>
          </mat-error>
      </div>
      <div class="ep-form-control-container">
        <div class="ep-form-control">
            <span>E-Mail</span>
            <input type="email" formControlName="email">
        </div>
        <mat-error *ngIf="contactForm?.get('email').touched">
            <control-messages [control]="{controlProperties: contactForm?.get('email'), controlName: 'email'}">
            </control-messages>
        </mat-error>
    </div>
    <div class="ep-form-control-container">
      <div class="ep-form-control">
          <span>Betreff</span>
          <input type="text" formControlName="topic">
      </div>
      <mat-error *ngIf="contactForm?.get('topic').touched">
          <control-messages [control]="{controlProperties: contactForm?.get('topic'), controlName: 'topic'}">
          </control-messages>
      </mat-error>
  </div>
  <div class="ep-form-control-container">
    <div class="ep-form-control">
        <span>Nachricht</span>
        <textarea type="text" formControlName="message"></textarea>
    </div>
    <mat-error *ngIf="contactForm?.get('message').touched">
        <control-messages [control]="{controlProperties: contactForm?.get('message'), controlName: 'message'}">
        </control-messages>
    </mat-error>
</div>


  </form>
  <div class="w-22" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
    <button mat-stroked-button (click)="onSubmit()" [disabled]="loading || contactForm.invalid">{{ "send" | translate }}</button>
    <button mat-stroked-button (click)="initProfile()">{{ "reset" | translate }}</button>
</div>
</div>