import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(
    private translate: TranslateService
  ) { }

  translateAPIResponse(data) {
    if(data.url) {
      if(data.url.includes("api/auth/signup") && data.error && data.status === 500 && data.error.message.includes("An error occurred while updating the entries. See the inner exception for details.")) {
        data.serviceErrorCode = this.translate.instant("apiResponses.18");
        data.kind = this.translate.instant("ERROR");
      }
      else if(data.url.includes("api/auth/signin") && data.error && data.status === 500 && data.error.message.includes("User could not be found")) {
        data.serviceErrorCode = this.translate.instant("apiResponses.8");
        data.kind = this.translate.instant("ERROR");
      }
      else if(data.url.includes("api/auth/signin") && data.error && data.status === 500 && data.error.message.includes("- VerifyHashes are wrong.")) {
        data.serviceErrorCode = this.translate.instant("apiResponses.20");
        data.kind = this.translate.instant("ERROR");
      }
      else if(data.url.includes("api/profile/setlicense") && data.error && data.status === 500 && data.error.message.includes("Object reference not set to an instance of an object.")) {
        data.serviceErrorCode = this.translate.instant("apiResponses.19");
        data.kind = this.translate.instant("ERROR");
      }
      else if(data.url.includes("api/file/getlayers") && data.error && data.status === 500 && data.error.message.includes("File extension is not valid.")) {
        data.serviceErrorCode = this.translate.instant("apiResponses.21");
        data.kind = this.translate.instant("ERROR");
      }
    }
    else {
      if(data && data.error) {
        data = data.error;
        data.kind = "ERROR";
      }
      if(data.serviceErrorCode === undefined) {
        data.serviceErrorCode = 0;
      }
      if(data.message) {
        if(data.message === "Unauthorized") {
          data.serviceErrorCode = 401;
        }
        else if(data.message.includes("User could not be found")) {
          data.serviceErrorCode = 8;
        }
        else if(data.message.includes("- VerifyHashes are wrong.")) {
          data.serviceErrorCode = 20;
        }
        else if(data.message.includes("Object reference not set to an instance of an object")) {
          data.serviceErrorCode = 10;
        }
        else if(data.message.includes("updateUser could not be null. (Parameter 'updateUser')")) {
          data.serviceErrorCode = 11;
        }
      }
      data.serviceErrorCode = this.translate.instant("apiResponses." + data.serviceErrorCode);
      data.kind = this.translate.instant(data.kind);
    }
    return data
  }

  translateSimpleToken(token) {
    token = this.translate.instant(token);
    return token;
}
}
