import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ValidationService } from '../../../services/status-and-control-services/validation/validation.service';
import { TranslationService } from '../../../services/operational-services/translation/translation.service';

@Component({
  selector: 'control-messages',
  template: '{{errorMessage}}'
})
export class ControlMessagesComponent {
  @Input() control: {controlProperties: UntypedFormControl, controlName: string};
  constructor(private translationService: TranslationService) { }

  get errorMessage() {
    if(this.control) {
      for (let propertyName in this.control.controlProperties.errors) {
        if (this.control.controlProperties.errors.hasOwnProperty(propertyName) && this.control.controlProperties.invalid) {
          return ValidationService.getValidatorErrorMessage(this.translationService, propertyName, this.control.controlProperties.errors[propertyName], this.control.controlName);
        }
      }
    }
    return null;
  }
}
