import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {DataService} from '../data/data.service';
import {map, take} from "rxjs/operators";
import {Layer} from "../../../models";
import {ModesService} from "../../status-and-control-services/modes/modes.service";
import {ActiveMode} from "../../../models/enums/active-mode.enum";
import {FileService} from "../file/file.service";
import { PlanService } from '../../user/plan/plan.service';

@Injectable({
  providedIn: 'root'
})
export class LayerService {
  activeLayer: string;
  activeLayer$: Observable<string>;
  private _activeLayerSource: BehaviorSubject<string>;
  private _layerNames: Array<string>;
  layerData$: Observable<Array<any>>;

  constructor(
    private dataService: DataService,
    private modeService: ModesService,
    private fileService: FileService,
    private planService: PlanService
  ) {
    this.layerData$ = this.dataService.geometricalData$
      .pipe(
        map(dataArray =>
          dataArray.map(({ geometry, ...data }) => data).reverse()
        )
      );
    this.layerNames = new Array<string>();
    this.initLayers().subscribe(res=>{
      this.setupObservables();
    });
  }

  initLayers(layerNames: string[] = null) {
    let obs: Observable<any> = new Observable(observer => {
      if(this.dataService.geometricalData && this.dataService.geometricalData.length) {
        this.activeLayer = this.dataService.geometricalData[0].layerName;
      }
      else {
        this.dataService.initGeometricalData();
        if(layerNames) {
          layerNames.forEach(name => {
            this.createLayer(name);
          })
          this.activeLayer = layerNames[0];
        }
        else {
          this.createBackgroundLayer();
          this.activeLayer = "Background";
        }
      }
      observer.next(true);
    });
    return obs;
  }

  resetLayers(exceptions: string[] = null) {
    this.dataService.initGeometricalData(exceptions);
  }

  initLayersFromResponse(layers: any[], preview: boolean = false) {
    this.layerNames = new Array<string>();
    layers = layers.filter(e => e.isSelectable);
    if(layers && layers.length) {
      let drawings = JSON.parse(localStorage.getItem("drawings"));
      if(!!drawings) {
        const layerOrder = drawings.find(drawing => drawing.id === this.planService.activePlanId)?.layerOrder ?? null;
        if(!!layerOrder) {
          const ordered = layers.sort((a, b) => {
            const indexA = layerOrder.indexOf(a.layerName);
            const indexB = layerOrder.indexOf(b.layerName);
            if (indexA === -1) {
              return 1;
            }
            if (indexB === -1) {
              return -1;
            }
            return indexA - indexB;
          });
        }
      }
      this.dataService.initGeometricalDataFromResponse(layers,preview).pipe(take(1)).subscribe(res => {
        if(!this.layerNames.length) {
          this.activeLayer = res.data[0].layerName;
        }
      });
    }
    else {
      this.dataService.initGeometricalData();
      this.activeLayer = "";
    }
    this.emitChanges();
    this.dataService.emitChanges();
  }

  initLayersFromDataBase(layers) {
    let obs: Observable<any> = new Observable(observer => {
      if(layers && layers.length) {
        this.dataService.initGeometricalDataFromDataBase(layers).subscribe(res => {
          if(!this._layerNames.length) {
            this.activeLayer = res[0].layerName;
          }
          else {
            this.activeLayer = this._layerNames[0];
          }
        });
      }
      else {
        this.dataService.initGeometricalData();
        this.activeLayer = "";
      }
        this.emitChanges();
        this.dataService.emitChanges();
        observer.next(true);
    });
    return obs;
  }

  createBackgroundLayer() {
    this.dataService.createLayer("Background");
  }

  setupObservables() {
    this._activeLayerSource = new BehaviorSubject(this.activeLayer);
    this.activeLayer$ = this._activeLayerSource.asObservable();
  }

  toggleLayerVisibility(index) {
    index = this.generateReversedIndex(index);
    this.dataService.geometricalData[index].isVisible = !this.dataService.geometricalData[index].isVisible;
    this.dataService.geometricalData[index].isLayerOn = !this.dataService.geometricalData[index].isLayerOn;
    if(!this.dataService.geometricalData[index].isVisible && this.activeLayer === this.dataService.geometricalData[index].layerName) {
      this.activeLayer = "";
      if(this.dataService.selectedElementsInLayerExist(index)) {
        this.dataService.resetSelection(this.activeLayer);
      }
    }
    this.emitChanges();
    this.dataService.emitChanges();
  }

  rearrangeLayers(event: CdkDragDrop<Array<string>>) {
    this.dataService.rearrangeLayers(event);
    this.emitChanges();
  }

  generateReversedIndex(index) {
    return this.dataService.generateReversedIndex(index);
  }

  toggleLayerSelection(layerName: string, index: number) {
    index = this.generateReversedIndex(index);
    if(this.activeLayer !== layerName && this.dataService.geometricalData[index].isVisible) {
      this.activeLayer = layerName;
      if(!this.dataService.selectedElementsInLayerExist(index)) {
        this.dataService.resetSelection(this.activeLayer);
      }
      this.emitChanges();
      this.dataService.emitChanges();
    }
  }

  emitChanges() {
    this._activeLayerSource.next(this.activeLayer);
  }

  get layerNames(): Array<string> {
    return this._layerNames;
  }

  set layerNames(value: Array<string>) {
    this._layerNames = value;
  }

  get layerNamesToRender(): Array<string> {
    const layerNames = this.layersSelectedByUser(this.dataService.getGeometricalData());
    return layerNames;
  }

  addGeometryToActiveLayer(activeGeometry, drawingMode,drawn=false) {
    return this.dataService.addGeometryToLayer(this.activeLayer, activeGeometry, drawingMode, drawn);
  }

  importImageToActiveLayer(image, originImage) {
    const obs: Observable<any> = new Observable(observer => {
      const geometry = {
        width: image.width,
        height: image.height,
        imagePath: image.src,
        origin: originImage,
        title: image.title
      };
      this.addGeometryToActiveLayer(geometry, 8).pipe(take(1)).subscribe(geomName => {
        observer.next(geomName);
      });
    });
    return obs;
  }

  removeActiveLayer() {
    this.dataService.removeLayerByName(this.activeLayer);
    if(this.modeService.mode === ActiveMode.editMode) {
      this.activeLayer = "";
      this.emitChanges()
    }
    else if(this.modeService.mode === ActiveMode.moveMode) {

      let deletedLayers = [this.activeLayer];
      this.activeLayer = "";
      this.fileService.deletePlanLayers(this.fileService.dxfFilePath, deletedLayers).subscribe(
        () => {},
        () => {}
      )
    }
  }

  createLayer(layerName:string) {
    this.dataService.createLayer(layerName);
    this.activeLayer = layerName;
    this.emitChanges();
  }

  insertNorthArrowIntoActivelayer(origin) {
    this.dataService.insertNorthArrowIntoLayer(this.activeLayer,origin);
  }

  private layersAvailable(layerDataSet: Array<Layer>): Array<string> {
    if(layerDataSet) {
      const layerNameList = layerDataSet.filter(a => a.isVisible).map(a => a.layerName);
      return layerNameList;
    }
    else return new Array<string>();
  }

  private layersSelectedByUser(layerDataSet: Array<Layer>): Array<string> {
    if(layerDataSet) {
      const layerNameList = layerDataSet.filter(a => a.isLayerOn).map(a => a.layerName);
      return layerNameList;
    }
    else return new Array<string>();
  }

  specialLayerActive() {
    return this.activeLayer === "Rahmen" || this.activeLayer === "Legende"
  }
}
