import { Component, OnInit } from '@angular/core';
import { LineTypeControl } from 'src/app/models/controls';
import { ColorService, ControlsService, DataService, ViewerService } from 'src/app/services';
import { ColorEvent } from 'ngx-color';
import { MatSelectChange } from '@angular/material/select';
import { ColorEnum } from 'src/app/models/enums/color.enum';
import { EventsPropagationService } from 'src/app/services/status-and-control-services/events-propagation/events-propagation.service';
import { GeometryPropertiesService } from 'src/app/services/operational-services/geometry-properties/geometry-properties.service';
import { SelectionService } from 'src/app/services/status-and-control-services/selection/selection.service';
import { GeometryEditingService } from 'src/app/services/operational-services/geometry-editing/geometry-editing.service';
import { CanvasDimensionsService } from 'src/app/services/status-and-control-services/canvas-dimensions/canvas-dimensions.service';
import { Subscription } from 'rxjs';
import { AppSettingsService } from 'src/app/services/status-and-control-services/app-settings/app-settings.service';
import { Rectangle } from 'src/app/models/geometries/rectangle.model';

@Component({
  selector: 'app-editing-common-properties-tools',
  templateUrl: './editing-common-properties-tools.component.html',
  styleUrls: ['./editing-common-properties-tools.component.scss']
})
export class EditingCommonPropertiesToolsComponent implements OnInit {
  posX: number;
  posY: number;
  currentLineType: LineTypeControl;
  lineTypesList: Array<LineTypeControl>;
  width: number;
  currentColor: string;
  geomDataSubscrioption: Subscription;
  units: string;
  canvasDimensions: any;
  selectionRect: Rectangle;
  presetColors: Array<string>;

  constructor(
    private eventsPropagationService: EventsPropagationService,
    private controlsService: ControlsService,
    private geometryPropertiesService: GeometryPropertiesService,
    private selectionService: SelectionService,
    private geometryEditingService: GeometryEditingService,
    private canvasDimensionsService: CanvasDimensionsService,
    private dataService: DataService,
    private appSettingsService: AppSettingsService,
    private viewerService: ViewerService,
    private colorService: ColorService
  ) {
      this.canvasDimensions = this.canvasDimensionsService.dimensions;
      this.width = 1;
      this.lineTypesList = this.controlsService.lineTypes;
      this.currentLineType = null;
      this.currentColor = ColorEnum.default;
      this.units = this.appSettingsService.activeUnitTypeName;
      this.geomDataSubscrioption = this.dataService.geometricalData$.subscribe(data => {
        let singleGeometry = this.selectionService.getSingleSelectedGeometry();
        this.selectionRect = this.selectionService.getSelectionRect();
        if(this.selectionRect) {
          this.posX = this.appSettingsService.dimensionToDisplay(this.selectionRect.origin.x);
          this.posY = this.appSettingsService.dimensionToDisplay(this.selectionRect.origin.y);
        }
        this.width = this.appSettingsService.dimensionToDisplay((singleGeometry && singleGeometry.geometryProperties && singleGeometry.geometryProperties.thickness) ? singleGeometry.geometryProperties.thickness : 1);
      });
      this.presetColors = this.colorService.presetColors;
    }

    ngOnInit(): void {
    }

    onBlur() {
      this.eventsPropagationService.enableEvents();
    }

    onFocus() {
      this.eventsPropagationService.disableEvents();
    }

    ngOnDestroy() {
      this.geomDataSubscrioption.unsubscribe();
      this.onBlur();
    }

    updateStrokeColorOfSelection(event: ColorEvent){
      this.geometryPropertiesService.updateStrokeColorOfSelection(event);
    }

    updateLineWidthOfSelection(width: number) {
      this.geometryPropertiesService.updateLineWidthOfSelection(width);
    }

    updateLineTypeOfSelection(event: MatSelectChange) {
      this.currentLineType = event.value;
      this.geometryPropertiesService.updateLineTypeOfSelection(event.value.type);
    }

    oneGeometrySelected() {
      return !this.selectionService.multipleObjectsSelected();
    }

    mirrorToYAxis() {
      this.geometryEditingService.mirrorSelectionToYAxis();
    }

    mirrorToXAxis() {
      this.geometryEditingService.mirrorSelectionToXAxis();
    }

    updatePosition() {
      let visualisationMultiplier = this.appSettingsService.getVisualisationMultiplier();
      let realPosX = this.posX/visualisationMultiplier;
      let realPosY = this.posY/visualisationMultiplier;
      let offset = {
          x: this.selectionRect.origin.x - realPosX,
          y: this.selectionRect.origin.y - realPosY,
      }
      this.geometryEditingService.repositionSelection(offset);
    }
}
