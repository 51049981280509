<div class="additional-tools-element" *ngIf="(isEditMode() && selectedText() )|| isTextInsertMode()" fxFlex fxLayout="column" fxLayoutAlign="start" fxLayoutGap="5px">
    <div class="ep-form-control-container" fxLayout="row" fxLayoutAlign="center center">
        <div class="w-100">
            <div class="ep-form-control">
                <span>Text</span>
                <textarea type="text" [(ngModel)]="text" (blur)="onBlur()" (focus)="onFocus()"
                (ngModelChange)="updateParameter($event,'text')"></textarea>
            </div>
        </div>
    </div>
    <div class="w-100">
        <mat-form-field class="w-100">
            <mat-label>Text Stil</mat-label>
            <mat-select (selectionChange)="updateParameter($event,'fontStyle')" [(ngModel)]="fontStyle">
                <mat-option [value]="fontStyle" *ngFor="let fontStyle of fontStyles">
                    <span>{{ "text.fontStyle." + fontStyle | translate}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="w-100">
        <mat-form-field class="w-100">
            <mat-label>Textausrichtung horizontal</mat-label>
            <mat-select (selectionChange)="updateParameter($event,'textAlign')" [(ngModel)]="textAlign">
                <mat-option [value]="textAlign" *ngFor="let textAlign of textAlignVariants">
                    <span>{{ "text.align." + textAlign | translate}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="w-100">
        <mat-form-field class="w-100">
            <mat-label>Textausrichtung vertikal</mat-label>
            <mat-select (selectionChange)="updateParameter($event,'verticalAlign')" [(ngModel)]="verticalAlign">
                <mat-option [value]="verticalAlign" *ngFor="let verticalAlign of verticalAlignVariants">
                    <span>{{ "text.verticalAlign." + verticalAlign | translate}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="w-100">
        <mat-form-field class="w-100">
            <mat-label>Text Art</mat-label>
            <mat-select (selectionChange)="updateParameter($event,'fontFamily')" [(ngModel)]="fontFamily">
                <mat-option [value]="fontFamily" *ngFor="let fontFamily of fontFamilies">
                    <span [ngStyle]="{'font-family': fontFamily}">{{fontFamily}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="w-100">
        <mat-form-field class="w-100">
            <mat-label>Text festlegen als</mat-label>
            <mat-select (selectionChange)="setPredefinedFormat($event)">
                <mat-option [value]="predifinedFormat" *ngFor="let predifinedFormat of predifinedFormats">
                    <span>{{predifinedFormat.name}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="ep-form-control-container" fxLayout="row" fxLayoutAlign="center center">
        <div class="w-100">
            <div class="ep-form-control" >
                <span>Zeilenhöhe</span>
                <input type="number" [(ngModel)]="lineHeight" (blur)="onBlur()" (focus)="onFocus()"
                (ngModelChange)="updateParameter($event,'lineHeight')">
            </div>
        </div>
    </div>
    <div class="ep-form-control-container" fxLayout="row" fxLayoutAlign="center center">
        <div class="w-100">
            <div class="ep-form-control">
                <span>Textgröße</span>
                <input type="number" [(ngModel)]="fontSize" (blur)="onBlur()" (focus)="onFocus()"
                (ngModelChange)="updateParameter($event,'fontSize')">
            </div>
        </div>
    </div>
    <div class="w-100">
        <mat-label class="font-grey">Textfarbe</mat-label>
        <color-sketch [presetColors]="frameColors" [color]="strokeColor" (onChangeComplete)="updateActiveStrokeColor($event)"></color-sketch>
    </div>
    <div class="w-100">
        <mat-label class="font-grey">Texthintergrund</mat-label>
        <button mat-button class="p-tb" [disabled]="!fillColor" [ngClass]="{'disabled-ep': !fillColor}" (click)="resetFillSettings()">Hintegrund zurücksetzen</button>
        <color-sketch [presetColors]="frameColors" [color]="fillColor ? fillColor : '#000000'" (onChangeComplete)="updateActiveFillColor($event)"></color-sketch>
    </div>
</div>