import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Point } from 'src/app/models';
import { ViewerService } from '../viewer/viewer.service';
import { ViewerOperationsService } from '../viewer-operations/viewer-operations.service';
import { ModesService } from '../../status-and-control-services/modes/modes.service';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { DrawingService } from '../drawing/drawing.service';
import { DrawingMode } from 'src/app/models/enums/drawing-mode.enum';
import { SelectionService } from '../../status-and-control-services/selection/selection.service';
import { GeometryEditingService } from '../geometry-editing/geometry-editing.service';
import { TextService } from '../text/text.service';
import { GeometryPropertiesService } from '../geometry-properties/geometry-properties.service';
import { FileService } from '../file/file.service';
import { LayerService } from '../layer/layer.service';
import { DataService } from '../data/data.service';
import { Rectangle } from 'src/app/models/geometries/rectangle.model';

@Injectable({
  providedIn: 'root'
})
export class MouseInteractionsService {
  mousePosition: Point;
  _mousePositionSource: BehaviorSubject<Point>;
  mousePosition$: Observable<Point>;
  cameraOffset: Point;
  _cameraOffsetSource: BehaviorSubject<any>;
  cameraOffset$: Observable<any>;
  isDragging: boolean = false;
  prevOffset: any;
  recentlyMoved: boolean;

  constructor(
    private viewerService: ViewerService,
    private viewerOperationsService: ViewerOperationsService,
    private modesService: ModesService,
    private drawingService: DrawingService,
    private selectionService: SelectionService,
    private geometryEditingService: GeometryEditingService,
    private textService: TextService,
    private geometryPropertiesService: GeometryPropertiesService,
    private fileService: FileService,
    private layerService: LayerService,
    private dataService: DataService
  ) {
    this.mousePosition = new Point();
    this._mousePositionSource = new BehaviorSubject(this.mousePosition);
    this.mousePosition$ = this._mousePositionSource.asObservable();
    this.cameraOffset = new Point();
    this._cameraOffsetSource = new BehaviorSubject({offset: this.cameraOffset,mousePos: new Point()});
    this.cameraOffset$ = this._cameraOffsetSource.asObservable();
    this.prevOffset = { x: 0, y: 0 };
    this.recentlyMoved = false;
  }

  setMouseCursor(canvas, cursor: string) {
    canvas.style.cursor = cursor;
  }

  onMouseMove(event: MouseEvent, rect, context, canvas: HTMLCanvasElement, scale) {
    let mousePos: Point = this.getTransformedMousePosition(event,rect);
    let selectionRect: Rectangle = this.selectionService.getSelectionRect();
    let multiple: boolean = this.selectionService.multipleObjectsSelected();
    let selectedRotatable: boolean = this.selectionService.getSelectedRotatableGeometry();
    let intersectedControl = null;
    let mouseBuff = {...mousePos};
    mouseBuff.x = mousePos.x - this.viewerOperationsService.translation.x;
    mouseBuff.y = mousePos.y - this.viewerOperationsService.translation.y;
    let intersects = selectionRect && selectionRect.mouseIntersectsGeometry(mouseBuff,context,canvas,this.viewerService.scale/* ,selectedRotatable && !multiple */);
    if(selectionRect) {
      intersectedControl = selectionRect.findControlToSnap(mousePos, context, canvas, this.viewerService.scale, selectedRotatable && !multiple);
    }
    if(intersectedControl && intersectedControl.name === "rotation") {
      this.setMouseCursor(canvas,"crosshair")
    }
    else if((this.geometryEditingService.lineControlsEditingMode || this.geometryEditingService.wallOpeningMode) && (this.geometryEditingService.lineControlPointBuffer) && this.selectionService.activeElement) {
      this.setMouseCursor(canvas,"none")
    }
    else if(this.geometryEditingService.lineControlsEditingMode || this.geometryEditingService.wallOpeningMode) {
      this.setMouseCursor(canvas,"crosshair")
    }
    else if(intersectedControl) {
      this.setMouseCursor(canvas,"nwse-resize")
    }
    else if(intersects && !intersectedControl) {
      this.setMouseCursor(canvas,"move")
    }
    else {
      this.setMouseCursor(canvas,"unset");
    }
    if (this.isDragging && (this.modesService.mode === ActiveMode.moveMode || this.modesService.editableMode)) {
      if(this.modesService.mode === ActiveMode.moveMode) {
        this.setMouseCursor(canvas,"grabbing");
      }
      this.calculateCameraOffset(event,rect)
      this.calculatePrevOffset(event,rect);
      let mousePos: Point = this.getTransformedMousePosition(event,rect);
      if(
        !this.selectionService.selectingRect
      ) {
        this._cameraOffsetSource.next({offset:this.cameraOffset,mousePos: mousePos, move: event.which === 3 ? true : false});
      }
      else if(
        this.isDragging && this.modesService.editableMode && this.selectionService.selectingRect
        ) {
          this.selectionService.dragRectSelection(mousePos);
          this.emitMousePosition(event, rect);
        }
    }
    else if(!this.isDragging && this.modesService.mode === ActiveMode.moveMode) {
      this.setMouseCursor(canvas,"grab");
    }
    else if(this.modesService.editableMode && !this.isDragging) {
      this.emitMousePosition(event, rect);
    }
    else if(!this.drawingService.drawingError && this.modesService.mode === ActiveMode.insertMode) {
      this.setMouseCursor(canvas,"crosshair");
      if(this.drawingService.activeGeometry && (this.drawingService.activeGeometry.gridSnapped || this.drawingService.activeGeometry.intersectedControl)) {
        this.setMouseCursor(canvas,"none");
      }
      if(!this.isDragging && (!this.drawingService.activeGeometry || (this.drawingService.activeGeometry && !this.drawingService.activeGeometry.step)) && (this.drawingService.drawingMode !== DrawingMode.arc && !this.drawingService.multipleLineMode())) {
        this.emitMousePosition(event, rect);
      }
      else if(!this.isDragging && (this.drawingService.drawingMode === DrawingMode.arc || (this.drawingService.multipleLineMode() && (!this.drawingService.activeGeometry || !this.drawingService.activeGeometry.step || (this.drawingService.activeGeometry && this.drawingService.activeGeometry.step === 2))))) {
        this.emitMousePosition(event, rect);
      }
      else if(this.isDragging && this.drawingService.activeGeometry && this.drawingService.drawingMode === DrawingMode.image) {
        let mousePos: Point = this.getMousePosition(event,rect);
        this._mousePositionSource.next(mousePos);
      }
      else if(this.isDragging && this.drawingService.activeGeometry) {
        this.emitMousePosition(event, rect);
      }
    }
  }

  onMouseClick(event: MouseEvent,rect,context,canvas) {
    if(!this.isDragging) {
      if(this.modesService.mode === ActiveMode.insertMode && this.drawingService.drawingMode === DrawingMode.arc && !this.drawingService.activeGeometry) {
        this.emitMousePosition(event, rect);
      }
      else if(this.modesService.mode === ActiveMode.insertMode && this.drawingService.drawingMode === DrawingMode.arc && !this.drawingService.activeGeometry.step) {
        this.drawingService.setDrawingStep(1);
        this.emitMousePosition(event, rect);
      }
      else if(this.modesService.mode === ActiveMode.insertMode && this.drawingService.drawingMode === DrawingMode.arc && this.drawingService.activeGeometry.step === 1 && this.drawingService.isDrawing) {
        this.drawingService.setDrawingStep(2);
        this.emitMousePosition(event, rect);
      }
      else if(this.modesService.mode === ActiveMode.insertMode && this.drawingService.drawingMode === DrawingMode.arc && this.drawingService.activeGeometry.step === 2 && this.drawingService.isDrawing) {
        this.drawingService.setDrawingStep(3);
        this.emitMousePosition(event, rect);
      }
      else if(this.modesService.mode === ActiveMode.insertMode && this.drawingService.multipleLineMode() && !this.drawingService.activeGeometry.step) {
        this.drawingService.setDrawingStep(1);
        this.emitMousePosition(event, rect);
      }
      else if(this.modesService.mode === ActiveMode.insertMode && this.drawingService.multipleLineMode() && this.drawingService.activeGeometry.step && this.drawingService.isDrawing) {
        this.drawingService.setDrawingStep(1);
        this.emitMousePosition(event, rect);
      }
      else if(this.modesService.mode === ActiveMode.insertMode && this.drawingService.drawingMode === DrawingMode.text && !this.recentlyMoved) {
        let mousePos: Point = this.getTransformedMousePosition(event,rect);
        this.textService.addText(mousePos,this.viewerService.scale);
        this.drawingService.switchToEditing();
      }
      else if(this.modesService.editableMode &&
        !this.recentlyMoved &&
        this.selectionService.activeElement &&
        this.selectionService.oneTextSelected()
      ) {
        this.textService.editTextContents();
      }
      else if(
        this.modesService.editableMode &&
        !this.geometryEditingService.activeControl &&
        this.selectionService.activeElement &&
        this.geometryEditingService.lineControlsEditingMode &&
        this.selectionService.oneElementOfLineTypeSelected() &&
        !this.recentlyMoved
      ) {
        this.geometryEditingService.updateLineControls();
      }
      else if(
        this.modesService.editableMode &&
        !this.geometryEditingService.activeControl &&
        this.selectionService.activeElement &&
        this.geometryEditingService.wallOpeningMode &&
        this.selectionService.oneElementOfLineTypeSelected() &&
        !this.recentlyMoved
      ) {
        if(this.geometryEditingService.wallOpeningCreationMode !== 6) {
          this.geometryEditingService.updateWallOpenings();
        }
        else {
          let geometry = this.selectionService.getSingleSelectedGeometry();
          this.geometryEditingService.handleNewOpening(geometry);
        }
      }
      else if(this.modesService.editableMode && this.selectionService.activeElement !== "" && !this.recentlyMoved) {
        if(event.shiftKey && this.selectionService.selectionIncludesGeometry(this.selectionService.activeElement)) {
          this.selectionService.removeActiveElementFromSelection();
        }
        else if(!this.selectionService.selectedElementsExist() || (this.selectionService.selectedElementsExist() && event.shiftKey)) {
          this.selectionService.addElementToSelection();
        }
        else {
          this.geometryEditingService.openingBufferToDisplay = null;
          this.selectionService.resetSelection();
          this.selectionService.addElementToSelection();
        }
        this.emitMousePosition(event, rect);
      }
      else if(this.modesService.editableMode && this.selectionService.activeElement === "" && !this.recentlyMoved) {
        if(!event.shiftKey) {
          this.geometryEditingService.openingBufferToDisplay = null;
          this.selectionService.resetSelection();
          this.emitMousePosition(event, rect);
        }
      }
      else if(this.recentlyMoved) {
        this.recentlyMoved = false;
      }
    }
  }

  onMouseDoubleClick(event: MouseEvent,rect,context,canvas,scale) {
    if(this.modesService.mode === ActiveMode.insertMode && this.drawingService.multipleLineMode() && !event.shiftKey) {
      this.isDragging = false;
      this.drawingService.setDrawingStep(3);
      this.emitMousePosition(event, rect);
    }
    if(this.modesService.mode === ActiveMode.insertMode && this.drawingService.multipleLineMode() && event.shiftKey) {
      this.isDragging = false;
      this.drawingService.setDrawingStep(3);
      this.drawingService.drawActiveGeometry(null,context,canvas,scale,true,false)
    }
    else if(this.modesService.editableMode && !event.shiftKey) {
      this.isDragging = false;
      this.geometryEditingService.openingBufferToDisplay = null;
      this.selectionService.resetSelection();
      this.selectionService.addElementToSelection(false);
    }
  }

  onMouseDown(event: MouseEvent,rect, canvas, context) {
    if((this.modesService.editableMode || this.modesService.mode === ActiveMode.moveMode) && !this.isDragging) {
      this.calculatePrevOffset(event,rect);
      this.setMouseCursor(canvas,"grab");
      this.isDragging = true;
      if(this.modesService.editableMode && event.which === 1) {

        let mousePos: Point = this.getTransformedMousePosition(event,rect);
        let multiple: boolean = this.selectionService.multipleObjectsSelected();
        let selectedRotatable: boolean = this.selectionService.getSelectedRotatableGeometry();
        let selectionRect: Rectangle = this.selectionService.getSelectionRect();
        let mouseBuff = {...mousePos};
        mouseBuff.x = mousePos.x - this.viewerOperationsService.translation.x;
        mouseBuff.y = mousePos.y - this.viewerOperationsService.translation.y;
        let singleRect:boolean = selectedRotatable && !multiple;
        let selectionExists = this.selectionService.selectedElementsExist();
        let mouseIntersectsGeometry = selectionRect && selectionRect.mouseIntersectsGeometry(mouseBuff,context,canvas,this.viewerService.scale)
        let singleLine = this.selectionService.oneElementOfLineTypeSelected();
        let intersectedControl = (!singleLine && selectionRect) ? selectionRect.findControlToSnap(mousePos, context, canvas, this.viewerService.scale, singleRect) : (selectionRect ? singleLine.findControlToSnap(mousePos, context, canvas, this.viewerService.scale) : false);
        if(this.selectionService.activeElement == "" && (!selectionExists || (selectionExists && !mouseIntersectsGeometry && !intersectedControl))) {
          this.selectionService.startRectSelection(mousePos);
        }
        else if(selectionExists) {
          if(intersectedControl) {
            this.geometryEditingService.setActiveControl(intersectedControl);
          }
          else if(!mouseIntersectsGeometry && !event.shiftKey) {
            this.geometryEditingService.openingBufferToDisplay = null;
            this.selectionService.resetSelection();
          }
        }
      }
    }
    else if(this.modesService.mode === ActiveMode.insertMode && this.drawingService.drawingMode === DrawingMode.image) {
      let mousePos: Point = this.getMousePosition(event,rect);
      this.isDragging = true;
      this._mousePositionSource.next(mousePos);
    }
    else if(this.modesService.mode === ActiveMode.insertMode && (this.drawingService.drawingMode !== DrawingMode.arc && !this.drawingService.multipleLineMode())) {
      let mousePos = this.getTransformedMousePosition(event,rect);
      if(mousePos.x && mousePos.y) {
        this.isDragging = true;
        this._mousePositionSource.next(mousePos);
      }
    }
    else if(this.modesService.editableMode && event.which === 3) {
      this.calculatePrevOffset(event,rect);
      this.setMouseCursor(canvas,"grab");
      this.isDragging = true;
    }
  }

  getEventLocation(e,rect)
  {
      if (e.touches && e.touches.length == 1)
      {
          return { x:e.touches[0].clientX, y: e.touches[0].clientY }
      }
      else if (e.clientX && e.clientY)
      {
          let mousepos = this.getTransformedMousePosition(e,rect)
          return { x: mousepos.x, y: mousepos.y }
      }
  }

  calculatePrevOffset(event, rect: DOMRect) {
    this.prevOffset.x = this.getMousePosition(event,rect).x;
    this.prevOffset.y = this.getMousePosition(event,rect).y;
  }

  calculateCameraOffset(event, rect: DOMRect, scale=1) {
    let offset:any = {x:0,y:0};
    let mouse = this.getMousePosition(event,rect);
    offset.x = this.prevOffset.x - mouse.x;
    offset.y = this.prevOffset.y - mouse.y;
    this.cameraOffset.x = offset.x/scale;
    this.cameraOffset.y = offset.y/scale;
    this.recentlyMoved = true;
    //console.log(this.cameraOffset);
  }

  calculateTranslation(event,scale=1) {
    this.cameraOffset.x = event.deltaX;
    this.cameraOffset.y = event.deltaY;
    this.recentlyMoved = true;
    //console.log(this.cameraOffset);
  }

  onMouseUp(event: MouseEvent, rect, canvas) {
    this.isDragging = false;
    if(this.modesService.mode === ActiveMode.insertMode && this.drawingService.isDrawing && (this.drawingService.drawingMode !== DrawingMode.arc && !this.drawingService.multipleLineMode())) {
      this.drawingService.setDrawingStep(3);
      if(this.selectionService.draggingImage) {
        this.selectionService.resetDraggingImage();
      }
      this.emitMousePosition(event, rect);
    }
    else if(this.modesService.editableMode && this.selectionService.canRectSelect()) {
      this.selectionService.selectWithRect(event.shiftKey);
      this.selectionService.selectingRect = null;
    }
    else if(this.modesService.mode === ActiveMode.moveMode || this.modesService.editableMode) {
      this.setMouseCursor(canvas,"unset");
      if(this.modesService.editableMode && this.geometryEditingService.activeControl) {
        this.geometryEditingService.resetActiveControl();
      }
    }
    if(this.modesService.editableMode || this.modesService.mode === ActiveMode.moveMode) {
     /*  this.fileService.requestSVGLayerDetail().subscribe(response => {
        if(response && response.details) {
          this.layerService.resetLayers();
          this.viewerOperationsService.resetFittingParams();
          this.layerService.initLayersFromResponse(response.details);
          this.dataService.emitChanges();
        }
      }) */
    }
  }

  onMouseWheel(event, rect,canvas) {
    if (event.ctrlKey) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.calculateCameraOffset(event,rect);
      this.viewerService.smoothScale(event,this.cameraOffset);
    }
    else {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.calculateTranslation(event);
      this._cameraOffsetSource.next({offset:this.cameraOffset,wheel:true});
    }
  }

  onImageDragEnter(event, rect, canvas) {
    if(this.selectionService.draggingImage) {
      this.onMouseDown(event,rect,canvas,null);
    }
  }

  onImageDrag(event, rect, context, canvas, scale) {
    if(this.selectionService.draggingImage) {
      this.onMouseMove(event,rect,context,canvas,scale);
    }
  }

  onImageDrop(event, rect, canvas) {
    if(this.selectionService.draggingImage) {
      this.onMouseUp(event,rect,canvas);
    }
  }

  getMousePosition(event: MouseEvent, rect: DOMRect): Point {
    let x = Math.round(event.clientX - rect.left);
    let y = Math.round(event.clientY - rect.top);
    //console.log(x,y)
    return new Point(x,y);
  }

  getTransformedMousePosition(event: MouseEvent, rect, scale?): Point {
    let trans = this.viewerOperationsService.translation;
    let x = Math.round((event.clientX - rect.left + trans.x));
    let y = Math.round((event.clientY - rect.top + trans.y));
    return new Point(x,y);
  }

  emitMousePosition(event: MouseEvent,rect) {
    let mousePos: Point = this.getTransformedMousePosition(event,rect);
    this._mousePositionSource.next(mousePos);
  }

}
