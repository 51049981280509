import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ProjectService } from 'src/app/services/user/project/project.service';

@Component({
  selector: 'app-project-choice',
  templateUrl: './project-choice.component.html',
  styleUrls: ['./project-choice.component.scss']
})
export class ProjectChoiceComponent implements OnInit {
  selectedProject: string;
  projectSummary: any;
  constructor(
    private projectService: ProjectService,
    public dialogRef: MatDialogRef<ProjectChoiceComponent>,
  ) {
    this.projectService.getMyProjects().subscribe((projectsSummary: any) => {
      this.projectSummary = projectsSummary.data.projects;
    });
  }

  ngOnInit(): void {
  }

  submitValue(value) {
    this.dialogRef.close(value);
  }

}
