import { Component, OnInit } from '@angular/core';
import { EventsPropagationService } from 'src/app/services/status-and-control-services/events-propagation/events-propagation.service';
import { ModesService } from 'src/app/services/status-and-control-services/modes/modes.service';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { ColorService, ControlsService, DataService, TranslationService, ViewerOperationsService, ViewerService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Frame } from 'src/app/models/geometries/frame.model';
import { Color, VertexPosition } from 'src/app/models';
import { MatSelectChange } from '@angular/material/select';
import { LineTypeControl } from 'src/app/models/controls';
import { InfoDialogComponent } from '../../dialogs/info-dialog/info-dialog.component';
import { GeometricType } from 'src/app/models/enums/geometric-types.enum';
import { GeometryPropertiesService } from 'src/app/services/operational-services/geometry-properties/geometry-properties.service';

@Component({
  selector: 'app-frame-tools',
  templateUrl: './frame-tools.component.html',
})
export class FrameToolsComponent implements OnInit {

  title: string;
  fontSize: number;
  paperFormatLocked: boolean;
  geometry: any;
  geometrySubscription: Subscription;
  strokeColor: string;
  frameColors: string[];
  geometryProperties: any = {
    lineType: 0,
    thickness: 2,
    strokeColor: null
  };
  lineTypesList: Array<LineTypeControl>;

  constructor(
    private eventsPropagationService: EventsPropagationService,
    private modesService: ModesService,
    private viewerOperationsService: ViewerOperationsService,
    private controlsService: ControlsService,
    private dataService: DataService,
    private colorService: ColorService,
    public dialog: MatDialog,
    public translationService: TranslationService,
    public geometryPropertiesService: GeometryPropertiesService
  ) {
    this.geometryPropertiesService.sideRatioLocked = false;
    this.fontSize = 20;
    this.geometry = this.dataService.getGeometricalData();
    this.paperFormatLocked = true;
    this.frameColors = this.colorService.legendFrameColors.map(a => a.colorHex);
    this.lineTypesList = this.controlsService.lineTypes;
    this.geometrySubscription = this.dataService.geometricalData$.subscribe( data => {
      this.getFrameElements();
      this.title = this.title ? this.title : "FLUCHT- UND RETTUNGSPLAN";
      this.strokeColor = this.strokeColor ? this.strokeColor : "#008e5e";
      this.paperFormatLocked = this.paperFormatLocked ? this.paperFormatLocked : true;
    })
  }

  ngOnInit(): void {
  }

  onBlur() {
    this.eventsPropagationService.enableEvents();
  }

  getFrameElements() {
    let frameData: any = this.dataService.getGeometricalDataFromLayer("Rahmen");
    if(frameData && frameData[0] && frameData[0].geometricType === GeometricType.Frame) {
      let frame: Frame = frameData[0];
      this.geometryProperties = frame.geometryProperties;
      this.title = frame.title;
      this.fontSize = frame.fontSize;
      this.strokeColor = frame.geometryProperties.strokeColor.rgbToHex(frame.geometryProperties.strokeColor);
      this.paperFormatLocked = frame.paperFormatLocked;
    }
  }

  isInsertMode(): boolean {
    return this.modesService.mode === ActiveMode.insertMode;
  }

  isEditMode(): boolean {
    return this.modesService.mode === ActiveMode.editMode;
  }

  onFocus() {
    this.eventsPropagationService.disableEvents();
  }

  togglePaperFormatLock() {
    this.paperFormatLocked = !this.paperFormatLocked;
    if(this.isEditMode) {
      this.generateFrame();
    }
  }

  generateFrame() {
    let extrema = this.viewerOperationsService.findGlobalExtrema(this.dataService.geometricalData,["Rahmen"]);
    if(extrema) {
      let frameData = {
        title: this.title,
        fontSize: this.fontSize,
        origin: new VertexPosition({x: extrema.minX - 20, y: extrema.minY - this.fontSize - 40}),
        width: extrema.maxX - extrema.minX + 40,
        height: extrema.maxY - extrema.minY + this.fontSize + 60,
        geometryProperties: this.geometryProperties,
        paperFormatLocked: this.paperFormatLocked
      }
      let stroke = new Color(this.strokeColor)
      this.dataService.createSpecialLayer("frame",frameData,stroke).subscribe(data => {});
    }
    else {
      let errorMessage = this.translationService.translateSimpleToken("frame.noGeometries");
      this.dialog.open(InfoDialogComponent,{
        data: {
          serviceErrorCode: errorMessage
        }
      })
    }
  }

  updateCurrentFrameColor(event) {
    this.strokeColor = event.color.hex;
  }

  updateLineWidth(width: number) {
    this.geometryProperties.thickness = width;
  }

  updateLineType(event: MatSelectChange) {
    this.geometryProperties.lineType = event.value;
  }

}
