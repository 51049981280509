<div class="grid-setting-dialog">
    <form [formGroup]="settingsForm" (submit)="onSubmit()" class="ep-form-control-container" fxLayout="column" fxLayoutAlign="center start">
        <h3>{{ "settings.gridSettings" | translate}}</h3>
        <mat-checkbox formControlName="gridOn">
            {{ "settings.gridOn" | translate }}
        </mat-checkbox>
        <mat-checkbox formControlName="gridOnForeground">
            {{ "settings.gridOnForeground" | translate }}
        </mat-checkbox>
        <mat-checkbox formControlName="snapping">
            {{ "settings.gridSnapping" | translate }}
        </mat-checkbox>
        <div class="w-100">
            <div class="ep-form-control">
                <span class="additional-tools-span">Rasterfeinheit ({{units}})</span>
                <input formControlName="cellSize" type="number" (blur)="onBlur()" (focus)="onFocus()">
            </div>
        </div>
    </form>
    <div class="w-100">
        <mat-label class="font-grey">Rasterfarbe</mat-label>
        <button mat-button class="p-tb" [disabled]="settingsForm.value.gridColor === '#bbb'" [ngClass]="{'disabled-ep': settingsForm.value.gridColor === '#bbb'}" (click)="setColor('#bbb','gridColor')">Rasterfarbe zurücksetzen</button>
        <color-sketch class="dialog-color-picker" [color]="settingsForm.value.gridColor ? settingsForm.value.gridColor : '#bbb'" (onChangeComplete)="setColor($event.color.hex,'gridColor')"></color-sketch>
    </div>
    <div class="w-100">
        <mat-label class="font-grey">Hintegrundfarbe</mat-label>
        <button mat-button class="p-tb" [disabled]="settingsForm.value.backgroundColor === '#aaa'" [ngClass]="{'disabled-ep': settingsForm.value.backgroundColor === '#aaa'}" (click)="setColor('#aaa','backgroundColor')">Hintegrund zurücksetzen</button>
        <color-sketch class="dialog-color-picker" [color]="settingsForm.value.backgroundColor ? settingsForm.value.backgroundColor : '#aaa'" (onChangeComplete)="setColor($event.color.hex,'backgroundColor')"></color-sketch>
    </div>
</div>
<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
    <button mat-flat-button class="ep-btn-call" (click)="onSubmit()" [disabled]="settingsForm.invalid">{{ "save" | translate }}</button>
    <button mat-stroked-button (click)="resetSettings()">{{ "reset" | translate }}</button>
    <button mat-stroked-button (click)="closeDialog(false)">{{ "cancel" | translate }}</button>
</div>
