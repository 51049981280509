import { Component, OnInit } from '@angular/core';
import { EditorControl } from 'src/app/models/controls';
import { ControlsService, LayerService, ViewerOperationsService } from 'src/app/services';
import { AdditionalToolsService } from 'src/app/services/status-and-control-services/additional-tools/additional-tools.service';
import { Subscription } from 'rxjs';
import { ModesService } from 'src/app/services/status-and-control-services/modes/modes.service';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { DrawingService } from 'src/app/services/operational-services/drawing/drawing.service';
import { DrawingMode } from 'src/app/models/enums/drawing-mode.enum';
import { ControlActiveSettingEnum } from 'src/app/models/enums/control-active-setting.enum';
import { GeometryPropertiesService } from 'src/app/services/operational-services/geometry-properties/geometry-properties.service';

@Component({
  selector: 'app-vert-editing-controls',
  templateUrl: './editing-controls.component.html',
  styleUrls: ['./editing-controls.component.scss']
})
export class VertEditingControlsComponent implements OnInit {
  controlItems: Array<EditorControl>;
  editMode: boolean;
  fillMode: boolean;
  arrowMode: boolean;
  frameMode: boolean;
  legend: boolean;
  editModeSubscription: Subscription;
  constructor(
    private controlsService: ControlsService,
    private additionalToolsService: AdditionalToolsService,
    private modesService: ModesService,
    private drawingService: DrawingService,
    private viewerOperationsService: ViewerOperationsService,
    private geometryPropertiesService: GeometryPropertiesService,
    private layerService: LayerService
  ) {
    this.controlItems = this.controlsService.controls.verticalControls.editingControlsList;
    this.editMode = false;
    this.editModeSubscription = this.modesService.activeMode$.subscribe(
      mode => {
        this.updateHighlightingState(mode);
      }
    );
  }

  updateHighlightingState(mode=null) {
    this.editMode = (mode != null && mode === ActiveMode.editMode);
    this.fillMode = this.modesService.mode === ActiveMode.insertMode && this.drawingService.drawingMode === DrawingMode.polygon;
    this.arrowMode = this.modesService.mode === ActiveMode.insertMode && this.drawingService.drawingMode === DrawingMode.arrow;
    this.frameMode = (mode != null && mode === ActiveMode.frameMode) &&
      this.additionalToolsService.activeTools === "frame" && this.additionalToolsService.additionalToolsOpen;
    this.legend = (mode != null && mode === ActiveMode.editMode) &&
      this.additionalToolsService.activeTools === "legend" && this.additionalToolsService.additionalToolsOpen;
  }

  ngOnInit(): void {
  }

  onButtonClick(controlsItem: EditorControl) {
    switch(controlsItem.id) {
      case 1: {
        this.toggleAdditionalTools("edit");
        this.enableEditMode();
        break
      }
      case 2: {
        this.setDrawingMode(2);
        this.toggleAdditionalTools("wall");
        this.enableInsertMode();
        break
      }
      case 6: {
        this.drawingService.setDrawingMode(DrawingMode.polygon);
        this.toggleAdditionalTools("fillcolors");
        this.enableInsertMode();
        break
      }
      case 8: {
        this.drawingService.setDrawingMode(DrawingMode.arrow);
        this.toggleAdditionalTools("arrows");
        this.enableInsertMode();
        break
      }
      case 9: {
        this.toggleAdditionalTools("group");
        break;
      }
      case 10: {
        this.toggleAdditionalTools("order");
        break;
      }
      case 11: {
        this.setDrawingMode(10);
        this.toggleAdditionalTools("text");
        this.enableInsertMode();
        break;
      }
      case 14: {
        this.toggleAdditionalTools("legend");
        this.enableEditMode();
        break;
      }
      case 15: {
        this.toggleAdditionalTools("frame");
        this.enableFrameMode();
        break;
      }
      default: return
      }
  }

  specialCondition(controlsItem: EditorControl) {
    return (controlsItem.id === 2 ||
    controlsItem.id === 6 ||
    controlsItem.id === 8 ||
    controlsItem.id === 11) && this.layerService.specialLayerActive();
  }

  toggleAdditionalTools(componentName) {
    this.additionalToolsService.toggleAdditionalTools(componentName);
  }

  variableValueFromComponent(controlItem: EditorControl) {
    if(controlItem.varName) {
      let varValue = this[controlItem.varName];
      return varValue;
    }
  }

  enableEditMode() {
    if(!this.modesService.editableMode) {
      this.modesService.setMode(ActiveMode.editMode);
      if(!this.viewerOperationsService.editing) {
        this.viewerOperationsService.toggleEdittingViewer(ActiveMode.editMode);
      }
    }
  }

  enableFrameMode() {
    if(!this.modesService.editableMode &&
      !this.viewerOperationsService.editing) {
        this.geometryPropertiesService.sideRatioLocked = true;
        this.modesService.setMode(ActiveMode.frameMode);
        this.viewerOperationsService.toggleEdittingViewer(ActiveMode.frameMode);
    }
  }

  enableInsertMode() {
    this.modesService.setMode(ActiveMode.insertMode);
  }

  setDrawingMode(mode: number) {
    this.drawingService.setDrawingMode(mode);
  }

  closeAdditionalToolsByComponent(componentName) {
    this.additionalToolsService.closeAdditionalTools(componentName);
  }

  controlActive(activeOn) {
    let result = this.viewerOperationsService.controlActive(activeOn);
    return result;
  }
}
