import { Component, OnInit } from '@angular/core';
import { GeometryEditingService } from 'src/app/services/operational-services/geometry-editing/geometry-editing.service';
import { SelectionService } from 'src/app/services/status-and-control-services/selection/selection.service';

@Component({
  selector: 'app-group-tools',
  templateUrl: './group-tools.component.html',
  styleUrls: ['./group-tools.component.scss']
})
export class GroupToolsComponent implements OnInit {

  constructor(
    private geometryEditingService: GeometryEditingService,
    private selectionSerive: SelectionService
  ) {}

  ngOnInit(): void {
  }

  selectionExists() {
    return this.selectionSerive.selectedElementsExist();
  }

  groupSelection() {
    this.geometryEditingService.groupSelection();
  }
  ungroupSelection() {
    this.geometryEditingService.ungroupSelection();
  }

}
