import { Injectable } from '@angular/core';
import * as controlLists from "../../../../assets/controls-lists/control-lists.json";
import * as lineTypesList from "../../../../assets/controls-lists/linetype-list.json";
import * as lineModesList from "../../../../assets/controls-lists/linemode-list.json";
import * as lineIconList from "../../../../assets/controls-lists/line-icon-list.json";
import * as doorTypesList from "../../../../assets/controls-lists/door-type-list.json";
import * as ratiosList from "../../../../assets/controls-lists/ratios-list.json";
import { EditorControl, LineTypeControl } from 'src/app/models/controls';
import { ApplicationControls } from 'src/app/models/controls/application-controls.model';
import { LineModeControl } from 'src/app/models/controls/line-mode.model';

@Injectable({
  providedIn: 'root'
})
export class ControlsService {
  controls: ApplicationControls;
  lineTypes: Array<LineTypeControl>;
  lineModes: Array<LineModeControl>;
  lineIcons: Array<LineModeControl>;
  doorTypes: Array<LineModeControl>;
  ratios: Array<number>;
  constructor() {
    this.initControls();
  }

  initControls() {
    this.initEditorControls();
    this.initLineTypeControls();
    this.initLineModeControls();
    this.initLineIconControls();
    this.initdoorTypesControls();
    this.initRatios();
  }

  initRatios() {
    this.ratios = [];
    let lists: any = ratiosList;
    this.ratios = lists.default.ratios;
  }

  initEditorControls() {
    this.controls = new ApplicationControls();
    let lists: any = controlLists;
    Object.keys(lists.default).forEach(key => {
       Object.keys(lists.default[key]).forEach(elemkey => {
        lists.default[key][elemkey].forEach(element => {
          this.controls[key][elemkey].push(new EditorControl(element));
        });
      }); 
    });
  }

  initLineTypeControls() {
    this.lineTypes = new Array<LineTypeControl>();
    let lists: any = lineTypesList;
    lists.default.lineTypes.forEach(item => {
      this.lineTypes.push(new LineTypeControl(item));
    });
  }

  initLineModeControls() {
    this.lineModes = new Array<LineModeControl>();
    let lists: any = lineModesList;
    lists.default.lineModes.forEach(item => {
      this.lineModes.push(new LineModeControl(item));
    });
  }

  initLineIconControls() {
    this.lineIcons = new Array<LineModeControl>();
    let lists: any = lineIconList;
    lists.default.lineIcons.forEach(item => {
      this.lineIcons.push(new LineModeControl(item));
    });
  }

  initdoorTypesControls() {
    this.doorTypes = new Array<LineModeControl>();
    let lists: any = doorTypesList;
    lists.default.doorTypes.forEach(item => {
      this.doorTypes.push(new LineModeControl(item));
    });
  }

}
