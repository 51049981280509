import { Component, OnInit } from '@angular/core';
import { EditorControl } from 'src/app/models/controls';
import { ControlsService, LayerService, ViewerOperationsService, CanvasDimensionsService } from 'src/app/services';
import { AdditionalToolsService } from 'src/app/services/status-and-control-services/additional-tools/additional-tools.service';
import { ModesService } from 'src/app/services/status-and-control-services/modes/modes.service';
import { DrawingService } from 'src/app/services/operational-services/drawing/drawing.service';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { MatDialog } from '@angular/material/dialog';
import { FileImportDialogComponent } from 'src/app/components/dialogs/file-import-dialog/file-import-dialog.component';
import { InfoDialogComponent } from 'src/app/components/dialogs/info-dialog/info-dialog.component';
import { Point } from 'src/app/models';
import { ControlActiveSettingEnum } from 'src/app/models/enums/control-active-setting.enum';

@Component({
  selector: 'app-inserting-controls',
  templateUrl: './inserting-controls.component.html',
  styleUrls: ['./inserting-controls.component.scss']
})
export class InsertingControlsComponent implements OnInit {
  controlItems: Array<EditorControl>
  constructor(
    private controlsService: ControlsService,
    private additionalToolsService: AdditionalToolsService,
    private modesService: ModesService,
    private drawingService: DrawingService,
    private layerService: LayerService,
    private dialog: MatDialog,
    public viewerOperationsService: ViewerOperationsService,
    public canvasDimensionsService: CanvasDimensionsService,
    private LayerService: LayerService
  ) {
    this.controlItems = this.controlsService.controls.verticalControls.insertControlsList;
  }

  ngOnInit(): void {
  }

  onButtonClick(controlsItem: EditorControl) {
    switch(controlsItem.id) {
      case 3: {
        this.enableEditMode();
        this.toggleAdditionalTools("wall-openings");
        break;
      }
      case 4: {
        this.setDrawingMode(8,"symbols");
        this.toggleAdditionalTools("symbols");
        break;
      }
      case 5: {
        this.setDrawingMode(8,"objects");
        this.toggleAdditionalTools("objects");
        break;
      }
      case 12: {
        this.openImageImportDialog();
        break;
      }
      case 16: {
        this.enableEditMode();
        this.additionalToolsService.closeAdditionalTools("");
        this.insertNorthArrow();
        break;
      }
      default: return
    }
  }

  openImageImportDialog() {
    if(this.layerService.activeLayer) {
      this.dialog.open(FileImportDialogComponent,
        {
          data: {
            actionTitle: "selectImage",
            actionSubtitle: "selectImageSubtitle"
          }
        });
    }
    else {
      let errorInfo = {
        kind: "Keine Ebene ist Aktiv",
        serviceErrorCode: "Bitte wählen Sie eine Ebene aus für eine weitere Bearbeitung"
      }
      this.dialog.open(InfoDialogComponent, {data: errorInfo});
    }
  }

  get specialCondition(): boolean {
    return this.LayerService.specialLayerActive();
  }

  setDrawingMode(value, toolsName: string) {
    if(this.modesService.mode !== ActiveMode.insertMode) {
      this.modesService.setMode(ActiveMode.insertMode);
    }
    this.drawingService.setDrawingMode(value);
  }

  enableEditMode() {
    this.modesService.setMode(ActiveMode.editMode);
  }

  toggleAdditionalTools(componentName) {
    this.additionalToolsService.toggleAdditionalTools(componentName);
  }

  insertNorthArrow() {
    let origin = new Point(
      this.viewerOperationsService.translation.x + this.canvasDimensionsService.dimensions.width/2,
      this.viewerOperationsService.translation.y + this.canvasDimensionsService.dimensions.height/2
    );
    this.layerService.insertNorthArrowIntoActivelayer(origin);
  }

  controlActive(activeOn) {
    let result = this.viewerOperationsService.controlActive(activeOn);
    return result;
  }

}
