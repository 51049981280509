import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { PrintService } from 'src/app/services/operational-services/print/print.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { EventsPropagationService } from 'src/app/services/status-and-control-services/events-propagation/events-propagation.service';
import { FileService, ViewerService } from 'src/app/services';
import { DxfViewerService } from 'src/app/services/operational-services/dxf-viewer/dxf-viewer.service';

@Component({
  selector: 'app-print-setup-dialog',
  templateUrl: './print-setup-dialog.component.html',
  styleUrls: ['./print-setup-dialog.component.scss']
})
export class PrintSetupDialogComponent implements OnInit {
  @ViewChild('cropper') cropperRef: ElementRef;
  previewSubscription: Subscription;
  orientationVariants: Array<string>;
  formatVariants: Array<string>;
  orientation: string;
  aspectRat: number;
  format: string;
  preview: any;
  edges: boolean;
  gridOn: boolean;
  cropImgPreview: string;
  backgroundOn: boolean;
  printQuality: number;
  frameOn: boolean;
  lastUpdateCall: number;
  loading: boolean = false;
  loadingSub: Subscription;

  constructor(
    private printService: PrintService,
    public dialogRef: MatDialogRef<PrintSetupDialogComponent>,
    private eventsPropagationService: EventsPropagationService,
    public fileService: FileService,
    private viewerService: ViewerService,
    private dxfViewerService: DxfViewerService
  ) {
    this.orientationVariants = ["l","p"]
    this.formatVariants = ["a4","a3","a2"];
    this.preview = this.printService.preview;
    this.orientation = this.printService.options.orientation;
    this.aspectRat = this.orientation === 'l' ? 1.41421 : 1/1.41421;
    this.format = this.printService.options.format;
    this.edges = this.printService.options.edges;
    this.gridOn = this.printService.options.gridOn;
    this.backgroundOn = this.printService.options.backgroundOn;
    this.printQuality = this.printService.options.printQuality;
    this.frameOn = this.printService.options.frameOn;
    this.lastUpdateCall = new  Date().getTime();
    this.loadingSub = this.viewerService.loading$.subscribe(state => {
      this.loading = state;
    })
    this.previewSubscription = this.printService.printState$.subscribe(
      (state) => {
        if(state) {
          this.preview = this.printService.preview;
          this.cropImgPreview = this.printService.preview.croppedImage ? this.printService.preview.croppedImage : "";
        }
      },
      () => {
        //Error handling
        console.log('Error on previewSubscription');
      }
      )
    }

    ngOnDestroy() {
      this.preview = null;
      this.orientation = null;
      this.aspectRat = null;
      this.format = null;
      this.cropImgPreview = null;
      this.printService.resetPrintSettings();
      this.previewSubscription.unsubscribe();
      this.previewSubscription.unsubscribe();
    }

    ngOnInit(): void {
    }

    onBlur() {
      this.eventsPropagationService.enableEvents();
    }

    onFocus() {
      this.eventsPropagationService.disableEvents();
    }

    changeQuality(printQuality) {
      this.printQuality = printQuality;
      this.printService.updateOption("printQuality", this.printQuality).subscribe(res => {
        this.printQuality = res;
        this.printService.updatePreviewImage(null);
      });
    }

    updateImage(event: ImageCroppedEvent) {
      let callTime = new Date().getTime();
      let interval: any = callTime - this.lastUpdateCall;
      if(interval > 2000) {
        this.printService.updateImage({blob: event.blob, dimensions:{width:event.width, height: event.height}}).then(result => {
          this.cropImgPreview = result ?? null;
        });
        this.lastUpdateCall = callTime;
      }
    }

    cropperToStart() {
      let cropperRef: any = this.cropperRef;
      cropperRef.cropper.x2 -= cropperRef.cropper.x1;
      cropperRef.cropper.x1 = 0;
    }

    updateOrientation(orientationValue) {
      this.printService.updateOption("orientation", orientationValue).subscribe(res => {
        this.orientation = res;
        this.aspectRat = this.orientation === 'l' ? 1.41421 : 1/1.41421;
      });
    }

  updateFormat(formatValue) {
    this.viewerService._loadingSource.next(true);
    this.printService.updateOption("format", formatValue).subscribe(res => {
      this.format = res;
      this.dxfViewerService.onResize(this.printService.formatMultiplier).subscribe(() => {
        this.printService.emitPrintEvent("update");
      });
    });
  }

  toggleParam(event,option) {
    if(event.checked) {
      this.printService.updateOption(option, true).subscribe(res => {
        this[option] = res;
      });
    }
    else {
      this.printService.updateOption(option, true).subscribe(res => {
        this[option] = res;
      });
    }
  }

  onNoClick(): void {
    this.closeDialog(null);
  }

  closeDialog(value) {
    this.viewerService._loadingSource.next(true);
    if(!value) {
      this.ngOnDestroy();
    }
    this.dialogRef.close(value);
  }

}