import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/user/authentication/authentication.service';
import { ValidationService } from 'src/app/services/status-and-control-services/validation/validation.service';

@Component({ templateUrl: 'registration.component.html' })
export class RegistrationComponent implements OnInit {
  public registerForm: UntypedFormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.isAuthenticated()) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      companyName: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(200),
          Validators.minLength(3),
        ])
      ),
      salutation: new UntypedFormControl(
        null,
        Validators.compose([
          Validators.required
        ])
      ),
      email: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.email,
          Validators.required,
          Validators.maxLength(200),
          Validators.minLength(3),
        ])
      ),
      firstName: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(200),
          Validators.minLength(3),
        ])
      ),
      lastName: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(200),
          Validators.minLength(3),
        ])
      ),
      passwords: this.formBuilder.group(
        {
          password: new UntypedFormControl(
            '',
            Validators.compose([
              Validators.required,
              ValidationService.passwordValidator,
              Validators.maxLength(500),
              Validators.minLength(8),
            ])
          ),

          passwordConfirm: new UntypedFormControl('', Validators.required),
        },
        { validator: this.matchValidator }
      ),
    });
  }

  getPasswordsControls() {
    return;
  }

  matchValidator(group: UntypedFormGroup) {
    let values = [];
    for (let name in group.controls) {
      values.push(group.controls[name].value);
    }

    if (values[0] === values[1]) {
      // console.log('values match')
      return true;
    } else {
      group.setErrors({ matchValidator: false });
      group.controls['passwordConfirm'].setErrors({ matchValidator: false });
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {
    this.loading = true;
    let obj = this.registerForm.value;
    let { passwords, ...params } = obj;
    params.password = this.f['passwords'].get('password').value;
    params.passwordConfirm = this.f['passwords'].get('passwordConfirm').value;
    params.salutation = parseInt(params.salutation)
    this.authenticationService
      .register(params)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          this.router.navigate(['authorization/login']);
        },
        error => {
          this.loading = false;
          return;
        }
      );
  }
}
