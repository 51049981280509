import { EditorControl } from './editor-control.model';

export class HorizontalControls {
    fileControlsList: Array <EditorControl>;
    viewerControlsList: Array <EditorControl>;
    printControlsList: Array <EditorControl>;
    editingControlsList: Array <EditorControl>;

    constructor() {
        this.fileControlsList= new Array <EditorControl>();
        this.viewerControlsList= new Array <EditorControl>();
        this.printControlsList= new Array <EditorControl>();
        this.editingControlsList= new Array <EditorControl>();
    }
}