import { Injectable } from '@angular/core';
import { SelectionService } from '../../status-and-control-services/selection/selection.service';
import { LayerService } from '../layer/layer.service';
import { DataService } from '../data/data.service';
import { LineControlsEditingMode } from 'src/app/models/enums/line-controls-editing-mode.enum';
import { Polyline, Point } from 'src/app/models';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import { Geometry } from 'src/app/models/geometries/geometry.model';
import { GeometryPropertiesService } from '../geometry-properties/geometry-properties.service';
import { WallOpeningCreationMode, WallOpeningMode } from 'src/app/models/enums/wall-opening-mode.enum';
import { WallOpening } from 'src/app/models/geometries/wall-opening.model';
import { Rectangle } from 'src/app/models/geometries/rectangle.model';
import { ViewerOperationsService } from '../viewer-operations/viewer-operations.service';
import { GeometricType } from 'src/app/models/enums/geometric-types.enum';

@Injectable({
  providedIn: 'root'
})
export class GeometryEditingService {
  activeControl: any;
  lineControlsEditingMode: LineControlsEditingMode;
  wallOpeningMode: WallOpeningMode;
  wallOpeningCreationMode: WallOpeningCreationMode;
  fixedWallOpeningsWidth: any;
  wallOpeningBuffer: WallOpening;
  lineControlPointBuffer: Point;
  doorTypeMode: number;
  openingBufferToDisplay: any;

  constructor(
    private selectionService: SelectionService,
    private layerService: LayerService,
    private dataService: DataService,
    private geometryPropertiesService: GeometryPropertiesService,
    private viewerOperationsService: ViewerOperationsService
  ) {
    this.activeControl = null;
    this.lineControlsEditingMode = null;
    this.wallOpeningMode = null;
    this.wallOpeningCreationMode = 6;
    this.lineControlPointBuffer = new Point();
    this.wallOpeningBuffer = new WallOpening();
    this.doorTypeMode = 0;
    this.openingBufferToDisplay = null;
    this.fixedWallOpeningsWidth = {
      on: false,
      value: null
    };
  }

  removeSelection() {
    if(this.selectionService.selectedElementsExist()) {
      this.openingBufferToDisplay = null;
      this.dataService.removeSelectedElementsFromLayer(this.layerService.activeLayer);
    }
  }

  editSelection(data, context, canvas, scale) {
    let selectionRect: Rectangle = this.selectionService.getSelectionRect();
    let multiple: boolean = this.selectionService.multipleObjectsSelected();
    let selectedRotatable: boolean = this.selectionService.getSelectedRotatableGeometry();
    let singleLine = this.selectionService.oneElementOfLineTypeSelected();
    let mouseBuff = {...data.mousePos};
    mouseBuff.x = data.mousePos.x - this.viewerOperationsService.translation.x;
    mouseBuff.y = data.mousePos.y - this.viewerOperationsService.translation.y;
    let intersected = selectionRect ? selectionRect.mouseIntersectsGeometry(mouseBuff, context, canvas,scale) : null;
    this.openingBufferToDisplay = null;
    if(intersected && !this.activeControl) {
      this.repositionSelection(data.offset);
    }
    else if(this.activeControl && singleLine) {
      this.movePointOfLine(this.activeControl.name, data.mousePos, context, canvas, scale);
    }
    else if(this.activeControl && this.activeControl.name === "rotation" && selectedRotatable && !multiple) {
      this.rotateSelection(selectionRect, data.mousePos, scale);
    }
    else if(this.activeControl && this.activeControl.name === "rightBottom" && !multiple) {
      let sideRatioLocked = this.geometryPropertiesService.sideRatioLocked;
      if(sideRatioLocked) {
        data.offset.y = {...data.offset.x};
      }
      this.resizeSelection(selectionRect, data, this.activeControl,selectedRotatable);
    }
    else if(this.activeControl && this.activeControl.name !== "rotation" && !multiple) {
      this.resizeSelection(selectionRect, data, this.activeControl);
    }
  }

  setLineControlsEditingMode(mode) {
    this.lineControlsEditingMode = mode;
    this.wallOpeningMode = null;
    this.wallOpeningCreationMode = 6;
  }

  setWallOpeningMode(mode) {
    this.wallOpeningMode = mode;
    this.wallOpeningCreationMode = 6;
    this.lineControlsEditingMode = null;
  }

  setActiveControl(control) {
    this.activeControl = control;
  }

  resetActiveControl() {
    this.activeControl = null;
  }

  setDoorTypeMode(mode) {
    this.doorTypeMode = mode;
  }

  resizeSelection(selectionRect, resizeData, intersectedControl,allControls = false) {
    if(this.selectionService.selectedElementsExist() && (resizeData.offset.x || resizeData.offset.y)) {
      if(this.geometryPropertiesService.sideRatioLocked) {
        resizeData.offset.y = resizeData.offset.x;
      }
      this.dataService.updateSizeOfSelectedElementsInLayer(this.layerService.activeLayer, selectionRect, resizeData, intersectedControl,allControls);
    }
  }

  rotateSelection(selectionRect, mousePos, intersectedControl) {
    if(this.selectionService.selectedElementsExist() && (mousePos.x || mousePos.y)) {
      this.dataService.rotateSelectedElementsInLayer(this.layerService.activeLayer, selectionRect, mousePos, intersectedControl);
    }
  }

  mirrorSelectionToYAxis() {
    if(this.selectionService.selectedElementsExist()) {
      this.dataService.mirrorToYAxisSelectedElementsInLayer(this.layerService.activeLayer, this.selectionService.getSelectionRect());
    }
  }

  mirrorSelectionToXAxis() {
    if(this.selectionService.selectedElementsExist()) {
      this.dataService.mirrorToXAxisSelectedElementsInLayer(this.layerService.activeLayer, this.selectionService.getSelectionRect());
    }
  }

  movePointOfLine(propertyName, mousePos, context, canvas, scale) {
    if(this.selectionService.selectedElementsExist() && (mousePos.x || mousePos.y)) {
      let intersectedControl = this.dataService.mouseIntersectsGeometryControlPoint(this.layerService.activeLayer, mousePos, context, canvas, scale);
      mousePos.x = mousePos.x/scale;
      mousePos.y = mousePos.y/scale;
      this.dataService.updatePropertyOfSelection(this.layerService.activeLayer, propertyName, intersectedControl ? intersectedControl : mousePos);
    }
  }

  repositionSelection(offset) {
    if(this.selectionService.selectedElementsExist() && (offset.x || offset.y)/*  && !this.goingOutOfBounds(canvas, selectionRect,offset,scale )*/) {
      this.dataService.updatePosiitonOfSelectedElementsInLayer(this.layerService.activeLayer, offset);
    }
  }
  
  moveSelectionWithArrows(canvas,selectionRect: Rectangle, event, offset,scale) {
    event.preventDefault();
    this.repositionSelection(offset);
  }

  goingOutOfBounds(canvas, selectionRect: Rectangle,offset,scale) {
    let selectedRotatable: boolean = this.selectionService.getSelectedRotatableGeometry();
    let outOfBounds = false;
    if(selectionRect.origin.x < 1 && offset.x > 0) {
      outOfBounds = true
    }
    else if(selectionRect.origin.x+selectionRect.width > canvas.width-1 && offset.x < 0) {
      outOfBounds = true
    }
    if(selectionRect.origin.y+selectionRect.height > canvas.height-1 && offset.y < 0) {
      outOfBounds = true
    }
    if(selectionRect.origin.y < 1 && offset.y > 0) {
      outOfBounds = true
    }
    return outOfBounds;
  }

  groupSelection() {
    if(this.selectionService.selectedElementsExist()) {
      let groupId = Math.floor(Math.random()*Date.now()).toString();
      this.dataService.updateGeometryGroupInfo(this.layerService.activeLayer, groupId);
    }
  }

  ungroupSelection() {
    if(this.selectionService.selectedElementsExist()) {
      this.dataService.updateGeometryGroupInfo(this.layerService.activeLayer, null);
    }
  }

  copySelection() {
    if(this.selectionService.selectedElementsExist()) {
      this.dataService.copySelectedElementsInLayer(this.layerService.activeLayer);
    }
  }

  copySelectionToBuffer() {
    if(this.selectionService.selectedElementsExist()) {
      this.dataService.performBufferAction(this.layerService.activeLayer,"copy");
    }
  }

  cutSelectionToBuffer() {
    if(this.selectionService.selectedElementsExist()) {
      this.dataService.performBufferAction(this.layerService.activeLayer, "cut");
    }
  }

  pasteBuffer() {
    this.dataService.performBufferAction(this.layerService.activeLayer, "paste");
  }

  setLinePointBuffer(point: Point) {
    this.lineControlPointBuffer = new Point(point.x, point.y);
  }

  resetLinePointBuffer() {
    this.lineControlPointBuffer = new Point();
  }

  setWallOpeningBuffer(index: number) {
    this.wallOpeningBuffer.afterVertexIndex = index;
    this.wallOpeningBuffer.type = this.wallOpeningMode;
    if((this.doorTypeMode || this.doorTypeMode === 0) && this.wallOpeningMode === WallOpeningMode.door) {
      this.wallOpeningBuffer.doorKind = this.doorTypeMode;
    }
  }

  resetWallOpeningBuffer() {
    this.wallOpeningBuffer = new WallOpening();
  }

  updateLineControls() {
    if(this.lineControlPointBuffer && this.lineControlPointBuffer.x && this.lineControlPointBuffer.y) {
      this.dataService.updateLineControls(this.layerService.activeLayer,this.lineControlsEditingMode,this.lineControlPointBuffer)
    }
  }

  handleNewOpening(geometry) {
    if(geometry.geometricType === GeometricType.Line) {
      let newPolyline = geometry as Polyline;
      newPolyline.vertexes = [{position: geometry.startPoint}, {position: geometry.endPoint}];
      newPolyline.intersectedAfterVertexIndex = 0;
      this.dataService.removeSelectedElementsFromLayer(this.layerService.activeLayer);
      this.dataService.addGeometryToLayer(this.layerService.activeLayer,newPolyline,1).subscribe(res => {
      })
    }
    else {
      this.handleOpeninngOnPolyline(geometry);
    }
  }
  
  handleOpeninngOnPolyline(geometry: Polyline) {
    geometry = new Polyline(geometry);
    if(this.lineControlPointBuffer && this.lineControlPointBuffer.x && this.lineControlPointBuffer.y && this.wallOpeningBuffer) {
      if(this.fixedWallOpeningsWidth.on && this.fixedWallOpeningsWidth.value && !this.openingBufferToDisplay && geometry.fixedWidthOpenignBuff) {
        this.openingBufferToDisplay = {
          afterVertexIndex: geometry.intersectedAfterVertexIndex,
          doorKind: 0,
          type: this.wallOpeningBuffer.type,
          startPoint: new Point(geometry.fixedWidthOpenignBuff.startPoint.x,geometry.fixedWidthOpenignBuff.startPoint.y),
          endPoint: new Point(geometry.fixedWidthOpenignBuff.endPoint.x,geometry.fixedWidthOpenignBuff.endPoint.y)
        }
      }
      if(!this.openingBufferToDisplay) {
        this.openingBufferToDisplay = {
          afterVertexIndex: this.wallOpeningBuffer.afterVertexIndex,
          doorKind: 0,
          type: this.wallOpeningBuffer.type,
          startPoint: new Point(this.lineControlPointBuffer.x, this.lineControlPointBuffer.y),
          endPoint: new Point()
        }
      }
      else if(this.openingBufferToDisplay.afterVertexIndex === geometry.intersectedAfterVertexIndex) {
        let endPoint = new Point();
        if(this.openingBufferToDisplay.endPoint && this.openingBufferToDisplay.endPoint.x && this.openingBufferToDisplay.endPoint.y) {
          endPoint.x = this.openingBufferToDisplay.endPoint.x;
          endPoint.y = this.openingBufferToDisplay.endPoint.y;
        }
        else {
          endPoint.x = this.lineControlPointBuffer.x;
          endPoint.y = this.lineControlPointBuffer.y;
        }
        let distanceToEndPoint = geometry.calculateDistance(geometry.vertexes[geometry.intersectedAfterVertexIndex].position,endPoint);
        let distanceTostartPoint = geometry.calculateDistance(geometry.vertexes[geometry.intersectedAfterVertexIndex].position,this.openingBufferToDisplay.startPoint);;
        if(distanceToEndPoint < distanceTostartPoint) {
          let pointBuff = {...endPoint};
          endPoint = {...this.openingBufferToDisplay.startPoint};
          this.openingBufferToDisplay.startPoint = pointBuff;
        }
        this.dataService.updateLineControls(this.layerService.activeLayer,1,this.openingBufferToDisplay.startPoint,geometry.intersectedAfterVertexIndex);
        this.dataService.updateLineControls(this.layerService.activeLayer,1,endPoint,geometry.intersectedAfterVertexIndex+1);
        this.wallOpeningBuffer.afterVertexIndex = geometry.intersectedAfterVertexIndex+1;
        this.wallOpeningBuffer.type = this.wallOpeningMode;
        if((this.doorTypeMode || this.doorTypeMode === 0) && this.wallOpeningMode === WallOpeningMode.door) {
          this.wallOpeningBuffer.doorKind = this.doorTypeMode;
        }
        this.dataService.updateWallOpenings(this.layerService.activeLayer, this.wallOpeningBuffer);
        this.openingBufferToDisplay = null;
        this.resetWallOpeningBuffer();
      }
      else {
        this.openingBufferToDisplay = null;
        this.resetWallOpeningBuffer();
      }
    }
  }

  updateWallOpenings() {
    if(this.lineControlPointBuffer && this.lineControlPointBuffer.x && this.lineControlPointBuffer.y) {
      this.dataService.updateWallOpenings(this.layerService.activeLayer, this.wallOpeningBuffer)
    }
  }

  moveSelectionToForeground() {
    this.dataService.moveSelectionToForeground(this.layerService.activeLayer);
  }

  moveSelectionToBackground() {
    this.dataService.moveSelectionToBackground(this.layerService.activeLayer);
  }

  moveSelectionOneStepToForeground() {
    this.dataService.moveSelectionOneStepToForeground(this.layerService.activeLayer);
  }
  
  moveSelectionOneStepToBackground() {
    this.dataService.moveSelectionOneStepToBackground(this.layerService.activeLayer);
  }

  rearrangeGeometries(event: CdkDragDrop<Array<Geometry>>) {
    this.dataService.rearrangeGeometriesInLayer(this.layerService.activeLayer,event);
  }
}
