import { SymbolItem } from './symbol-item.model';

export class SymbolList {
    name: string;
    folderName: string;
    items: Array<SymbolItem>;
    folderId: number;

    constructor() {
        this.items = new Array<SymbolItem>();
    }
}