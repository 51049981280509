
<div class="w-100" fxLayout="column" fxLayoutAlign="center center">
    <h2 class="m-tb">{{ "project.projetOverview" | translate }}</h2>

    <div class="w-80">
      <div class="m-tb">
          <button mat-flat-button class="ep-btn-call" (click)="createNewProject()">
              {{ "project.newProject" | translate }}
          </button>
      </div>
      <div *ngIf="projects && projectsInitialized">
        <mat-accordion *ngIf="projects.length; else noProjects">
          <mat-expansion-panel [ngClass]="{'sample-project-style': project.id === 'sampleProject'}" *ngFor="let project of projects;let i = index" #projdetails [expanded]="openIndex === i" (opened)="openIndex = i">
            <mat-expansion-panel-header class="project-header">
              <mat-panel-title ngClass.xs="p-lr" class="w-100 no-overflow mob-small" fxLayoutAlign="space-between center">
                  <div>
                      <span>{{project.name}}</span>
                      <span *ngIf="project.creator && project.creator.address && project.creator.address.streetWithNumber">,</span>&nbsp;&nbsp;
                      <span *ngIf="project.creator && project.creator.address && project.creator.address.streetWithNumber">{{project.creator.address.streetWithNumber}},</span>&nbsp;
                      <span *ngIf="project.creator && project.creator.address && project.creator.address.postCode">{{project.creator.address.postCode}},</span>&nbsp;
                      <span *ngIf="project.creator && project.creator.address && project.creator.address.city">{{project.creator.address.city}}</span>&nbsp;
                  </div>
                <div fxFlex fxLayoutAlign="space-between center">
                  <span *ngIf="project.creationDate" class="mob-small">{{project.creationDate}}</span>
                </div>
              </mat-panel-title>
              <div fxLayout="row" fxLayoutAlign="start center" class="m-lr clickable">
                <button mat-icon-button (click)="createPlan($event,project)">
                    <mat-icon svgIcon="add-black-24dp"></mat-icon>
                </button>
                <button mat-icon-button (click)="editProjectData($event,project)">
                    <mat-icon svgIcon="pencil-alt-solid"></mat-icon>
                </button>
                <button mat-icon-button (click)="animateProgressBar()" (click)="copyProject($event,project)">
                    <mat-icon svgIcon="copy-regular"></mat-icon>
                </button>
                <button mat-icon-button (click)="animateProgressBar()" (click)="deleteProject($event,project,i)">
                    <mat-icon svgIcon="trash-solid"></mat-icon>
                </button>
              </div>
            </mat-expansion-panel-header>
            <div cdkDropList (cdkDropListDropped)="rearrangePlans(project,$event)">
              <h4 *ngIf="project.projectDrawings.length === 0">Sie haben noch keine Pläne angelegt.</h4>
              <div cdkDrag *ngFor="let plan of project.projectDrawings; let j = index">
                <div class="layer-drag-placeholder" *cdkDragPlaceholder></div>
                  <div  fxLayout="row" fxLayoutAlign="space-between center">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <button mat-button (click)="renamePlan(project,plan)">
                          <span>{{plan.drawingName}}</span>&nbsp;
                        </button>
                        <!--<span>{{plan.id}}</span>-->
                      </div>
                      <div fxLayout="row" fxLayoutAlign="end center">
                          <button mat-icon-button *ngIf="project.projectDrawings.length" (click)="editPlan(project,plan)">
                              <mat-icon svgIcon="pencil-alt-solid"></mat-icon>
                          </button>
                          <button mat-icon-button *ngIf="project.projectDrawings.length" (click)="animateProgressBar()" (click)="copyPlan(project,plan)">
                              <mat-icon svgIcon="copy-regular"></mat-icon>
                          </button>
                          <button mat-icon-button *ngIf="project.projectDrawings.length" (click)="animateProgressBar()" (click)="deletePlan(plan)">
                            <mat-icon svgIcon="trash-solid"></mat-icon>
                        </button>
                      </div>
                  </div>
                  <mat-divider></mat-divider>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <ng-template #noProjects>
            <p *ngIf="projectsInitialized" class="grey-box">
              {{ "project.no_projects" | translate }}
            </p>
        </ng-template>
        <ng-template #noPlans>
          <div class="w-100 m-tb" fxLayout="column" fxLayoutAlign="center center">
            <div class="w-90">
              <p>
                Keine Pläne gefunden. Bitte legen Sie zuerst einen Plan an.
              </p>
            </div>
          </div>
        </ng-template>
      </div>
      <div>
        <mat-accordion>
          <mat-expansion-panel class="sample-project-style" #projdetails>
            <mat-expansion-panel-header class="project-header">
              <mat-panel-title ngClass.xs="p-lr" class="w-100 no-overflow mob-small" fxLayoutAlign="space-between center">
                  <div>
                      <span>{{sampleProject.summary.name}} ,</span>&nbsp;
                      <span>{{sampleProject.projectDetails.creator.address.streetWithNumber}},</span>&nbsp;
                      <span>{{sampleProject.projectDetails.creator.address.postCode}},</span>&nbsp;
                      <span>{{sampleProject.projectDetails.creator.address.city}}</span>&nbsp;
                  </div>
                <div fxFlex fxLayoutAlign="space-between center">
                  <span *ngIf="sampleProject.summary.creationDate" class="mob-small">{{sampleProject.summary.creationDate}}</span>
                </div>
              </mat-panel-title>
              <div  fxLayout="row" fxLayoutAlign="start center" class="m-lr clickable">
                <button mat-icon-button (click)="editProjectData($event,sampleProject.summary)">
                    <mat-icon svgIcon="eye-regular"></mat-icon>
                </button>
              </div>
            </mat-expansion-panel-header>
            <mat-divider></mat-divider>
            <div>
              <div cdkDrag *ngFor="let plan of sampleProject.summary.projectDrawings; let j = index">
                <div class="layer-drag-placeholder" *cdkDragPlaceholder></div>
                  <div  fxLayout="row" fxLayoutAlign="space-between center">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <button mat-button disabled>
                          <span>{{plan.drawingName}}</span>&nbsp;
                        </button>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="end center">
                          <button mat-icon-button *ngIf="sampleProject.summary.projectDrawings.length" (click)="editPlan(sampleProject.summary,plan)">
                              <mat-icon svgIcon="pencil-alt-solid"></mat-icon>
                          </button>
                      </div>
                  </div>
                  <mat-divider></mat-divider>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    
    <div class="loading-progress" *ngIf="loading || !projectsInitialized" fxLayout="row" fxLayoutAlign="center center">
      <mat-progress-bar class="loading-indicator" mode="determinate" value="{{progressvalue}}"></mat-progress-bar>
    </div>
</div>