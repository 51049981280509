import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/user/authentication/authentication.service';
import { ValidationService } from 'src/app/services/status-and-control-services/validation/validation.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslationService } from 'src/app/services';
import { InfoDialogComponent } from '../../dialogs/info-dialog/info-dialog.component';
import { InputDialogComponent } from '../../dialogs/input-dialog/input-dialog.component';
import { NotificationService } from 'src/app/services/status-and-control-services/notification/notification.service';
import { ProfileService } from 'src/app/services/user/profile/profile.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading: boolean;
  passed: boolean;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private profileService: ProfileService,
    private router: Router,
    private dialog: MatDialog,
    private translationService: TranslationService,
    private notificationService: NotificationService
  ) {
    this.passed = this.ifPassed();
    if (this.authenticationService.isAuthenticated()) {
      this.profileService.getMyProfile().subscribe(res => {
        if(res && res.data && res.data.isActivated) {
          this.router.navigate(['/']);
        }
      })
    }
    this.loginForm = this.formBuilder.group({
      email: new UntypedFormControl('',
      Validators.compose([
        Validators.email,
        Validators.required,
        Validators.maxLength(200),
        Validators.minLength(3),
      ])),
      password: new UntypedFormControl('',
      Validators.compose([
        Validators.required,
        ValidationService.passwordValidator,
        Validators.maxLength(500),
        Validators.minLength(8),
      ])),
    })
    this.loginForm.markAsUntouched();
  }

  ngOnInit(): void {
  }

  ifPassed() {
    let now = new Date();
    let maintananceEnd = new Date('2023-01-28T00:00:00');
    return now > maintananceEnd;
  }

  login() {
    let formValue = this.loginForm.value;
    this.authenticationService.login(formValue.email,formValue.password).subscribe(loginData => {
      if(loginData.isActivated) {
        this.router.navigate(['/projects']);
      }
      else {
        let response = this.translationService.translateAPIResponse({serviceErrorCode:12, kind: "ERROR", code: 12})
        let dialogRef = this.dialog.open(InfoDialogComponent,{data: response});
        dialogRef.afterClosed().subscribe(result => {
          if(result && result === "resend") {
            this.authenticationService.sendActivationRequest().subscribe(
              (res:any) => {
                this.notificationService.openNotification('activation_success');
              })
          }
        })

      }
    })
  }

  openForgotPasswordDialog() {
    let dialogRefPass = this.dialog.open(InputDialogComponent, {
      data: {
        inputType: "email",
        valueName: "Email",
        buttonText: "Absenden"
      }
    });
    dialogRefPass.afterClosed().subscribe(result => {
      if(result && result.email) {
        this.authenticationService.sendForgotPasswordRequest(result.email).subscribe(
          data => {
            this.notificationService.openNotification('forgotpassword_success')
            this.loading = false;
          },
          error => {
            this.notificationService.openNotification('forgotpassword_failure')
            this.loading = false;
            return;
          }
        );
      }
    })
  }

}
