import { Component, OnInit } from '@angular/core';
import { SelectionService } from 'src/app/services/status-and-control-services/selection/selection.service';
import { AppSettingsService } from 'src/app/services/status-and-control-services/app-settings/app-settings.service';
import { EventsPropagationService } from 'src/app/services/status-and-control-services/events-propagation/events-propagation.service';
import { ModesService } from 'src/app/services/status-and-control-services/modes/modes.service';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { GeometryPropertiesService } from 'src/app/services/operational-services/geometry-properties/geometry-properties.service';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services';

@Component({
  selector: 'app-arc-tools',
  templateUrl: './arc-tools.component.html',
  styleUrls: ['./arc-tools.component.scss']
})
export class ArcToolsComponent implements OnInit {
  arc: any;
  units: string;
  geomDataSubscrioption: Subscription;
  constructor(
    private eventsPropagationService: EventsPropagationService,
    private selectionService: SelectionService,
    private appSettingsService: AppSettingsService,
    private modesService: ModesService,
    private geometryPropertiesService: GeometryPropertiesService,
    private dataService: DataService,
  ) {
    this.units = this.appSettingsService.activeUnitTypeName;
    
    this.geomDataSubscrioption = this.dataService.geometricalData$.subscribe(data => {
      this.arc = this.selectedArc();
      if(this.arc) {
        this.arc.radius = this.appSettingsService.dimensionToDisplay(this.arc.radius);
        this.arc.startAngle = Math.round(this.arc.startAngle * 1000) / 1000;
        this.arc.endAngle = Math.round(this.arc.endAngle * 1000) / 1000;
      }
    })
  }

  ngOnInit(): void {
  }

  onBlur() {
    this.eventsPropagationService.enableEvents();
  }

  isInsertMode(): boolean {
    return this.modesService.mode === ActiveMode.insertMode;
  }

  isEditMode(): boolean {
    return this.modesService.mode === ActiveMode.editMode;
  }

  onFocus() {
    this.eventsPropagationService.disableEvents();
  }

  ngOnDestroy() {
    this.geomDataSubscrioption.unsubscribe();
    this.onBlur();
  }

  selectedArc() {
    return this.selectionService.selectedArc();
  }

  updateRadius() {
    let visualisationMultiplier = this.appSettingsService.getVisualisationMultiplier();
    this.geometryPropertiesService.updateRadiusOfSelection(this.arc.radius/visualisationMultiplier);
  }

  updateAngle(angle:string) {
    if(angle === "end") {
      this.updateEndAngle()
    }
    if(angle === "start") {
      this.updateStartAngle()
    }
  }

  updateStartAngle() {
    this.arc.startAngle = this.calculateBasicAngle(this.arc.startAngle);
    this.geometryPropertiesService.updateStartAngleOfSelection(this.arc.startAngle);
  }

  updateEndAngle() {
    this.arc.endAngle = this.calculateBasicAngle(this.arc.endAngle);
    this.geometryPropertiesService.updateEndAngleOfSelection(this.arc.endAngle);
  }

  calculateBasicAngle(angle) {
    if(angle > 360){
      angle -= 360;
    }
    else if(angle < 0){
      angle += 360;
    }
    return angle;
  }

}
