import { Injectable, Injector } from "@angular/core";
import { HttpHeaders, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from "rxjs";
import { InfoDialogComponent } from '../components/dialogs/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslationService, ViewerService } from '../services';
import { AuthenticationService } from "../services/user/authentication/authentication.service";
import { Router } from '@angular/router';

@Injectable({
  providedIn: "root"
})
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private inj: Injector,
    private dialog: MatDialog,
    private translationService: TranslationService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private viewerService: ViewerService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let currentUserData = localStorage.getItem("currentUser");
    if (currentUserData) {
      request = request.clone({
        //headers
        setHeaders: {
          //"Content-Type": "application/json",
          Authorization:  currentUserData
        }
      });
    } else {
      request = request.clone({
        //headers
       /*  setHeaders: {
          "Content-Type": "application/json"
        } */
      });
    }

    return next.handle(request)
      .pipe(
          retry(1),
          catchError((error: HttpErrorResponse) => {
            if(currentUserData && error && error.error && error.error.message === "Unauthorized") {
              this.authenticationService.logout().subscribe(loggedOut => {
                if(loggedOut) {
                  this.router.navigate(["authorization/login"]);
                }
              })
            }
            else if(error && error.error) {
              let response = this.translationService.translateAPIResponse({...error, kind: "ERROR"})
              this.viewerService._loadingSource.next(false);
              this.dialog.open(InfoDialogComponent,{data: response});
              return throwError(error);
            }
        }));

  }
}



