<div class="additional-tools-element">
    <div class="p-b" *ngIf="oneGeometrySelected()" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
        <mat-divider class="p-b"></mat-divider>
        <span>Position</span>
        <div class="ep-form-control-container" fxLayout="row" fxLayoutAlign="center center">
            <div class="w-100">
                <div class="ep-form-control">
                    <span class="additional-tools-span">x ({{units}})</span>
                    <input type="number" min="0" [(ngModel)]="posX" (blur)="onBlur()" (focus)="onFocus()"
                    (ngModelChange)="updatePosition()">
                </div>
            </div>
            <div fxFlex="40px"></div>
        </div>
        <div class="ep-form-control-container" fxLayout="row" fxLayoutAlign="center center">
            <div class="w-100">
                <div class="ep-form-control">
                    <span class="additional-tools-span">y ({{units}})</span>
                    <input type="number" min="0" [(ngModel)]="posY" (blur)="onBlur()" (focus)="onFocus()"
                    (ngModelChange)="updatePosition()">
                </div>
            </div>
            <div fxFlex="40px"></div>
        </div>
        <div class="ep-form-control-container" fxLayout="row" fxLayoutAlign="center center">
            <div class="w-100">
                <div class="ep-form-control">
                    <span class="additional-tools-span">Linienstärke</span>
                    <input type="number" [ngModel]="width" (blur)="onBlur()" (focus)="onFocus()"
                    (ngModelChange)="updateLineWidthOfSelection($event)">
                </div>
            </div>
            <div fxFlex="40px"></div>
        </div>
    </div>
    <div class="w-100 p-tb">
        <mat-form-field class="w-100">
            <mat-label>Linientyp</mat-label>
            <mat-select (selectionChange)="updateLineTypeOfSelection($event)" [(value)]="currentLineType">
                <mat-option *ngFor="let lineType of lineTypesList" [value]="lineType">{{lineType.patternSample}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        <button mat-icon-button (click)="mirrorToYAxis()" matTooltip="Auswahl horizontal Spiegeln">
            <mat-icon svgIcon="horizontal-spiegeln"></mat-icon>
        </button>
        <button mat-icon-button (click)="mirrorToXAxis()" matTooltip="Auswahl vertical Spiegeln">
            <mat-icon svgIcon="vertikal-spiegeln"></mat-icon>
        </button>
    </div>
    <div class="w-100">
        <span class="font-grey">Linienfarbe</span>
        <color-sketch [presetColors]="presetColors" [color]="currentColor" (onChangeComplete)="updateStrokeColorOfSelection($event)"></color-sketch>
    </div>
</div>