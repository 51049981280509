import { Component, OnInit } from '@angular/core';
import { SymbolLibraryService } from 'src/app/services/status-and-control-services/symbol-library/symbol-library.service';
import { SymbolList } from 'src/app/models/symbol-library/symbol-list.model';
import { environment } from "../../../../environments/environment";
import { SelectionService } from 'src/app/services/status-and-control-services/selection/selection.service';
import { LayerService } from 'src/app/services';
import { MatDialog } from '@angular/material/dialog';
import { FileImportDialogComponent } from '../../dialogs/file-import-dialog/file-import-dialog.component';
import { InfoDialogComponent } from '../../dialogs/info-dialog/info-dialog.component';
import { OwnSymbolItem } from 'src/app/models/symbol-library/own-symbol-item.model';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { Subscription } from 'rxjs';
import { SymbolCategory } from 'src/app/models/symbol-library/symbol-category.model';

@Component({
  selector: 'app-symbol-library-tools',
  templateUrl: './symbol-library-tools.component.html',
  styleUrls: ['./symbol-library-tools.component.scss']
})
export class SymbolLibraryToolsComponent implements OnInit {
  symbolsFolderPath: string;
  selectedList: SymbolList;
  selectedCategory: SymbolCategory;
  ownSymbolsList: SymbolList;
  symbolCategories: Array <SymbolCategory>;
  symbolsSubscription: Subscription;
  constructor(
    private symbolLibraryService: SymbolLibraryService,
    private selectionService: SelectionService,
    private layerService: LayerService,
    private dialog: MatDialog
  ) {
    this.symbolCategories = this.symbolLibraryService.symbolsLybrary.symbols;
    this.selectedList = this.symbolCategories[0].symbolLists[0];
    this.selectedCategory = this.symbolCategories[0];
    this.symbolsFolderPath = environment.symbolsFolderPath;
    this.ownSymbolsList = new SymbolList();
    this.initSymbols();
    this.symbolsSubscription = this.symbolLibraryService.symbolsUpdated$.subscribe(updated => {
      this.initSymbols();
    })
  }

  ngOnInit(): void {
  }

  initSymbols() {
    this.symbolLibraryService.getImageItemsByFolderId(this.selectedList.folderId).subscribe((response:any) => {
      this.ownSymbolsList = new SymbolList();
      if(response && response.data && response.data.imageItems && response.data.imageItems.length) {
        response.data.imageItems.forEach(element => {
          if(this.selectedList.folderId === element.folderId) {
            let newSybolItem: OwnSymbolItem = new OwnSymbolItem();
            newSybolItem.folderId = element.folderId;
            newSybolItem.id = element.id;
            newSybolItem.imageName = element.imageName;
            newSybolItem.imageBase64 = element.imageBase64;
            this.ownSymbolsList.items.push(newSybolItem);
          }
        });
      }
    })
  }

  onDragStart(event,icon) {
    let width = event.target.clientWidth;
    let height = event.target.clientHeight;
    let size = {
      width: width,
      height: height
    }
    this.selectionService.setDraggingImage(icon.imageFileName ? (this.symbolsFolderPath + icon.imageFileName) : icon.imageBase64, size, icon.title ? icon.title : (icon.imageName ? icon.imageName : new Date().getTime().toString()));
  }

  uploadSymbol() {
    if(this.layerService.activeLayer) {
      this.dialog.open(FileImportDialogComponent,
        {
          data: {
            actionTitle: "selectSymbol",
            actionSubtitle: "selectSymbolSubtitle",
            folderId: this.selectedList.folderId
          }
        });
    }
    else {
      let errorInfo = {
        kind: "Keine Ebene ist Aktiv",
        serviceErrorCode: "Bitte wählen Sie eine Ebene aus für eine weitere Bearbeitung"
      }
      this.dialog.open(InfoDialogComponent, {data: errorInfo});
    }
  }

  selectList(categoryIndex: number, listIndex: number) {
    this.selectedCategory = this.symbolCategories[categoryIndex];
    this.selectedList = this.symbolCategories[categoryIndex].symbolLists[listIndex];
    this.initSymbols();
  }

  deleteSymbol(id: string) {
    let dialogRefPass = this.dialog.open(ConfirmationDialogComponent,{
      data: {
        question: "delete_symbol"
      }
    });
    dialogRefPass.afterClosed().subscribe(result => {
      if(result) {
        this.symbolLibraryService.deleteImageItem(id).subscribe((response : any) => {
          if(response && response.status && response.status === 1) {
            let errorInfo = {
              kind: "Erfolg",
              serviceErrorCode: "Das Symbol wurde erfolgreich entfernt."
            }
            let dialogRef = this.dialog.open(InfoDialogComponent, {data: errorInfo});
            dialogRef.afterClosed().subscribe(result => {
              this.initSymbols();
            })
          }
          else {
            let errorInfo = {
              kind: "Fehler",
              serviceErrorCode: "Beim entfernen des Symbols ist ein Fehler aufgetretten."
            }
            this.dialog.open(InfoDialogComponent, {data: errorInfo});
          }
        })
      }
    });

  }

}
