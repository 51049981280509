import { SymbolList } from './symbol-list.model';

export class SymbolCategory {
    name: string;
    folderName: string;
    symbolLists: Array<SymbolList>;

    constructor() {
        this.symbolLists = new Array<SymbolList>();
    }
}