import { Component, OnInit } from '@angular/core';
import { EditorControl } from 'src/app/models/controls';
import { ControlActiveSettingEnum } from 'src/app/models/enums/control-active-setting.enum';
import { ControlsService, ViewerOperationsService, ViewerService } from 'src/app/services';
import { PrintService } from 'src/app/services/operational-services/print/print.service';
import { ProfileService } from 'src/app/services/user/profile/profile.service';

@Component({
  selector: 'app-print-controls',
  templateUrl: './print-controls.component.html',
  styleUrls: ['./print-controls.component.scss']
})
export class PrintControlsComponent implements OnInit {
  controlItems: Array<EditorControl>;
  demoVersion: boolean;

  constructor(
    private controlsService: ControlsService,
    private printService: PrintService,
    private profileService: ProfileService,
    private viewerOperationsService: ViewerOperationsService,
    private viewerService: ViewerService
  ) {
    this.profileService.getMyProfile().subscribe((res:any) => {
      this.demoVersion = res.licenseStatus === 2;
    })
    this.controlItems = this.controlsService.controls.horizontalControls.printControlsList;
  }

  ngOnInit(): void {
  }

  onButtonClick(controlsItem: EditorControl) {
    switch(controlsItem.id) {
      case 8 : {
        return this.startPrintingSetup();
      }
      default: return
      }
  }

  startPrintingSetup() {
    this.viewerService._loadingSource.next(true);
    this.printService.emitPrintEvent("setup");
  }

  controlActive(activeOn) {
    let result = this.viewerOperationsService.controlActive(activeOn);
    return result;
  }

}
