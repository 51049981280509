<div class="full-height-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="40px">
    <div></div>
    <div class="ep-single-form" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <h3>Aktivierungscode eingeben</h3>
        <p class="text-center">{{ "INPUT_LICENSE" | translate }}</p>
        <div class="ep-form-control-container">
            <div class="ep-form-control">
                <span class="m-r-2">Aktivierungscode</span>
                <input type="text" [(ngModel)]="code">
            </div>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="50px">
        <button mat-flat-button class="ep-btn-call" [disabled]="!code" (click)="activateLicense()">{{ "save" | translate }}</button>
    </div>
</div>