import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ContactService } from 'src/app/services/operational-services/contact/contact.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/status-and-control-services/notification/notification.service';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  loading: boolean;
  contactSubscription: Subscription;
  isBrowser: boolean;
  contactForm: UntypedFormGroup;
  constructor(
    private contactService: ContactService,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService
  ) {
    this.loading = false;
    this.initProfile();
  }


  initProfile() {
    this.contactForm = this.formBuilder.group({
      salutation: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required
        ])
      ),
      firstName: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(200),
          Validators.minLength(3),
        ])
      ),
      lastName: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(200),
          Validators.minLength(3),
        ])
      ),
      email: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.maxLength(200),
          Validators.minLength(3),
        ])
      ),
      topic: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(200),
          Validators.minLength(3),
        ])
      ),
      message: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(800),
          Validators.minLength(30),
        ])
      )
    })
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.loading = true;
    this.contactService
      .sendContactRequest(this.contactForm.value)
      .subscribe(
        data => {
          this.notificationService.openNotification('contact_success')
          this.loading = false;
        },
        error => {
          this.notificationService.openNotification('contant_failure')
          this.loading = false;
          return;
        }
      );
  }

}
