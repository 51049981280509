<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <mat-form-field class="ep-input">
        <mat-label>{{data.valueName}}</mat-label>
        <input *ngIf="data.inputType !== 'textarea' && data.inputType !== 'email'" matInput type="text" [(ngModel)]="value" (blur)="onBlur()" (focus)="onFocus()">
        <textarea *ngIf="data.inputType === 'textarea'" matInput [(ngModel)]="value" (blur)="onBlur()" (focus)="onFocus()"></textarea>
        <input *ngIf="data.inputType === 'email'" matInput type="email" [(ngModel)]="value" (blur)="onBlur()" (focus)="onFocus()">
        <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <button matSuffix mat-flat-button class="ep-btn-call" [disabled]="value === ''" (click)="submitValue()">
        <span *ngIf="data.buttonText">{{data.buttonText}}</span>
        <span *ngIf="!data.buttonText">Layer hinzufügen</span>
    </button>    
</div>