<div class="additional-tools-element">
    <div *ngIf="isEditMode() && circle" fxLayout="column" fxLayoutAlign="center center">
        <div class="ep-form-control-container">
            <div class="w-100">
                <div class="ep-form-control">
                    <span>Radius ({{units}})</span>
                    <input type="number" [(ngModel)]="circle.radius" (blur)="onBlur()" (focus)="onFocus()"
                    (ngModelChange)="updateRadius()">
                </div>
            </div>
            <div fxFlex="40px"></div>
        </div>
        <div fxFlex="5px"></div>
    </div>
    <app-common-properties *ngIf="isInsertMode()"></app-common-properties>
    <app-fill-tools *ngIf="isInsertMode()"></app-fill-tools>
</div>