import { Injectable } from '@angular/core';
//import { Polyline, Point, PolylineVertex } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class RectHelperService {

  constructor() { }

  /* genrateRectangleData(item: Polyline) {
    let obs: Observable<any> = new Observable(observer => {
      this.findRectOrigin(item.polylineVertexes).subscribe(origin => {
        this.calculateRectWidth(origin.x,item.polylineVertexes).subscribe(width=> {
          this.calculateRectWidth(origin.y,item.polylineVertexes).subscribe(height => {
            observer.next({ origin: origin, width: width, height: height});
          });
        });
      });
    });
    return obs;
  }

  findRectOrigin(vertexes: PolylineVertex[]) {
    let obs: Observable<any> = new Observable(observer => {
      let origin = new Point();
      this.findLowest(vertexes,"x").subscribe(lowest => {
        origin.x = lowest
        this.findHighest(vertexes,"y").subscribe(highest => {
          origin.y = highest
          observer.next(origin);
        });
      });
    });
    return obs;
  }

  calculateRectWidth(x: number, vertexes: PolylineVertex[]) {
    let obs: Observable<any> = new Observable(observer => {
      this.findLowest(vertexes,"y").subscribe(highest => {
        observer.next(highest - x);
      });
    });
    return obs;
  }

  calculateRectHeight(y, vertexes: PolylineVertex[]) {
    let obs: Observable<any> = new Observable(observer => {
      this.findLowest(vertexes,"y").subscribe(lowest => {
        observer.next(y - lowest);
      });
    });
    return obs;

  }

  findLowest(vertexes, property: string): Observable<number> {
    let obs: Observable<any> = new Observable(observer => {
      let lowest = Number.POSITIVE_INFINITY;
      let tmp;
      for (let i=vertexes.length-1; i>=0; i--) {
          tmp = vertexes[i].position[property];
          if (tmp < lowest) lowest = tmp;
      }
      observer.next(lowest);
    });
    return obs;
  }

  findHighest(vertexes, property: string): Observable<number> {
    let obs: Observable<any> = new Observable(observer => {
      var highest = Number.NEGATIVE_INFINITY;
      var tmp;
      for (var i=vertexes.length-1; i>=0; i--) {
          tmp = vertexes[i].position[property];
          if (tmp > highest) highest = tmp;
      }
      observer.next(highest);
    });
    return obs;
  } */
}
