<div class="additional-tools-element">
  <div
    class="padding"
    fxlayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
  >
    <button
      [ngClass]="!selectionExists() ? 'disabled-ep' : 'enabled-ep'"
      mat-icon-button
      [disabled]="!selectionExists()"
      (click)="moveSelectionToForeground()"
      matTooltip="{{ 'orderObjects.toForeground' | translate }}"
    >
      <mat-icon svgIcon="ganz-in-den-vordergrund"></mat-icon>
    </button>
    <button
      [ngClass]="multipleObjectsSelected() ? 'disabled-ep' : 'enabled-ep'"
      mat-icon-button
      [disabled]="multipleObjectsSelected()"
      (click)="selectionOneStepToForeground()"
      matTooltip="{{ 'orderObjects.oneStepToForeground' | translate }}"
    >
      <mat-icon svgIcon="einen-schritt-nach-vorne"></mat-icon>
    </button>
    <button
      [ngClass]="!selectionExists() ? 'disabled-ep' : 'enabled-ep'"
      mat-icon-button
      [disabled]="!selectionExists()"
      (click)="moveSelectionToBackground()"
      matTooltip="{{ 'orderObjects.toBackground' | translate }}"
    >
      <mat-icon svgIcon="ganz-in-den-hintergrund"></mat-icon>
    </button>
    <button
      [ngClass]="multipleObjectsSelected() ? 'disabled-ep' : 'enabled-ep'"
      mat-icon-button
      [disabled]="multipleObjectsSelected()"
      (click)="selectionOneStepToBackground()"
      matTooltip="{{ 'orderObjects.oneStepToBackground' | translate }}"
    >
      <mat-icon svgIcon="einen-schritt-nach-hinten"></mat-icon>
    </button>
  </div>
  <div *ngIf="geometries && geometries.length; else no_or_too_many">
    <p>Elemente in der aktiven Ebene</p>
    <div cdkDropList (cdkDropListDropped)="rearrangeGeometries($event)">
      <div
        [ngClass]="{ 'active-layer': isSelected(geometry.entityName) }"
        *ngFor="let geometry of geometries; let i = index"
        cdkDrag
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <div class="layer-drag-placeholder" *cdkDragPlaceholder></div>
        <button
          [matTooltip]="geometry.entityName"
          class="symbol-category-selection-trigger-text"
          mat-button
          fxFlex
          fxLayout="row"
          fxLayoutAlign="start center"
          (click)="selectElement(geometry.entityName)"
        >
          <span>{{
            "geometries." + getGeometricTypeName(geometry.geometricType)
              | translate
          }}</span
          >&nbsp;
          <span>{{ geometry.entityName }}</span>
        </button>
        <mat-divider></mat-divider>
      </div>
    </div>
  </div>
  <ng-template #no_or_too_many>
    <p>Keine oder zu viele Elemente in der Ebene.</p>
    <small
      >Sie können die ausgewählten Elemente umordnen, aber die volle Liste wird
      nicht generiert.</small
    >
  </ng-template>
</div>
