<div class="print-dialog" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutGap="20px">
        <div>
            <mat-form-field>
                <mat-label>Ausrichtung</mat-label>
                <mat-select (selectionChange)="updateOrientation($event.value)" [(ngModel)]="orientation">
                    <mat-option *ngFor="let orientationVariant of orientationVariants" [value]="orientationVariant">{{"print." + orientationVariant | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Papierformat</mat-label>
                <mat-select (selectionChange)="updateFormat($event.value)" [(ngModel)]="format">
                    <mat-option *ngFor="let formatVariant of formatVariants" [value]="formatVariant">{{"print." + formatVariant | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Qualität %</mat-label>
                <mat-select (selectionChange)="changeQuality($event.value)" [(ngModel)]="printQuality">
                    <mat-option *ngFor="let qualityPercent of [1,0.75,0.50,0.25]" [value]="qualityPercent">{{qualityPercent*100}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="print-preview-container">
        <div class="w-80 print-preview-page" fxLayout="column" fxLayoutAlign="center center">
            <div class="w-100" *ngIf="!loading; else spinner">
              <image-cropper #cropper
                [imageBase64]="preview.fullImage"
                [maintainAspectRatio]="false"
                [aspectRatio]="aspectRat"
                format="png"
                (cropperReady)="cropperToStart()"
                (imageCropped)="updateImage($event)">
              </image-cropper>
            </div>
          </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="50px">
        <button mat-flat-button [disabled]="loading || !cropImgPreview" class="ep-btn-call" (click)="closeDialog(true)">{{ "print.saveForPrint" | translate }} <img  *ngIf="loading" class="loading-animation"/></button>
        <button mat-flat-button (click)="closeDialog(false)">{{ "cancel" | translate }}</button>
    </div>
</div>

<ng-template #spinner>
    <div class="loading-progress" *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
