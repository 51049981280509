import { Geometry } from './geometry.model';
import { GeometryProperties } from './geometry-properties.model';
import { Extrema } from '../extrema.model';
import { VertexPosition } from '../position.model';
import { Point } from '../point.model';
import { ColorEnum } from '../enums/color.enum';
import { Rectangle } from './rectangle.model';
import { LineIcon } from './line-icon.model';

export class Line implements Geometry {
    entityName: string;
    entityTag: string;
    geometricType: number;
    layerName: string;
    geometryProperties: GeometryProperties;
    
    //direction -> {x,y,z,isNormalized}, vector -> {x,y,z,isNormalized}
    endPoint: VertexPosition;
    startPoint: VertexPosition;
    startIcon: any;
    endIcon: any;
    intersectedPoint?: Point;
    selected?: boolean

    constructor(data) {
        this.endPoint =  new VertexPosition(data.endPoint);
        this.startPoint = new VertexPosition(data.startPoint);
        this.geometryProperties =  new GeometryProperties({...data,...data.geometryProperties});
        this.entityName = data.entityName;
        this.entityTag = (data.entityTag && data.entityTag != data.entityName) ? data.entityTag : "tag_" + data.entityName;
        this.geometricType = data.geometricType;
        this.layerName = data.layerName;
        this.startIcon = data.startIcon;
        this.endIcon = data.endIcon;
    }

    roundUpCoords() {
        this.endPoint = new VertexPosition(this.endPoint,true);
        this.startPoint = new VertexPosition(this.startPoint,true);
    }
    
    extrema() {
        let extrema = new Extrema();
        extrema.maxX = Math.max(this.startPoint.x,this.endPoint.x);
        extrema.minX = Math.min(this.startPoint.x,this.endPoint.x);
        extrema.maxY = Math.max(this.startPoint.y,this.endPoint.y);
        extrema.minY = Math.min(this.startPoint.y,this.endPoint.y);
        if(Math.abs(extrema.minY - extrema.maxY) < 10) {
            extrema.minY -= 10;
            extrema.maxY += 10;
        }
        else if(Math.abs(extrema.minX - extrema.maxX) < 10) {
            extrema.minX -= 10;
            extrema.maxX += 10;
        }
        return extrema;
    }

    scale(multiplier: Point, selectionRect: Rectangle=null, equalizeThicknessFactor: number = null) {
        let controls = this.getLineControls();
        Object.keys(controls).forEach(key => {
            controls[key].x *= multiplier.x;
            controls[key].y *= multiplier.y;
        })
        if(equalizeThicknessFactor) {
            this.geometryProperties.thickness *= equalizeThicknessFactor;
        }
    }

    draw(context, scale, color, canvas) {
        context.save();
        context.strokeStyle = color ? color : ColorEnum.default;
        context.translate(0,0);
        context.beginPath();
        context.moveTo(this.startPoint.x * scale, this.startPoint.y * scale);
        context.lineTo(this.endPoint.x * scale, this.endPoint.y * scale);
        context.stroke();
        if(this.startIcon && this.startIcon.type) {
            this.renderLineIcon(this.startIcon,context,"start",scale);
        }
        if(this.endIcon && this.endIcon.type) {
            this.renderLineIcon(this.endIcon,context,"end",scale);
        }
        context.restore();
    }

    renderLineIcon(icon: any, context, position: string,scale) {
        var angle = position === "start" ? this.getLineAngle("toStart") : this.getLineAngle("toEnd");
        let lineIcon = new LineIcon(icon);
        lineIcon.draw(context,angle,position === "end" ? this.endPoint : this.startPoint, scale);
      }

    mouseIntersectsGeometry(mousePosition,context: CanvasRenderingContext2D,canvas,scale) {
        if(
            this.startPoint.x === this.endPoint.x &&
            Math.abs(mousePosition.x - this.startPoint.x) < 2 &&
            mousePosition.y > ((this.startPoint.y > this.endPoint.y) ? this.endPoint.y : this.startPoint.y) && 
            mousePosition.y < ((this.startPoint.y < this.endPoint.y) ? this.endPoint.y : this.startPoint.y)
        ) {
            this.defineIntersectedPosition(mousePosition,scale);
            return true;
        }
        else if(
            this.startPoint.y === this.endPoint.y &&
            Math.abs(mousePosition.y - this.startPoint.y) < 2 &&
            mousePosition.x > ((this.startPoint.x > this.endPoint.x) ? this.endPoint.x : this.startPoint.x) && 
            mousePosition.x < ((this.startPoint.x < this.endPoint.x) ? this.endPoint.x : this.startPoint.x)
        ) {
            this.defineIntersectedPosition(mousePosition,scale);
            return true;
        }
        else if(
            mousePosition.x > ((this.startPoint.x > this.endPoint.x) ? this.endPoint.x : this.startPoint.x) && 
            mousePosition.x < ((this.startPoint.x < this.endPoint.x) ? this.endPoint.x : this.startPoint.x) && 
            mousePosition.y > ((this.startPoint.y > this.endPoint.y) ? this.endPoint.y : this.startPoint.y) && 
            mousePosition.y < ((this.startPoint.y < this.endPoint.y) ? this.endPoint.y : this.startPoint.y)
        ) {
            this.defineIntersectedPosition(mousePosition,scale);
            var dx=mousePosition.x-this.intersectedPoint.x;
            var dy=mousePosition.y-this.intersectedPoint.y;
            var distance=Math.abs(Math.sqrt(dx*dx+dy*dy));
            if(distance<5) {
                return true;
            }
        }
    }

    defineIntersectedPosition(mousePosition, scale) {
        let linePoint = this.findLinePointNearestToMouse({x0:this.startPoint.x*scale,x1:this.endPoint.x*scale,y0:this.startPoint.y*scale,y1:this.endPoint.y*scale}, mousePosition, scale);
        this.intersectedPoint = linePoint;
    }

    findLinePointNearestToMouse(line,mousePosition,scale) {
        let lerp=function(a,b,x){ return(a+x*(b-a)); };
        var dx=line.x1-line.x0;
        var dy=line.y1-line.y0;
        var t=((mousePosition.x*scale-line.x0)*dx+(mousePosition.y*scale-line.y0)*dy)/(dx*dx+dy*dy);
        var lineX=lerp(line.x0, line.x1, t);
        var lineY=lerp(line.y0, line.y1, t);
        return({x:lineX,y:lineY});
    };

    lineLength(scale): number {
        let lineLength = Math.round(Math.sqrt(Math.pow((this.endPoint.x - this.startPoint.x)*scale,2) + Math.pow((this.endPoint.y - this.startPoint.y)*scale,2)));
        return lineLength;
    }

    getLineAngle(direction: string) {
        var dx = direction === "toStart" ? (this.startPoint.x - this.endPoint.x) : (this.endPoint.x - this.startPoint.x);
        var dy = direction === "toStart" ? (this.startPoint.y - this.endPoint.y) : (this.endPoint.y - this.startPoint.y);
        var angle = Math.atan2(dy, dx);
        return angle;
    }

    findControlToSnap(mousePos, context, canvas, scale) {
        let pointRadius = 5;
        let controlPoint = null;
        let controls = this.getLineControls();
        Object.keys(controls).forEach(key => {
            if(this.intersectsControlPoint(controls[key], pointRadius, mousePos, scale)) {
                controlPoint = {...controls[key], name: key};
            }
        })
        return controlPoint;
    }

    getLineControls() {
        let controls = {
            startPoint: this.startPoint,
            endPoint: this.endPoint,
        }
        return controls;
    }

    drawControls(mousePos,context,canvas,scale,controlsMode) {
        let pointRadius = 5;
        let controls = this.getLineControls();
        Object.keys(controls).forEach(key => {
            context.beginPath();
            context.fillStyle = "green";
            let intersectsControl = this.intersectsControlPoint(controls[key], pointRadius, mousePos, scale);
            if(intersectsControl) {
                context.fillStyle = "yellow";
            }
            context.arc(controls[key].x * scale, controls[key].y * scale, pointRadius, 0 * Math.PI, 2 * Math.PI);
            context.fill();
            if(!intersectsControl && controlsMode && this.mouseIntersectsGeometry(mousePos,context,canvas,scale)) {
                if(this.intersectedPoint) {
                    context.save();
                    context.beginPath();
                    context.fillStyle = controlsMode === 1 ? "green" : "red";
                    context.arc(this.intersectedPoint.x, this.intersectedPoint.y, 5, 0, Math.PI*2);
                    context.fill();
                    context.restore();
                }
            }
            else if(!this.mouseIntersectsGeometry(mousePos,context,canvas,scale) && this.intersectedPoint) {
                delete this.intersectedPoint;
            }
        })
    }

    drawInformation(context,canvas,scale,visualisationMultiplier,unit,ratio) {
        let extrema = this.extrema();
        context.fillStyle = ColorEnum.fontDefault;
        context.font = "18px Arial";
        context.beginPath();
        let length = this.lineLength(1);
        context.fillText("Länge: " + Math.round(length*visualisationMultiplier * ratio * 1000) / 1000 + unit, extrema.minX, extrema.maxY+20);
    }

    intersectsControlPoint(position: VertexPosition, r, mousePos: Point, scale) {
        var distSqr = Math.pow(position.x * scale - mousePos.x, 2) + Math.pow(position.y * scale - mousePos.y, 2);
        if(distSqr < r * r) {
            return true;
        }
        else {
            return false;
        }
    }

    move(offset) {
        let controls = this.getLineControls();
        Object.keys(controls).forEach(key => {
            controls[key].x -= offset.x;
            controls[key].y -= offset.y;
        });
    }

    mirrorToYAxis(referenceX: number) {
        let controls = this.getLineControls();
        Object.keys(controls).forEach(key => {
            controls[key].x += (referenceX-controls[key].x)*2;
        });
    }

    mirrorToXAxis(referenceY: number) {
        let controls = this.getLineControls();
        Object.keys(controls).forEach(key => {
            controls[key].y += (referenceY-controls[key].y)*2;
        });
    }

}