import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LineModeControl } from 'src/app/models/controls/line-mode.model';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { WallOpeningCreationMode, WallOpeningMode } from 'src/app/models/enums/wall-opening-mode.enum';
import { WallOpening } from 'src/app/models/geometries/wall-opening.model';
import { ControlsService, DataService } from 'src/app/services';
import { GeometryEditingService } from 'src/app/services/operational-services/geometry-editing/geometry-editing.service';
import { GeometryPropertiesService } from 'src/app/services/operational-services/geometry-properties/geometry-properties.service';
import { AppSettingsService } from 'src/app/services/status-and-control-services/app-settings/app-settings.service';
import { EventsPropagationService } from 'src/app/services/status-and-control-services/events-propagation/events-propagation.service';
import { ModesService } from 'src/app/services/status-and-control-services/modes/modes.service';
import { SelectionService } from 'src/app/services/status-and-control-services/selection/selection.service';

@Component({
  selector: 'app-wall-openings',
  templateUrl: './wall-openings.component.html',
  styleUrls: ['./wall-openings.component.scss']
})
export class WallOpeningsComponent implements OnInit {
  wallOpeningMode: number;
  openings: Array<WallOpening>;
  geometrySubscription: Subscription;
  selectedOpening: number;
  doorTypes: Array<LineModeControl>;
  currentDoorType: number;
  currentWallOpeningCreationMode: number;
  fixedWallOpeningsWidth: any;
  units: string;

  constructor(
    private modesService: ModesService,
    private selectionService: SelectionService,
    private geometryEditingService: GeometryEditingService,
    private dataService: DataService,
    private geometryPropertiesService: GeometryPropertiesService,
    private controlsService: ControlsService,
    private appSettingsService: AppSettingsService,
    private eventsPropagationService: EventsPropagationService
  ) {
    this.units = this.appSettingsService.activeUnitTypeName;

    this.doorTypes = this.controlsService.doorTypes;
    this.currentDoorType = this.geometryEditingService.doorTypeMode;
    this.selectedOpening = null;
    this.wallOpeningMode = this.geometryEditingService.wallOpeningMode;
    this.currentWallOpeningCreationMode = this.geometryEditingService.wallOpeningCreationMode;
    this.geometrySubscription = this.dataService.geometricalData$.subscribe( data => {
      let line = this.oneElementOfLineTypeSelected();
      this.openings = (line && line.openings) ? line.openings : [];
    })
    this.fixedWallOpeningsWidth = this.geometryEditingService.fixedWallOpeningsWidth;
  }

  ngOnInit(): void {
  }

  isEditMode(): boolean {
    return this.modesService.mode === ActiveMode.editMode;
  }

  oneElementOfLineTypeSelected() {
    return this.selectionService.oneElementOfLineTypeSelected();
  }

  wallOpeningName(type: number) {
    let parsedType: string = WallOpeningMode[type.toString()];
    return parsedType;
  }

  removeOpening(index: number) {
    this.openings.splice(index,1);
    this.geometryPropertiesService.updateOpeningsOfSelection(this.openings);

  }

  editOpening(index: number) {
    if(this.openings[index].type) {

    }
  }

  setWallOpeningMode(mode) {
    if(mode !== this.geometryEditingService.wallOpeningMode) {
      this.geometryEditingService.setWallOpeningMode(mode);
      this.wallOpeningMode = mode;
    }
    else {
      this.geometryEditingService.setWallOpeningMode(null);
      this.wallOpeningMode = null;
    }
  }

  mirrorDoorTo(invertDirection) {
    this.openings[this.selectedOpening][invertDirection] = this.openings[this.selectedOpening][invertDirection] ? !this.openings[this.selectedOpening][invertDirection] : true;
    this.geometryPropertiesService.updateOpeningsOfSelection(this.openings);
  }

  updateDoorType(event) {
    this.geometryEditingService.setDoorTypeMode(event.value);
    this.currentDoorType = event.value;
  }

  updateMode(event) {
    this.geometryEditingService.wallOpeningCreationMode = event.value;
    this.currentWallOpeningCreationMode = this.geometryEditingService.wallOpeningCreationMode;
  }

  updateFixedWidth() {
    this.geometryEditingService.fixedWallOpeningsWidth.value = this.geometryEditingService.fixedWallOpeningsWidth.value ? null : 1;
    this.fixedWallOpeningsWidth.on = this.geometryEditingService.fixedWallOpeningsWidth.value !== null;
  }

  activeWallOpeningCreationModeName(mode: number): string {
    let parsedMode: string = WallOpeningCreationMode[mode.toString()];
    return parsedMode;
  }

  onBlur() {
    this.eventsPropagationService.enableEvents();
  }

  onFocus() {
    this.eventsPropagationService.disableEvents();
  }

  updateWallOpeningsWidth() {
    this.geometryEditingService.fixedWallOpeningsWidth = this.fixedWallOpeningsWidth;
  }

}
