export class Address {
    name:string;
    steeetWithNumber: string;
    postCode: number;
    city: string;
    phone: number;
    mobile: number;
    fax: number;
    email: string

    constructor() {}
}