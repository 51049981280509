import { Injectable } from '@angular/core';
import * as symbolLists from "../../../../assets/symbol-llibrary/symbol-library.json";
import { SymbolLibrary } from 'src/app/models/symbol-library/symbol-library.model';
import { SymbolList } from 'src/app/models/symbol-library/symbol-list.model';
import { SymbolItem } from 'src/app/models/symbol-library/symbol-item.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { SymbolCategory } from 'src/app/models/symbol-library/symbol-category.model';

@Injectable({
  providedIn: 'root'
})
export class SymbolLibraryService {
  symbolsUpdated: boolean;
  symbolsUpdated$: Observable<boolean>;
  private _symbolsUpdatedSource: BehaviorSubject<boolean>;
  symbolsLybrary: SymbolLibrary;
  constructor(
    private httpClient: HttpClient
  ) {
    this.symbolsUpdated = false;
    this._symbolsUpdatedSource = new BehaviorSubject(this.symbolsUpdated);
    this.symbolsUpdated$ = this._symbolsUpdatedSource.asObservable();
    this.initSymbolLibrary();
  }

  initSymbolLibrary() {
    this.symbolsLybrary = new SymbolLibrary();
    let lists: any = symbolLists;
    Object.keys(lists.default).forEach(key => {
      lists.default[key].forEach(list => {
      if(key === "symbols") {
        let newSymbolCategory = new SymbolCategory();
        newSymbolCategory.folderName = list.folderName;
        newSymbolCategory.name = list.name;
        list.symbolLists.forEach(symbolList => {
          let newSymbolList = new SymbolList();
          newSymbolList.name = symbolList.name;
          newSymbolList.folderId = symbolList.folderId;
          newSymbolList.folderName = symbolList.folderName;
          symbolList.items.forEach(item => {
            let newSymbol = new SymbolItem();
            newSymbol.imageFileName = list.folderName + "/" + symbolList.folderName + "/" + item.imageFileName;
            newSymbol.title = item.title;
            newSymbolList.items.push(newSymbol);
          });
          newSymbolCategory.symbolLists.push(newSymbolList);
        });
        this.symbolsLybrary[key].push(newSymbolCategory);
      }
      else {
          let newSymbolList = new SymbolList();
          newSymbolList.name = list.name;
          newSymbolList.folderId = list.folderId;
          list.items.forEach(item => {
            let newSymbol = new SymbolItem();
            newSymbol.imageFileName = list.folderName + "/" + item.imageFileName;
            newSymbol.title = item.title;
            newSymbolList.items.push(newSymbol);
          });
          this.symbolsLybrary[key].push(newSymbolList);
        }
      });
    });
  }

  emitSymbolsUpdated() {
    this._symbolsUpdatedSource.next(true);
  }

  getSymbolListByName(listName: string) {
    return this.symbolsLybrary.objects.find(element => element.name === listName);
  }

  getImageItemsByFolderId(folderId: number) {
    return this.httpClient.get(
      environment.requestUrl + "/api/imagelibrary/" + folderId
    );
  }

  addImageToLibrary(imageData) {
    return this.httpClient.post(
      environment.requestUrl + "/api/imagelibrary", imageData
    );
  }

  updateImageItem(editData,id) {
    return this.httpClient.put(
      environment.requestUrl + "/api/imagelibrary/"+ id, editData
    );
  }

  deleteImageItem(id) {
    return this.httpClient.delete(
      environment.requestUrl + "/api/imagelibrary/" + id
    );
  }
}
