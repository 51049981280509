import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { AppSettingsService } from 'src/app/services/status-and-control-services/app-settings/app-settings.service';
import { isPlatformBrowser, Location } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';
import { ControlsService } from 'src/app/services';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  settingsForm: UntypedFormGroup;
  isBrowser: boolean;
  ratios: Array<number>;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private appSettingsService: AppSettingsService,
    private location: Location,
    private controlsService: ControlsService,
    @Inject(PLATFORM_ID) platformId: Object

  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if(this.isBrowser) {
      this.ratios = this.controlsService.ratios;
      this.initForm();
    }
  }

  ngOnInit(): void {
  }

  goBack() {
    this.location.back();
  }

  initForm() {
    this.settingsForm = this.formBuilder.group({
      pixelInMeter: new UntypedFormControl(this.appSettingsService.dimensionsSettings.pixelInMeter, Validators.required),
      units: new UntypedFormControl(this.appSettingsService.dimensionsSettings.units.toString(), Validators.required),
      ratio: new UntypedFormControl(this.appSettingsService.dimensionsSettings.ratio.toString(), Validators.required),
    });
  }

  onSubmit() {
    let params = {
      pixelInMeter: this.settingsForm.value.pixelInMeter,
      units: parseInt(this.settingsForm.value.units),
      ratio: parseInt(this.settingsForm.value.ratio)
    }
    if(!params.pixelInMeter) {
      this.settingsForm.get('pixelInMeter').setValue(this.appSettingsService.dimensionsSettings.pixelInMeter);
      return
    }
    else {
      this.appSettingsService.saveDimensionsSettings(params);
    }
  }
}
