<div class="additional-tools-element">
    <p>Layer ein/ - ausblenden</p>
    <div class="p-tb" fxLayout="column">
        <div class="p-b" fxLayout="row" fxLayoutAlign="start center">
            <button *ngIf="mode !== 1" mat-icon-button (click)="createNewLayer()">
                <mat-icon>
                    add
                </mat-icon>
            </button>
            <button *ngIf="mode === 1 && activeLayer && activeLayer !== ''" mat-icon-button (click)="removeLayer()">
                <mat-icon>
                    delete
                </mat-icon>
            </button>
        </div>
        <mat-divider></mat-divider>
        <div cdkDropList (cdkDropListDropped)="rearrangeLayers($event)">
            <div [ngClass]="{'active-layer': element.layerName === activeLayer}" *ngFor="let element of (layers$ | async); let i = index" cdkDrag fxLayout="row" fxLayoutAlign="start center">
                <div class="layer-drag-placeholder" *cdkDragPlaceholder></div>
                <mat-checkbox (click)="toggleLayerVisibility(i)" [checked]="element.isLayerOn">
                </mat-checkbox>
                <button mat-button fxFlex (click)="toggleLayerSelection(element.layerName,i)" fxLayout="row" fxLayoutAlign="start center">
                    <span>{{element.layerName}}</span>
                </button>
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
</div>
