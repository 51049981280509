import { Component, OnInit } from '@angular/core';
import { SymbolLibraryService } from 'src/app/services/status-and-control-services/symbol-library/symbol-library.service';
import { SymbolList } from 'src/app/models/symbol-library/symbol-list.model';
import { SymbolItem } from 'src/app/models/symbol-library/symbol-item.model';
import { environment } from "../../../../environments/environment";
import { SelectionService } from 'src/app/services/status-and-control-services/selection/selection.service';
import { Subscription } from 'rxjs';
import { OwnSymbolItem } from 'src/app/models/symbol-library/own-symbol-item.model';
import { MatDialog } from '@angular/material/dialog';
import { FileImportDialogComponent, InfoDialogComponent } from '../..';
import { LayerService } from 'src/app/services';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-graphic-objects',
  templateUrl: './graphic-objects.component.html',
  styleUrls: ['./graphic-objects.component.scss']
})
export class GraphicObjectsComponent implements OnInit {

  symbolsFolderPath: string;
  selectedList: SymbolList;
  symbolLists: Array <SymbolList>;
  ownObjectsList: SymbolList;
  symbolsSubscription: Subscription;

  constructor(
    private symbolLibraryService: SymbolLibraryService,
    private selectionService: SelectionService,
    private dialog: MatDialog,
    private layerService: LayerService,
  ) {
    this.selectedList = null;
    this.symbolLists = this.symbolLibraryService.symbolsLybrary.objects;
    this.selectedList = this.symbolLists[1];
    this.ownObjectsList = new SymbolList();
    this.symbolsFolderPath = environment.symbolsFolderPath;
    this.initSymbols();
    this.symbolsSubscription = this.symbolLibraryService.symbolsUpdated$.subscribe(updated => {
      this.initSymbols();
    })
  }

  initSymbols() {
    this.symbolLibraryService.getImageItemsByFolderId(this.selectedList.folderId).subscribe((response:any) => {
      this.ownObjectsList = new SymbolList();
      if(response && response.data && response.data.imageItems && response.data.imageItems.length) {
        response.data.imageItems.forEach(element => {
          let newSybolItem: OwnSymbolItem = new OwnSymbolItem();
          newSybolItem.folderId = element.folderId;
          newSybolItem.id = element.id;
          newSybolItem.imageName = element.imageName;
          newSybolItem.imageBase64 = element.imageBase64;
          this.ownObjectsList.items.push(newSybolItem);
        });
      }
    })
  }

  ngOnInit(): void {
  }

  activeList(listName: string): Array<SymbolItem>  {
    return this.symbolLibraryService.getSymbolListByName(listName).items;
  }

  onDragStart(event,icon) {
    let width = event.target.clientWidth;
    let height = event.target.clientHeight;
    let size = {
      width: width,
      height: height
    }
    let iconData = {
      title: icon.imageFileName ? icon.title : icon.imageName,
      imagePath: icon.imageFileName ? (this.symbolsFolderPath + icon.imageFileName) : icon.imageBase64
    }
    this.selectionService.setDraggingImage(iconData.imagePath, size, iconData.title);
  }

  uploadSymbol() {
    if(this.layerService.activeLayer) {
      this.dialog.open(FileImportDialogComponent,
        {
          data: {
            actionTitle: "selectSymbol",
            actionSubtitle: "selectSymbolSubtitle",
            folderId: this.selectedList.folderId
          }
        });
    }
    else {
      let errorInfo = {
        kind: "Keine Ebene ist Aktiv",
        serviceErrorCode: "Bitte wählen Sie eine Ebene aus für eine weitere Bearbeitung"
      }
      this.dialog.open(InfoDialogComponent, {data: errorInfo});
    }
  }

  deleteObject(id: string) {
    let dialogRefPass = this.dialog.open(ConfirmationDialogComponent,{
      data: {
        question: "delete_graph_object"
      }
    });
    dialogRefPass.afterClosed().subscribe(result => {
      if(result) {
        this.symbolLibraryService.deleteImageItem(id).subscribe((response : any) => {
          if(response && response.status && response.status === 1) {
            let errorInfo = {
              kind: "Erfolg",
              serviceErrorCode: "Das grafische Object wurde erfolgreich entfernt."
            }
            let dialogRef = this.dialog.open(InfoDialogComponent, {data: errorInfo});
            dialogRef.afterClosed().subscribe(result => {
              this.initSymbols();
            })
          }
          else {
            let errorInfo = {
              kind: "Fehler",
              serviceErrorCode: "Beim entfernen des grafischen Objects ist ein Fehler aufgetretten."
            }
            this.dialog.open(InfoDialogComponent, {data: errorInfo});
          }
        })
      }
    });

  }

}
