import { Injectable } from '@angular/core';
import { ViewerService } from '../viewer/viewer.service';
import { Layer, Point  } from 'src/app/models';
import { ViewerOperationsService } from '../viewer-operations/viewer-operations.service';
import { ColorService } from '../color/color.service';
import { ModesService } from '../../status-and-control-services/modes/modes.service';
import { DrawingMode } from 'src/app/models/enums/drawing-mode.enum';
import { SelectionService } from '../../status-and-control-services/selection/selection.service';
import { LayerService } from '../layer/layer.service';
import { ColorEnum } from 'src/app/models/enums/color.enum';
import { LineTypeEnum } from 'src/app/models/enums/line-type.enum';
import { GeometryPropertiesService } from '../geometry-properties/geometry-properties.service';
import { LineIcon } from 'src/app/models/geometries/line-icon.model';
import { GeometryEditingService } from '../geometry-editing/geometry-editing.service';
import { AppSettingsService } from '../../status-and-control-services/app-settings/app-settings.service';
import { GeometryFactory } from 'src/app/models/geometries/geometry.factory.model';
import { PrintService } from '../print/print.service';
import { GeometricType } from 'src/app/models/enums/geometric-types.enum';
import { DrawingService } from '../drawing/drawing.service';
import { Rectangle } from 'src/app/models/geometries/rectangle.model';
import { FileService } from '../file/file.service';
import { Observable } from 'rxjs';
import { Extrema } from 'src/app/models/extrema.model';

@Injectable({
  providedIn: 'root'
})

export class RenderService {
  fps: number = 25;
  prevDelta: number = 0;
  FRAME_DIFF = 1000 / this.fps;
  first = true;
  constructor(
    private viewerService: ViewerService,
    private viewerOperationsService: ViewerOperationsService,
    private drawingService: DrawingService,
    private colorService: ColorService,
    private modesService: ModesService,
    private selectionService: SelectionService,
    private layerService: LayerService,
    private geometryPropertiesService: GeometryPropertiesService,
    private geometryEditingService: GeometryEditingService,
    private appSettingsService: AppSettingsService,
    private printService: PrintService,
    private fileService: FileService,
  ) {
  }

  timeToRender(now): boolean {
    let delta = now - this.prevDelta
    return  delta >= this.FRAME_DIFF;
  }
  
  animate(geometry: Array<Layer>, context, canvas, scale = 1, mousePosition = null, translate: boolean = false,cameraOffset: Point=null,forExport=null): void {
    var now = Date.now();
    if(this.timeToRender(now) && this.viewerOperationsService.geometryFitted) {
        this.clearCanvas(context,canvas,mousePosition);
        this.setupCanvas(context,canvas, geometry, forExport);
        if(!forExport) {
          this.renderContents(mousePosition, geometry, context, canvas, scale).subscribe(() => {
            this.prevDelta = now;
          });
        }
    }
  }
  setupCanvas(context: CanvasRenderingContext2D,canvas,geometry,forExport = null) {
    const scale = forExport ? this.printService.options.printQuality * 4 : 1;
    context.setTransform(1, 0, 0, 1, -this.viewerOperationsService.translation.x * scale, -this.viewerOperationsService.translation.y * scale); 
    this.viewerOperationsService.translated = true;
    if(forExport) {
      this.generatePreview(context, canvas, geometry, scale)
    }
  }

  generatePreview(context, canvas, geometry, scale) {
    this.viewerService._loadingSource.next(true);
    this.setCanvasBackground(canvas,context,"#fff",scale)
    this.renderContents(null, geometry, context, canvas, scale, true).subscribe(res => {
      this.printService.savePreview(context, canvas, this.fileService.dxfFilePath);
    });
  }

  setCanvasBackground(canvas,context,color,scale=1) {
    context.save();
    context.fillStyle = color;
    context.fillRect(0,0,canvas.width*scale,canvas.height*scale);
    context.restore();
  }

  setCanvasInitialScale(canvas, scale: number) {
    canvas.width  = (scale > 1 ? window.innerWidth * scale : window.innerWidth) - 60;
    canvas.height = (scale > 1 ? window.innerHeight * scale : window.innerHeight) - 130;
  }

  createCanvasGrid(canvas, context: CanvasRenderingContext2D, scale: number) {
    context.save();
    context.setTransform(1,0,0,1,0,0)
    scale = this.viewerService.scale;
    let transX = 0//this.viewerOperationsService.translation.x;
    let transY = 0//this.viewerOperationsService.translation.y;
    let cellSize = this.appSettingsService.gridSettings.cellSize/this.appSettingsService.dimensionsSettings.ratio*this.viewerService.scale;
    if(scale > 0.3 && cellSize>=4) {
      for (var x = 0.5+transX; x < canvas.width+transX; x += cellSize) {
        context.moveTo(x, transY);
        context.lineTo(x, canvas.height + transY);
      }
  
      for (var y = canvas.height + transY; y > 0 + transY; y -= cellSize) {
        context.moveTo(transX, y);
        context.lineTo(canvas.width+transX, y);
      }

    }

    context.moveTo(transX,transY);
    context.lineTo(canvas.width+transX,canvas.height+transY);

    context.strokeStyle = this.appSettingsService.gridSettings.gridColor ? this.appSettingsService.gridSettings.gridColor : ColorEnum.gridColor;
    context.stroke();
    context.restore();

  }

  clearCanvas(context: CanvasRenderingContext2D, canvas, mousePosition) {
    canvas.width = canvas.width;

   /*  if(this.first) {
      canvas.width = canvas.width;
    }
    else {
      let selectionExtrema = this.dataService.getSelectionExtrema(this.layerService.activeLayer, 100);
      if(selectionExtrema) {
        context.clearRect(selectionExtrema.minX, selectionExtrema.minY, selectionExtrema.maxX - selectionExtrema.minX, selectionExtrema.maxY - selectionExtrema.minY);
      }
      if(mousePosition) {
        context.clearRect(mousePosition.x - 200, mousePosition.y - 100, 400, 200)
      }
    }
 */
  }

  renderContents(mousePosition, geometry: Array<Layer>, context: CanvasRenderingContext2D, canvas, scale, forExport: boolean = false) {
    let obs: Observable<any> = new Observable(observer => {      
      if(geometry && geometry.length) {
        this.renderGeometries(mousePosition, geometry, context, canvas, scale, forExport);
      }
      if(this.selectionService.canRectSelect()) {
        this.renderSelectingRect(context,canvas,1, this.selectionService.selectingRect);
      }
      if(this.drawingService.activeGeometry && !this.drawingService.drawingError) {
        this.renderActiveGeometry(this.drawingService.activeGeometry,context,canvas,this.drawingService.drawingMode);
      }
      if(this.first && geometry.length && (mousePosition && mousePosition.x && mousePosition.y)) {
        this.first = false;
      }
      observer.next(true);
    });
    return obs;
  }

  renderSelectingRect(context: CanvasRenderingContext2D, canvas: HTMLCanvasElement, scale: number, selectingRect: Rectangle) {
    let color = selectingRect.geometryProperties.strokeColor.rgbToHex(selectingRect.geometryProperties.strokeColor);
    selectingRect.draw(context, scale, color, canvas);
  }

  extremaOverlap(geomExtrema: Extrema, activeExtrema: Extrema): boolean {
    // if rectangle has area 0, no overlap
    if (geomExtrema.minX === geomExtrema.maxX || geomExtrema.minY === geomExtrema.maxY || activeExtrema.minX == activeExtrema.maxX || activeExtrema.minY == activeExtrema.maxY) return false;
    // If one rectangle is on left side of other
    if (geomExtrema.minX > activeExtrema.maxX || activeExtrema.minX > geomExtrema.maxX) return false;
    // If one rectangle is above other
    if (geomExtrema.minY > activeExtrema.maxY || activeExtrema.minY > geomExtrema.maxY) return false;
    return true;
  }

  renderGeometries(mousePosition: Point, geometry: Array<Layer>, context: CanvasRenderingContext2D, canvas: HTMLCanvasElement, scale: number, forExport = false, customThickness = null) {
    let scaleMult = 2;
    let multipleSelected = this.selectionService.multipleObjectsSelected();
    let visualisationMultiplier = this.appSettingsService.getVisualisationMultiplier();
    let oneLineSelected = this.selectionService.oneElementOfLineTypeSelected();
    let selectionExists = this.selectionService.selectedElementsExist();
    //let selectionExtrema: Extrema = this.dataService.getSelectionExtrema(this.layerService.activeLayer, 100);
    let mouseIsActive = (mousePosition && (mousePosition.x || mousePosition.y)) ? true : false;
    let mouseRectExtrema: Extrema = null;
    if(mouseIsActive) {
      mouseRectExtrema = {
        minX: mousePosition.x - 200,
        minY: mousePosition.y - 100,
        maxX: mousePosition.x + 200,
        maxY: mousePosition.y + 100,
      }
    }
    let strokeColor = "";
    let fillColor = "";
    if(!mousePosition) {
      mousePosition = new Point(0,0);
    }
    let wallOpeningMode = null;
    if(
      this.selectionService.oneElementOfLineTypeSelected() && this.modesService.editableMode
    ) {
      wallOpeningMode = (this.geometryEditingService.wallOpeningCreationMode == 6 && this.geometryEditingService.wallOpeningMode) ? this.geometryEditingService.wallOpeningCreationMode : this.geometryEditingService.wallOpeningMode;
    }
    geometry.forEach(layer => {
      if(layer.isVisible) {
        if(this.modesService.editableMode && mousePosition && mouseIsActive) {
          if(this.layerService.activeLayer === layer.layerName) {
            let activeElement = layer.findIntersectedGeometry(mousePosition,this.viewerOperationsService.translation,context,canvas,scale);
            if(activeElement){
              this.selectionService.setActiveElement(activeElement);
            }
            else if(!activeElement && this.selectionService.activeElement){
              this.selectionService.resetActiveElement();
            }
          }
        }
        layer.geometry.forEach(geometry => {
          /* let geommExtrema = geometry.extrema();
          let withMouse = (mouseIsActive && this.extremaOverlap(mouseRectExtrema,geommExtrema));
          let withSelection = (selectionExtrema && !geometry.selected && this.extremaOverlap(selectionExtrema,geommExtrema));
          if(
            this.first ||
            geometry.selected ||
            geometry.entityName === this.selectionService.activeElement ||
            withSelection ||
            withMouse 
            true
          ) {*/
            strokeColor = geometry.geometryProperties.strokeColor ? this.colorService.rgbToHex(geometry.geometryProperties.strokeColor) : ColorEnum.default;
            if(strokeColor === "#ffffff" && geometry.geometricType !== GeometricType.Text) {
              strokeColor = "#000000";
            }
            if(geometry.geometryProperties.thickness) {
              context.lineWidth = geometry.geometryProperties.thickness * (customThickness ? scaleMult : 1);
            }
            else {
              context.lineWidth = 1 * (customThickness ? scaleMult : 1);
            }
            if(geometry.geometryProperties.lineType !== LineTypeEnum.default) {
              let lineDash = this.geometryPropertiesService.getDashPatternSettings(geometry.geometryProperties.lineType);
              if(lineDash) {
                context.setLineDash(lineDash);
              }
            }
            else {
              context.setLineDash([]);
            }
            if(this.modesService.editableMode && this.layerService.activeLayer === layer.layerName && this.selectionService.activeElement === geometry.entityName) {
              strokeColor = ColorEnum.highlighted;
            }
            if(geometry.geometryProperties.fillColor) {
              fillColor = this.colorService.rgbToHex(geometry.geometryProperties.fillColor);
            }
            else {
              fillColor = "";
            }

            if(geometry.geometricType === GeometricType.Image) {
  
              geometry.draw(context, scale, strokeColor, canvas, fillColor, forExport);
            }
            else {
              geometry.draw(context, scale, strokeColor, canvas, fillColor, wallOpeningMode);
            }
            if(this.layerService.activeLayer === layer.layerName) {
              if(!multipleSelected && geometry.selected && geometry.geometricType !== GeometricType.Image) {
                let unit = this.appSettingsService.activeUnitTypeName;
                geometry.drawInformation(context,canvas,scale,visualisationMultiplier,unit,this.appSettingsService.dimensionsSettings.ratio);
              }
              if(oneLineSelected && geometry.selected) {
                if(this.geometryEditingService.openingBufferToDisplay) {
                  this.renderOpeningBuffer(context,scale);
                }
                let contorlsMode = (this.geometryEditingService.wallOpeningCreationMode == 6 && this.geometryEditingService.wallOpeningMode && this.geometryEditingService.fixedWallOpeningsWidth.on && this.geometryEditingService.fixedWallOpeningsWidth.value) ? this.geometryEditingService.fixedWallOpeningsWidth : ((wallOpeningMode == 6  && !this.geometryEditingService.lineControlsEditingMode) ? 6 : this.geometryEditingService.lineControlsEditingMode);
                geometry.drawControls(mousePosition,context,canvas,scale,contorlsMode);
                if(oneLineSelected) {
                  let controlPointBuffer = this.geometryEditingService.lineControlPointBuffer;
                  if(geometry.intersectedPoint && this.selectionService.activeElement) {
                    this.geometryEditingService.setLinePointBuffer({x:geometry.intersectedPoint.x/scale,y:geometry.intersectedPoint.y/scale});
                    this.geometryEditingService.setWallOpeningBuffer(geometry.intersectedAfterVertexIndex);
                  }
                  else if(!this.selectionService.activeElement && controlPointBuffer.x && controlPointBuffer.y) {
                    this.geometryEditingService.resetLinePointBuffer();
                    this.geometryEditingService.resetWallOpeningBuffer();
                  }
                }
              }
            }
          //}
        })
      }
    });
    if(selectionExists) {
      this.renderSelectionBoundaries(mousePosition,context,canvas,scale);
    }
    /* if(mousePosition && (mousePosition.x || mousePosition.y)) {
      context.save();
      context.fillStyle = ColorEnum.default;
      let textX = "x: " + this.appSettingsService.dimensionToDisplay(Math.floor((mousePosition.x)*this.appSettingsService.dimensionsSettings.ratio));
      let textY = "y: " + this.appSettingsService.dimensionToDisplay(Math.floor((mousePosition.y)*this.appSettingsService.dimensionsSettings.ratio));
      context.setTransform(1,0,0,1,mousePosition.x-this.viewerOperationsService.translation.x, mousePosition.y - this.viewerOperationsService.translation.y);
      context.fillText(textX, 10, 0);
      context.fillText(textY, 10, 10);
      context.restore();
    } */
  }

  renderOpeningBuffer(context, scale) {
    let opening = this.geometryEditingService.openingBufferToDisplay
    context.beginPath();
    context.fillStyle = "orange";
    context.arc(opening.startPoint.x * scale, opening.startPoint.y * scale, 5, 0, 2 * Math.PI);
    context.fill();
    if(opening.endPoint.x > 0 || opening.endPoint.y > 0) {
      context.arc(opening.endPoint.x * scale, opening.endPoint.y * scale, 5, 0, 2 * Math.PI);
      context.fill();
    }
  }

  setupGeometryProperties(geometry, context) {
    context.beginPath();
    context.strokeStyle = geometry.strokeColor;
    if(geometry.thickness) {
      context.lineWidth = geometry.thickness;
    }
    else {
      context.lineWidth = 1;
    }
    if(geometry.lineType !== LineTypeEnum.default) {
      let lineDash = this.geometryPropertiesService.getDashPatternSettings(geometry.lineType);
      if(lineDash) {
        context.setLineDash(lineDash);
      }
    }
    else {
      context.setLineDash([]);
    }
  }

  renderActiveGeometry(geometry, context, canvas, mode) {
    if(geometry) {
      let scale = this.viewerService.scale;
      this.setupGeometryProperties(geometry, context);
      this.displayActiveGeometry(geometry, context, canvas, mode, scale);
      this.renderActiveGeometryText(geometry, context, canvas, mode, scale);

      if(geometry.intersectedControl) {
        this.drawIntersectedControlOfGeometry(geometry.intersectedControl,context,scale)
      }
      else if(geometry.gridSnapped) {
        this.drawSnappedGrid(geometry.gridSnapped,context,scale)
      }
    }
  }

  displayActiveGeometry(geometry, context, canvas, mode, scale) {
    if(mode === DrawingMode.line) {
      context.moveTo(geometry.startPoint.x * scale, geometry.startPoint.y * scale);
      context.lineTo(geometry.endPoint.x * scale, geometry.endPoint.y * scale);
    }
    else if(mode === DrawingMode.circle) {
      if(geometry.fillColor) {
        context.fillStyle = geometry.fillColor;
        context.arc(geometry.center.x * scale, geometry.center.y * scale, geometry.radius * scale, 0, 2 * Math.PI);
        context.fill();
      }
      context.arc(geometry.center.x * scale, geometry.center.y * scale, geometry.radius * scale, 0, 2 * Math.PI);
    }
    else if(mode === DrawingMode.rect) {
      context.save();
      //context.setTransform(1, 0, 0, 1, geometry.origin.x * scale, (canvas.clientHeight - geometry.origin.y * scale));
      context.rect(geometry.origin.x, geometry.origin.y, geometry.width, geometry.height);
      if(geometry.fillColor) {
        context.fillStyle = geometry.fillColor;
        context.fillRect(geometry.origin.x, geometry.origin.y, geometry.width, geometry.height);
      }
      context.restore();     
    }
    else if(mode === DrawingMode.arrow) {
      let factory = new GeometryFactory();
      let geometryItem = factory.createGeometry({...geometry, geometricType: mode});
      geometryItem.draw(context, scale, geometry.strokeColor ? geometry.strokeColor : ColorEnum.default, canvas, geometry.fillColor ? geometry.fillColor : null);
      return;
    }
    else if(mode === DrawingMode.image) {
      let imageObj = new Image();
      imageObj.src = geometry.imagePath;
      context.save();
      context.setTransform(1, 0, 0, 1, geometry.origin.x , geometry.origin.y);
      context.drawImage(imageObj, 0, 0, geometry.width * scale, geometry.height * scale);
      context.restore();    
    }
    else if((mode === DrawingMode.polygon || mode === DrawingMode.Polyline || mode === DrawingMode.LWPolyline) && geometry.mousePos) {
      context.beginPath();
      context.moveTo(geometry.vertexes[0].position.x * scale, geometry.vertexes[0].position.y * scale);
      if(geometry.vertexes.length > 1) {
        geometry.vertexes.forEach(vertex => {
          context.lineTo(vertex.position.x * scale, vertex.position.y * scale);
      })
      }
      if(mode === DrawingMode.polygon) {
        context.closePath();
        if(geometry.fillColor) {
          context.fillStyle = geometry.fillColor;
          context.fill();
        }
      }
    }
    else if(mode === DrawingMode.arc) {
      if(geometry.step === 1) {
        context.setLineDash([10,10]);
        context.strokeStyle = geometry.strokeColor ? geometry.strokeColor : ColorEnum.default;
        context.moveTo(geometry.center.x * scale, geometry.center.y * scale);
        context.lineTo(geometry.mousePos.x, geometry.mousePos.y);
      }
      else if(geometry.step === 2) {
        context.arc(geometry.center.x * scale, geometry.center.y * scale, geometry.radius * scale, geometry.startAngle, geometry.endAngle);   
      }
    }
    context.stroke();
    if(geometry.startIcon) {
      this.renderLineIcon(geometry.startIcon,context,geometry,"start",scale);
    }
    if(geometry.endIcon) {
      this.renderLineIcon(geometry.startIcon,context,geometry,"end",scale);
    }
  }

  renderLineIcon(icon: any, context, line, position:string, scale) {
    var dx = position === "end" ? (line.endPoint.x - line.startPoint.x) : (line.startPoint.x - line.endPoint.x);
    var dy = position === "end" ? (line.endPoint.y - line.startPoint.y) : (line.startPoint.y - line.endPoint.y);
    var angle = Math.atan2(dy, dx);
    let lineIcon = new LineIcon(icon);
    lineIcon.draw(context,angle,position=== "end" ? line.endPoint : line.startPoint,scale);
  }

  renderActiveGeometryText(geometry, context: CanvasRenderingContext2D, canvas, mode, scale) {
    let unit = this.appSettingsService.activeUnitTypeName;
    context.fillStyle = ColorEnum.fontDefault;
    context.font = "18px Arial";
    context.save();
    if(mode === DrawingMode.line) {
      if(geometry.distance) {
        //context.setTransform(1, 0, 0, 1, geometry.startPoint.x * scale, (canvas.clientHeight - geometry.startPoint.y * scale));
        context.fillText("Länge: " + this.appSettingsService.dimensionToDisplay(geometry.distance*this.appSettingsService.dimensionsSettings.ratio) + " " + unit, geometry.startPoint.x, geometry.startPoint.y);
      }
    }
    else if(mode === DrawingMode.circle) {
      if(geometry.radius) {
        //context.setTransform(1, 0, 0, 1, geometry.center.x * scale, (canvas.clientHeight - geometry.center.y * scale));
        context.fillText("r: " + this.appSettingsService.dimensionToDisplay(geometry.radius*this.appSettingsService.dimensionsSettings.ratio) + " " + unit, geometry.center.x, geometry.center.y);
      }
    }
    else if(mode === DrawingMode.rect) {
      if(geometry.width || geometry.height) {
        context.fillText("Breite: " + this.appSettingsService.dimensionToDisplay(Math.abs(geometry.width*this.appSettingsService.dimensionsSettings.ratio)) + " " + unit, 0, 20);
        context.fillText("Höhe: " + this.appSettingsService.dimensionToDisplay(Math.abs(geometry.height*this.appSettingsService.dimensionsSettings.ratio)) + " " + unit, 0, 40);
      }
    }
    else if(mode === DrawingMode.arc) {
      if(geometry.radius) {
        //context.setTransform(1, 0, 0, 1, geometry.center.x, geometry.center.y);
        context.fillText("radius: " + this.appSettingsService.dimensionToDisplay(geometry.radius*this.appSettingsService.dimensionsSettings.ratio) + " " + unit, 0, 10);
        context.fillText("startWink: " + (geometry.startAngle * 180/Math.PI).toFixed(2) + "°", 0, 30);
        context.fillText("endWink: " + (geometry.endAngle * 180/Math.PI).toFixed(2) + "°", 0, 50);
      }
    }
    context.restore();
  }

  renderSelectionBoundaries(mousePosition, context, canvas, scale) {
    let selectionFrame: Rectangle = this.selectionService.getSelectionRect();
    if(selectionFrame) {
      let color = this.colorService.rgbToHex(selectionFrame.geometryProperties.strokeColor);
      context.lineWidth = 1;
      context.setLineDash([]);
      let singleRotatable = this.selectionService.getSelectedRotatableGeometry() && !this.selectionService.multipleObjectsSelected();
      if(selectionFrame.rotation !== 0 && Math.abs(selectionFrame.rotation%90) === 0) {
        context.strokeStyle = ColorEnum.rightAngleHighlight;
      }
      else {
        context.strokeStyle = color;
      }
      selectionFrame.draw(context, scale, color, canvas);
      if(!this.selectionService.multipleObjectsSelected() && !this.selectionService.oneElementOfLineTypeSelected()) {
        let mousePositionBuff = {...mousePosition};
        mousePositionBuff.x = mousePosition.x - this.viewerOperationsService.translation.x;
        mousePositionBuff.y = mousePosition.y - this.viewerOperationsService.translation.y;
        selectionFrame.drawControls(mousePositionBuff, context, canvas, scale, singleRotatable);
      }
    }
  }

  drawIntersectedControlOfGeometry(point,context,scale) {
    context.beginPath();
    context.fillStyle = "yellow";
    context.arc(point.x * scale, point.y * scale, 5, 0 * Math.PI, 2 * Math.PI);
    context.fill();
  }

  drawSnappedGrid(gridSnappedInfo,context,scale) {
    let gridCellSize = this.appSettingsService.gridSettings.cellSize/this.appSettingsService.dimensionsSettings.ratio;
    let gridSnapped = gridSnappedInfo.gridSnapping;
    let point = gridSnappedInfo.point;
    context.save();
    context.beginPath();
    context.strokeStyle = "yellow";
    context.setLineDash([]);
    if(gridSnapped.y) {
      context.moveTo((point.x-gridCellSize)*scale, point.y * scale);
      context.lineTo((point.x+gridCellSize)*scale, point.y * scale);
    }
    if(gridSnapped.x) {
      context.moveTo(point.x*scale, (point.y-gridCellSize) * scale);
      context.lineTo(point.x*scale, (point.y+gridCellSize) * scale);
    }
    context.stroke();
    context.restore();
  }

  renderRuler(horRuler, vertRuler, mainCanvas) {
    let scale = this.viewerService.scale/this.appSettingsService.dimensionsSettings.ratio;
    horRuler.width = mainCanvas.clientWidth / (scale < 1 ? scale : 1);
    horRuler.height = 30;
    vertRuler.width = 30;
    vertRuler.height  = mainCanvas.clientHeight / (scale < 1 ? scale : 1);
    let horRulerContext = horRuler.getContext('2d');
    let vertRulerContext = vertRuler.getContext('2d');
    horRulerContext.fillText("Meter", 12, 10);
    horRulerContext.beginPath();
    let metersNumber = 0;
    let ratioMultiplier = this.appSettingsService.dimensionsSettings.pixelInMeter/1000*this.appSettingsService.dimensionsSettings.ratio;
    for(let i = 0; i < horRuler.width; i = i + 10) {
      horRulerContext.moveTo(i * scale, horRuler.height);
      if(i % (ratioMultiplier*1000) == 0) {
        horRulerContext.lineTo(i * scale, 0);
      }
      else if(this.appSettingsService.dimensionsSettings.ratio < 50) {
        if(i % (ratioMultiplier*500) == 0) {
          horRulerContext.lineTo(i * scale, 10);
        }
        else if(i % (ratioMultiplier*250) == 0 && this.appSettingsService.dimensionsSettings.ratio > 4) {
          horRulerContext.lineTo(i * scale, horRuler.height - 10);
        }
        else if(this.appSettingsService.dimensionsSettings.ratio < 4) {
          horRulerContext.lineTo(i * scale, horRuler.height - 10);
        }
      }
      if(i % (ratioMultiplier * 1000) == 0) {
        horRulerContext.fillText(metersNumber*this.appSettingsService.dimensionsSettings.ratio, i * scale + 5, 10);
        metersNumber++;
      }
      horRulerContext.stroke();
    }

    vertRulerContext.beginPath();
    metersNumber = 0;
    for(let i = 0; i < vertRuler.height; i = i + 10) {
      vertRulerContext.moveTo(vertRuler.width, i * scale);
      if(i % (ratioMultiplier*1000) == 0) {
        vertRulerContext.lineTo(0, i * scale);
      }
      else if(this.appSettingsService.dimensionsSettings.ratio < 50) {
      
        if(i % (ratioMultiplier*500) == 0) {
          vertRulerContext.lineTo(10, i * scale);
        }
        else if(i % (ratioMultiplier*250) == 0 && this.appSettingsService.dimensionsSettings.ratio > 4) {
          vertRulerContext.lineTo(20, i * scale);
        }
        else if(this.appSettingsService.dimensionsSettings.ratio < 4) {
          vertRulerContext.lineTo(20, i * scale);
        }
      }
      if(i % (ratioMultiplier * 1000) == 0) {
        vertRulerContext.fillText(metersNumber*this.appSettingsService.dimensionsSettings.ratio, 5, i * scale + 10);
        metersNumber++;
      }
      vertRulerContext.stroke();
    }
  }

}