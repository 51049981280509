import { Plan } from './plan.model';
import { Client } from './client.model';
import { Creator } from './creator.model';
import { ProjectAdditionalData } from './project-additional-data.model';

export class Project {
    id?: string;
    name: string;
    type: string;
    projectNo: string;
    object: string;
    floor: string;
    room: string;
    creationDate: string;
    lastChangedDate: string;
    nextReviewDate: string;
    creator: Creator;
    comment: string;
    client: Client;
    detail: ProjectAdditionalData;
    projectDrawings: Array<Plan>;

    constructor() {
        this.projectDrawings = new Array<Plan>();
        this.creator = new Creator();
        this.client = new Client();
        this.detail = new ProjectAdditionalData();
    }
}