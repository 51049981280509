import { Rectangle } from './rectangle.model';
import { ColorEnum } from '../enums/color.enum';
import { Point } from '../point.model';

export class EPText extends Rectangle {
    text: string;
    textHeight: number;
    fontSize: number;
    textAlign: string;
    verticalAlign: string;
    fontStyle: string;
    fontFamily: string;
    lineHeight: number;
    forExport: any[];

    constructor(data) {
        data.origin = data.position ? data.position : data.origin;
        super(data);
        this.text = data.text;
        this.fontSize = data.fontSize;
        this.textHeight = data.textHeight;
        this.textAlign = data.textAlign;
        this.verticalAlign = data.verticalAlign ? data.verticalAlign : "top";
        this.fontStyle = data.fontStyle ? data.fontStyle : "normal";
        this.fontFamily = data.fontFamily ? data.fontFamily : "Arial";
        this.lineHeight = data.lineHeight ? data.lineHeight : data.fontSize;
        this.forExport = new Array<any>();
    }

    override draw(context, scale, color, canvas, fillColor = null) {
        if(fillColor) {
            super.draw(context,scale,fillColor,canvas,fillColor);
        }
        context.save();
        let translation = new Point(this.origin.x  + this.width/2, this.origin.y + this.height/2);
        context.translate(translation.x*scale, translation.y * scale);
        if(this.rotation) {
            context.rotate((this.rotation) * Math.PI / 180);
        }
        context.fillStyle = color ? color : ColorEnum.fontDefault;
        context.font = (this.fontStyle ? (this.fontStyle + " ") : "") + (this.fontSize ? ((this.fontSize * scale).toString()+"px") : (20*scale).toString + "px") + " " + (this.fontFamily ? this.fontFamily : "Arial");
        let alignOffsetX = 0;
        let alignOffsetY = 0;
        if(this.textAlign) {
            context.textAlign = this.textAlign;
            if(this.textAlign == "center") {
                alignOffsetX = this.width/2*scale;
            }
            else if(this.textAlign == "right") {
                alignOffsetX = this.width*scale;
            }
        }
        this.wrapText(context, this.text, -this.width/2*scale+alignOffsetX, -this.height/2*scale + (this.lineHeight ? this.lineHeight : this.fontSize+1) + alignOffsetY, this.width * scale, translation);
        context.restore();
    }

    wrapText(context, text, x, y, maxWidth, translation: Point) {
        this.forExport = [];
        let initailY = y;
        var words = text.split(' ');
        var line = '';

        for(var n = 0; n < words.length; n++) {
          var testLine = line + words[n] + ' ';
          var metrics = context.measureText(testLine);
          var testWidth = metrics.width;
          if (testWidth > maxWidth && n > 0) {
            this.forExport.push({pos: {x: translation.x + x, y: translation.y + y }, text: line, width: maxWidth});
            context.fillText(line, x, y);
            line = words[n] + ' ';
            y += this.lineHeight ? this.lineHeight : this.fontSize+1;
          }
          else {
            line = testLine;
          }
        }
        this.forExport.push({pos: {x: translation.x + x, y: translation.y + y }, text: line});
        context.fillText(line, x, y);
        if(initailY < y) {
            return y-initailY;
        }
    }

    override mirrorToXAxis(referenceY: number) {
        this.origin.y += (referenceY-this.origin.y) * 2 - this.height;
    }

    override scale(mult: Point, selectionRect: Rectangle,scaleText: any = false) {
        super.scale(mult, selectionRect);
        if(scaleText) {
            this.fontSize *= mult.x;
            this.lineHeight *= mult.x;
        }
    }
}
