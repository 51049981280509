import { SymbolCategory } from './symbol-category.model';
import { SymbolList } from './symbol-list.model';

export class SymbolLibrary {
    objects: Array <SymbolList>;
    symbols: Array <SymbolCategory>;

    constructor() {
        this.objects = new Array<SymbolList>();
        this.symbols = new Array<SymbolCategory>();
    }
}