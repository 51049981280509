import { Component, OnInit } from '@angular/core';
import { Project } from '../../../models/project/project.model';
import { Router } from "@angular/router";
import { ProjectService } from 'src/app/services/user/project/project.service';
import { DataService, FileService, LayerService, TranslationService, ViewerOperationsService, ViewerService } from 'src/app/services';
import { Plan } from 'src/app/models/project/plan.model';
import { Subscription } from 'rxjs';
import { InputDialogComponent } from '../../dialogs/input-dialog/input-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PlanService } from 'src/app/services/user/plan/plan.service';
import { isPlatformBrowser, Location } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { ProfileService } from 'src/app/services/user/profile/profile.service';
import { InfoDialogComponent } from '../../dialogs/info-dialog/info-dialog.component';
import { AuthenticationService } from 'src/app/services/user/authentication/authentication.service';
import * as sampleProjectData from "../../../../assets/projects/sample-project.json";
import { NotificationService } from 'src/app/services/status-and-control-services/notification/notification.service';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { ModesService } from 'src/app/services/status-and-control-services/modes/modes.service';

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss']
})
export class ProjectsListComponent implements OnInit {
  projects: Array<Project>;
  projectsSubscription: Subscription;
  isBrowser: boolean;
  openIndex: number;
  progressvalue: number;
  loading: boolean;
  loadingSubscription: Subscription;
  projectsInitialized: boolean;
  sampleProject: any;
  sampleProjectSummary: any;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private projectService: ProjectService,
    private planService: PlanService,
    private layerService: LayerService,
    private profileService: ProfileService,
    private translationService: TranslationService,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private fileService: FileService,
    private viewerOperationsService: ViewerOperationsService,
    private dataService: DataService,
    private modesService: ModesService,
    private viewerService: ViewerService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.progressvalue = null;
    this.loadingSubscription = this.viewerService.loading$.subscribe(
      laodingStatus => {
        this.loading = laodingStatus;
      }
    );
    this.viewerService._loadingSource.next(true);
    this.openIndex = null;
    this.modesService.setMode(ActiveMode.moveMode);
    this.isBrowser = isPlatformBrowser(platformId);
    this.projectsInitialized = false;
    this.projectService.setActiveProjectId(null);
    this.planService.setActivePlanData({id:null,drawingName:null});
    this.fileService.resetFileName();
    this.dataService.initializeGeometryDataLists();
    if(this.isBrowser) {
      this.viewerOperationsService.translation = {x:0,y:0};
      this.projectService.updateProjectsState();
      this.projects = new Array<Project>();
      this.projectsSubscription = this.projectService.projects$.subscribe(projects => {
        let projectData: any = sampleProjectData;
        this.sampleProject = projectData.default;
        if(projectService.projectsInitialized) {
          this.projects = projects;
          this.projectsInitialized = true;
          this.viewerService._loadingSource.next(false);
        }
      })
    }
    this.profileService.requestMyProfile().subscribe((profileResponse: any) => {
      if(profileResponse && profileResponse.data && !profileResponse.data.isActivated) {
        let response = this.translationService.translateAPIResponse({serviceErrorCode: 12, kind: "ERROR"})
        this.dialog.open(InfoDialogComponent,{data: response});
        this.authenticationService.logout().subscribe(res => {
        })
        this.router.navigate(["authorization/login"])
      }
    })
  }

  ngOnInit(): void {
  }

  editProjectData(event,project:Project) {
    event.stopPropagation();
    this.router.navigate(["../project/" + project.id]);
  }

  createNewProject() {
    this.profileService.requestMyProfile().subscribe((profileResponse: any) => {
      if(profileResponse && profileResponse.data && profileResponse.data.licenseStatus) {
        this.router.navigate(["../project/newProject"]);
      }
      else {
        let response = this.translationService.translateAPIResponse({serviceErrorCode: 23, kind: "ERROR"})
        this.dialog.open(InfoDialogComponent,{data: response});
      }
    })
  }

  copyProject(event,project: Project) {
    event.stopPropagation();
    this.loading = true;
    this.animateProgressBar();
    let copyProjectData = {
      projectId : project.id
    }
    this.projectService.copyProject(copyProjectData).subscribe((response: any) => {
      this.projectService.updateProjectsState();
      this.notificationService.openNotification("copy_project_success");
      this.loading = false;
      this.progressvalue = 0;
    },
    error => {
      this.loading = false;
      this.notificationService.openNotification("copy_project_error");

    });

  }

  deleteProject(event,project,index) {
    event.stopPropagation();
    this.viewerService._loadingSource.next(true);
    let dialogRefPass = this.dialog.open(ConfirmationDialogComponent,{
      data: {
        question: "delete_project"
      }
    });
    dialogRefPass.afterClosed().subscribe(result => {
      if(result) {
        this.projects.splice(index,1);
        this.openIndex = null;
        this.projectService.deleteProject(project.id).subscribe(response => {
          this.projectService.updateProjectsState();
          this.notificationService.openNotification("delete_project_success")
        });
      }
      this.viewerService._loadingSource.next(false);
      this.progressvalue = 0;
    });
  }

  createPlan(event,project) {
    event.stopPropagation();
      let dialogRefPass = this.dialog.open(InputDialogComponent, {
        data: {
          valueName: "Plan-Name",
          buttonText: "Plan anlegen"
        }
      },);
      dialogRefPass.afterClosed().subscribe(result => {
        if(result && result.planName) {
          let unique = true;
          project.projectDrawings.forEach(drawing => {
            if(drawing.drawingName === result.planName) {
              let errorMessage = this.translationService.translateSimpleToken("DRAWING_NAME_DUPLICATE");
              this.dialog.open(InfoDialogComponent,{
                data: {
                  serviceErrorCode: errorMessage
                }
              })
              unique = false;
            }
          })
          if(unique) {
            let planData = {
              drawingName: result.planName,
              projectId: project.id
            }
            this.planService.createPlan(planData).subscribe(response => {
              this.projectService.updateProjectsState();
              this.projectService.saveOrder(this.projects);
              this.notificationService.openNotification("create_drawing_success");
            });
          }
          else {
            return;
          }
        }
      });
  }

  rearrangePlans(project, event: CdkDragDrop<Array<string>>) {
    moveItemInArray(project.projectDrawings, event.previousIndex, event.currentIndex);
    this.projectService.saveOrder(this.projects);
  }

  renamePlan(project,plan) {

    let dialogRefPass = this.dialog.open(InputDialogComponent, {
      data: {
        value: plan.drawingName,
        valueName: "Plan-Name",
        buttonText: "Name speichern"
      }
    });
    dialogRefPass.afterClosed().subscribe(result => {
      if(result && result.planName) {
        let unique = true;
        project.projectDrawings.forEach(drawing => {
          if(drawing.drawingName === result.planName) {
            let errorMessage = this.translationService.translateSimpleToken("DRAWING_NAME_DUPLICATE");
            this.dialog.open(InfoDialogComponent,{
              data: {
                serviceErrorCode: errorMessage
              }
            })
            unique = false;
          }
        })
        if(unique) {
          this.loading = true;
          this.animateProgressBar();
          this.planService.getPlanById(plan.id).subscribe((response: any) => {
            let drawingName = result.planName;
            let drawingData = {
              drawingName: drawingName
            }
            this.planService.editPlanName(drawingData,plan.id).subscribe(
              response => {
                this.projectService.updateProjectsState();
                this.loading = false;
                this.notificationService.openNotification("rename_drawing_success");
              })
          })
        }
        else {
          return;
        }
      }
    })
  }

  copyPlan(project,plan) {

    this.animateProgressBar();
    let copyPlanData = {
      drawingId : plan.id,
      projectId : project.id
    }
    this.planService.copyPlan(copyPlanData).subscribe((response: any) => {
      this.projectService.updateProjectsState();
      this.projectService.saveOrder(this.projects);
      this.notificationService.openNotification("copy_drawing_success");
      this.loading = false;
      this.progressvalue = 0;
    },
    error => {
      this.loading = false;
    });

  }

  deletePlan(plan) {
    this.viewerService._loadingSource.next(true);
    let dialogRefPass = this.dialog.open(ConfirmationDialogComponent,{
      data: {
        question: "delete_drawing"
      }
    });
    dialogRefPass.afterClosed().subscribe(result => {
      if(result) {
        this.planService.deletePlan(plan.id).subscribe(response => {
          this.projectService.updateProjectsState();
          this.projectService.saveOrder(this.projects);
          this.notificationService.openNotification("delete_drawing_success");
        });
      }
      this.viewerService._loadingSource.next(false);
      this.progressvalue = 0;
    });
  }

  editPlan(project, plan: Plan) {
    this.animateProgressBar();
    this.viewerService._loadingSource.next(true);
    if(project.id !== "sampleProject") {
      this.projectService.setActiveProjectId(project.id);
      this.planService.setActivePlanData(plan);
      if(plan.drawingDxfPath) {
        this.viewerOperationsService.toggleEdittingViewer(ActiveMode.moveMode, {filePath: plan.drawingDxfPath });
        this.fileService.getPreviewByLayers({FileName: plan.drawingDxfPath }).subscribe(res => {
          this.layerService.initLayersFromResponse(res.data.allLayers,true);
          this.progressvalue = 0;
          this.router.navigate(["../editor"]);
        })
      }
      else {
        this.layerService.initLayers().subscribe(res => {
          this.viewerService._loadingSource.next(false);
          this.progressvalue = 0;
          this.router.navigate(["../editor"]);
        });
      }
    }
    else {
      this.layerService.initLayersFromDataBase(this.sampleProject.samplePlanJson).subscribe(res=>{
        this.projectService.setActiveProjectId(project.id);
        this.planService.setActivePlanData(plan);
        this.viewerService._loadingSource.next(false);
        this.progressvalue = 0;
        this.router.navigate(["../editor"]);
        this.modesService.setMode(ActiveMode.editMode);
        this.viewerOperationsService.editing = true;
        this.viewerOperationsService._edittingSource.next(this.viewerOperationsService.editing);
      });
    }
  }

  animateProgressBar() {
    setTimeout(() => {
      this.progressvalue++;
      if (this.progressvalue < 100) {
        this.animateProgressBar();
      }
      else {
        this.progressvalue = 0;
        this.animateProgressBar();
      }
    }, 100)
  }

}

