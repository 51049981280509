import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/status-and-control-services/notification/notification.service';
import { ValidationService } from 'src/app/services/status-and-control-services/validation/validation.service';
import { AuthenticationService } from 'src/app/services/user/authentication/authentication.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public forgotPasswordForm: UntypedFormGroup;
  public data : any;
  public loading: boolean;

  constructor(
      private route: ActivatedRoute,
      private formBuilder: UntypedFormBuilder,
      private router: Router,
      private notificationService: NotificationService,
      private authenticationService: AuthenticationService,
      @Inject(PLATFORM_ID) private platformId: any
    ) {

  }

  matchValidator(group: UntypedFormGroup) {
    let values = [];
    for (let name in group.controls) {
      values.push(group.controls[name].value);
    }

    if (values[0] === values[1]) {
      // console.log('values match')
      return true;
    } else {
      group.setErrors({ matchValidator: false });
      group.controls['passwordConfirm'].setErrors({ matchValidator: false });
    }
  }

  onSubmit() {
    this.route.queryParams.subscribe(params => {
      this.loading = true;
      if(this.route.snapshot.params) {
        this.data = this.route.snapshot.params;

        let passwordResetData = {
          Password: this.forgotPasswordForm.controls['passwords'].get('password').value,
          PasswordConfirm: this.forgotPasswordForm.controls['passwords'].get('passwordConfirm').value,
          Token: this.data.token

        }
        if (isPlatformBrowser(this.platformId)) {
          this.authenticationService.resetPassword(passwordResetData).subscribe(
            (res:any) => {
              this.notificationService.openNotification('resetpassword_success');
              this.router.navigate(['authorization/login']);
              this.loading = false;
            })
        }
      }
    })
  }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      passwords: this.formBuilder.group(
        {
          password: new UntypedFormControl(
            '',
            Validators.compose([
              Validators.required,
              ValidationService.passwordValidator,
              Validators.maxLength(500),
              Validators.minLength(8),
            ])
          ),

          passwordConfirm: new UntypedFormControl('', Validators.required),
        },
        { validator: this.matchValidator }
      ),
    });
  }


}


​

