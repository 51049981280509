import { Component, OnInit } from '@angular/core';
import * as documentationContents from "../../../assets/text-contents/documentation.json";

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent implements OnInit {
  data: any;
  constructor() {
    if(!this.data) {
      let documentationData: any = documentationContents;
      Object.keys(documentationData.default).forEach(key => {
        this.data = documentationData.default.contents;
      });
    }
  }

  ngOnInit() {}

}
