import { Color } from '../color.model'

export class GeometryProperties {
    strokeColor: Color;
    lineType: number;
    lineTypeScale: number;
    lineWeight: number;
    thickness?: number;
    fillColor?: Color;
    opacity?: number;

    constructor(data) {
        if(data.strokeColor) {
            this.strokeColor = new Color(data.strokeColor);
        }
        else if(data.fillColor) {
            this.strokeColor = new Color(data.fillColor);            
        }
        this.lineType = data.lineType;
        this.lineTypeScale = data.lineType;
        this.lineWeight = data.lineWeight;
        this.thickness = data.thickness;
        this.opacity = data.opacity ? data.opacity : 1;
        if(data.fillColor) {
            this.fillColor = new Color(data.fillColor);
        }
    }

}