<div class="additional-tools-element" fxFlex fxLayout="column" fxLayoutAlign="start" fxLayoutGap="5px" >
    <mat-label>Graphische Objekte</mat-label>
    <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-form-field fxFlex>
            <mat-label>Kategorie</mat-label>
            <mat-select [(value)]="selectedList" (selectionChange)="initSymbols()">
                <mat-option *ngFor="let symbolList of symbolLists" [value]="symbolList">{{symbolList.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-icon-button (click)="uploadSymbol()" matTooltip="Eigene Grafik hochladen">
            <mat-icon svgIcon="upload-solid"></mat-icon>
        </button>
    </div>
    <div fxFlex fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px">
        <div *ngFor="let icon of selectedList.items" fxLayout="column" fxLayoutAlign="start center">
            <div class="ep-symbol-container" fxLayout="column" fxLayoutAlign="center center">
                <img class="ep-symbol" [src]="symbolsFolderPath + icon.imageFileName" alt="" onerror="this.onerror=null; this.src='assets/images/placeholder.png'" draggable="false" (mousedown)="onDragStart($event,icon)">
            </div>
            <span class="ep-symbol-title" [matTooltip]="icon.title">{{icon.title}}</span>
        </div>
        <div *ngFor="let icon of ownObjectsList.items" fxLayout="column" fxLayoutAlign="start center">
            <div class="ep-symbol-container" fxLayout="column" fxLayoutAlign="center center">
                <img class="ep-symbol" [src]="icon.imageBase64" alt="" onerror="this.onerror=null; this.src='assets/images/placeholder.png'" draggable="false" (mousedown)="onDragStart($event,icon)">
                <button class="symbol-delete-button" mat-icon-button (click)="deleteObject(icon.id)" matTooltip="Objekt entfernen">
                    <mat-icon svgIcon="trash-solid"></mat-icon> 
                </button>
            </div>
            <span class="ep-symbol-title" [matTooltip]="icon.imageName">{{icon.imageName}}</span>
        </div>
    </div>
</div>