import { Injectable } from '@angular/core';
import { ActiveMode } from '../../../models/enums/active-mode.enum';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModesService {
  mode: ActiveMode;
  editting: boolean;
  activeMode$: Observable<ActiveMode>;
  private _activaModeSource: BehaviorSubject<ActiveMode>;

  constructor() {
    this.setupObservables();
  }

  setupObservables() {
    this.mode = ActiveMode.moveMode;
    this._activaModeSource = new BehaviorSubject(this.mode);
    this.activeMode$ = this._activaModeSource.asObservable();
  }

  get editableMode(): boolean {
    return this.mode === ActiveMode.editMode || this.mode === ActiveMode.frameMode;
  }

  setMode(mode: number) {
    this.mode = mode;
    this._activaModeSource.next(this.mode);
  }

  toggleDrawingMode() {
    this.mode = this.mode === ActiveMode.insertMode ? ActiveMode.moveMode : ActiveMode.insertMode;
  }


}
