import { Injectable } from '@angular/core';
import { LineTypeControl } from 'src/app/models/controls';
import { ControlsService } from '../../status-and-control-services/controls/controls.service';
import { ColorService } from '../color/color.service';
import { ColorEvent } from 'ngx-color';
import { DataService } from '../data/data.service';
import { LayerService } from '../layer/layer.service';

@Injectable({
  providedIn: 'root'
})
export class GeometryPropertiesService {
  currentLineTypeSettings: LineTypeControl;
  currentLineWidth: number;
  currentStartIcon: any;
  currentEndIcon: any;
  sideRatioLocked: boolean;

  constructor(
    private controlsService: ControlsService,
    private colorService: ColorService,
    private dataService: DataService,
    private layerService: LayerService
  ) {
    this.sideRatioLocked = false;
    this.currentLineTypeSettings = null;
    this.currentStartIcon = null;
    this.currentEndIcon = null;
    this.currentLineWidth = 1;
  }

  get activeStrokeColor (): string {
    return this.colorService.activeGeometryStrokeColor;
  }

  get activeFillColor(): string {
    return this.colorService.activeGeometryFillColor;
  }

  setActiveStrokeColor(event: ColorEvent) {
    this.colorService.setActiveStrokeColor(event);
  }

  setActiveFillColor(event: ColorEvent) {
    this.colorService.setActiveFillColor(event);
  }

  setStartLineIcon(value) {
    this.currentStartIcon = value
  }

  setEndLineIcon(value) {
    this.currentEndIcon = value
  }

  toggleSideRatioLock() {
    this.sideRatioLocked = !this.sideRatioLocked;
  }

  resetActiveFillColor() {
    this.colorService.resetActiveFillColor();
  }

  resetActiveStrokeColor() {
    this.colorService.resetActiveStrokeColor();
  }

  updateCurrentLineType(type: LineTypeControl) {
    this.currentLineTypeSettings = type;
  }

  getCurrentLineTypeId() {
    return this.currentLineTypeSettings ? this.currentLineTypeSettings.type : 0;
  }

  updateCurrentLineWidth(width: number) {
    this.currentLineWidth = width;
  }

  getDashPatternSettings(type: number) {
    let lineTypeElement = this.controlsService.lineTypes.find(element => element.type === type);
    if(lineTypeElement && lineTypeElement.dashSetting) {
      return lineTypeElement.dashSetting;
    }
  }

  toggleFillColorOfSelection(value: boolean) {
    this.dataService.updatePropertyOfSelection(this.layerService.activeLayer, "fillColor", null);
  }

  updateStrokeColorOfSelection(event: ColorEvent){
    this.dataService.updatePropertyOfSelection(this.layerService.activeLayer, "strokeColor", event.color.rgb);
  }

  updateLineWidthOfSelection(width: number) {
    this.dataService.updatePropertyOfSelection(this.layerService.activeLayer, "thickness", width);
  }

  updateLineTypeOfSelection(type: number) {
    this.dataService.updatePropertyOfSelection(this.layerService.activeLayer, "lineType", type);
  }

  updateFillColorOfSelection(color: string) {
    this.dataService.updatePropertyOfSelection(this.layerService.activeLayer, "fillColor", color ? color : null);
  }

  updateStartIconOfSelection(value) {
    this.dataService.updatePropertyOfSelection(this.layerService.activeLayer, "startIcon", value ? value : null);
  }

  updateEndIconOfSelection(value) {
    this.dataService.updatePropertyOfSelection(this.layerService.activeLayer, "endIcon", value ? value : null);
  }

  updateRadiusOfSelection(value) {
    this.dataService.updatePropertyOfSelection(this.layerService.activeLayer, "radius", value ? value : null);
  }

  updateStartAngleOfSelection(value) {
    this.dataService.updatePropertyOfSelection(this.layerService.activeLayer, "startAngle", value);
  }

  updateEndAngleOfSelection(value) {
    this.dataService.updatePropertyOfSelection(this.layerService.activeLayer, "endAngle", value);
  }

  updateWidthOfSelection(value) {
    this.dataService.updatePropertyOfSelection(this.layerService.activeLayer, "width", value ? value : null);
  }

  updateHeightOfSelection(value) {
    this.dataService.updatePropertyOfSelection(this.layerService.activeLayer, "height", value ? value : null);
  }

  updateRotationOfSelection(value) {
    this.dataService.updatePropertyOfSelection(this.layerService.activeLayer, "rotation", value);
  }

  updateOpeningsOfSelection(value) {
    this.dataService.updatePropertyOfSelection(this.layerService.activeLayer, "openings", value);
  }

  updateTextOfSelection(value) {
    this.dataService.updatePropertyOfSelection(this.layerService.activeLayer, "text", value);
  }

  updateTextParameterOfSelecton(value, parameterName) {
    this.dataService.updateTextPropertyOfSelection(this.layerService.activeLayer, parameterName, value);
  }

  updateCurrentLineIconsSize(value) {
    if(this.currentStartIcon) {
      this.currentStartIcon.size = value;
    }
    if(this.currentEndIcon) {
      this.currentEndIcon.size = value;
    }
  }

}
