import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(public authenticationService: AuthenticationService, private router: Router, @Inject(PLATFORM_ID) private platformId: any) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(isPlatformBrowser(this.platformId)) {
            let user = this.authenticationService.isAuthenticated()
            if (!user) {
                this.router.navigate(["authorization/login"]);
                return false;
            }
            return true;
        }
        else {
            return true;
        }
    }
}