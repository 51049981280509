import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfileService } from 'src/app/services/user/profile/profile.service';

@Component({
  selector: 'app-account-activation',
  templateUrl: './account-activation.component.html',
  styleUrls: ['./account-activation.component.scss']
})
export class AccountActivationComponent implements OnInit {
  data: any;
  successfullyActivated: boolean;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private profileService : ProfileService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.route.queryParams.subscribe(params => {
      if(this.route.snapshot.params) {
        this.data = this.route.snapshot.params;
        let activationData = {
          activationCode: this.data.code,
          userId: this.data.user
        }
        if (isPlatformBrowser(platformId)) {
          this.profileService.activateUser(activationData).subscribe(
            (res:any) => {
              this.successfullyActivated = true;
            })
        }
      }
    })
  }

  ngOnInit(): void {
  }

  navigateToLogin() {
    this.router.navigate(["authorization/login"]);
  }

}
