<div class="p-b-3" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="40px">
  <div></div>
  <form class="ep-single-form" [formGroup]="forgotPasswordForm" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
      <h3>{{ "createNewPassword" | translate }}</h3>
 

      <div formGroupName="passwords" fxLayout="column" fxLayoutGap="20px">
          <div class="ep-form-control-container">
              <div class="ep-form-control">
                  <span>Passwort</span>
                  <input type="password" formControlName="password">
              </div>
              <mat-error *ngIf="forgotPasswordForm?.get('passwords')['controls']?.password.touched">
                  <control-messages [control]="{controlProperties: forgotPasswordForm?.get('passwords')['controls']?.password, controlName: 'password'}">
                  </control-messages>
              </mat-error>
          </div>
          <div class="ep-form-control-container">
              <div class="ep-form-control">
                  <span>Passwort wiederholen</span>
                  <input type="password" formControlName="passwordConfirm">
              </div>
              <mat-error *ngIf="
                  forgotPasswordForm?.get('passwords')['controls']?.password.touched &&
                  forgotPasswordForm?.get('passwords')['controls']?.passwordConfirm.touched &&
                  !matchValidator(forgotPasswordForm?.get('passwords'))
              ">
                  {{ "authentication.passwordsDontMatch" | translate }}
              </mat-error>
          </div>
      </div>
  </form>
  
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="50px">
      <button mat-flat-button class="ep-btn-call" [disabled]="forgotPasswordForm.invalid || loading" (click)="onSubmit()">{{ "save" | translate }}</button>
  </div>
</div>