<div class="p-b-3" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="40px">
    <div></div>
    <form class="ep-single-form" [formGroup]="registerForm" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
        <h3>Registrieren</h3>
        <div class="ep-form-control-container">
            <div class="ep-form-control">
                <span>Firma</span>
                <input type="text" formControlName="companyName">
            </div>
            <mat-error *ngIf="registerForm?.get('companyName').touched">
                <control-messages [control]="{controlProperties: registerForm?.get('companyName'), controlName: 'companyName'}">
                </control-messages>
            </mat-error>
        </div>
        <div class="w-100">
            <div class="ep-form-control-container">
                <div class="ep-form-control select-box">
                    <span>Anrede</span>
                    <select formControlName="salutation" [(value)]="registerForm?.get('salutation').value">
                        <option value=1>Herr</option>
                        <option value=2>Frau</option>
                        <option value=3>Divers</option>
                    </select>                
                </div>
            </div>    
            <mat-error *ngIf="registerForm?.get('salutation').touched">
                <control-messages [control]="{controlProperties: registerForm?.get('salutation'), controlName: 'salutation'}">
                </control-messages>
            </mat-error>
        </div>
        <div class="ep-form-control-container">
            <div class="ep-form-control">
                <span>Vorname</span>
                <input type="text" formControlName="firstName">
            </div>
            <mat-error *ngIf="registerForm?.get('firstName').touched">
                <control-messages [control]="{controlProperties: registerForm?.get('firstName'), controlName: 'firstName'}">
                </control-messages>
            </mat-error>
        </div>
        <div class="ep-form-control-container">
            <div class="ep-form-control">
                <span>Nachname</span>
                <input type="text" formControlName="lastName">
            </div>
            <mat-error *ngIf="registerForm?.get('lastName').touched">
                <control-messages [control]="{controlProperties: registerForm?.get('lastName'), controlName: 'lastName'}">
                </control-messages>
            </mat-error>
        </div>
        <div class="ep-form-control-container">
            <div class="ep-form-control">
                <span>Email</span>
                <input type="text" formControlName="email">
            </div>
            <mat-error *ngIf="registerForm?.get('email').touched">
                <control-messages [control]="{controlProperties: registerForm?.get('email'), controlName: 'email'}">
                </control-messages>
            </mat-error>
        </div>
        <div class="ep-form-control-container" formGroupName="passwords" fxLayout="column" fxLayoutGap="20px">
            <div>
                <div class="ep-form-control">
                    <span>Passwort</span>
                    <input type="password" formControlName="password">
                </div>
                <mat-error *ngIf="registerForm?.get('passwords')['controls']?.password.touched">
                    <control-messages [control]="{controlProperties: registerForm?.get('passwords')['controls']?.password, controlName: 'password'}">
                    </control-messages>
                </mat-error>
            </div>
            <div>
                <div class="ep-form-control">
                    <span>Passwort wiederholen</span>
                    <input type="password" formControlName="passwordConfirm">
                </div>
                <mat-error *ngIf="
                    registerForm?.get('passwords')['controls']?.password.touched &&
                    registerForm?.get('passwords')['controls']?.passwordConfirm.touched &&
                    !matchValidator(registerForm?.get('passwords'))
                ">
                    {{ "authentication.passwordsDontMatch" | translate }}
                </mat-error>
            </div>
        </div>
    </form>
    
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="50px">
        <button mat-flat-button class="ep-btn-call" [disabled]="registerForm.invalid || loading" (click)="onSubmit()">{{ "authentication.register" | translate }}</button>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            <!-- <a [routerLink]="['../forgot_password']" class="disabled">{{ "authentication.forgotPassword" | translate }}</a> 
            <a [routerLink]="['../login']" class="ep-link">Login</a >-->
        </div>
    </div>
</div>