<div class="additional-tools-element" *ngIf="isEditMode()" fxLayout="column">
    <div class="w-100" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <button [ngClass]="{'bg-normal-greyblue': (oneElementOfLineTypeSelected() && wallOpeningMode === 3)}" mat-icon-button matTooltip="Fenster" (click)="setWallOpeningMode(3)">
            <mat-icon svgIcon="fenster"></mat-icon>
        </button>
        <button [ngClass]="{'bg-normal-greyblue': (oneElementOfLineTypeSelected() && wallOpeningMode === 4)}" mat-icon-button matTooltip="Wandöffnung"  (click)="setWallOpeningMode(4)">
            <mat-icon svgIcon="wandoeffnung"></mat-icon>
        </button>
        <button [ngClass]="{'bg-normal-greyblue': (oneElementOfLineTypeSelected() && wallOpeningMode === 5)}" mat-icon-button matTooltip="Tür"  (click)="setWallOpeningMode(5)">
            <mat-icon svgIcon="tuer"></mat-icon>
        </button>
    </div>
    <div class="w-100 p-b" *ngIf="wallOpeningMode === 5">
        <mat-form-field class="w-100">
            <mat-label>Türtyp</mat-label>
            <mat-select (selectionChange)="updateDoorType($event)" [matTooltip]="'doorTypes.' + currentDoorType | translate" [(value)]="currentDoorType">
                <mat-option [value]="0">
                    <img class="door-icon" src="assets/images/doors/normal-door.png" alt="" onerror="this.onerror=null; this.src='assets/images/placeholder.png'">
                    <span>{{"doorTypes." + "0" | translate}}</span>
                </mat-option>
                <mat-option [matTooltip]="'doorTypes.' + doorType.type | translate" *ngFor="let doorType of doorTypes; let i = index" [value]="i+1">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <img class="door-icon" src="{{'assets/images/doors/' + doorType.icon + '.svg'}}" alt="" onerror="this.onerror=null; this.src='assets/images/placeholder.png'">
                        <span>{{"doorTypes." + doorType.type | translate}}</span>
                    </div>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="w-100 p-tb" *ngIf="currentWallOpeningCreationMode">
        <mat-form-field class="w-100">
            <mat-label>Modus</mat-label>
            <mat-select (selectionChange)="updateMode($event)" [(value)]="currentWallOpeningCreationMode">
                <mat-option *ngFor="let mode of [6,7]" [value]="mode">
                    <span>{{"wallOpeningCreationMode." + activeWallOpeningCreationModeName(mode) | translate}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="currentWallOpeningCreationMode && currentWallOpeningCreationMode === 6">
      <div fxLayout="column" fxLayoutAlign="center start">
          <mat-checkbox (click)="updateFixedWidth()" [checked]="fixedWallOpeningsWidth.on">
              {{"fixedOpeningsWidth" | translate }}
          </mat-checkbox>
          <div class="ep-form-control-container" *ngIf="fixedWallOpeningsWidth.on">
              <div class="w-100">
                  <div class="ep-form-control">
                      <span>Breite ({{units}})</span>
                      <input type="number" [(ngModel)]="fixedWallOpeningsWidth.value" (blur)="onBlur()" (focus)="onFocus()">
                  </div>
              </div>
              <div fxFlex="40px" fxLayoutAlign="center center">
                  <button mat-icon-button (click)="updateWallOpeningsWidth()">
                      OK
                  </button>
              </div>
          </div>
      </div>
    </div>
    <div *ngIf="oneElementOfLineTypeSelected()" class="m-t" [class.m-b]="currentWallOpeningCreationMode && currentWallOpeningCreationMode !== 6">
        <div *ngFor="let opening of openings; let i = index" fxLayout="row" fxLayoutAlign="start center">
          <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
              <button mat-button fxFlex fxLayout="row" fxLayoutAlign="start center" [disabled]="opening.type !== 5" [matMenuTriggerFor]="menu" (click)="selectedOpening = i">
                  <span>{{i+1}}</span>&nbsp;
                  <span>{{ "openings." + wallOpeningName(opening.type) | translate }}</span>&nbsp;
                </button>
              <button mat-icon-button (click)="removeOpening(i)" matTooltip="Öffnung entfernen">
                <mat-icon svgIcon="trash-solid"></mat-icon> 
            </button>
          </div>
            <mat-divider></mat-divider>
        </div>
      </div>
</div>

<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="mirrorDoorTo('invertX')" matTooltip="Tür horizontal Spiegeln">
        <mat-icon svgIcon="horizontal-spiegeln"></mat-icon>
    </button>
    <button mat-menu-item (click)="mirrorDoorTo('invertY')" matTooltip="Tür vertical Spiegeln">
        <mat-icon svgIcon="vertikal-spiegeln"></mat-icon>
    </button>
</mat-menu>