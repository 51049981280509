import { Component, OnInit } from '@angular/core';
import * as agreeementContents from "../../../assets/text-contents/license-agreement.json";

@Component({
  selector: 'app-license-agreement',
  templateUrl: './license-agreement.component.html',
  styleUrls: ['./license-agreement.component.scss']
})
export class LicenseAgreementComponent implements OnInit {

  data: any;
  constructor() {
    if(!this.data) {
      let agreementData: any = agreeementContents;
      Object.keys(agreementData.default).forEach(key => {
        this.data = agreementData.default.contents;
      });
    }
  }

  ngOnInit() {}

}
