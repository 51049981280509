import { ElementRef, HostListener, Inject, Injectable, PLATFORM_ID, ViewChild } from '@angular/core';
import { observable, Observable, Subscription } from 'rxjs';
import { Point, VertexPosition } from 'src/app/models';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { Extrema } from 'src/app/models/extrema.model';
import * as THREE from 'three'
import { Vector2, Vector3 } from 'three';
import { DXFLoader } from 'three-dxf-loader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { ModesService } from '../../status-and-control-services/modes/modes.service';
import { FileService } from '../file/file.service';
import { ViewerOperationsService } from '../viewer-operations/viewer-operations.service';
import { ViewerService } from '../viewer/viewer.service';
import * as imagebase64 from "../../../../assets/encoded-20220819111318.json";
import { EPImage } from 'src/app/models/geometries/ep-image.model';
import { CanvasDimensionsService } from '../../status-and-control-services/canvas-dimensions/canvas-dimensions.service';
import { ObserversModule } from '@angular/cdk/observers';
import { ColorService } from '../color/color.service';
import { DataService } from '../data/data.service';
import { GeometricType } from 'src/app/models/enums/geometric-types.enum';
import { LayerService } from '../layer/layer.service';
import { isPlatformBrowser } from '@angular/common';
import { PlanService } from '../../user/plan/plan.service';

interface MergedLineParam {
  color: string;
  linesData: Vector2[];
  orderIndexes: number[];
  lastIndex: number;
}
@Injectable({
  providedIn: 'root'
})
export class DxfViewerService {

  /* @ViewChild("container")
  private containerRef: ElementRef = new ElementRef(null); */
  private previewSubscription: Subscription;
  private renderer: THREE.WebGLRenderer;
  public scene: THREE.Scene;
  private camera: THREE.OrthographicCamera;
  private controls: OrbitControls;
  public meshGroup: THREE.Group;
  modules: Array<THREE.Group> = Array<THREE.Group>();
  canvas: HTMLCanvasElement;
  dxfLoader: DXFLoader;
  fileReader: any;
  initialPosition: Point;
  threeViewerMultiplier: number;
  dxfBuff: string;
  fileSubscription: Subscription;
  images: Array<any>;
  bgColor: any;
  textureLoader: THREE.TextureLoader;
  boundaries: Extrema;
  noCam: boolean = true;
  isBrowser: boolean;
  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    public viewerOperationsService: ViewerOperationsService,
    public canvasDimensionsService: CanvasDimensionsService,
    private viewerService: ViewerService,
    public fileService: FileService,
    private modesService: ModesService,
    private colorService: ColorService,
    private dataService: DataService,
    private layerService: LayerService,
    private planService: PlanService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.fileReader = new FileReader();
    this.dxfLoader = new DXFLoader();
    this.dxfBuff = "";
    viewerService.scale$.subscribe(scale => {
      if(this.scene && this.scene.children && this.camera && modesService.mode === ActiveMode.moveMode) {
        this.setZoom(scale);
        this.rendering();
      }
    });
    this.previewSubscription = this.viewerOperationsService.previewEvent.subscribe(generatePreview => {
      if(generatePreview && this.modesService.mode === ActiveMode.moveMode) {
        this.generatePreviewForFrame();
      }
    })
  }

  onResize(mult: number = 1) {
    let obs = new Observable<any>(observer => {
      this.updateCamera();
      observer.next(true);
    })
    return obs;
  }

  initThree(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.textureLoader = new THREE.TextureLoader();
    this.boundaries = new Extrema();
    this.loadFont().subscribe(() => {

      this.initSceneSetup();
      this.createRenderer();
      this.createCamera();
      this.addControls();
      setTimeout(() => this.rendering(), 1000);

      let onLoad = (data) => {
        if (data && data.entities) {
          if(this.scene.children && this.scene.children.length ) {
            this.scene?.children.forEach((child: any) => {
              child.geometry.dispose();
              child.material.dispose();
              child = undefined;
              this.scene.remove(child);
            })
          }
          this.resetViewerMultipliers();
          this.initSceneSetup();
          this.createRenderer();
          data.entities.forEach((ent) => {
            if(
              ent.geometry.type !== "TextGeometry" &&
              ent.material.color.r === 0.8392156862745098 &&
              ent.material.color.g === 0.8392156862745098 &&
              ent.material.color.b === 0.8392156862745098) {
              ent.material.color.setRGB(0,0,0);
            }
            if(ent.type !== "Line" || (ent.type === "Line" && (ent.material.type === "LineDashedMaterial" || (ent.position && (ent.position.x || ent.position.y))))) {
              if(ent.type === "Mesh" && (ent.geometry.type === "ShapeGeometry" || ent.geometry.type === "CircleGeometry") && ent.geometry.vertices.length) {
                ent.geometry.vertices.forEach(vertex => {
                  vertex.z = -1;
                })
              }
              this.scene.add(ent)
            }
          }
          );
          this.createLinesGeometry(data.dxf.entities);
          if(this.images && this.images.length) this.addImages().subscribe(() => {
            this.createCamera();
            this.addControls();
            this.setZoom(1);
            this.controls.addEventListener('change', this.rendering.bind(this));
          });
          else {
            this.createCamera();
            this.addControls();
            this.setZoom(1);
            this.controls.addEventListener('change', this.rendering.bind(this));
          }

          setTimeout(() => {
            this.rendering();
            this.viewerService._loadingSource.next(false);
          }, 5000);
        }
      }
      const onError = (error) => {
        console.log(error);
      }
      const onProgress = (xhr) => {
        console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
      }
      this.fileSubscription = this.fileService.file$.subscribe((fileResponse: any) => {
        this.clearScene();
        if(fileResponse && fileResponse.data && fileResponse.data.fileBase64) {
          this.images = fileResponse.data.images;
          this.fileService.dxfFile = fileResponse.data.fileBase64;
          this.dxfBuff = "data:image/vnd.dxf;base64," + fileResponse.data.fileBase64;
          let initScale = this.planService.getActiveDrawingInitScale();
          this.dxfLoader.load(this.dxfBuff, initScale, onLoad, onProgress, onError);
        }
      })
    });
  }

  createLinesGeometry(entities) {
    let lines = new Array<MergedLineParam>();
    let firstIndexBuff = null;
    entities.forEach(entity => {
      if(
        (!entity.lineType || entity.lineType === "0") &&
        entity.vertices && entity.vertices.length > 1 &&
        (entity.type === "LWPOLYLINE" ||
          entity.type === "LINE" ||
          entity.type === "POLYLINE")
      ) {
        let entityHexColor = this.colorService.intToHex(entity.color);
        let lineIndex = lines.findIndex(e => e.color === entityHexColor);
        if(lineIndex > -1) {
          if(entity.shape) {
            firstIndexBuff = lines[lineIndex].lastIndex;
          };
          entity.vertices.forEach((vertex, index) => {
            lines[lineIndex].linesData.push(vertex);
            if(index > 0 && index < entity.vertices.length - 1) lines[lineIndex].orderIndexes.push(lines[lineIndex].lastIndex);
            lines[lineIndex].orderIndexes.push(lines[lineIndex].lastIndex);
            if(index === entity.vertices.length - 1 && entity.shape) {
              lines[lineIndex].orderIndexes.push(lines[lineIndex].lastIndex);
              lines[lineIndex].orderIndexes.push(firstIndexBuff);
            }
            lines[lineIndex].lastIndex++;
          });
        }
        else {
          let newLine: MergedLineParam = {
            color: entityHexColor,
            linesData: [],
            orderIndexes: [],
            lastIndex: 0
          };
          if(entity.shape) {
            firstIndexBuff = 0;
          };
          entity.vertices.forEach((vertex, index) => {
            newLine.linesData.push(vertex);
            if(index > 0 && index < entity.vertices.length - 1) newLine.orderIndexes.push(newLine.lastIndex);
            newLine.orderIndexes.push(newLine.lastIndex);
            if(index === entity.vertices.length - 1 && entity.shape) {
              newLine.orderIndexes.push(newLine.lastIndex);
              newLine.orderIndexes.push(firstIndexBuff);
            }
            newLine.lastIndex++;
          });
          lines.push(newLine);
        }
      }

    });

    lines.forEach(line => {
      const lg = new THREE.BufferGeometry().setFromPoints(line.linesData);
      lg.setIndex(line.orderIndexes);
      var lsMaterial = new THREE.LineBasicMaterial( { color: line.color === "#d6d6d6" ? 0x000000 : line.color} );
      var lineSegments = new THREE.LineSegments( lg, lsMaterial );
      this.scene.add( lineSegments );

    })
  }

  addImages() {
    let obs = new Observable<any>(observer => {
      const geomSize = this.getGeometryBoundingBox().getSize(new THREE.Vector3());
      var texture: THREE.Texture = null;
      let rectangle: THREE.Mesh = new THREE.Mesh();
        this.images.forEach((image,index) => {
          if(image.imagePath && image.width && image.height && (image.imagePath.includes('assets/symbols/') || image.imagePath.includes('base64') || image.imagePath.includes('assets/images/'))) {
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            canvas.width = canvas.width;
            canvas.width = image.width*10 > 500 ? image.width*10 : 500;
            canvas.height = canvas.width/image.width*image.height;
            const canvasImg = new Image();
            canvasImg.src = image.imagePath;

            canvasImg.onload = () => {
              const img = new Image();
              ctx.drawImage(canvasImg,0,0,canvas.width,canvas.height);
              img.src = canvas.toDataURL("image/png", 1.0);
              img.onload = () =>  {
                let plane = new THREE.PlaneBufferGeometry(image.width,image.height);
                texture = this.textureLoader.load( img.src );
                texture.generateMipmaps = false;
                texture.minFilter = THREE.LinearFilter;
                texture.needsUpdate = true;
                var material = new THREE.MeshBasicMaterial({transparent: true});
                material.map = texture;
                rectangle = new THREE.Mesh(plane,material);
                rectangle.position.set(image.origin.x + image.width/2, image.origin.y - image.height * 1.5, this.bigImage(geomSize,image) ? -2 : 0);
                rectangle.rotateZ(-image.rotation * Math.PI/180);
                this.scene.add( rectangle );
                texture.dispose();
                if(index === this.images.length - 1 ) observer.next(true);
              }
            }
          }
        })
    })

    return obs;
  }

  bigImage(size: THREE.Vector3, image: {width: number, height: number}) {
    return size.x/2*size.y/2 < image.width*image.height;
  }

  loadFont() {
    let obs = new Observable<any>(observer => {
      var loader = new THREE.FontLoader();

      loader.load( 'assets/fonts/Arial_Regular.json',  ( font ) => {
        this.dxfLoader.setFont(font);
        observer.next(true);
      } );
    })
    return obs;
  }

  onDestroy() {
    if(this.isBrowser) {
      //this.previewSubscription.unsubscribe();
      this.fileService._fileSource.next({data: null})
      this.clearScene();
      this.renderer = null;
      this.camera = null;
      this.controls = null;
      this.scene = null;
      this.canvas = null;
      if(this.fileSubscription) {
        this.fileSubscription.unsubscribe();
      };
      this.dxfBuff = "";
    }
  }

  cropGeometryFromWorld() {
    const box = this.getGeometryBoundingBox();

    this.defineViewportBoundaries();
		this.camera.updateMatrixWorld();
    let canvas = {width:null,height:null};
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    let vectorMin = new Vector3();
    let objMin = new THREE.Object3D();
    objMin.position.set(box.min.x,box.min.y,0);
    objMin.updateMatrixWorld();  // `obj´ is a THREE.Object3D
    vectorMin.setFromMatrixPosition(objMin.matrixWorld);
    vectorMin.project(this.camera);
    const minX = Math.round((vectorMin.x * canvas.width) + canvas.width);
    const minY = Math.round((vectorMin.y * canvas.height) + canvas.height);

    let vectorMax = new Vector3();
    let objMax = new THREE.Object3D();
    objMax.position.set(box.max.x,box.max.y,0);
    objMax.updateMatrixWorld();  // `obj´ is a THREE.Object3D
    vectorMax.setFromMatrixPosition(objMax.matrixWorld);
    vectorMax.project(this.camera);
    const maxX = Math.round((vectorMax.x * canvas.width) + canvas.width);
    const maxY = Math.round((vectorMax.y * canvas.height) + canvas.height);

    return {
      min: {x: minX, y: minY},
      max: {x: maxX, y: maxY}
    }
  }

  generatePreviewForFrame() {
    this.viewerService._loadingSource.next(true);

    this.setBackground(null);
    this.rendering();

    const img = new Image();

    img.src = this.renderer.domElement.toDataURL("image/png", 1.0);;
    img.title = "previewimage";

    img.onload = () => {
      this.layerService.initLayers();
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d')

      let ext = this.cropGeometryFromWorld();
      canvas.width = this.canvasDimensionsService.dimensions.width/2;
      canvas.height = (ext.max.y - ext.min.y)/(ext.max.x - ext.min.x)*this.canvasDimensionsService.dimensions.width/2;
      ctx.drawImage(img, ext.min.x, ext.min.y, ext.max.x - ext.min.x, ext.max.y - ext.min.y, 0, 0, canvas.width, canvas.height);
      const img2 = new Image();

      img2.src = canvas.toDataURL("image/png", 1.0);
      img2.title = "previewimage";
      img2.onload = () => {
        img2.width /= this.threeViewerMultiplier;
        img2.height /= this.threeViewerMultiplier;
        this.layerService.importImageToActiveLayer(img2,origin).subscribe(geomName => {
          if(geomName) {
            this.viewerOperationsService.resetFittingParams();
            this.viewerOperationsService.fitGeometryInViewport(this.dataService.geometricalData, true);
            let index = this.dataService.findGeometryIndexInLayerByName(this.layerService.activeLayer, geomName);
            if(index > -1) {
              let layerIndex = this.dataService.findLayerIndex(this.layerService.activeLayer);
              if(layerIndex > -1) {
                let img = this.dataService.geometricalData[layerIndex].geometry[index] as EPImage;
                img.origin.x = this.viewerOperationsService.translation.x + this.canvasDimensionsService.dimensions.width/2 - img.width/2;
                img.origin.y = this.viewerOperationsService.translation.y + this.canvasDimensionsService.dimensions.height/2 - img.height/2;
              }
            }
            this.viewerOperationsService.editing = true;
            this.dataService.emitChanges();
            this.viewerService._loadingSource.next(false);
            this.viewerOperationsService._edittingSource.next(this.viewerOperationsService.editing);
          }
        });
        },
        error => {
          console.log('Error while gathering SVG response.');
          this.viewerService._loadingSource.next(false);
        };
    }
  }

  loadPreviewWithParameters(colorHex) {
    let obs = new Observable<any>(observer => {
      if(this.bgColor !== colorHex) {
        this.setBackground(colorHex);
        observer.next(true);
      }
    })
    return obs;
  }

  setBackground(hexVal) {
    if(hexVal) {
      this.bgColor = hexVal;
      this.scene.background = new THREE.Color(hexVal);
    }
    else {
      this.scene.background = null;
      this.renderer.setClearColor( 0x000000, 0 );
    }
  }

  resetViewerMultipliers() {
    this.viewerOperationsService.resetMultipliers();
  }

  clearScene() {
    if(this.scene && this.scene.children && this.scene.children.length) {
      this.scene.children = new Array<THREE.Object3D>();
    }
  }

  initSceneSetup() {
    this.scene = new THREE.Scene();
    this.setBackground(0xaaaaaa);
  }

  createRenderer(mult: number = 1) {
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
      alpha: true,
      preserveDrawingBuffer: true
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.canvasDimensionsService.dimensions.width*mult,this.canvasDimensionsService.dimensions.height*mult);

    this.renderer.shadowMap.enabled = false;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.renderer.autoClear = true;
    this.renderer.sortObjects = false;
  }

  updateCamera() {
    const box = this.getGeometryBoundingBox();

    const center = box.getCenter(new THREE.Vector3());
    const size = box.getSize(new THREE.Vector3());
    let cameraDimensions = this.calculateCameraDimensions(size);
    let cameraConf = {
      left: -cameraDimensions.horizontal,
      right: cameraDimensions.horizontal,
      top: cameraDimensions.vertical,
      bottom: -cameraDimensions.vertical
    };
    this.camera.left = cameraConf.left;
    this.camera.right = cameraConf.right;
    this.camera.top = cameraConf.top;
    this.camera.bottom = cameraConf.bottom;

    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.canvasDimensionsService.dimensions.width,this.canvasDimensionsService.dimensions.height);
  }

  createCamera() {
    const box = this.getGeometryBoundingBox();

    const center = box.getCenter(new THREE.Vector3());
    const size = box.getSize(new THREE.Vector3());
    let cameraDimensions = this.calculateCameraDimensions(size);
    let cameraConf = {
      left: -cameraDimensions.horizontal,
      right: cameraDimensions.horizontal,
      top: cameraDimensions.vertical,
      bottom: -cameraDimensions.vertical
    };
    this.threeViewerMultiplier = this.scene.children && this.scene.children.length ? this.calculateThreeViewerMultiplier(size) : 1;
    let offset = {x: (cameraConf.left + size.x/2 + box.min.x) * this.threeViewerMultiplier, y: (cameraConf.bottom - size.y/2 - box.min.y) * this.threeViewerMultiplier}
    this.camera = new THREE.OrthographicCamera(
      cameraConf.left,
      cameraConf.right,
      cameraConf.top,
      cameraConf.bottom);
    this.camera.position.set(center.x,center.y,100);
    this.camera.lookAt(center.x,center.y,0);
    this.defineViewportBoundaries();

    if(this.scene.children && this.scene.children.length) {
      this.viewerOperationsService.setupViewerExtrema(this.boundaries, this.viewportCenter, this.threeViewerMultiplier, offset);
      this.initialPosition = {...this.camera.position};
    }

    if(this.scene.children && this.scene.children.length > 1) {
      let mult = this.threeViewerMultiplier > 1 ? 1/this.threeViewerMultiplier : this.threeViewerMultiplier;
      this.scene.children.forEach((child:any) => {
        if(child.type == "Mesh" && child.geometry.type == "TextGeometry") {
          child.scale.set(mult,mult,mult);
        }
      })
    }

    this.camera.updateProjectionMatrix();
    this.camera.updateMatrixWorld(true);
    if(this.noCam) {
      this.scene.add(this.camera);
      this.noCam = false;
    }
  }

  calculateCameraDimensions(size: THREE.Vector3) {
    const HeightToWidth = this.canvasDimensionsService.dimensions.height/this.canvasDimensionsService.dimensions.width;
    const fitByWidth: boolean = this.fitByWidth(size);
    let horizontal = (fitByWidth ? size.x/2 : this.widthToHeightRatio * size.y/2);
    let vertical = (fitByWidth ? HeightToWidth * size.x/2 : size.y/2);
    return { horizontal: horizontal ? horizontal : 0, vertical: vertical ? vertical : 0}
  }

  calculateThreeViewerMultiplier(size: Point) {
    let fitByWidth = this.fitByWidth(size);
    let widthSizeRatio = this.canvasDimensionsService.dimensions.width/size.x;
    let heightSizeRatio = this.canvasDimensionsService.dimensions.height/size.y;
    let mult = fitByWidth ? widthSizeRatio : heightSizeRatio;
    return  mult
  }

  fitByWidth(size: Point): boolean {
    return size.x/size.y > 1 && this.widthToHeightRatio < size.x/size.y
  }

  get widthToHeightRatio(): number {
    return this.canvasDimensionsService.dimensions.width/this.canvasDimensionsService.dimensions.height;
  }

  get heightWidthRatio(): number {
    return this.canvasDimensionsService.dimensions.height/this.canvasDimensionsService.dimensions.width;
  }

  get viewportCenter(): Point {
    return new Point(this.camera.position.x,this.camera.position.y);
  }

  getGeometryBoundingBox() {
    const box = new THREE.Box3();
    if(this.scene && this.scene.children && this.scene.children.length) {
      for(const object of this.scene.children) {
        if(object.name !== "viewportCenter") {
          box.expandByObject(object);
        }
      }
      box.min = new Vector3(box.min.x - 20,box.min.y - 20,0);
      box.max = new Vector3(box.max.x + 20,box.max.y + 20,0);
    }
    else {
      box.min = new Vector3(0,0,0);
      box.max = new Vector3(0,0,0);
    }
    return box;
  }

  addControls() {
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.target.set(this.camera.position.x,this.camera.position.y,100);

    //default all are true;
    //this.controls.enablePan = false;
    //this.controls.enableZoom = false;
    this.controls.enableRotate = false;
    this.controls.update();
  }

  rendering() {
    /* const box = this.getGeometryBoundingBox();
    const center = box.getCenter(new THREE.Vector3());
    const size = box.getSize(new THREE.Vector3()); */

    /* let leftBottom = this.scene.getObjectByName("viewportCenter");
    leftBottom.position.set(this.controls.target.x, this.controls.target.y, 0); */

    // = this.calculateOffset(this.initialPosition, this.camera.position);
    let scale = this.threeViewerMultiplier * this.camera.zoom;

    if(this.viewerOperationsService.initialScaleFactor === 1 && this.scene.children && this.scene.children.length) {
      this.viewerOperationsService.initialScaleFactor = scale;
    }
    this.viewerOperationsService.scaleFactor = scale;

    let viewportCenter = new Point(this.viewportCenter.x, -this.viewportCenter.y);

    //boundaries are corner coordinates of the viewport in three
    this.defineViewportBoundaries();
    let canvasOffset = {x: (this.boundaries.minX) * scale, y: (-this.boundaries.maxY) * scale};
    if(this.scene.children && this.scene.children.length) {
      this.viewerOperationsService.setupViewerExtrema(this.boundaries, viewportCenter, scale, canvasOffset);
    }

    this.renderer.render(this.scene, this.camera);
  }

  renderWithoutUpdate() {
    this.renderer.render(this.scene, this.camera);
  }

  defineViewportBoundaries() {
    var vector = new THREE.Vector3();
    var zNearPlane = -1;
    var zFarPlane = 1;

    let topLeft = {...vector.set( -1, 1, zNearPlane ).unproject( this.camera )};
    let topRight = {...vector.set( 1, 1, zNearPlane ).unproject( this.camera )};
    let bottomLeft = {...vector.set( -1, -1, zNearPlane ).unproject( this.camera )};
    let bottomRight = {...vector.set( 1, -1, zNearPlane ).unproject( this.camera )};

    this.boundaries.minX = topLeft.x;
    this.boundaries.maxX = topRight.x;
    this.boundaries.maxY = topRight.y;
    this.boundaries.minY = bottomLeft.y;

    if(!this.scene.children || !this.scene.children.length) {
      this.boundaries.minX = 0;
      this.boundaries.maxX = 0;
      this.boundaries.maxY = 0;
      this.boundaries.minY = 0;
    }

    //return this.boundaries;
  }

  setZoom(zoom = 1) {
    this.camera.zoom = zoom;
    const box = this.getGeometryBoundingBox();

    const center = box.getCenter(new THREE.Vector3());
    this.camera.position.set(center.x, center.y, 100);
    this.camera.lookAt(center.x,center.y,0);
    this.camera.updateProjectionMatrix();
    this.camera.updateMatrixWorld(true);
    this.controls.target.set(center.x, center.y, 0);
    this.controls.update();

  }

  /* calculateOffset(start: Point, end: Point): Point {
    return new Point(Math.abs(end.x - start.x) < 0.00001 ? 0 : end.x - start.x, Math.abs(end.y - start.y) < 0.0001 ? 0 : end.y - start.y);
  } */

  disposeNode (node)
  {
    if (node instanceof THREE.Mesh)
    {
        if (node.geometry)
        {
            node.geometry.dispose ();
        }

        if (node.material)
        {
            if (node.material.materials)
            {
                node.material.materials.forEach((idx, mtrl) =>
                {
                    if (mtrl.map)               mtrl.map.dispose ();
                    if (mtrl.lightMap)          mtrl.lightMap.dispose ();
                    if (mtrl.bumpMap)           mtrl.bumpMap.dispose ();
                    if (mtrl.normalMap)         mtrl.normalMap.dispose ();
                    if (mtrl.specularMap)       mtrl.specularMap.dispose ();
                    if (mtrl.envMap)            mtrl.envMap.dispose ();
                    if (mtrl.alphaMap)          mtrl.alphaMap.dispose();
                    if (mtrl.aoMap)             mtrl.aoMap.dispose();
                    if (mtrl.displacementMap)   mtrl.displacementMap.dispose();
                    if (mtrl.emissiveMap)       mtrl.emissiveMap.dispose();
                    if (mtrl.gradientMap)       mtrl.gradientMap.dispose();
                    if (mtrl.metalnessMap)      mtrl.metalnessMap.dispose();
                    if (mtrl.roughnessMap)      mtrl.roughnessMap.dispose();

                    mtrl.dispose ();    // disposes any programs associated with the material
                });
            }
            else
            {
                if (node.material.map)              node.material.map.dispose ();
                if (node.material.lightMap)         node.material.lightMap.dispose ();
                if (node.material.bumpMap)          node.material.bumpMap.dispose ();
                if (node.material.normalMap)        node.material.normalMap.dispose ();
                if (node.material.specularMap)      node.material.specularMap.dispose ();
                if (node.material.envMap)           node.material.envMap.dispose ();
                if (node.material.alphaMap)         node.material.alphaMap.dispose();
                if (node.material.aoMap)            node.material.aoMap.dispose();
                if (node.material.displacementMap)  node.material.displacementMap.dispose();
                if (node.material.emissiveMap)      node.material.emissiveMap.dispose();
                if (node.material.gradientMap)      node.material.gradientMap.dispose();
                if (node.material.metalnessMap)     node.material.metalnessMap.dispose();
                if (node.material.roughnessMap)     node.material.roughnessMap.dispose();

                node.material.dispose();   // disposes any programs associated with the material
            }
        }
    }
  }   // disposeNode

  disposeHierarchy (node, callback)
  {
    if(node && node.children && node.children.length) {
      for (var i = node.children.length - 1; i >= 0; i--)
      {
          var child = node.children[i];
          this.disposeHierarchy (child, callback);
          callback (child);
      }
    }
  }

  cleanViewerCache() {
    if(this.renderer) {
      this.renderer.forceContextLoss();
      if(this.renderer.domElement) {
        this.renderer.domElement = null;
      }
      this.renderer = null;
    }
  }

}
