import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr"
import { Subject } from 'rxjs/internal/Subject';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SignalrService {

  public hubConnection: signalR.HubConnection;
  public connectionId: string = "";
  updateLogs = new Subject<string>();

  constructor() {
    this.createConnection();
    this.registerOnEvents();
    this.startConnection();
  }

  private createConnection() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.requestUrl + '/ephub')
      .configureLogging(signalR.LogLevel.Critical)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          if (retryContext.elapsedMilliseconds < 60000) {
            return Math.random() * 10000;
          } else {
            return null;
          }
        }
      })
      .build();
  }

  private startConnection() {
    try {
      if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
        return;
      }
      this.hubConnection
        .start()
        .then(() => this.getConnectionId())
        .finally(() => this.connectionId = this.hubConnection.connectionId)
        .catch(err => console.log('Error while starting connection: ' + err));
    }
    catch (error) {
      console.log(error);
     }
  }

  private registerOnEvents() {
    this.addUpdateListener();
  }

  private getConnectionId = () => {
    this.hubConnection.invoke('getconnectionid')
      .then((data) => {
        this.connectionId = data;
      });
  }

  private addUpdateListener() {
    this.hubConnection.on('updateprojectdrawing', (data) => {
      this.updateLogs.next(data);
    });
  }

  public layerStream(token, dxffilename, layerNames, filter) {
    return this.hubConnection.stream('getlayerdetailstream', token, dxffilename, layerNames, filter);
  }


}
