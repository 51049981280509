import { Injectable } from '@angular/core';
import { DrawingMode } from 'src/app/models/enums/drawing-mode.enum';
import { Point, Vertex } from 'src/app/models';
import { LayerService } from '../layer/layer.service';
import { DataService } from '../data/data.service';
import { ColorEnum } from '../../../models/enums/color.enum';
import { ColorService } from '../color/color.service';
import { GeometryPropertiesService } from '../geometry-properties/geometry-properties.service';
import { SelectionService } from '../../status-and-control-services/selection/selection.service';
import { ModesService } from '../../status-and-control-services/modes/modes.service';
import { GeometryEditingService } from '../geometry-editing/geometry-editing.service';
import { AppSettingsService } from '../../status-and-control-services/app-settings/app-settings.service';
import { Subscription } from 'rxjs';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { AdditionalToolsService } from '../../status-and-control-services/additional-tools/additional-tools.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DrawingService {
  drawingMode: DrawingMode;
  isDrawing: boolean;
  activeGeometry: any;
  drawingStep: number;
  drawingError: boolean;
  axisSnapping: boolean;
  axisSnappingHighlightColor: string;
  geometryControlsSnappingHighlightColor: string;
  geometryControlsSnapping: boolean;
  gridSnapping: boolean;
  fillMode: boolean;
  settingsSubscription: Subscription;

  constructor(
    //private renderService: RenderService,
    private layerService: LayerService,
    private dataService: DataService,
    private colorService: ColorService,
    private geometryPropertiesService: GeometryPropertiesService,
    private selectionService: SelectionService,
    private modesService: ModesService,
    private geometryEditingService: GeometryEditingService,
    private appSettingsService: AppSettingsService,
    private additionalToolsService: AdditionalToolsService,
    private router: Router

  ) {
    this.isDrawing = false;
    this.drawingMode = null;
    this.activeGeometry = null;
    this.drawingStep = 0;
    this.drawingError = false;
    this.axisSnapping = true;
    this.geometryControlsSnapping = true;
    this.axisSnappingHighlightColor = ColorEnum.axisSnapping;
    this.geometryControlsSnappingHighlightColor = ColorEnum.geometryControlsSnapping;
    this.settingsSubscription = this.appSettingsService.settingsChange$.subscribe(change => {
      this.gridSnapping = this.appSettingsService.gridSettings.snapping;
    })
    this.fillMode = true;
  }

  drawActiveGeometry(position, context, canvas, scale = 1, drawingStarted:boolean,shiftKey) {
    if(this.layerService.activeLayer !== "") {
      if(position && position.x && position.y) {
        let controlPointToSnap = null;
        let gridSnappedInfo = null;
        if(this.geometryControlsSnapping) {
          controlPointToSnap = this.dataService.mouseIntersectsGeometryControlPoint(this.layerService.activeLayer,position,context,canvas,scale)
        }
        if(this.gridSnapping && this.appSettingsService.gridSettings.gridOn) {
          gridSnappedInfo = this.snapToGrid(position,scale,canvas);
        }
        if(!this.isDrawing) {
          this.isDrawing = drawingStarted ? true : false;
          this.drawingStep = 1;
          if(this.drawingMode === DrawingMode.line) {
            this.activeGeometry = {startPoint: {x: position.x/scale, y: position.y/scale}, endPoint: {x: position.x/scale, y: position.y/scale}};
            if(controlPointToSnap) {
              this.activeGeometry = {startPoint: {x: controlPointToSnap.x, y: controlPointToSnap.y}, endPoint: {x: controlPointToSnap.x, y: controlPointToSnap.y}};
            }
            else if(gridSnappedInfo) {
              this.activeGeometry = {startPoint: {x: gridSnappedInfo.point.x, y: gridSnappedInfo.point.y}, endPoint: {x: gridSnappedInfo.point.x, y: gridSnappedInfo.point.y}, gridSnapped: gridSnappedInfo};
            }
          }
          else if(this.drawingMode === DrawingMode.circle) {
            this.activeGeometry = {center: {x: position.x/scale, y: position.y/scale}, radius: 0};
            if(controlPointToSnap) {
              this.activeGeometry.center.x = controlPointToSnap.x;
              this.activeGeometry.center.y = controlPointToSnap.y;
            }
            else if(gridSnappedInfo) {
              this.activeGeometry = {center: {x: gridSnappedInfo.point.x, y: gridSnappedInfo.point.y}, radius: 0, gridSnapped: gridSnappedInfo};
            }
            if(this.fillMode) {
              this.activeGeometry.fillColor = this.geometryPropertiesService.activeFillColor;
            }
            else {
              delete this.activeGeometry.fillColor;
            }
          }
          else if(this.drawingMode === DrawingMode.rect || this.drawingMode === DrawingMode.arrow) {
            this.activeGeometry = {origin: {x: position.x/scale, y: position.y/scale}, width: 0, height: 0};
            if(controlPointToSnap) {
              this.activeGeometry.origin.x = controlPointToSnap.x;
              this.activeGeometry.origin.y = controlPointToSnap.y;
            }
            else if(gridSnappedInfo) {
              this.activeGeometry = {origin: {x: gridSnappedInfo.point.x, y: gridSnappedInfo.point.y}, width: 0, height: 0, gridSnapped: gridSnappedInfo};
            }
            if(this.fillMode) {
              this.activeGeometry.fillColor = this.geometryPropertiesService.activeFillColor;
            }
            else {
              delete this.activeGeometry.fillColor;
            }
          }
          else if(this.drawingMode === DrawingMode.arc) {
            this.activeGeometry = {center: {x: position.x/scale, y: position.y/scale}, radius: 0, startAngle: 0, endAngle: 0, step: this.isDrawing ? this.drawingStep : 0, mousePos: position};
            if(controlPointToSnap) {
              this.activeGeometry.center.x = controlPointToSnap.x;
              this.activeGeometry.center.y = controlPointToSnap.y;
            }
            else if(gridSnappedInfo) {
              this.activeGeometry = {center: {x: gridSnappedInfo.point.x, y: gridSnappedInfo.point.y}, radius: 0, startAngle: 0, endAngle: 0, step: this.isDrawing ? this.drawingStep : 0, mousePos: position, gridSnapped: gridSnappedInfo};
            }
          }
          else if(this.drawingMode === DrawingMode.image && this.selectionService.draggingImage) {
            this.activeGeometry = {imagePath: this.selectionService.draggingImage.path, title: this.selectionService.draggingImage.title, origin: {x: position.x/scale, y: position.y/scale}, width: this.selectionService.draggingImage.size.width, height: this.selectionService.draggingImage.size.height};
            if(controlPointToSnap) {
              this.activeGeometry.origin.x = controlPointToSnap.x;
              this.activeGeometry.origin.y = controlPointToSnap.y;
            }
            else if(gridSnappedInfo) {
              this.activeGeometry = {imagePath: this.selectionService.draggingImage.path, title: this.selectionService.draggingImage.title, origin: {x: gridSnappedInfo.point.x/scale, y: gridSnappedInfo.point.y/scale}, width: this.selectionService.draggingImage.size.width, height: this.selectionService.draggingImage.size.height, gridSnapped: gridSnappedInfo};
            }
          }
          else if(this.multipleLineMode()) {
            let vertex = new Vertex({position: {x: position.x/scale, y: position.y/scale}});
            this.activeGeometry = {vertexes: [vertex],step: 0};
            if(controlPointToSnap) {
              this.activeGeometry.vertexes[0].position.x = controlPointToSnap.x;
              this.activeGeometry.vertexes[0].position.y = controlPointToSnap.y;
            }
            else if(gridSnappedInfo) {
              let vertex = new Vertex({position: {x: gridSnappedInfo.point.x/scale, y: gridSnappedInfo.point.y/scale}});
              this.activeGeometry = {vertexes: [vertex],step: 0, gridSnapped: gridSnappedInfo};
            }
            if(this.fillMode && this.drawingMode === DrawingMode.polygon) {
              this.activeGeometry.fillColor = this.geometryPropertiesService.activeFillColor;
            }
            else {
              delete this.activeGeometry.fillColor;
            }
          }
          if(this.activeGeometry) {
            this.activeGeometry.strokeColor = this.geometryPropertiesService.activeStrokeColor;
            this.activeGeometry.intersectedControl = null;
            this.activeGeometry.gridSnapped = null;
          }
          if(this.activeGeometry && controlPointToSnap) {
            this.activeGeometry.intersectedControl = controlPointToSnap;
          }
          else if(this.activeGeometry && gridSnappedInfo) {
            this.activeGeometry.gridSnapped = gridSnappedInfo;
          }
        }
        else {
          this.drawingStep = 2;
          if(this.isDrawing && this.drawingMode === DrawingMode.line) {
            this.activeGeometry.distance = this.calculateDistance(this.activeGeometry.startPoint, position, scale);
            if(shiftKey) {
              this.activeGeometry.endPoint.y = Math.abs(this.activeGeometry.startPoint.x - position.x/scale) > Math.abs(this.activeGeometry.startPoint.y - position.y/scale) ? this.activeGeometry.startPoint.y : position.y/scale;
              this.activeGeometry.endPoint.x = Math.abs(this.activeGeometry.startPoint.x - position.x/scale) < Math.abs(this.activeGeometry.startPoint.y - position.y/scale) ? this.activeGeometry.startPoint.x : position.x/scale;
            }
            else {
              this.activeGeometry.endPoint.x = this.axisSnapping ? this.snapToAxis(this.activeGeometry.startPoint,position,"x",scale) : position.x/scale;
              this.activeGeometry.endPoint.y = this.axisSnapping ? this.snapToAxis(this.activeGeometry.startPoint,position,"y",scale) : position.y/scale;
            }
            if(controlPointToSnap) {
              this.activeGeometry.strokeColor = this.geometryControlsSnappingHighlightColor;
              this.activeGeometry.endPoint = controlPointToSnap;
              this.activeGeometry.intersectedControl = controlPointToSnap;
            }
            else if(gridSnappedInfo) {
              this.activeGeometry.endPoint = gridSnappedInfo.point;
              this.activeGeometry.gridSnapped = gridSnappedInfo;
            }
            else {
              this.activeGeometry.intersectedControl = null;
              this.activeGeometry.gridSnapped = false;
              this.activeGeometry.strokeColor = this.geometryPropertiesService.activeStrokeColor;
            }
            if(this.activeGeometry.endPoint.x === this.activeGeometry.startPoint.x || this.activeGeometry.endPoint.y === this.activeGeometry.startPoint.y) {
              this.activeGeometry.strokeColor = this.axisSnappingHighlightColor;
            }
            if(this.geometryPropertiesService.currentStartIcon) {
              this.activeGeometry.startIcon = this.geometryPropertiesService.currentStartIcon;
            }
            if(this.geometryPropertiesService.currentEndIcon) {
              this.activeGeometry.endIcon = this.geometryPropertiesService.currentEndIcon;
            }
          }
          else if(this.isDrawing && this.drawingMode === DrawingMode.circle) {
            this.activeGeometry.radius = this.calculateDistance(this.activeGeometry.center, position,scale);
            if(controlPointToSnap) {
              this.activeGeometry.strokeColor = this.geometryControlsSnappingHighlightColor;
              let scaledPoint = {x:controlPointToSnap.x*scale,y: controlPointToSnap.y*scale};
              this.activeGeometry.radius = this.calculateDistance(this.activeGeometry.center, scaledPoint, scale);
              this.activeGeometry.intersectedControl = controlPointToSnap;
            }
            else if(gridSnappedInfo) {
              let scaledPoint = {x:gridSnappedInfo.point.x*scale,y: gridSnappedInfo.point.y*scale};
              this.activeGeometry.radius = this.calculateDistance(this.activeGeometry.center, scaledPoint,scale);
              this.activeGeometry.gridSnapped = gridSnappedInfo;
            }
            else {
              this.activeGeometry.intersectedControl = null;
              this.activeGeometry.gridSnapped = false;
              this.activeGeometry.strokeColor = this.geometryPropertiesService.activeStrokeColor;
            }
          }
          else if(this.isDrawing && this.drawingMode === DrawingMode.rect || this.isDrawing && this.drawingMode === DrawingMode.arrow) {
            this.activeGeometry.width = position.x/scale - this.activeGeometry.origin.x;
            this.activeGeometry.height = position.y/scale - this.activeGeometry.origin.y;
            if(controlPointToSnap) {
              this.activeGeometry.strokeColor = this.geometryControlsSnappingHighlightColor;
              this.activeGeometry.width = controlPointToSnap.x - this.activeGeometry.origin.x;
              this.activeGeometry.height = controlPointToSnap.y - this.activeGeometry.origin.y;
              this.activeGeometry.intersectedControl = controlPointToSnap;
            }
            else if(gridSnappedInfo) {
              this.activeGeometry.width = gridSnappedInfo.point.x - this.activeGeometry.origin.x;
              this.activeGeometry.height = gridSnappedInfo.point.y - this.activeGeometry.origin.y;
              this.activeGeometry.gridSnapped = gridSnappedInfo;
            }
            else {
              this.activeGeometry.intersectedControl = null;
              this.activeGeometry.gridSnapped = false;
              this.activeGeometry.strokeColor = this.geometryPropertiesService.activeStrokeColor;
            }
          }
          else if(this.drawingMode === DrawingMode.arc) {
            if(this.activeGeometry.step === 1){
              if(shiftKey) {
                this.activeGeometry.mousePos.y = Math.abs(this.activeGeometry.center.x - position.x) > Math.abs(this.activeGeometry.center.y - position.y) ? this.activeGeometry.center.y : position.y*scale;
                this.activeGeometry.mousePos.x = Math.abs(this.activeGeometry.center.x - position.x) < Math.abs(this.activeGeometry.center.y - position.y) ? this.activeGeometry.center.x : position.x*scale;
              }
              else {
                this.activeGeometry.mousePos.x = this.axisSnapping ? this.snapToAxis(this.activeGeometry.center,position,"x",scale)*scale : position.x*scale;
                this.activeGeometry.mousePos.y = this.axisSnapping ? this.snapToAxis(this.activeGeometry.center,position,"y",scale)*scale : position.y*scale;
              }
              if(controlPointToSnap) {
                this.activeGeometry.strokeColor = this.geometryControlsSnappingHighlightColor;
                this.activeGeometry.mousePos.x = controlPointToSnap.x*scale;
                this.activeGeometry.mousePos.y = controlPointToSnap.y*scale;
                this.activeGeometry.intersectedControl = controlPointToSnap;
              }
              else if(gridSnappedInfo) {
                this.activeGeometry.mousePos.x = gridSnappedInfo.point.x*scale;
                this.activeGeometry.mousePos.y = gridSnappedInfo.point.y*scale;
                this.activeGeometry.gridSnapped = gridSnappedInfo;
              }
              else {
                this.activeGeometry.intersectedControl = null;
                this.activeGeometry.strokeColor = this.geometryPropertiesService.activeStrokeColor;
              }
              this.activeGeometry.radius = this.calculateDistance(this.activeGeometry.center, this.activeGeometry.mousePos, scale);
              this.activeGeometry.startAngle = this.calculateArcAngle(this.activeGeometry.center, this.activeGeometry.mousePos, scale);
              this.activeGeometry.endAngle = this.activeGeometry.startAngle;
              if(this.activeGeometry.startAngle*180/Math.PI%90 === 0) {
                this.activeGeometry.strokeColor = this.axisSnappingHighlightColor;
              }
              else {
                this.activeGeometry.strokeColor = this.geometryPropertiesService.activeStrokeColor;
              }
            }
            else if(this.activeGeometry.step === 2){
              if(shiftKey) {
                this.activeGeometry.mousePos.y = Math.abs(this.activeGeometry.center.x - position.x) > Math.abs(this.activeGeometry.center.y - position.y) ? this.activeGeometry.center.y : position.y*scale;
                this.activeGeometry.mousePos.x = Math.abs(this.activeGeometry.center.x - position.x) < Math.abs(this.activeGeometry.center.y - position.y) ? this.activeGeometry.center.x : position.x*scale;
              }
              else {
                this.activeGeometry.mousePos.x = this.axisSnapping ? this.snapToAxis(this.activeGeometry.center,position,"x",scale)*scale : position.x*scale;
                this.activeGeometry.mousePos.y = this.axisSnapping ? this.snapToAxis(this.activeGeometry.center,position,"y",scale)*scale : position.y*scale;
              }
              this.activeGeometry.endAngle = this.calculateArcAngle(this.activeGeometry.center, position, scale);
              let angleDiffToRightAngleMultiples = Math.abs((this.activeGeometry.endAngle-this.activeGeometry.startAngle)*180/Math.PI%90);
              if(this.activeGeometry.endAngle*180/Math.PI%90 === 0) {
                this.activeGeometry.strokeColor = this.axisSnappingHighlightColor;
              }
              else if(angleDiffToRightAngleMultiples < 2) {
                this.activeGeometry.strokeColor = ColorEnum.rightAngleHighlight;
                this.activeGeometry.endAngle -= angleDiffToRightAngleMultiples/180*Math.PI;
              }
              else if(angleDiffToRightAngleMultiples > 88) {
                this.activeGeometry.strokeColor = ColorEnum.rightAngleHighlight;
                this.activeGeometry.endAngle += Math.PI/2 - angleDiffToRightAngleMultiples/180*Math.PI;;
              }
              else {
                this.activeGeometry.strokeColor = this.geometryPropertiesService.activeStrokeColor;
              }
            }
          }
          else if(this.isDrawing && this.drawingMode === DrawingMode.image && this.activeGeometry) {
            this.activeGeometry.origin.x = position.x/scale;
            this.activeGeometry.origin.y = position.y/scale;
            if(controlPointToSnap) {
              this.activeGeometry.origin.x = controlPointToSnap.x;
              this.activeGeometry.origin.y = controlPointToSnap.y;
              this.activeGeometry.intersectedControl = controlPointToSnap;
            }
            else {
              this.activeGeometry.intersectedControl = null;
              this.activeGeometry.strokeColor = this.geometryPropertiesService.activeStrokeColor;
            }
          }
          else if(this.multipleLineMode()) {
            if(shiftKey && this.activeGeometry.vertexes.length > 1) {
              position.y = Math.abs(this.activeGeometry.vertexes[this.activeGeometry.vertexes.length-2].position.x - position.x) > Math.abs(this.activeGeometry.vertexes[this.activeGeometry.vertexes.length-2].position.y - position.y) ? this.activeGeometry.vertexes[this.activeGeometry.vertexes.length-2].position.y : position.y*scale;
              position.x = Math.abs(this.activeGeometry.vertexes[this.activeGeometry.vertexes.length-2].position.x - position.x) < Math.abs(this.activeGeometry.vertexes[this.activeGeometry.vertexes.length-2].position.y - position.y) ? this.activeGeometry.vertexes[this.activeGeometry.vertexes.length-2].position.x: position.x*scale;
            }
            else {
              position.x = (this.axisSnapping && this.activeGeometry.vertexes.length > 1) ? this.snapToAxis(this.activeGeometry.vertexes[this.activeGeometry.vertexes.length-2].position,position,"x",scale)*scale : position.x*scale;
              position.y = (this.axisSnapping && this.activeGeometry.vertexes.length > 1) ? this.snapToAxis(this.activeGeometry.vertexes[this.activeGeometry.vertexes.length-2].position.x,position,"y",scale)*scale : position.y*scale;
            }
            let vertex = new Vertex({position:{x: position.x/scale, y: position.y/scale}});
            if(!this.activeGeometry.step || this.activeGeometry.step === 1) {
              this.activeGeometry.vertexes.push(vertex);
              this.setDrawingStep(2);
            }
            else if(this.activeGeometry.step === 2) {
              this.activeGeometry.vertexes[this.activeGeometry.vertexes.length-1] = vertex;
            }
            if(controlPointToSnap) {
              this.activeGeometry.vertexes[this.activeGeometry.vertexes.length-1].position.x = controlPointToSnap.x;
              this.activeGeometry.vertexes[this.activeGeometry.vertexes.length-1].position.y = controlPointToSnap.y;
              this.activeGeometry.intersectedControl = controlPointToSnap;
            }
            else if(gridSnappedInfo) {
              this.activeGeometry.vertexes[this.activeGeometry.vertexes.length-1].position.x = gridSnappedInfo.point.x;
              this.activeGeometry.vertexes[this.activeGeometry.vertexes.length-1].position.y = gridSnappedInfo.point.y;
              this.activeGeometry.mousePos.x = gridSnappedInfo.point.x;
              this.activeGeometry.mousePos.y = gridSnappedInfo.point.y;
              this.activeGeometry.gridSnapped = gridSnappedInfo;
            }
            else {
              this.activeGeometry.intersectedControl = null;
              this.activeGeometry.gridSnapped = false;
              this.activeGeometry.strokeColor = this.geometryPropertiesService.activeStrokeColor;
            }
            if(this.activeGeometry.step === 2 && this.activeGeometry.vertexes.length > 1 && this.activeGeometry.vertexes[this.activeGeometry.vertexes.length-2].position.x === this.activeGeometry.vertexes[this.activeGeometry.vertexes.length-1].position.x || this.activeGeometry.vertexes[this.activeGeometry.vertexes.length-2].position.y === this.activeGeometry.vertexes[this.activeGeometry.vertexes.length-1].position.y) {
              this.activeGeometry.strokeColor = this.axisSnappingHighlightColor;
            }
            else {
              this.activeGeometry.strokeColor = this.geometryPropertiesService.activeStrokeColor;
            }
          }
        }
        if(this.activeGeometry && !controlPointToSnap && !gridSnappedInfo && this.drawingMode !== DrawingMode.arc) {
          this.activeGeometry.mousePos = position;
        }
        if(this.activeGeometry) {
          this.activeGeometry.lineType = this.geometryPropertiesService.getCurrentLineTypeId();
          this.activeGeometry.thickness = this.geometryPropertiesService.currentLineWidth;
        }
      }
      //this.renderService.renderActiveGeometry(this.activeGeometry, context, canvas, this.drawingMode);
      if(this.activeGeometry && (this.activeGeometry.step === 3 || this.drawingStep === 3)) {
        if(this.drawingMode === DrawingMode.arc) {
          this.activeGeometry = this.convertAnglesOfArcToDegrees(this.activeGeometry);
        }
        this.activeGeometry.strokeColor = this.colorService.activeStrokeColorHexToRgb();
        this.activeGeometry.fillColor = this.colorService.activeFillColorHexToRgb();
        this.selectionService.resetSelection();
        this.activeGeometry.selected = true;
        this.layerService.addGeometryToActiveLayer(this.activeGeometry, this.drawingMode, true).subscribe(geomName => {
          this.selectionService.setActiveElement(geomName);
          this.switchToEditing();
        });
        this.stopDrawing();
      }
    }
    else {
      this.drawingError = true;
    }
  }

  switchToEditing() {
    this.geometryEditingService.setLineControlsEditingMode(null);
    this.modesService.setMode(ActiveMode.editMode);
    this.additionalToolsService.closeAdditionalTools("");
    this.additionalToolsService.toggleAdditionalTools("edit");
    this.router.navigate(['/editor/edit']);
  }

  calculateDistance(origin, mousePos, scale) {
    let radius = Math.round(Math.abs(Math.sqrt(Math.pow(origin.x - mousePos.x/scale, 2) + Math.pow(origin.y - mousePos.y/scale, 2))));
    return radius;
  }

  calculateArcAngle(center: Point, mousePos: Point, scale) {
    let dy = mousePos.y/scale - center.y;
    let dx = mousePos.x/scale - center.x;
    let angle = Math.atan((dy) / (dx));

    if (dx < 0) angle += Math.PI;
    else if (dy < 0) angle += Math.PI*2; 
    return angle;
  }

  setDrawingStep(number) {
    this.drawingStep = number;
    if(this.activeGeometry) {
      this.activeGeometry.step = number;
    }
  }

  convertAnglesOfArcToDegrees(geometry) {
    geometry.startAngle = geometry.startAngle*180/Math.PI;
    geometry.endAngle = geometry.endAngle*180/Math.PI;
    return geometry;
  }

  setDrawingMode(value: number) {
    this.stopDrawing();
    this.drawingMode = value;
  }

  stopDrawing() {
    this.isDrawing = false;
    this.activeGeometry = null;
  }

  multipleLineMode() {
    return this.drawingMode === DrawingMode.Polyline || this.drawingMode === DrawingMode.polygon
  }

  snapToAxis(origin,position,axisName,scale) {
    if(Math.abs(origin[axisName] - position[axisName]/scale) < 5 ) return origin[axisName]
    else return position[axisName]/scale;
  }

  setFillMode(value) {
    if(this.fillMode !== value) {
      this.fillMode = value;
    }
  }

  snapToGrid(point, scale, canvas) {
    let newPoint = {...point};
    newPoint.x/=scale;
    newPoint.y/=scale;
    let snappingInfo: any = {
      point: null,
      gridSnapping: {
        x: false,
        y: false
      },
    };
    let cellSize = this.appSettingsService.gridSettings.cellSize/this.appSettingsService.dimensionsSettings.ratio;
    let horDiff = Math.floor(newPoint.x%cellSize);
    let vertDiff = Math.floor(newPoint.y%cellSize);
    if(Math.abs(horDiff)<2 || Math.abs(horDiff)>cellSize-2) {
      newPoint.x -= horDiff < 2 ? horDiff : horDiff-cellSize;
      snappingInfo.point = newPoint;
      snappingInfo.gridSnapping.x = true;
    }
    if(Math.abs(vertDiff)<2 || Math.abs(vertDiff)>cellSize-2) {
      newPoint.y += vertDiff < 2 ? vertDiff : vertDiff-cellSize;
      snappingInfo.point = newPoint;
      snappingInfo.gridSnapping.y = true;
    }
    return snappingInfo.point ? snappingInfo : null;
  }

}