export enum GeometricType {
    LwPolyline = 1,
    Polyline = 2,
    Circle = 4,
    Arc = 5,
    Line = 6,
    Rectangle = 7,
    Image = 8,
    Polygon = 9,
    Text = 10,
    Arrow = 11,
    Legend = 12,
    Frame = 13,
    Solid = 15
}