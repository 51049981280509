import { GeometryProperties } from './geometry-properties.model';
import { Extrema } from '../extrema.model';
import { VertexPosition } from '../position.model';
import { Point } from '../point.model';
import { Rectangle } from './rectangle.model';

export class EPImage extends Rectangle {
    title: string
    override geometricType: number;
    override layerName: string;
    override geometryProperties: GeometryProperties;

    //direction -> {x,y,z,isNormalized}, vector -> {x,y,z,isNormalized}
    override origin: VertexPosition;
    override width: number;
    override height: number;
    override intersectedControl: Point;
    imagePath: string;
    override rotation?: number;
    image: any;

    constructor(data) {
        super(data);
        this.origin =  new VertexPosition(data.origin);
        this.width =  data.width;
        this.height =  data.height;
        this.geometryProperties =  new GeometryProperties({...data,...data.geometryProperties});
        this.geometricType = data.geometricType;
        this.layerName = data.layerName;
        this.imagePath = data.imagePath;
        this.title = data.title;
        this.rotation = data.rotation ? data.rotation : (data.brightness ? data.brightness : 0);
        this.image = new Image();
        this.image.src = this.imagePath
    }

    override draw(context, scale, color, canvas, fillColor = null, forExport = null) {
        context.save();
        context.translate((this.origin.x+this.width/2)*scale, (this.origin.y+this.height/2) * scale);
        if(this.rotation) {
            context.rotate((this.rotation) * Math.PI / 180);
        }
        context.drawImage(this.image, -this.width/2*scale, -this.height/2*scale, this.width * scale, this.height * scale);
        context.restore();
    }

    override mirrorToXAxis(referenceY: number) {
        super.mirrorToXAxis(referenceY,false);
    }

}
