<div class="w-100" xLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
    <div ngClass.gt-sm="w-25"></div>
    <div ngClass.xs="p-lr" ngClass.sm="p-lr-2" class="p-t-3 full-height-container" fxFlex fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">
        <div class="w-100" *ngFor="let dataElement of data; let i = index">
            <h3 fxLayout="row" fxLayoutAlign="start center" *ngIf="dataElement.type === 'heading'" class="p-t-2">{{dataElement.content}}</h3>
            <!-- <iframe *ngIf="dataElement.type === 'video'" [src]="getSanitizedURL(dataElement.link)" width="640" height="400" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> -->

            <div *ngIf="dataElement.type === 'video'" class="w-100" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px">
                <a ngClass.xs="w-80" fxFlex.gt-xs="40" href="{{dataElement.link}}" target="_blank">
                    <div class="video-preview" fxLayout="column" fxLayoutAlign="center center">
                        <img class="play" src="assets/images/play-button.svg" alt="">
                        <img class="preview" [src]="'assets/images/videopreviews/' + dataElement.preview +'.png'" alt="">
                    </div>
                </a>
            </div>
        </div>
    </div>
    <div ngClass.gt-sm="w-25"></div>
</div>

