<div class="additional-tools-element">
    <div class="ep-form-control-container">
        <div class="ep-form-control">
            <mat-label>Linienstärke</mat-label>
            <input type="number" [ngModel]="width" (blur)="onBlur()" (focus)="onFocus()"
            (ngModelChange)="updateLineWidth($event)">
        </div>
    </div>
    <div class="w-100 p-b">
        <mat-form-field name="type" class="w-100">
            <mat-label>Typ</mat-label>
            <mat-select (selectionChange)="updateLineType($event)" [(value)]="currentLineType">
                <mat-option *ngFor="let lineType of lineTypesList" [value]="lineType">{{lineType.patternSample}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="w-100">
        <span class="font-grey">Linienfarbe</span>
        <color-sketch [presetColors]="presetColors" [color]="currentColor" (onChangeComplete)="updateActiveColor($event)"></color-sketch>
    </div>
</div>