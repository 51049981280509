import { Rectangle } from './rectangle.model';
import { ColorEnum } from '../enums/color.enum';


export class Arrow extends Rectangle {
    constructor(data) {
        super(data);
    }

    override draw(context, scale, color, canvas, fillColor = null) {
        context.save();
        this.defineArrow(context, scale);
        if(fillColor) {
            context.fillStyle = fillColor;
            context.fill();
        }
        context.strokeStyle = color ? color : ColorEnum.default;
        context.stroke();
        context.restore();
    }

    defineArrow(context, scale) {
        context.translate((this.origin.x + this.width/2)*scale, (this.origin.y + this.height/2)*scale);
        let offX = this.width/2;
        let offY = this.height/2;
        if(this.rotation) {
            context.rotate((this.rotation) * Math.PI / 180);
        }
        context.beginPath();
        context.moveTo((this.arrowControls[0].x - offX) * scale, (this.arrowControls[0].y - offY) * scale);
        this.arrowControls.forEach(controlPoint => {
            context.lineTo((controlPoint.x - offX) * scale, (controlPoint.y - offY) * scale);
        })
        context.closePath();
    }

    override mouseIntersectsGeometry(mousePosition,context,canvas,scale) {
        context.save();
        this.defineArrow(context, scale);
        let intersected = context.isPointInPath(mousePosition.x, mousePosition.y);
        context.restore();
        if(intersected) {
            return true;
        }
    }

    get arrowControls() {
        let controls = [
            {x: 0, y: this.height/3},
            {x: this.width*4/6, y: this.height/3},
            {x: this.width*4/6, y: 0},
            {x: this.width, y: this.height/2},
            {x: this.width*4/6, y: this.height},
            {x: this.width*4/6, y: this.height*2/3},
            {x: 0, y: this.height*2/3},
        ]
        return controls;
    }

    override mirrorToYAxis(referenceX: number) {
        super.mirrorToYAxis(referenceX);
        this.rotation = this.rotation - 180;
    }

    override mirrorToXAxis(referenceY: number) {
        super.mirrorToXAxis(referenceY);
        this.rotation = 180 + this.rotation;
        if(this.rotation>360) {
            this.rotation = this.rotation - 360;
        }
    }

}
