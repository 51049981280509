import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { InputDialogComponent } from '../../dialogs/input-dialog/input-dialog.component';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {DataService, FileService, LayerService, ViewerService} from 'src/app/services';
import { InfoDialogComponent } from '../../dialogs/info-dialog/info-dialog.component';
import {ModesService} from "../../../services/status-and-control-services/modes/modes.service";
import {NotificationService} from "../../../services/status-and-control-services/notification/notification.service";
import {ActiveMode} from "../../../models/enums/active-mode.enum";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-layer-tools',
  templateUrl: './layer-tools.component.html',
  styleUrls: ['./layer-tools.component.scss']
})
export class LayerToolsComponent implements OnInit {
  activeLayer: string;
  activeLayerSubscription: Subscription;
  modeSub: Subscription;
  mode: ActiveMode;
  layers$: Observable<any[]>;
  
  constructor(
    private layerService: LayerService,
    public dialog: MatDialog,
    public dataService: DataService,
    private modeService: ModesService,
    private fileService: FileService,
    private notificationService: NotificationService,
    private viewerService: ViewerService
  ) {
    this.layers$ = this.layerService.layerData$.pipe(takeUntilDestroyed());
    this.subscribeToLayers();
    this.modeSub = this.modeService.activeMode$.subscribe(mode => {
      this.mode = mode;
    })
  }

  subscribeToLayers() {
    this.activeLayerSubscription = this.layerService.activeLayer$.subscribe(
      activeLayer => {
        this.activeLayer = activeLayer;
      }
    )
  }

  ngOnDestroy() {
    this.activeLayerSubscription.unsubscribe();
    this.modeSub.unsubscribe();
  }

  ngOnInit(): void {
  }

  toggleLayerVisibility(index) {
    if(this.modeService.mode === ActiveMode.moveMode) {
      this.viewerService._loadingSource.next(true);
      const requestData = {
        FileName : this.fileService.dxfFilePath,
        LayerNames : this.layerService.layerNamesToRender
      };
      this.fileService.getPreviewByLayers(requestData, false).subscribe(
        () => {
          this.notificationService.openNotification('filter_plan_layer_success');
        },
        () => {
          this.notificationService.openNotification('filter_plan_error');
        })
    }
    else {
      this.layerService.toggleLayerVisibility(index);
    }
  }

  createNewLayer() {
    let dialogRefPass = this.dialog.open(InputDialogComponent, {
      data: {
        valueName: "Layer Name"
      }
    });
    dialogRefPass.afterClosed().subscribe(result => {
      if(result && result.layerName) {
        let alreadyExistentLayer = this.layerService.layerNames.indexOf(result.layerName);
        if(alreadyExistentLayer < 0) {
          this.layerService.createLayer(result.layerName);
        }
        else {
          let dialogRefPass = this.dialog.open(InfoDialogComponent,{
            data: {
              serviceErrorCode: "Ebene mit diesem Namen existiert bereits."
            }
          })
          dialogRefPass.afterClosed().subscribe(() => {
            this.createNewLayer();
          })
        }
      }
    });
  }

  rearrangeLayers(event: CdkDragDrop<Array<string>>) {
    this.layerService.rearrangeLayers(event);
  }

  toggleLayerSelection(layerName: string, index: number) {
    this.layerService.toggleLayerSelection(layerName, index);
  }

  removeLayer() {
    this.viewerService._loadingSource.next(true);
    this.layerService.removeActiveLayer();
  }


}
