import { ControlActiveSettingEnum } from "../enums/control-active-setting.enum";
export class EditorControl {
    id: number;
    icon: string;
    activeOn: ControlActiveSettingEnum[];
    name?: string;
    varName?: string;
    disabledOnDemo?: boolean;
    
    constructor(data) {
        Object.keys(data).forEach(key => {
            this[key] = data[key];
        })
    }
}
