export enum DrawingMode  {
    LWPolyline = 1,
    Polyline = 2,
    line = 6,
    rect = 7,
    circle = 4,
    arc = 5,
    image = 8,
    polygon = 9,
    text = 10,
    arrow = 11
}