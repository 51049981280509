import { Geometry } from './geometry.model';
import { VertexPosition } from '../position.model';
import { GeometryProperties } from './geometry-properties.model';
import { Extrema } from '../extrema.model';
import { Point } from '../point.model';
import { ColorEnum } from '../enums/color.enum';
import { Rectangle } from './rectangle.model';

export class Circle implements Geometry {
    entityName: string;
    entityTag: string;
    geometricType: number;
    layerName: string;
    geometryProperties: GeometryProperties;
    
    center: VertexPosition;
    radius: number;
    
    constructor(data: any) {
        this.center = new VertexPosition(data.center);
        this.radius = data.radius;
        if(data.fillColor && !data.strokeColor) {
            data.strokeColor = data.fillColor;
        }
        this.geometryProperties =  new GeometryProperties({...data,...data.geometryProperties});
        this.entityName = data.entityName;
        this.entityTag = (data.entityTag && data.entityTag != data.entityName) ? data.entityTag : "tag_" + data.entityName;
        this.geometricType = data.geometricType;
        this.layerName = data.layerName;
    }

    roundUpCoords() {
        this.center = new VertexPosition(this.center,true);
        this.radius = Math.round(this.radius);
    }

    extrema() {
        let extrema= new Extrema();
        extrema.maxX = this.center.x + this.radius;
        extrema.maxY = this.center.y + this.radius;
        extrema.minX = this.center.x - this.radius;
        extrema.minY = this.center.y - this.radius;
        return extrema;
    }
    
    scale(multiplier: Point, selectionRect: Rectangle=null, equalizeThicknessFactor: number = null) {
        this.center.x *= !selectionRect ? multiplier.x : 1;
        this.center.y *= !selectionRect ? multiplier.y : 1;
        this.radius *= multiplier.x !== 1 ? multiplier.x : multiplier.y;
        if(equalizeThicknessFactor) {
            this.geometryProperties.thickness *= equalizeThicknessFactor;
        }
    }

    draw(context,scale,color,canvas = null,fillColor = null) {
        context.beginPath();
        context.strokeStyle = color ? color : ColorEnum.default;
        context.arc(this.center.x*scale,this.center.y*scale,this.radius*scale,0*Math.PI,2*Math.PI);
        if(fillColor) {
            context.fillStyle = fillColor;
            context.arc(this.center.x*scale,this.center.y*scale,this.radius*scale,0*Math.PI,2*Math.PI);
            context.fill();
        }
        context.stroke();
    }

    mouseIntersectsGeometry(mousePosition,context,canvas,scale) {
        var dx = mousePosition.x - this.center.x * scale;
        var dy = mousePosition.y - this.center.y * scale;
        let diff = Math.abs((dx*dx+dy*dy) - (this.radius*scale*this.radius*scale));
        return diff < 400;
    }

    findControlToSnap(mousePos, context, canvas, scale) {
        let controlPoint = null
        if(this.intersectsControlPoint(this.center, 5, mousePos, scale)) {
            controlPoint = {...this.center};
        }
        return controlPoint;
    }

    drawControls(mousePos,context,canvas,scale) {
        context.beginPath();
        context.fillStyle = "green";
        let pointRadius = 5;
        if(this.intersectsControlPoint(this.center, pointRadius, mousePos, scale)) {
            context.fillStyle = "yellow";
        }
        context.arc(this.center.x * scale, this.center.y * scale, pointRadius, 0 * Math.PI, 2 * Math.PI);
        context.fill();
    }

    drawInformation(context,canvas,scale,visualisationMultiplier,unit,ratio) {
        context.save();
        context.fillStyle = ColorEnum.fontDefault;
        context.font = "18px Arial";
        context.beginPath();
        context.translate(this.center.x * scale, this.center.y * scale);
        context.fillText("Radius: " + Math.round(this.radius * visualisationMultiplier * ratio * 1000) / 1000 + " " + unit, 0, this.radius + 20);
        context.restore();
    }

    intersectsControlPoint(position: VertexPosition, r, mousePos: Point, scale) {
        var distSqr = Math.pow(position.x * scale - mousePos.x, 2) + Math.pow(position.y * scale - mousePos.y, 2);
        if(distSqr < r * r) {
            return true;
        }
        return false;
    }

    move(offset) {
        this.center.x -= offset.x;
        this.center.y -= offset.y;
    }

    mirrorToYAxis(referenceX: number) {
        this.center.x += (referenceX-this.center.x)*2;
    }

    mirrorToXAxis(referenceY: number) {
        this.center.y += (referenceY-this.center.y)*2;
    }

}