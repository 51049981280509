<div class="horizontal-control-panel" fxLayout="row" (mousedown)="stopEvent($event)" (click)="stopEvent($event)">
    <app-file-controls></app-file-controls>
    <app-print-controls></app-print-controls>
    <app-hor-viewer-controls></app-hor-viewer-controls>
    <app-hor-editing-controls></app-hor-editing-controls>
    <button mat-button (click)="toggleModes()">
        <span>Bearbeitungsmodus
            <span>
                {{editing ? "beenden" : "starten"}}
            </span>
        </span>
    </button>
</div>