import { WallOpeningMode } from "../enums/wall-opening-mode.enum";
import * as doorTypesList from "../../../assets/controls-lists/door-type-list.json";

export class WallOpening {
    afterVertexIndex: number;
    type: number;
    doorKind?: number;
    invertX?: boolean;
    invertY?: boolean;

    constructor(){}

    draw(angle, startPosition, endPosition, context, canvas, scale, distance, lineWidth) {
        switch(this.type) {
            case WallOpeningMode.door: this.drawDoor(context, startPosition, endPosition, canvas, angle, scale, distance, lineWidth); break;
            case WallOpeningMode.window: this.drawWindow(context, startPosition, endPosition, canvas, angle, scale, distance, lineWidth); break;
            default: return
        }        
    }

    drawDoor(context, startPosition, endPosition, canvas, angle, scale, distance, lineWidth) {
        let doorKinds: any = doorTypesList;
        let imageName = "";
        if(this.doorKind) {
            imageName = doorKinds.default.doorTypes.find(element => element.type === this.doorKind).icon;
            imageName = 'assets/images/doors/' + imageName + '.svg';
        }
        switch(this.doorKind) {
            case 1: return this.drawDoorKind1(context,startPosition,endPosition,canvas,angle,scale,distance,imageName);
            case 2: return this.drawDoorKind2(context,startPosition,endPosition,canvas,angle,scale,distance,imageName); 
            case 3: return this.drawDoorKind3(context,startPosition,endPosition,canvas,angle,scale,distance,imageName);
            case 4: return this.drawDoorKind4(context,startPosition,endPosition,canvas,angle,scale,distance,imageName);
            case 5: return this.drawDoorKind3(context,startPosition,endPosition,canvas,angle,scale,distance,imageName);
            default: return this.drawDefaultDoor(context,startPosition,endPosition,canvas,angle,scale,distance,lineWidth);
        }
    }

    drawDoorKind1(context,startPosition,endPosition,canvas,angle,scale,distance,imageName) {
        let imageObj = new Image();
        imageObj.src = imageName;
        context.save();
        context.translate(startPosition.x * scale, startPosition.y * scale);
        context.rotate(angle+Math.PI);
        context.translate(0,(-(distance+10)/2)*scale);
        let inversionApplies = (this.invertX || this.invertY) && !(this.invertX && this.invertY);
        if(inversionApplies) {
            context.scale(-1, 1);
        }
        context.drawImage(imageObj, 0, 0, distance * (inversionApplies ? -1 : 1) * scale, (distance+10)*scale);
        context.restore();
    }

    drawDoorKind2(context,startPosition,endPosition,canvas,angle,scale,distance,imageName) {
        let imageObj = new Image();
        imageObj.src = imageName;
        context.save();
        context.translate(startPosition.x * scale, startPosition.y * scale);
        context.rotate(angle+Math.PI);
        if(this.invertY) {
            context.scale(1, -1);
        }   
        context.translate(0,(-distance/1.87)*(this.invertY ? 0 : 1)*scale);
        context.drawImage(imageObj, 0, 0, distance * scale, (distance/1.87)*(this.invertY ? -1 : 1)*scale);
        context.restore();
    }

    drawDoorKind3(context,startPosition,endPosition,canvas,angle,scale,distance,imageName) {
        let imageObj = new Image();
        imageObj.src = imageName;
        context.save();
        context.translate(startPosition.x * scale, startPosition.y * scale);
        context.rotate(angle+Math.PI);
        context.translate(0,-distance/2*scale);
        if(this.invertX) {
            context.scale(-1, 1);
        }
        context.drawImage(imageObj, 0, 0, distance * (this.invertX ? -1 : 1) * scale, distance*scale);
        context.restore();
    }

    drawDoorKind4(context,startPosition,endPosition,canvas,angle,scale,distance,imageName) {
        let imageObj = new Image();
        imageObj.src = imageName;
        context.save();
        context.translate(startPosition.x * scale, startPosition.y * scale);
        context.rotate(angle+Math.PI);
        context.translate(-distance/4*scale,-distance/2*scale);
        if(this.invertX) {
            context.scale(-1, 1);
        }
        context.drawImage(imageObj, -distance/4 * scale * (this.invertX ? -1 : 1), 0, distance * 2 * (this.invertX ? -1 : 1) * scale, distance*scale);
        context.restore();
    }

    drawDefaultDoor(context,startPosition,endPosition,canvas,angle,scale,distance,lineWidth) {
        context.save();
        let originX = this.invertX ? endPosition.x : startPosition.x;
        let originY = this.invertX ? endPosition.y : startPosition.y;
        context.translate(originX * scale, originY*scale);
        context.rotate(angle);
        context.beginPath();
        context.fillStyle = "black";
        context.lineWidth = 0.5;
        let angleXInvertionOffset = this.invertX ? -Math.PI/2 : 0;
        let angleYnvertionOffset = (this.invertY && this.invertX) ? -Math.PI/2 : (this.invertY && !this.invertX ? Math.PI/2 : 0);
        context.arc(0, 0, distance * scale, Math.PI/2+angleXInvertionOffset+angleYnvertionOffset, Math.PI+angleXInvertionOffset+angleYnvertionOffset);
        context.moveTo(0, 0);
        let directionMultiplier = this.invertY ? -1 : 1;
        context.lineTo(0, distance * scale * directionMultiplier);

        context.stroke();
        context.restore();
    }

    drawWindow(context, startPosition, endPosition, canvas, angle, scale, distance, lineWidth) {
        context.save();
        context.translate(startPosition.x * scale, startPosition.y * scale);
        context.rotate(angle);
        context.beginPath();
        context.storkeStyle = "black";
        context.fillStyle = "white";
        context.lineWidth = 0.5;
        context.moveTo(0, 0);
        context.fillRect(0,lineWidth/2,-distance,-lineWidth);
        context.rect(0,lineWidth/2,-distance,-lineWidth);
        context.stroke();
        context.restore();
    }
}