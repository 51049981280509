import { Injectable } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  userData: User;
  currentUserSubject: BehaviorSubject<User>;
  currentUser$: Observable<User>;
  constructor(
    private httpClient: HttpClient,
    private auth: AuthenticationService,
    ) {
      this.initUserData();
    }

    initUserData() {
      this.userData = new User();
      this.currentUserSubject = new BehaviorSubject(this.userData);
      this.currentUser$ = this.currentUserSubject.asObservable();
      let obs: Observable<any> = new Observable(observer => {
        this.requestMyProfile()
        .pipe(
          map((res: any) => {
            this.userData = res.data;
            observer.next(this.userData);
          }),
          catchError(
              (error: any) =>
              Observable.throw(error || 'Server Error'))
          )
      })
      return obs;
    }
  
    requestMyProfile():Observable<User> {
      if (this.auth.isAuthenticated()) {
        return this.httpClient.get<User>(environment.requestUrl + "/api/profile");
      }
    };

    updateProfile(userData) {
      if (this.auth.isAuthenticated()) {
        return this.httpClient.put<any>(environment.requestUrl + "/api/profile",userData);
      }
    }
  
    eventUserDataChange() {
      this.currentUserSubject.next(this.userData);
    }
  
    getMyProfile(): Observable<any> {
      let obs: Observable<any> = new Observable(observer => {
        if (this.userData && this.userData.email) {
          observer.next(this.userData);
        }
        else {
          this.requestMyProfile()
          .subscribe((res: any) => {
              this.userData = res.data;
              observer.next(this.userData)
              this.eventUserDataChange()
            })
        }
      })
      return obs;
    }

    activateUser(data: any): Observable<any> {
      return this.httpClient.patch<any>(`${environment.requestUrl}/api/profile/activate`, {...data})
    }

    setLicense(data: any): Observable<any> {
      return this.httpClient.patch<any>(`${environment.requestUrl}/api/profile/setlicense`, {...data})
    }
}
