<div *ngIf="isBrowser && isAuthenticated; else logo" class="ep-nav" fxLayout="row wrap" fxLayoutAlign="space-between">
    <div fxFlex fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon svgIcon="bars-solid"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item routerLink="/license-agreement" routerLinkActive="active">Lizenzvertrag</button>
            <button mat-menu-item routerLink="/video" routerLinkActive="active">Video</button>
            <button mat-menu-item routerLink="/tutorial" routerLinkActive="active">Anleitung</button>
            <button mat-menu-item routerLink="/knowledge" routerLinkActive="active">Wissen</button>
            <button mat-menu-item routerLink="/faq" routerLinkActive="active">FAQ</button>
            <button mat-menu-item routerLink="/documentation" routerLinkActive="active">Dokumente</button>
        </mat-menu>
        <button mat-icon-button (click)="navigateTo('projects')">
          <mat-icon svgIcon="folder-open-solid"></mat-icon>
        </button>
        <button mat-icon-button [routerLink]="['../settings']">
          <mat-icon svgIcon="cog-solid"></mat-icon>
        </button>
    </div>
    <div fxFlex fxLayout="row" style="padding-top: 10px;">
      <span class="font-light">{{ getPlanName()}}</span>
    </div>

    <div fxLayoutAlign="end center">
        <button mat-icon-button [matMenuTriggerFor]="menu2">
          <mat-icon svgIcon="user-solid"></mat-icon>
        </button>
        <mat-menu #menu2="matMenu">
          <button *ngIf="isAuthenticated" [routerLink]="['../profile']" mat-menu-item>Profil bearbeiten</button>
          <button *ngIf="!isAuthenticated" [routerLink]="['../authorization/login']" mat-menu-item>Anmelden</button>
          <button [routerLink]="['../authorization/licenseactivation']" mat-menu-item>Aktivierungscode eingeben</button>
          <button *ngIf="isAuthenticated" (click)="logOut()" mat-menu-item>Abmelden</button>
      </mat-menu>
    </div>
</div>
<ng-template #logo>
  <div class="w-100" fxLayout="row" fxLayoutAlign="center center" class="p-t-2">
    <img fxFlex="22" src="assets/images/logo.png" alt="">
  </div>
</ng-template>
