
import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { LayerService, FileService, ViewerOperationsService, TranslationService, DataService, ViewerService } from 'src/app/services';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Layer, FileInfo, Point } from 'src/app/models';
import { HttpClient } from '@angular/common/http';
import { InfoDialogComponent } from '../../dialogs/info-dialog/info-dialog.component';
import { SymbolLibraryService } from 'src/app/services/status-and-control-services/symbol-library/symbol-library.service';
import { InputDialogComponent } from '../input-dialog/input-dialog.component';
import { CanvasDimensionsService } from 'src/app/services/status-and-control-services/canvas-dimensions/canvas-dimensions.service';
import { ModesService } from 'src/app/services/status-and-control-services/modes/modes.service';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { NotificationService } from 'src/app/services/status-and-control-services/notification/notification.service';
import { Subscription, timer } from 'rxjs';
import { PlanService } from 'src/app/services/user/plan/plan.service';

@Component({
  selector: 'app-file-import-dialog',
  templateUrl: './file-import-dialog.component.html',
  styleUrls: ['./file-import-dialog.component.scss']
})
export class FileImportDialogComponent implements OnInit {
  @ViewChild('pdfViewer') viewerRef: ElementRef;
  selectedIndexes: Array<number>;
  fileInfo: FileInfo;
  cadfile: File;
  loading: boolean;
  loadingSubscription: Subscription;
  pdfSrc: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fileService: FileService,
    private planService: PlanService,
    private layerService: LayerService,
    public dialogRef: MatDialogRef<FileImportDialogComponent>,
    public httpClient: HttpClient,
    public viewerOperationsService: ViewerOperationsService,
    public viewerService: ViewerService,
    public dataService: DataService,
    public canvasDimensionsService: CanvasDimensionsService,
    public translationService: TranslationService,
    private dialog: MatDialog,
    private symbolLibraryService: SymbolLibraryService,
    private modesService: ModesService,
    private notificationService: NotificationService,

  ) {
    this.fileInfo = new FileInfo();
    this.pdfSrc = "";
    this.selectedIndexes = [];
    this.viewerService._loadingSource.next(false);
    this.loadingSubscription = this.viewerService.loading$
    .subscribe(status => {
      this.loading = status;
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.resetFileData();
  }

  handleFileInput(event) {
    let suitableCADFile: boolean = false;
    if(event.target.files.length === 1 && event.target.files[0].type) {
      suitableCADFile =
        event.target.files[0].type  === "image/vnd.dxf" ||
        event.target.files[0].type === "application/dwg" ||
        event.target.files[0].type === "application/pdf";
    }
    else if(event.target.files.length === 1 && !event.target.files[0].type) {
      let format = this.extractFileTypeFromName(event.target.files[0].name)
      suitableCADFile =
        format === "dxf" ||
        format === "dwg" ||
        format === "pdf";
    }
    if (suitableCADFile) {
      this.cadfile = event.target.files[0];
    }
    else {
      let errorData = this.translationService.translateSimpleToken("fileImport.wrongFormat");
      this.dialog.open(InfoDialogComponent,{data: {serviceErrorCode: errorData}});
      this.resetFileData();
    }
  }

  extractFileTypeFromName(FileName: string) {
    let nameParts = FileName.split(".");
    let format = nameParts[nameParts.length-1].toLowerCase();
    return format;
  }

  uploadFileToActivity() {
    this.viewerService._loadingSource.next(true);
    const formData: FormData = new FormData();
    formData.append('file', this.cadfile, this.cadfile.name);
    let count = 1;
    let subscription = timer(5000, 30000).subscribe(() => this.notificationService.openNotification("loading_progress_" + (count++%2), true,50000,{horizontalPosition: 'center'}));
    this.fileService.requestLayers(formData).subscribe(response => {
      subscription.unsubscribe();
      if(response.data && response.status === 1) {
        this.fileInfo = new FileInfo();
          this.fileInfo.filePath = response.data.filePath;
          response.data.layerNames.forEach(element => {
            if(element.layerName && element.isSelectable) {
              let newLayer = new Layer();
              newLayer.layerName = element.layerName;
              this.fileInfo.layers.push(newLayer);
            }
          });
          this.fileService.setFileName(response.data.filePath);
      }
      else if(response && response.serviceErrorCode === 7) {
        let errorData = this.translationService.translateAPIResponse({serviceErrorCode: 7, kind: "ERROR"});
        this.dialog.open(InfoDialogComponent,{data: errorData});
      }
      else {
        let errorData = this.translationService.translateAPIResponse({...response, kind: "ERROR"})
        this.dialog.open(InfoDialogComponent,{data: errorData});
        this.resetFileData();
      }
      this.viewerService._loadingSource.next(false);
      }, error => {
        subscription.unsubscribe();
        this.viewerService._loadingSource.next(false);
        //TODO: A notification is to be implemented!
      });
  }

  toggleLayerSelection(event,index) {
    if(event.checked) {
      this.selectedIndexes.push(index);
    }
    else {
      let buff = this.selectedIndexes.filter(element => element != index);
      this.selectedIndexes = buff;
    }
  }

  toggleSelectAllLayers() {
    if(this.selectedIndexes && this.selectedIndexes.length === this.fileInfo.layers.length) {
      this.selectedIndexes = new Array<number>();
    }
    else {
      this.selectedIndexes = [...Array(this.fileInfo.layers.length).keys()];
    }
  }

  generateLayerSelection(layers: Array<Layer>) {
    let layerNames = layers.map(a => a.layerName);
    let result: Array<string> = [];
    this.selectedIndexes.forEach(element => {
      result.push(layerNames[element]);
    })
    return result;
  }

  getPreviewByLayers() {
    if(this.planService.activeProjectId && this.planService.activePlanId) {
      let drawings: any[] = JSON.parse(localStorage.getItem("editorData"));
      if(drawings) {
        let drawingIndex = drawings.findIndex(drawing => drawing.planId === this.planService.activePlanId);
        if((drawingIndex && drawingIndex > -1) || drawingIndex == 0) {
          drawings.splice(drawingIndex,1);
          localStorage.setItem("editorData", JSON.stringify(drawings));
        }
      }
    }
    this.viewerService._loadingSource.next(true);
    let params = {
      FileName: this.fileInfo.filePath,
      LayerNames: this.generateLayerSelection(this.fileInfo.layers),
      IsFirstLoad:true
    }
    this.viewerService._loadingSource.next(true);
    let notification = this.notificationService.openNotification("Bearbeitung läuft. Es kann einige Minuten dauern.",false,10000,{horizontalPosition: 'center'});
    let count = 1;
    let subscription = timer(5000, 30000).subscribe(() => this.notificationService.openNotification("loading_progress_" + (count++%2), true,50000,{horizontalPosition: 'center'}));
    this.fileService.getPreviewByLayers(params).subscribe(response => {
      subscription.unsubscribe();
      if(response && response.data) {
        let layers = new Array<Layer>();
        response.data.allLayers.forEach(layer => {
          let layerBuff = new Layer();
          layerBuff.layerName = layer.layerName;
          layers.push(layerBuff);
        })
        this.handleLayerDetailResponse(response.data.allLayers, true);
        this.modesService.setMode(ActiveMode.moveMode);
        this.viewerOperationsService.toggleEdittingViewer(ActiveMode.moveMode,response.data);
        notification.dismiss();
      }
      else {
        this.layerService.initLayersFromResponse(null);
        let errorData = this.translationService.translateAPIResponse({...response, kind: "ERROR"});
        this.dialog.open(InfoDialogComponent,{data: errorData});
        this.resetFileData();
      }
      this.viewerService._loadingSource.next(false);
    }, error => {
      subscription.unsubscribe();
      this.resetFileData();
      console.log(error);
    })
  }

  handleLayerDetailResponse(layers: any, preview = false) {
    this.layerService.resetLayers();
    this.viewerOperationsService.resetFittingParams();
    this.layerService.initLayersFromResponse(layers,preview);
    if(!preview) {
      this.viewerOperationsService.fitGeometryInViewport(this.dataService.geometricalData, true);
    }
    this.dataService.emitChanges();
    this.viewerService._loadingSource.next(false);
    this.dialogRef.close();
  }

  resetFileData() {
    this.fileInfo = new FileInfo();
    this.cadfile = null;
    this.selectedIndexes = [];
    this.viewerService._loadingSource.next(false);
    this.pdfSrc = "";
  }

  onNoClick(): void {
    this.resetFileData();
    this.dialogRef.close(null);
  }

  importImage(event) {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e: any) => {
        if(file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/svg+xml") {
        var img = new Image();
        img.src = reader.result.toString();
        img.addEventListener('load',() => {
            if(this.data.actionTitle === "selectSymbol") {
              //200kB symbol file limitation
              if(file.size < 200000) {
                let dialogRefPass = this.dialog.open(InputDialogComponent, {
                  data: {
                    valueName: "Symbol Name",
                    buttonText: "Symbol hochladen"
                  }
                });
                dialogRefPass.afterClosed().subscribe(result => {
                  if(result && result.symbolName) {
                    let imageData = {
                      folderId: this.data.folderId,
                      imageName : result.symbolName,
                      imageBase64: img.src
                    }
                    this.symbolLibraryService.addImageToLibrary(imageData).subscribe((response: any) => {
                      if(response && response.status === 1) {
                        let errorData = this.translationService.translateAPIResponse({serviceErrorCode: 15, kind: "SUCCESS"});
                        this.dialog.open(InfoDialogComponent,{data: errorData});
                        this.symbolLibraryService.emitSymbolsUpdated();
                      }
                      else {
                        let errorData = this.translationService.translateAPIResponse({serviceErrorCode: 16, kind: "ERROR"});
                        this.dialog.open(InfoDialogComponent,{data: errorData});
                      }
                    });
                  }
                });
              }
              else {
                let errorData = this.translationService.translateAPIResponse({serviceErrorCode: 17, kind: "ERROR"});
                this.dialog.open(InfoDialogComponent,{data: errorData});
              }
            }
            else if(this.data.actionTitle === "selectImage") {
              let image: any = img;
              let origin = new Point(
                this.viewerOperationsService.translation.x + this.canvasDimensionsService.dimensions.width/2,
                this.viewerOperationsService.translation.y + this.canvasDimensionsService.dimensions.height/2
              )
              this.layerService.importImageToActiveLayer(image,origin).subscribe(()=> {});
            }
          this.resetFileData();
          this.dialogRef.close(null);
        });
      }
      else if(file.type === "application/pdf") {

      }
      };
      reader.onerror = (error) => {
        let errorData = this.translationService.translateAPIResponse({serviceErrorCode: 1, kind: "ERROR"});
        this.dialog.open(InfoDialogComponent,{data: errorData});
        this.resetFileData();
      };
    }
 }

 generateIamgeFromDPF() {
  let canvas = this.viewerRef.nativeElement.children[0].children[0].children[0].children[0].children[0].children[0];
  let imageBase64 = canvas.toDataURL('image/jpeg', 1.0);
  let geometry = {
    width: canvas.width,
    height: canvas.height,
    src: imageBase64,
  };
  let origin = new Point(
    this.viewerOperationsService.translation.x + this.canvasDimensionsService.dimensions.width/2,
    this.viewerOperationsService.translation.y + this.canvasDimensionsService.dimensions.height/2
  )
  this.layerService.importImageToActiveLayer(geometry,origin).subscribe(()=> {});
  this.resetFileData();
  this.dialogRef.close(null);
}

}
