import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ModesService } from '../modes/modes.service';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';

@Injectable({
  providedIn: 'root'
})
export class AdditionalToolsService {
  activeTools: string;
  additionalToolsOpen: boolean;
  additionalToolsOpen$: Observable<boolean>;
  private _additionalToolsOpenSource: BehaviorSubject<boolean>;

  constructor(
    private modeService: ModesService
  ) {
    this.activeTools = "";
    this.setupObservables();
  }

  setupObservables() {
    this.additionalToolsOpen = false;
    this._additionalToolsOpenSource = new BehaviorSubject(this.additionalToolsOpen);
    this.additionalToolsOpen$ = this._additionalToolsOpenSource.asObservable();
  }

  toggleAdditionalTools(toolsName) {
    if(toolsName !== "editor") {
      if(toolsName === this.activeTools) {
        this.additionalToolsOpen = false;
        this.activeTools = "";
        //this.modeService.setMode(ActiveMode.moveMode);
      }
      else {
        this.additionalToolsOpen = true;
        this.activeTools = toolsName;
      }
    }
    else {
      this.additionalToolsOpen = !this.additionalToolsOpen;
    }
    this.emitChanges();
  }

  closeAdditionalTools(componentName) {
    if(componentName !== this.activeTools) {
      this.activeTools = componentName;
      this.additionalToolsOpen = false;
      this.activeTools = "";
      this.emitChanges();
    }
  }
  
  emitChanges() {
    this._additionalToolsOpenSource.next(this.additionalToolsOpen);
  }

}
