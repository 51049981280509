import { Component, OnInit } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { ProfileService } from 'src/app/services/user/profile/profile.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  loading: boolean;
  profileSubscription: Subscription;
  profileData: User;
  isBrowser: boolean;
  userDataForm: UntypedFormGroup;
  constructor(
    private profileService: ProfileService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.loading = false;
    this.initProfile();
  }


  initProfile() {
    this.profileData = null;
    this.profileService.requestMyProfile().subscribe((res: any) => {
      this.profileData = res.data;
      this.userDataForm = this.formBuilder.group({
        companyName: new UntypedFormControl(
          this.profileData.companyName,
          Validators.compose([
            Validators.required,
            Validators.maxLength(200),
            Validators.minLength(3),
          ])
        ),
        salutation: new UntypedFormControl(
          this.profileData.salutation.toString(),
          Validators.compose([
            Validators.required
          ])
        ),
        firstName: new UntypedFormControl(
          this.profileData.firstName,
          Validators.compose([
            Validators.required,
            Validators.maxLength(200),
            Validators.minLength(3),
          ])
        ),
        lastName: new UntypedFormControl(
          this.profileData.lastName,
          Validators.compose([
            Validators.required,
            Validators.maxLength(200),
            Validators.minLength(3),
          ])
        ),
      })
    })
  }

  ngOnInit(): void {
  }

  fillForm() {
    this.userDataForm.get('companyName').setValue(this.profileData.companyName);
    this.userDataForm.get('salutation').setValue(this.profileData.salutation.toString());
    this.userDataForm.get('firstName').setValue(this.profileData.firstName);
    this.userDataForm.get('lastName').setValue(this.profileData.lastName);
    this.userDataForm.markAsUntouched();
  }

  onSubmit() {
    this.loading = true;
    let obj = this.userDataForm.value;
    let { passwords, ...params } = obj;
    params.salutation = parseInt(params.salutation)
    this.profileService
      .updateProfile(params)
      .subscribe(
        data => {
          this.initProfile();
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      );
  }

}
