import { Component, OnInit } from '@angular/core';
import { SelectionService } from 'src/app/services/status-and-control-services/selection/selection.service';
import { MatSelectChange } from '@angular/material/select';
import { ModesService } from 'src/app/services/status-and-control-services/modes/modes.service';
import { GeometryPropertiesService } from 'src/app/services/operational-services/geometry-properties/geometry-properties.service';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { DrawingService } from 'src/app/services/operational-services/drawing/drawing.service';
import { ControlsService } from 'src/app/services';
import { LineModeControl } from 'src/app/models/controls/line-mode.model';

@Component({
  selector: 'app-line-icon-tools',
  templateUrl: './line-icon-tools.component.html',
  styleUrls: ['./line-icon-tools.component.scss']
})
export class LineIconToolsComponent implements OnInit {
  iconSize: number;
  sizes: Array<{sizeValue:number,name:string}>;
  lineIconsList: Array<LineModeControl>;
  constructor(
    private geometryPropertiesService: GeometryPropertiesService,
    private selectionService: SelectionService,
    private modesService: ModesService,
    private controlsService: ControlsService,
    public drawingService: DrawingService

  ) {
    this.iconSize = 1;
    this.sizes = [
      {name: "small", sizeValue: 1},
      {name: "middle", sizeValue: 2},
      {name: "big", sizeValue: 3}
    ]
    this.lineIconsList = this.controlsService.lineIcons;
  }

  ngOnInit(): void {
  }

  updateStartLineIcon(event) {
    if(this.isInsertMode()) {
      this.geometryPropertiesService.setStartLineIcon({type: event.value,size: this.iconSize});
    }
    else if(this.isEditMode()) {
      this.geometryPropertiesService.updateStartIconOfSelection({type: event.value,size: this.iconSize});
    }
  }

  changeSize(size) {
    this.iconSize = size.sizeValue;
    if(this.isInsertMode()) {
      this.geometryPropertiesService.updateCurrentLineIconsSize(size.sizeValue);
    }
  }

  updateEndLineIcon(event) {
    if(this.isInsertMode()) {
      this.geometryPropertiesService.setEndLineIcon({type: event.value,size: this.iconSize});
    }
    else if(this.isEditMode()) {
      this.geometryPropertiesService.updateEndIconOfSelection({type: event.value,size: this.iconSize});
    }
  }

  singleLineSelected() {
    return this.selectionService.singleLineSelected();
  }

  isInsertMode(): boolean {
    return this.modesService.mode === ActiveMode.insertMode;
  }

  isEditMode(): boolean {
    return this.modesService.mode === ActiveMode.editMode;
  }

}
