
<div class="w-100" fxLayout="column" fxLayoutAlign="start center">
    <h3>{{ "settings.programSettings" | translate }}</h3>
    <form [formGroup]="settingsForm">
        <div class="ep-form-control-container">
            <div class="ep-form-control" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                <span>{{"settings.pixelInMeter" | translate}}</span>
                <input type="number" formControlName="pixelInMeter">
                <span>/{{this.settingsForm.value.ratio}}</span>

            </div>
            <mat-error *ngIf="settingsForm?.get('pixelInMeter').touched">
                <control-messages [control]="{controlProperties: settingsForm?.get('pixelInMeter'), controlName: 'pixelInMeter'}">
                </control-messages>
            </mat-error>
        </div>
        <div class="pixel-ratio-preview p-tb-2 bg-light">
            <span class="small-text">{{"settings.shownDimension" | translate}}</span>
            <div [ngStyle]="{'width': settingsForm?.get('pixelInMeter').value/settingsForm.value.ratio + 'px'}"></div>
        </div>
        <div class="w-100">
            <div class="ep-form-control-container">
                <div class="ep-form-control">
                    <mat-label>{{"settings.units" | translate}}</mat-label>
                    <mat-form-field>
                        <mat-select formControlName="units" [(value)]="settingsForm?.get('units').value">
                            <mat-option value=0>Millimeter</mat-option>
                            <mat-option value=1>Zentimeter</mat-option>
                            <mat-option value=2>Meter</mat-option>
                            <mat-option value=3>Pixel</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <mat-error *ngIf="settingsForm?.get('units').touched">
                <control-messages [control]="{controlProperties: settingsForm?.get('units'), controlName: 'units'}">
                </control-messages>
            </mat-error>
        </div>
        <div class="w-100">
            <div class="ep-form-control-container">
                <div class="ep-form-control">
                    <span>{{"settings.ratio" | translate}}</span>
                    <mat-form-field>
                        <mat-select formControlName="ratio" [(value)]="settingsForm?.get('ratio').value">
                            <mat-option *ngFor="let ratio of ratios; let i = index"  value="{{ratio}}">1:{{ratio}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <mat-error *ngIf="settingsForm?.get('ratio').touched">
                <control-messages [control]="{controlProperties: settingsForm?.get('ratio'), controlName: 'ratio'}">
                </control-messages>
            </mat-error>
        </div>
    </form>
    <div class="w-22" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <button mat-stroked-button (click)="onSubmit()" [disabled]="settingsForm.invalid">{{ "save" | translate }}</button>
        <button mat-stroked-button (click)="goBack()">{{ "cancel" | translate }}</button>
        <button mat-stroked-button (click)="initForm()">{{ "reset" | translate }}</button>
    </div>
</div>
