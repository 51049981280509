import { Component, OnInit } from '@angular/core';
import * as tutorialContents from '../../../assets/text-contents/tutorial.json';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from '../dialogs/image-dialog/image-dialog.component';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent implements OnInit {

  data: any;
  constructor(
    public dialog: MatDialog,

  ) {
    if(!this.data) {
      let tutorialData: any = tutorialContents;
      Object.keys(tutorialData.default).forEach(key => {
        this.data = tutorialData.default.contents;
      });
    }
  }

  ngOnInit(): void {
  }

  openPictureDialog(pic): void {
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      width: "80vw",
      data: { src: pic }
    });
  }

}
