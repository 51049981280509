import { Component, OnInit } from '@angular/core';
import { SelectionService } from 'src/app/services/status-and-control-services/selection/selection.service';
import { EventsPropagationService } from 'src/app/services/status-and-control-services/events-propagation/events-propagation.service';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { ModesService } from 'src/app/services/status-and-control-services/modes/modes.service';
import { GeometryPropertiesService } from 'src/app/services/operational-services/geometry-properties/geometry-properties.service';
import { AppSettingsService } from 'src/app/services/status-and-control-services/app-settings/app-settings.service';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services';

@Component({
  selector: 'app-circle-tools',
  templateUrl: './circle-tools.component.html',
  styleUrls: ['./circle-tools.component.scss']
})
export class CircleToolsComponent implements OnInit {
  circle: any;
  units: string;
  geomDataSubscrioption: Subscription;
  constructor(
    private selectionService: SelectionService,
    private eventsPropagationService: EventsPropagationService,
    private modesService: ModesService,
    private geometryPropertiesService: GeometryPropertiesService,
    private appSettingsService: AppSettingsService,
    private dataService: DataService

  ) {
    this.units = this.appSettingsService.activeUnitTypeName;
    this.geomDataSubscrioption = this.dataService.geometricalData$.subscribe(data => {
      this.circle = this.selectedCircle();
      if(this.circle) {
        this.circle.radius = this.appSettingsService.dimensionToDisplay(this.circle.radius);
      }
    })
  }

  ngOnInit(): void {
  }

  onBlur() {
    this.eventsPropagationService.enableEvents();
  }

  isInsertMode(): boolean {
    return this.modesService.mode === ActiveMode.insertMode;
  }

  isEditMode(): boolean {
    return this.modesService.mode === ActiveMode.editMode;
  }

  onFocus() {
    this.eventsPropagationService.disableEvents();
  }

  ngOnDestroy() {
    this.geomDataSubscrioption.unsubscribe();
    this.onBlur();
  }

  selectedCircle() {
    return this.selectionService.selectedCircle();
  }

  updateRadius() {
    let visualisationMultiplier = this.appSettingsService.getVisualisationMultiplier();
    this.geometryPropertiesService.updateRadiusOfSelection(this.circle.radius/visualisationMultiplier);
  }

}
