<div *ngIf="isBrowser" class="editor-container" fxLayout="column" fxLayoutAlign="start">
    <div class="horizontal-controls-container">
        <app-horizontal-controls></app-horizontal-controls>
    </div>
    <div fxLayout="row" fxLayoutAlign="start" fxFlex>
        <div class="vertical-controls-container">
            <app-vertical-controls></app-vertical-controls>
            <div class="additional-tools-container" *ngIf="additionalToolsOpen">
                <button mat-icon-button (click)="toggleAdditionalTools()" matTooltip="Zusatzwerkzeuge zuklappen">
                    <mat-icon svgIcon="arrow-left-solid"></mat-icon>
                </button>
                <app-additional-tools></app-additional-tools>
            </div>
        </div>
        <div class="additional-tools-toggle-button-container tools-closed" *ngIf="!additionalToolsOpen">
            <button mat-icon-button (click)="toggleAdditionalTools()" matTooltip="Zusatzwerkzeuge aufklappen">
                <mat-icon svgIcon="arrow-right-solid"></mat-icon>
            </button>
        </div>
        <div *ngIf="loading" class="viewer overlay bg-light-grey">
        </div>
        <div class="viewer" #viewer (mouseenter)="onImageDragEnter($event)" (mouseover)="onImageDrag($event)" (mouseup)="onImageDrop($event)" (dblclick)="onMouseDoubleClick($event)" fxFlex>
            <div id="editor-viewport" [ngClass]="{'no-print': demoVersion}">
                <div id="horizontal-ruler-container" #horizontalRulerContainer [class.hidden]="!rulerOn">
                    <canvas id="horizontal-ruler" #horizontalRuler></canvas>
                </div>
                <div id="vertical-ruler-container" #verticalRulerContainer [class.hidden]="!rulerOn">
                    <canvas id="vertical-ruler" #verticalRuler></canvas>
                </div>
                <div [ngClass]="{'demo-stamp': demoVersion}">
                </div>
                <canvas #cadCanvas [ngClass]="{'on_top': !editing, 'on_bg': editing, 'demo-stamp': demoVersion}" class="cad-canvas" [class.canvas-with-ruler]="rulerOn">
                    <div>Dieser browser wird nicht unterstützt.</div>
                </canvas>
                <div *ngIf="!editing">
                    <button class="absolute" mat-button (click)="resetThreecamera()">
                        <mat-icon>
                            reset_tv
                        </mat-icon>
                    </button>
                </div>
                <canvas [ngClass]="editing ? 'on_top' : 'on_bg'" [class.hidden]="!grid" [class.canvas-with-ruler]="rulerOn" class="ep-canvas" #gridCanvas></canvas>
                <canvas [ngClass]="editing ? 'on_top' : 'on_bg'" [class.canvas-with-ruler]="rulerOn" class="ep-canvas" #canvas></canvas>
            </div>
        </div>
    </div>
</div>
<div class="loading-progress" *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
    <div *ngIf="projectDrawingUpdateLogs">
        <div class="font-white percentage">
            {{projectDrawingUpdateLogs}}
        </div>
    </div>

</div>
