import { Component, OnInit } from '@angular/core';
import { ColorService } from 'src/app/services';

@Component({
  selector: 'app-special-arrow-tools',
  templateUrl: './special-arrow-tools.component.html',
  styleUrls: ['./special-arrow-tools.component.scss']
})
export class SpecialArrowToolsComponent implements OnInit {

  activeFillColor: string;
  colors: Array<{colorName:string, colorHex:string}>;

  constructor(
    private colorService: ColorService
  ) {
    this.activeFillColor = this.colorService.activeFillColor;
    this.colors = this.colorService.arrowColors;
  }

  ngOnInit(): void {
  }

  setupColor(color) {
    this.colorService.activeFillColor = color.colorHex; 
    this.colorService.activeStrokeColor = color.colorHex; 
    this.activeFillColor = color.colorName;
  }


}
