import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { jsPDF } from "jspdf";
import { PlanService } from '../../user/plan/plan.service';
import { ModesService } from '../../status-and-control-services/modes/modes.service';
import { ViewerService } from '../viewer/viewer.service';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  printState: string;
  printState$: Observable<string>;
  private _printStateSource: BehaviorSubject<string>;
  options: any;
  preview: any;
  pdf: jsPDF;

  constructor(
    private planService: PlanService,
    private viewerService: ViewerService
  ) {
    this.resetPrintSettings();
    this.setupObservables();
  }

  resetPrintSettings() {
    this.resetPreview();
    this.resetOptions();
  }

  resetPreview() {
    this.preview = {
      fullImage: "",
      croppedImage: null,
      width: null,
      height: null,
      name: ""
    };
  }

  resetOptions() {
    this.options = {
      format: "a4",
      orientation: "l",
      edges: false,
      gridOn: false,
      backgroundOn: false,
      printQuality: 1,
      frameOn: false
    }
  }

  setEdges(value) {
    this.options.edges = value;
    //this.emitPrintEvent("setup");
  }

  updateImage(params: {blob: Blob, dimensions: {width: number, height: number}}) {
    return this.convertBlobToBase64(params.blob).then((base64) => {
      this.preview.croppedImage = base64;
      this.updatePreviewImage(params.dimensions);
      return base64;
    }).catch((err) => {
      console.error(err);
      return null;
    });
  }

  setupObservables() {
    this.printState = "";
    this._printStateSource = new BehaviorSubject(this.printState);
    this.printState$ = this._printStateSource.asObservable();
  }

  emitPrintEvent(state) {
    this.printState = state;
    this._printStateSource.next(this.printState);
    this.printState = "";
    this._printStateSource.next(this.printState);

  }

  updateOption(optionName: string, value) {
    let obs = new Observable<any>(observer => {
      this.options[optionName] = value;
      observer.next(this.options[optionName]);
    });
    return obs;
  }

  printContents() {
    if(this.planService.activePlanId) {
        if(this.planService.drawingName) {
          this.pdf.save(this.planService.drawingName + ".pdf");
        }
        else {
          this.pdf.save("plan.pdf");
        }
    }
    else {
      this.pdf.save("plan.pdf");
    }
  }

  saveForPrint() {
    this.options.edges = false;
    this.emitPrintEvent("printing");
    this.printContents();
  }

  savePreview(context, canvas, fileName) {
    this.preview.name = fileName;
    if(this.options.edges) {
      context.fillStyle = "red";
      context.fillRect(10, 10, 60, 10);
      context.fillRect(10, 10, 10, 60);
      context.fillRect(canvas.width - 80, 10, 60, 10);
      context.fillRect(canvas.width - 20, 10, 10, 60);
      context.fillRect(10, canvas.height - 20, 60, 10);
      context.fillRect(10, canvas.height - 80, 10, 60);
      context.fillRect(canvas.width - 80, canvas.height - 20, 60, 10);
      context.fillRect(canvas.width - 20, canvas.height - 70, 10, 60);
    }
    /* if (this.options.frame) {

    } */
    this.generatePreview(canvas);
  }

  generatePreview(canvas: HTMLCanvasElement) {
    this.preview.fullImage = canvas.toDataURL();
    this.preview.width = canvas.width;
    this.preview.height = canvas.height;
    this.updatePreviewImage({width: canvas.width, height: canvas.height});
  }

  updatePreviewImage(dimensions?: { width: number, height: number }, toUpdate: boolean = false) {
    this.pdf = new jsPDF(this.options.orientation, 'mm', this.options.format, true);
    const pageWidth = this.pdf.internal.pageSize.getWidth() * 4;
    const pageHeight = this.pdf.internal.pageSize.getHeight() * 4;

    let width = this.preview.croppedImage ? dimensions.width : this.preview.width;
    let height = this.preview.croppedImage ? dimensions.height : this.preview.height;
    const aspectRatio = width / height;

    if (width > pageWidth) {
      width = pageWidth;
      height = width / aspectRatio;
    }

    if (height > pageHeight) {
      height = pageHeight;
      width = height * aspectRatio;
    }

    const x = (pageWidth - width) / 2;
    const y = (pageHeight - height) / 2;

    this.preview.width = width / 4;
    this.preview.height = height / 4;

    const img = (!toUpdate && this.preview.croppedImage) ? this.preview.croppedImage : this.preview.fullImage;
    this.pdf.addImage(img, 'SVG', x / 4, y / 4, width / 4, height / 4, '', 'SLOW');
    this.viewerService._loadingSource.next(false);
  }

  get formatMultiplier(): number {
    switch (this.options.format) {
      case "a3" : return 2;
      case "a2" : return 4;
      default : return 1;
    }
  }

  convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = () => {
        reject('Could not convert Blob to Base64');
      };
      reader.readAsDataURL(blob);
    });
  }

}
