import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { FitAreaData, Layer, Point } from 'src/app/models';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { Extrema } from 'src/app/models/extrema.model';
import { Geometry } from 'src/app/models/geometries/geometry.model';
import { ModesService } from '../../status-and-control-services/modes/modes.service';
import { NotificationService } from '../../status-and-control-services/notification/notification.service';
import { PlanService } from '../../user/plan/plan.service';
import { ProjectService } from '../../user/project/project.service';
import { DataService } from '../data/data.service';
import { FileService } from '../file/file.service';
import { LayerService } from '../layer/layer.service';
import { ViewerService } from '../viewer/viewer.service';
import { CanvasDimensionsService } from 'src/app/services/status-and-control-services/canvas-dimensions/canvas-dimensions.service';
import { Subject } from 'rxjs/internal/Subject';
import { GeometricType } from 'src/app/models/enums/geometric-types.enum';

@Injectable({
  providedIn: 'root'
})
export class ViewerOperationsService {
  viewerExtrema: Extrema;
  viewerMultiplier: number;
  fittedLayerNames: string[];

  geometryFitted: boolean;
  localFitted: boolean = false;
  scaleFillY: number;
  translateY: number;
  fittedTimes: number;
  translation: Point;
  viewportCenter: Point;
  translated: boolean;
  _fitAreaOperationSource: BehaviorSubject<FitAreaData>;
  fitAreaOperation$: Observable<FitAreaData>;
  previewEvent = new Subject<boolean>();
  editing: boolean;
  _edittingSource: BehaviorSubject<boolean>;
  editting$: Observable<boolean>;
  initialPosition: Point;
  scaleFactor: number;
  initialScaleFactor: number;
  activeMode: ActiveMode;
  lastGetCall: number;
  constructor(
    private viewerService: ViewerService,
    private dataService: DataService,
    private layerService: LayerService,
    private fileService: FileService,
    private planService: PlanService,
    private projectService: ProjectService,
    private notificationService: NotificationService,
    private canvasDimensionsService: CanvasDimensionsService,
    private modesService: ModesService
  ) {
    this.lastGetCall = new Date().getTime();
    this.translation = new Point(0,0);
    this.geometryFitted = false;
    this._fitAreaOperationSource = new BehaviorSubject(new FitAreaData());
    this.fitAreaOperation$ = this._fitAreaOperationSource.asObservable();
    this.editing = false;
    this._edittingSource = new BehaviorSubject(this.editing);
    this.editting$ = this._edittingSource.asObservable();
    this.viewerMultiplier = 1;
    this.scaleFactor = 1;
    this.initialScaleFactor = 1;
    this.fittedLayerNames = [];
  }

  toggleEdittingViewer(mode: ActiveMode, drawing = null) {
    let emitted = false;
    this.viewerService._loadingSource.next(true);
    this.editing = this.modesService.editableMode ? true : false;
    if(mode === ActiveMode.editMode) {
      this.getByLocation().subscribe(() => {});
    }
    else if(mode === ActiveMode.frameMode){
      this.getByLocation().subscribe(() => {
        setTimeout(() => {
          let callTime = new Date().getTime();
          if(!emitted && callTime - this.lastGetCall > 1000) {
            this.previewEvent.next(true);
            emitted = true;
          }
        },1100);
      });
    }
    else {
      this.dataService.convertSpecial(this.planService.activePlanId, 1/this.viewerMultiplier).subscribe(() => {
      this.scaleGeometry(this.dataService.geometricalData,1/this.viewerMultiplier,false);
      this.dataService.geometricalData.forEach((layer: Layer, indexLayer: number) => {
        layer.geometry.forEach((geometryItem: Geometry, indexGeometryItem: number) => {
          if(geometryItem.title && geometryItem.title === 'previewimage') {
            layer.geometry.splice(indexGeometryItem,1);
          }
        });
      });
      this.dataService.filterSpecialGeometries(1/this.viewerMultiplier).subscribe(() => {
          this.dataService.mirrorAllToXAxis().subscribe(() => {
            this.planService.savePlanAction(
              this.projectService.activeProjectId,
              this.dataService.deletedEntityNames,
              this.fileService.dxfFilePath,
              this.dataService.newOrUpdatedEntities,
              this.dataService.layerNames).subscribe(() => {
                this.notificationService.openNotification("save_plan_success");
                if(this.editing) {
                  this.viewerService._loadingSource.next(false);
                }
              },
            error => {
              this.notificationService.openNotification("save_plan_error");
              this._edittingSource.next(this.editing);
              if(this.editing) {
                this.viewerService._loadingSource.next(false);
              }
            });
          })
        })

      })
    }
    if(drawing && mode === ActiveMode.editMode) {
      if(drawing && drawing.filePath)  {
        this.fileService.setFileName(drawing.filePath);
      }
    }
    this._edittingSource.next(this.editing);
  }

  getByLocation(): Observable<any> {
    this.fittedLayerNames = [];
    let obs: Observable<any> = new Observable(observer => {
      if(this.modesService.editableMode) {
        let drawingToMap = null;
        let drawings: any[] = JSON.parse(localStorage.getItem("editorData"));
        if(drawings && drawings.length) {
          let drawingIndex = drawings.findIndex(drawing => drawing.planId === this.planService.activePlanId);
          if((drawingIndex && drawingIndex > -1) || drawingIndex == 0) {
            drawingToMap = drawings[drawingIndex];
          }
        }
        this.getDxfLayerDetailByLocation(drawingToMap).subscribe(() => {});
      }
    });
    return obs;
  }

  getDxfLayerDetailByLocation(toMap = null): Observable<any> {
    let obs: Observable<any> = new Observable(observer => {
      this.fileService.getDxfLayerDetailByLocation(this.viewerExtrema,
        (this.layerService.layerNamesToRender.length > 1 ||
          this.layerService.layerNamesToRender[0] !== "Background") ?
          this.layerService.layerNamesToRender : null,
          toMap).subscribe(response => {
        if(response && response.length) {
          this.dataService.initGeometricalDataFromResponse(response, false, toMap).subscribe(res => {
            if(!res) {
              this.modesService.setMode(ActiveMode.moveMode);
              this.editing = false;
              this.viewerService._loadingSource.next(false);
              observer.error("no geometries");
            }
            else {
              if(res.amount.total > 10000 || res.amount.perLayer > 10000) {
                this.planService.emitError("TOO_MUCH_ELEMENTS");
              }
              this.resetFittingParams();
              setTimeout(() => {
                this.fitGeometryInViewport(this.dataService.geometricalData, true);
              },1100);
              let callTime = new Date().getTime();
              this.lastGetCall = callTime - this.lastGetCall;
              this.dataService.emitChanges();
              this.viewerService._loadingSource.next(false);
              observer.next(true);
            }
            this.viewerService._loadingSource.next(false);
            this._edittingSource.next(this.editing);
          });
        }
        else {
          this.layerService.initLayers().subscribe(res=>{});
          this.editing = true;
          this.viewerService._loadingSource.next(false);
          this._edittingSource.next(this.editing);
        }
      })
    })
    return obs;
  }

  roundUpCoords() {
    this.dataService.geometricalData.forEach(layer => layer.geometry.forEach(geom => geom.roundUpCoords()))
  }

  resetMultipliers() {
    this.initialPosition = null;
    this.initialScaleFactor = 1;
  }

  setupViewerExtrema(box, viewportCenter: Point, multiplier: number, offset: Point) {
    this.viewerExtrema = box;
    this.viewerMultiplier = multiplier;
    this.viewportCenter = viewportCenter;
    this.translation.x = (offset && (offset.x || offset.y)) ? Math.round(offset.x) : this.translation.x;
    this.translation.y = (offset && (offset.x || offset.y)) ? Math.round(offset.y) : this.translation.y;
    this.planService.setActiveDrawingScale(multiplier);
    // const xVal = offset.x;
    // const yVal = offset.y;
    // this.initialPosition = new Point(xVal, yVal);
    if(!this.initialPosition) {
      this.initialPosition = offset;
    }
  }

  resetFittingParams() {
    this.geometryFitted = false;
    this.fittedTimes = 0;
    this.viewerService.setScale(1);
    this.localFitted = false;
  }

  normalizeCoordinates(context,canvas) {
    this.scaleFillY = -1
    this.translateY = canvas.clientHeight;
    context.setTransform(1, 0, 0, this.scaleFillY, 0, this.translateY);
  }

  placeText(text, x, y, context, canvas, scale) {
    context.save();
		context.setTransform(1, 0, 0, 1, x*scale, y*scale);
		context.fillText(text, 0, 0);
		context.restore();
  }

  findGlobalExtrema(layers: Array<Layer>, exceptionLayerNames: string[] = null, withoutTranslation: boolean = false) {
    if(layers.length) {
      let firstGeometry: Geometry = null;
      for(let i = 0; i < layers.length; i++) {
        if(layers[i].geometry.length && layers[i].isVisible) {
          firstGeometry = layers[i].geometry[0];
          break;
        }
      }
      if(firstGeometry) {
        let globalExtrema = firstGeometry.extrema();
      layers.forEach(layer=> {
        if(layer.isVisible && ((exceptionLayerNames && exceptionLayerNames.length && (!exceptionLayerNames.includes(layer.layerName)) || (!exceptionLayerNames || !exceptionLayerNames.length))) ) {
          layer.geometry.forEach(geometricDetails => {
              const extrema = geometricDetails.extrema();
              if(extrema && (extrema.minX || extrema.minX === 0)) {
                if(globalExtrema.minX > extrema.minX) globalExtrema.minX = extrema.minX;
                if(globalExtrema.minY > extrema.minY) globalExtrema.minY = extrema.minY;
                if(globalExtrema.maxX < extrema.maxX) globalExtrema.maxX = extrema.maxX;
                if(globalExtrema.maxY < extrema.maxY) globalExtrema.maxY = extrema.maxY;
              }
          })
        }
      });
      if(withoutTranslation) {
        globalExtrema.minX -= this.translation.x;
        globalExtrema.maxX -= this.translation.x;
        globalExtrema.minY -= this.translation.y;
        globalExtrema.maxY -= this.translation.y;
      }

      return globalExtrema;
      }
      else return null;
    }
  }

  fitGeometryInViewport(geometryLayers: Array<Layer>, fromResponse: boolean = false) {
    if(!this.geometryFitted && geometryLayers && geometryLayers.length) {
      if(fromResponse && this.viewerMultiplier === 1 && this.initialScaleFactor === 1) {
        let extrema: Extrema = this.findGlobalExtrema(geometryLayers);
        let offsetY = extrema.maxY > 0 ? extrema.maxY : extrema.minY;
        this.moveGeometry(geometryLayers,{x: 0, y: -offsetY});
        extrema = this.findGlobalExtrema(geometryLayers);
      }
      while(!this.geometryFitted && this.fittedTimes < 4){
        this.fit(geometryLayers)
        this.fittedTimes += 1;
      }
      this.dataService.emitChanges();
      return geometryLayers;
    }
    else {
      return null;
    }
  }

  fitAreaInViewport() {
    let fitData: FitAreaData = new FitAreaData();
    let scale = this.viewerService.scale;
    let extrema = this.selectionExtrema;
    if(extrema && extrema.maxY && extrema.maxX) {
      fitData.width = (extrema.maxX - extrema.minX);
      fitData.height = (extrema.maxY - extrema.minY);
      fitData.point = new Point(Math.floor(extrema.minX), Math.floor(extrema.minY));
      let dimensions = {clientWidth: (window.innerWidth*scale - 60) , clientHeight: (window.innerHeight*scale - 130)};
      let scaleMultiplier = Math.round(this.calculateGeometryScaleMultiplier(fitData.height,fitData.width, dimensions) * 1000) / 1000;
      this.viewerService.setScale(scaleMultiplier > 1 ? scaleMultiplier/1.2 : scaleMultiplier);
      extrema = this.selectionExtrema;
      fitData.point.x = extrema.minX - (window.innerWidth - 60)/2 + (extrema.maxX - extrema.minX)/2;
      fitData.point.y = extrema.minY - (window.innerHeight - 130)/2 + (extrema.maxY - extrema.minY)/2;
      this._fitAreaOperationSource.next(fitData);
    }
  }

  get selectionExtrema(): Extrema {
    let scale = this.viewerService.scale;
    let dimensions = {clientWidth: (window.innerWidth*scale - 60) , clientHeight: (window.innerHeight*scale - 130)};
    let extrema : Extrema = this.dataService.getSelectionExtrema(this.layerService.activeLayer);
    if(extrema && extrema.maxY && extrema.maxX) {
      let maxBuff = extrema.maxY;
      extrema.maxY = dimensions.clientHeight - extrema.minY*scale;
      extrema.minY = dimensions.clientHeight - maxBuff*scale;
      extrema.minX *= scale;
      extrema.maxX *= scale;
      return extrema
    }
  }

  fit(geometry: Array<Layer>) {
    let dimensions = this.canvasDimensionsService.scaledCanvasDimensions(1/this.scaleFactor);
    if(this.viewerMultiplier && this.viewerExtrema && this.viewerExtrema.minX) {
      let extrema = this.findGlobalExtrema(geometry);
      this.scaleGeometry(geometry,this.viewerMultiplier,true,true);
      extrema = this.findGlobalExtrema(geometry);
      this.geometryFitted = true;
    }
    else {
      let geometryExtrema = this.findGlobalExtrema(geometry);
      geometryExtrema = this.moveGlobalExtremaToOrigin(geometryExtrema);
      let multiplier = 1;
      if(!this.geometryFitsIntoViewport(geometryExtrema,dimensions)) {
        multiplier = this.calculateGeometryScaleMultiplier(geometryExtrema.maxY, geometryExtrema.maxX, dimensions)
      }
      if(multiplier > 1.27 || multiplier < 0.73) {
        this.executeFittingOperations(geometry, multiplier);
      }
      geometryExtrema = this.findGlobalExtrema(geometry);
      this.moveGeometryToOrigin(geometry, geometryExtrema);
      if(this.geometryFitsIntoViewport(geometryExtrema,dimensions) || this.fittedTimes === 4) {
        this.geometryFitted = true;
      }
    }
  }

  moveGeometryToOrigin(geometry: Array<Layer>, globalExtrema) {
    let offset = {x:null,y:null};
    offset.x = (globalExtrema.minX /* - 20 */);
    offset.y = (globalExtrema.minY /* - 20 */);
    this.moveGeometry(geometry,offset);
  }

  moveGeometry(geometryLayers: Array<Layer>,offset: Point,scaleForImg=null) {
    geometryLayers.forEach(layer => {
      layer.geometry.forEach(geometry => {
        if(scaleForImg && geometry.geometricType === GeometricType.Image) {
          geometry.move({x: -offset.x*scaleForImg, y: -offset.y*scaleForImg});
        }
        else {
          geometry.move(offset);
        }
      })
    })
  }

  moveGlobalExtremaToOrigin(extrema) {
    extrema.maxX -= (extrema.minX - 20);
    extrema.minX -= (extrema.minX - 20);
    extrema.maxY -= (extrema.minY - 20);
    extrema.minY -= (extrema.minY - 20);
    return extrema;
  }

  executeFittingOperations(geometry: Array<Layer>, multiplier) {
    this.scaleGeometry(geometry,multiplier);
  }

  scaleGeometry(geometry: Array<Layer>, multiplier, fromStream = true, scaleText = false, ignoreImages = false) {
    geometry.forEach(layer => {
      if(this.fittedLayerNames.findIndex(e => e === layer.layerName) < 0 || !fromStream) {
        layer.geometry.forEach(geometry => {
          if(this.dataService.geometryWithText(geometry.geometricType) ) {
            geometry.scale({x:multiplier, y:multiplier},null,scaleText);
          }
          else if(ignoreImages && geometry.geometricType === GeometricType.Image) {
            geometry.scale({x:1, y:1},1);
          }
          else if(geometry.geometryProperties.thickness && geometry.geometryProperties.thickness != 1) {
            let scale = this.planService.getActiveDrawingInitScale();
            geometry.scale({x:multiplier, y:multiplier},null, multiplier != this.viewerMultiplier ? 1/scale : scale);
          }
          else {
            geometry.scale({x:multiplier, y:multiplier});
          }
        })
        this.fittedLayerNames.push(layer.layerName);
      }
    });
  }

  geometryFitsIntoViewport(extrema,dimensions) {
    if((extrema.maxY > dimensions.height/1.5 || extrema.maxX > dimensions.width/1.5) && extrema.maxY <= dimensions.height - 30 && extrema.maxX <= dimensions.width - 50) {
      return true;
    }
    else {
      return false;
    }
  }

  calculateGeometryScaleMultiplier(maxY, maxX, dimensions) {
    let multiplyier = Math.min((dimensions.width - 50) / maxX,(dimensions.height - 30) / maxY)
    return multiplyier;
  }

  controlActive(activeOn: number[]) {
    if(activeOn[0] < 5) {
      return activeOn.includes(this.modesService.mode);
    }
    else {
      return true;
    }
  }

}
