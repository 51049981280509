import { Layer } from '../layer.model';

export class Plan {
    id: string;
    projectId: number;
    name: string;
    geometricalData: Array<Layer>;
    drawingDxfPath?: string;
    drawingJsonPath?: string;
    drawingName?: string;

    constructor() {
        this.geometricalData = new Array<Layer>();
    }
}