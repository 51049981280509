import { Component, OnInit } from '@angular/core';
import { EditorControl } from 'src/app/models/controls';
import { ControlsService, ViewerOperationsService } from 'src/app/services';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { SelectionService } from 'src/app/services/status-and-control-services/selection/selection.service';
import { GeometryEditingService } from 'src/app/services/operational-services/geometry-editing/geometry-editing.service';
import { ControlActiveSettingEnum } from 'src/app/models/enums/control-active-setting.enum';

@Component({
  selector: 'app-hor-editing-controls',
  templateUrl: './editing-controls.component.html',
  styleUrls: ['./editing-controls.component.scss']
})
export class EditingControlsComponent implements OnInit {
  controlItems: Array<EditorControl>;
  constructor(
    private controlsService: ControlsService,
    private dialog: MatDialog,
    private selectionService: SelectionService,
    private geometryEditingService: GeometryEditingService,
    private viewerOperationsService: ViewerOperationsService
  ) {
    this.controlItems = this.controlsService.controls.horizontalControls.editingControlsList;
  }

  ngOnInit(): void {
  }

  onButtonClick(controlsItem: EditorControl) {
    switch(controlsItem.id) {
      case 5: {
        return this.confirmRemoveSelection();
      }
      case 4: {
        return this.copySelection();
      }
      default: return
    }
  }

  get selected() {
    return this.selectionService.selectedElementsExist();
  }

  variableFromComponent(controlItem: EditorControl) {
    return this[controlItem.varName];
  }

  confirmRemoveSelection() {
    if(this.selected) {
      let dialogRefPass = this.dialog.open(ConfirmationDialogComponent,{
        data: {
          question: "delete_selection"
        }
      });
      dialogRefPass.afterClosed().subscribe(result => {
        if(result) {
          this.geometryEditingService.removeSelection();
        }
      });
    }
  }

  copySelection() {
    this.geometryEditingService.copySelection();
  }

  controlActive(activeOn) {
    let result = this.viewerOperationsService.controlActive(activeOn);
    return result;
  }


}
