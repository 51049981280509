import { Component, OnInit } from '@angular/core';
import { SelectionService } from 'src/app/services/status-and-control-services/selection/selection.service';
import { EventsPropagationService } from 'src/app/services/status-and-control-services/events-propagation/events-propagation.service';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { ModesService } from 'src/app/services/status-and-control-services/modes/modes.service';
import { GeometryPropertiesService } from 'src/app/services/operational-services/geometry-properties/geometry-properties.service';
import { AppSettingsService } from 'src/app/services/status-and-control-services/app-settings/app-settings.service';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-rect-tools',
  templateUrl: './rect-tools.component.html',
  styleUrls: ['./rect-tools.component.scss']
})
export class RectToolsComponent implements OnInit {
  sideRatioLocked: boolean;
  rect: any;
  units: string;
  geomDataSubscrioption: Subscription;
  percentageForm: UntypedFormGroup;

  constructor(
    private selectionService: SelectionService,
    private eventsPropagationService: EventsPropagationService,
    private modesService: ModesService,
    private geometryPropertiesService: GeometryPropertiesService,
    private appSettingsService: AppSettingsService,
    private dataService: DataService,
    private formBuilder: UntypedFormBuilder

  ) {
    this.sideRatioLocked = this.geometryPropertiesService.sideRatioLocked;
    this.units = this.appSettingsService.activeUnitTypeName;
    this.geomDataSubscrioption = this.dataService.geometricalData$.subscribe(data => {
      let rotatable = this.selectedRotatable();
      if(rotatable) {
        this.rect = {...rotatable};
      }
      if(this.rect) {
        this.rect.width = this.appSettingsService.dimensionToDisplay(this.rect.width);
        this.rect.height = this.appSettingsService.dimensionToDisplay(this.rect.height);
        this.rect.rotation = Math.round(this.rect.rotation * 1000) / 1000;
      }
    });
    this.initPercentageForm(100);
  }

  initPercentageForm(value) {
    this.percentageForm = this.formBuilder.group({
      width: new UntypedFormControl(value),
      height: new UntypedFormControl(value),
    })
  }

  ngOnInit(): void {
  }

  toggleSideRatioLock() {
    this.geometryPropertiesService.toggleSideRatioLock();
    this.sideRatioLocked = this.geometryPropertiesService.sideRatioLocked;
    this.initPercentageForm(100);
  }

  onBlur() {
    this.eventsPropagationService.enableEvents();
  }

  isInsertMode(): boolean {
    return this.modesService.mode === ActiveMode.insertMode;
  }

  isEditMode(): boolean {
    return this.modesService.editableMode;
  }

  onFocus() {
    this.eventsPropagationService.disableEvents();
  }

  ngOnDestroy() {
    this.geomDataSubscrioption.unsubscribe();
    this.onBlur();
  }

  selectedRotatable() {
    return this.selectionService.getSelectedRotatableGeometry();
  }

  updateWidth() {
    let visualisationMultiplier = this.appSettingsService.getVisualisationMultiplier();
    let width = Math.round(this.rect.width/visualisationMultiplier * 1000) / 1000;
    if(this.sideRatioLocked) {
      let originalWidth = this.selectedRotatable().width;
      let originalHeight = this.selectedRotatable().height;
      let proportionMultiplier = width/originalWidth;
      this.geometryPropertiesService.updateWidthOfSelection(width);
      this.geometryPropertiesService.updateHeightOfSelection(Math.round(originalHeight*proportionMultiplier * 1000) / 1000);
    }
    else {
      this.geometryPropertiesService.updateWidthOfSelection(width);

    }
  }

  updateHeight() {
    let visualisationMultiplier = this.appSettingsService.getVisualisationMultiplier();
    let height = this.rect.height/visualisationMultiplier;
    if(this.sideRatioLocked) {
      let originalWidth = this.selectedRotatable().width;
      let originalHeight = this.selectedRotatable().height;
      let proportionMultiplier = height/originalHeight;
      this.geometryPropertiesService.updateHeightOfSelection(height);
      this.geometryPropertiesService.updateWidthOfSelection(originalWidth*proportionMultiplier);
    }
    else {
      this.geometryPropertiesService.updateHeightOfSelection(height);
    }
  }

  updateRotation() {
    this.geometryPropertiesService.updateRotationOfSelection(this.rect.rotation);
  }

  submitPercentage() {
    let formValue = this.percentageForm.value;
    this.rect.height *= formValue.height/100;
    this.updateHeight();
    this.rect.width *= formValue.width/100;
    this.updateWidth();
    this.initPercentageForm(100);
  }

}
