<div class="additional-tools-element">
    <p>Gruppierung Einstellungen</p>
    <div class="p-tb" fxLayout="row">
        <div fxFlex fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px">
            <button mat-icon-button (click)="groupSelection()" [disabled]="!selectionExists()" matTooltip="Auswahl gruppieren">
                <mat-icon svgIcon="object-group-regular" [ngClass]="{'font-light-greyblue': !selectionExists()}"></mat-icon>
            </button>
            <button mat-icon-button (click)="ungroupSelection()" [disabled]="!selectionExists()" matTooltip="Gruppe löschen">
                <mat-icon svgIcon="object-ungroup-regular" [ngClass]="{'font-light-greyblue': !selectionExists()}"></mat-icon>
            </button>
        </div>
    </div>
</div>
