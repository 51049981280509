<div mat-dialog-content fxLayout="column" *ngIf="!fileInfo.filePath; else layerSelection">
    <h2>{{"fileImport." + data.actionTitle | translate }}</h2>
    <h3 class="no-m">Schritt 1</h3>
    <span class="m-tb">{{"fileImport." + data.actionSubtitle | translate }}</span>
    <div class="m-tb-2">
        <input type="file" name="file" (change)="data.actionTitle === 'selectCAD' && handleFileInput($event)" (change)="(data.actionTitle === 'selectImage' || data.actionTitle === 'selectSymbol') && importImage($event)"/>
    </div>
    <button mat-flat-button class="ep-btn-call" *ngIf="pdfSrc" (click)="generateIamgeFromDPF()">Importieren</button>
</div>
<ng-template #layerSelection>
    <div mat-dialog-content>
        <h2>Ebenen auswählen</h2>
        <h3 class="no-m">Schritt 2</h3>
        <span class="m-tb">Bitte wählen Sie die gewünschten Ebenen aus der Zeichnung an.</span>
        <div class="p-tb" fxLayout="column">
            <mat-checkbox [checked]="selectedIndexes.length === fileInfo.layers.length" (change)="toggleSelectAllLayers()">
                Alle auswählen
            </mat-checkbox>
            <mat-divider></mat-divider>
            <mat-checkbox *ngFor="let element of fileInfo.layers; let i = index" (change)="toggleLayerSelection($event,i)" [checked]="selectedIndexes.includes(i)">
              {{element.layerName}}
            </mat-checkbox>
        </div>
    </div>
</ng-template>
<div mat-dialog-actions>
    <button mat-flat-button class="ep-btn-call" [disabled]="(!cadfile && !fileInfo.filePath) || loading" (click)="fileInfo.filePath && getPreviewByLayers()" (click)="!fileInfo.filePath && uploadFileToActivity()">
        <span *ngIf="!fileInfo.filePath">
            <span *ngIf="loading">
             wird geladen...
             <img class="loading-animation"/>
            </span>
            <span *ngIf="!loading">
             Datei importieren
            </span>
         </span>
       <span *ngIf="fileInfo.filePath">Layers Anzeigen
        <span *ngIf="loading">
            <img class="loading-animation"/>
        </span>
       </span> 
    </button>
    <button mat-stroked-button class="ep-btn-system" (click)="(!cadfile && !fileInfo.filePath) && onNoClick()" (click)="(cadfile || fileInfo.filePath) && resetFileData()">Abbrechen</button>
</div>

<div class="pdf-container" #pdfViewer>
    <pdf-viewer [src]="pdfSrc" [render-text]="true" [page]="1" [show-all]="false" [original-size]="true"
        [fit-to-page]="false" style="display: block;">
    </pdf-viewer>
</div>