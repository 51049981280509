<div *ngIf="this.profileData" class="full-height-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="40px">
    <div></div>
    <form class="ep-single-form" [formGroup]="userDataForm" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <h3>Ihr Profil</h3>
        <span>Hier können Sie Ihr Profil bearbeiten</span>
        <div class="ep-form-control-container">
            <div class="ep-form-control">
                <span>Firma</span>
                <input type="text" formControlName="companyName">
            </div>
            <mat-error *ngIf="userDataForm?.get('companyName').touched">
                <control-messages [control]="{controlProperties: userDataForm?.get('companyName'), controlName: 'companyName'}">
                </control-messages>
            </mat-error>
        </div>
        <div class="w-100">
            <div class="ep-form-control-container">
                <div class="ep-form-control">
                    <span>Anrede</span>
                    <select formControlName="salutation" [(value)]="userDataForm?.get('salutation').value">
                        <option value=1>Herr</option>
                        <option value=2>Frau</option>
                        <option value=3>Divers</option>
                    </select>                
                </div>
            </div>    
            <mat-error *ngIf="userDataForm?.get('salutation').touched">
                <control-messages [control]="{controlProperties: userDataForm?.get('salutation'), controlName: 'salutation'}">
                </control-messages>
            </mat-error>
        </div>
        <div class="ep-form-control-container">
            <div class="ep-form-control">
                <span>Vorname</span>
                <input type="text" formControlName="firstName">
            </div>
            <mat-error *ngIf="userDataForm?.get('firstName').touched">
                <control-messages [control]="{controlProperties: userDataForm?.get('firstName'), controlName: 'firstName'}">
                </control-messages>
            </mat-error>
        </div>
        <div class="ep-form-control-container">
            <div class="ep-form-control">
                <span>Nachname</span>
                <input type="text" formControlName="lastName">
            </div>
            <mat-error *ngIf="userDataForm?.get('lastName').touched">
                <control-messages [control]="{controlProperties: userDataForm?.get('lastName'), controlName: 'lastName'}">
                </control-messages>
            </mat-error>
        </div>
    </form>
    <div class="w-22" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <button mat-flat-button class="ep-btn-call" (click)="onSubmit()" [disabled]="loading || userDataForm.invalid">{{ "save" | translate }}</button>
        <button mat-stroked-button (click)="fillForm()">{{ "cancel" | translate }}</button>
    </div>
</div>