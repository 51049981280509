import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsPropagationService {
  eventsDisabled: boolean;
  eventsDisabled$: Observable<boolean>;
  private _eventsDisabledSource: BehaviorSubject<boolean>;

  constructor() {
    this.eventsDisabled = false;
    this._eventsDisabledSource = new BehaviorSubject(this.eventsDisabled);
    this.eventsDisabled$ = this._eventsDisabledSource.asObservable();
  }

  disableEvents() {
    this.eventsDisabled = true;
    this.emitChanges();
  }

  emitChanges() {
    this._eventsDisabledSource.next(this.eventsDisabled);
  }

  enableEvents() {
    this.eventsDisabled = false;
    this.emitChanges();
  }

}
