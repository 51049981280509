import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Project } from 'src/app/models/project/project.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { isPlatformBrowser } from "@angular/common";
import { PlanService } from '../plan/plan.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  activeProjectId: string;
  projects: Array<Project>;
  projectsSubject: BehaviorSubject<Array<Project>>;
  projects$: Observable<Array<Project>>;
  projectsInitialized: boolean;
  constructor(
    private httpClient: HttpClient,
    private planService: PlanService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.projects = new Array<Project>();
    this.projectsSubject = new BehaviorSubject(this.projects);
    this.projects$ = this.projectsSubject.asObservable();
  }

  getMyProjects() {
    return this.httpClient.get<Array<Project>>(
      environment.requestUrl + "/api/project"
    );
  }

  setActiveProjectId(id) {
    this.activeProjectId = id;
    this.planService.activeProjectId = id;
  }

  updateProjectsState() {
    return this.getMyProjects().subscribe((response:any) => {

      let orders = JSON.parse(localStorage.getItem("order"))
      this.projects = (response && response.data && response.data.projects) ?  response.data.projects : response;
      this.projects.forEach(project => {
        this.getProjectById(project.id).subscribe((response:any) => {
          if(response && response.data) {
            project.creator = response.data.creator;
            project.client = response.data.creator;
            project.detail = response.data.creator;
          }
        })
      })
      this.projects = this.sortAlphabeticallyByParameter(this.projects, "name");
      this.projects.forEach(project => {
        if(project.projectDrawings.length) {
          if(orders && orders.length) {
            let projectOrder = orders.find(order => order.id === project.id);
            if(projectOrder) {
              let plansBuff = [];
              for(let i = 0; i < projectOrder.drawings.length; i++) {
                let plan = project.projectDrawings.find(drawing => drawing.id === projectOrder.drawings[i]);
                if(plan) {
                  plansBuff.push(plan);
                }
                else {
                  projectOrder.drawings.splice(i,1)
                  i--
                }
              }
              if(projectOrder.drawings.length !== project.projectDrawings) {
                project.projectDrawings.forEach(drawing => {
                  let alreadyFound = projectOrder.drawings.find(order => order === drawing.id);
                  if(!alreadyFound) {
                    plansBuff.push(drawing);
                  }
                })
              }
              project.projectDrawings = plansBuff;
            }
          }
          else {
            project.projectDrawings = this.sortAlphabeticallyByParameter(project.projectDrawings, "drawingName");
          }
        }
      })
      this.projectsInitialized = true;
      this.projectsSubject.next(this.projects);
    });
  }

  sortAlphabeticallyByParameter(arr,propertyName) {
    return arr.sort(function(a, b) {
      var textA = a[propertyName].toUpperCase();
      var textB = b[propertyName].toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }

  getProjectById(id) {
    return this.httpClient.get<Project>(environment.requestUrl + "/api/project/"+id);
  }

  createNewProject(projectData) {
      return this.httpClient.post<any>(environment.requestUrl + "/api/project", projectData);
  }

  editProject(projectData) {
    return this.httpClient.put(
      environment.requestUrl + "/api/project", projectData
    );
  }

  deleteProject(id) {
    return this.httpClient.delete(
      environment.requestUrl + "/api/project/"+id
    );
  }

  saveOrder(projects) {
    let projectsOrder = []
    projects.forEach((project: Project) => {
      let projectOrderData = {
        id: project.id,
        drawings: project.projectDrawings.map(drawing => drawing.id)
      }
      projectsOrder.push(projectOrderData);
    });
    localStorage.setItem("order",JSON.stringify(projectsOrder));
  }

  copyProject(projectData) {
    return this.httpClient.post(
      environment.requestUrl + "/api/project/copy", projectData
    );
  }

}
