import { NgModule } from '@angular/core';
import { Routes, RouterModule, withEnabledBlockingInitialNavigation } from '@angular/router';
import { StyleguideComponent, EditorComponent } from './components';
import { LayerToolsComponent } from './components/additional-tools/layer-tools/layer-tools.component';
import { LineToolsComponent } from './components/additional-tools/line-tools/line-tools.component';
import { GroupToolsComponent } from './components/additional-tools/group-tools/group-tools.component';
import { RectToolsComponent } from './components/additional-tools/rect-tools/rect-tools.component';
import { CircleToolsComponent } from './components/additional-tools/circle-tools/circle-tools.component';
import { ArcToolsComponent } from './components/additional-tools/arc-tools/arc-tools.component';
import { EditingToolsComponent } from './components/additional-tools/editing-tools/editing-tools.component';
import { SymbolLibraryToolsComponent } from './components/additional-tools/symbol-library-tools/symbol-library-tools.component';
import { TextToolsComponent } from './components/additional-tools/text-tools/text-tools.component';
import { ObjectsOrderToolsComponent } from './components/additional-tools/objects-order-tools/objects-order-tools.component';
import { GraphicObjectsComponent } from './components/additional-tools/graphic-objects/graphic-objects.component';
import { LoginComponent } from './components/authorization/login/login.component';
import { RegistrationComponent } from './components/authorization/registration/registration.component';
import { AuthGuard } from './services/user/auth-guard/auth-guard.service';
import { ProfileComponent } from './components/profile/profile.component';
import { ActivationCodeInputComponent } from './components/authorization/activation-code-input/activation-code-input.component';
import { ProjectsListComponent } from './components/projects/projects-list/projects-list.component';
import { ProjectDataComponent } from './components/projects/project-data/project-data.component';
import { FillColorsToolsComponent } from './components/additional-tools/fill-colors-tools/fill-colors-tools.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SpecialArrowToolsComponent } from './components/additional-tools/special-arrow-tools/special-arrow-tools.component';
import { WallOpeningsComponent } from './components/additional-tools/wall-openings/wall-openings.component';
import { AccountActivationComponent } from './components/authorization/account-activation/account-activation.component';
import { LegendComponent } from './components/additional-tools/legend/legend.component';
import { LicenseAgreementComponent } from './components/license-agreement/license-agreement.component';
import { FaqComponent } from './components/faq/faq.component';
import { TutorialComponent } from './components/tutorial/tutorial.component';
import { VideoComponent } from './components/video/video.component';
import { DocumentationComponent } from './components/documentation/documentation.component';
import { WallToolsComponent } from './components/additional-tools/wall-tools/wall-tools.component';
import { ContactComponent } from './components/contact/contact.component';
import { ForgotPasswordComponent } from './components/authorization/forgot-password/forgot-password.component';
import { KnowledgeComponent } from './components/knowledge/knowledge.component';
import { FrameToolsComponent } from './components/additional-tools/frame-tools/frame-tools.component';


const routes: Routes = [
  { path: "styleguide", component: StyleguideComponent },
  {
    path: "license-agreement",
    component: LicenseAgreementComponent,
  },
  {
    path: "faq",
    component: FaqComponent,
  },
  {
    path: "tutorial",
    component: TutorialComponent,
  },
  {
    path: "knowledge",
    component: KnowledgeComponent,
  },
  {
    path: "video",
    component: VideoComponent,
  },
  {
    path: "documentation",
    component: DocumentationComponent,
  },
  {
    path: "settings",
    component: SettingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "profile",
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "projects",
    component: ProjectsListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "project/:id",
    component: ProjectDataComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "activation/:user/:code",
    component: AccountActivationComponent,
  },
  {
    path: "authorization",
    children: [
      {
        path: "login",
        component: LoginComponent
      },
      {
        path: "licenseactivation",
        component: ActivationCodeInputComponent
      },
      {
        path: "registration",
        component: RegistrationComponent
      },
      {
        path: "",
        redirectTo: "login",
        pathMatch: "prefix"
      },
      {
        path: "**",
        redirectTo: "",
        pathMatch: "prefix"
      },
    ]
  },
  {
    path: "editor",
    component: EditorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "layers",
        component: LayerToolsComponent
      },
      {
        path: "group",
        component: GroupToolsComponent
      },
      {
        path: "line",
        component: LineToolsComponent
      },
      {
        path: "rect",
        component: RectToolsComponent
      },
      {
        path: "circle",
        component: CircleToolsComponent
      },
      {
        path: "arc",
        component: ArcToolsComponent
      },
      {
        path: "edit",
        component: EditingToolsComponent
      },
      {
        path: "symbols",
        component: SymbolLibraryToolsComponent
      },
      {
        path: "objects",
        component: GraphicObjectsComponent
      },
      {
        path: "text",
        component: TextToolsComponent
      },
      {
        path: "order",
        component: ObjectsOrderToolsComponent
      },
      {
        path: "fillcolors",
        component: FillColorsToolsComponent
      },
      {
        path: "arrows",
        component: SpecialArrowToolsComponent
      },
      {
        path: "wall-openings",
        component: WallOpeningsComponent
      },
      {
        path: "legend",
        component: LegendComponent
      },
      {
        path: "frame",
        component: FrameToolsComponent
      },
      {
        path: "wall",
        component: WallToolsComponent
      },
      {
        path: "**",
        redirectTo: ""
      },
    ]
  },
  { path: "", redirectTo: "/projects", pathMatch: "full" },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: "resetpassword/:token",
    component: ForgotPasswordComponent,
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    initialNavigation: "enabledNonBlocking"
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
