import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-plan-close-dialog',
  templateUrl: './plan-close-dialog.component.html',
  styleUrls: ['./plan-close-dialog.component.scss']
})
export class PlanCloseDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PlanCloseDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  submitValue(value) {
    this.dialogRef.close(value);
  }

}
