import { Component, OnInit } from '@angular/core';
import { ColorService } from 'src/app/services';

@Component({
  selector: 'app-fill-colors-tools',
  templateUrl: './fill-colors-tools.component.html',
  styleUrls: ['./fill-colors-tools.component.scss']
})
export class FillColorsToolsComponent implements OnInit {
  activeFillColor: string;
  colors: Array<{colorName:string, colorHex:string}>;

  constructor(
    private colorService: ColorService
  ) {
    this.activeFillColor = this.colorService.activeFillColor;
    this.colors = this.colorService.defaultColors.filter(c => c.colorName.length > 2);
  }

  ngOnInit(): void {
  }

  setupColor(color: string) {
    this.colorService.activeFillColor = color; 
    this.colorService.activeStrokeColor = color; 
    this.activeFillColor = color;
  }

}
