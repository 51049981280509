import { Component, OnInit } from '@angular/core';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { SelectionService } from 'src/app/services/status-and-control-services/selection/selection.service';
import { ModesService } from 'src/app/services/status-and-control-services/modes/modes.service';
import { GeometryEditingService } from 'src/app/services/operational-services/geometry-editing/geometry-editing.service';
import { LineControlsEditingMode } from 'src/app/models/enums/line-controls-editing-mode.enum';

@Component({
  selector: 'app-line-control-points-tools',
  templateUrl: './line-control-points-tools.component.html',
  styleUrls: ['./line-control-points-tools.component.scss']
})
export class LineControlPointsToolsComponent implements OnInit {
  controlsMode: LineControlsEditingMode;
  constructor(
    private selectionService: SelectionService,
    private modesService: ModesService,
    private geometryEditingService: GeometryEditingService,
  ) {
    this.controlsMode = this.geometryEditingService.lineControlsEditingMode;
  }

  ngOnInit(): void {
  }

  oneElementOfLineTypeSelected() {
    return this.selectionService.oneElementOfLineTypeSelected();
  }

  isEditMode(): boolean {
    return this.modesService.mode === ActiveMode.editMode;
  }

  setLineControlsMode(mode) {
    if(mode !== this.geometryEditingService.lineControlsEditingMode) {
      this.geometryEditingService.setLineControlsEditingMode(mode);
      this.controlsMode = mode;
    }
    else {
      this.geometryEditingService.setLineControlsEditingMode(null);
      this.controlsMode = null;
    }
  }

}
