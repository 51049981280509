import { Injectable } from '@angular/core';
import { GeometryPropertiesService } from '../geometry-properties/geometry-properties.service';
import { EventsPropagationService } from '../../status-and-control-services/events-propagation/events-propagation.service';
import * as predefinedStyles from "../../../../assets/fonts/predefined-styles.json";
import { LayerService } from '../layer/layer.service';
import { SelectionService } from '../../status-and-control-services/selection/selection.service';
import { GeometryEditingService } from '../geometry-editing/geometry-editing.service';
import { MatDialog } from '@angular/material/dialog';
import { InputDialogComponent } from 'src/app/components/dialogs/input-dialog/input-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class TextService {
  textAreaOpen: boolean;
  textarea: any;
  fontFamily: string;
  fontFamilies: Array<string>;
  fontStyle: string;
  fontStyles: Array<string>;
  fontSize: number;
  textAlign: string;
  textAlignVariants: Array<string>;
  verticalAlign: string;
  verticalAlignVariants: Array<string>;
  activeNode: number;
  selectedNode: number;
  strokeColor: string;
  fillColor: string;
  predifinedFormats: Array<any>;
  lineHeight: number;

  constructor(
    private geometryPropertiesService: GeometryPropertiesService,
    private eventsPropagationService: EventsPropagationService,
    private layerService: LayerService,
    private selectionService: SelectionService,
    private geometryEditingService: GeometryEditingService,
    private dialog: MatDialog
  ) {
    this.fontSize = 18;
    this.lineHeight = 20;
    this.fontStyles = [
      'normal', 'bold', 'italic','italic bold'
    ];
    this.fontStyle = "normal";
    this.textAlignVariants = [
      "left", "center", "right"
    ];
    this.textAlign = "left";
    this.fontFamilies = [
      "Arial", "Roboto", "Times New Roman", "Calibri, sans-serif", "Tahoma"
    ];
    this.verticalAlignVariants = [
      "top", "middle", "bottom"
    ];
    this.verticalAlign = "top";
    this.fontFamily = "Roboto";
    this.textAreaOpen = false;
    this.textarea = null;
    this.activeNode = null;
    this.selectedNode = null;
    this.strokeColor = this.geometryPropertiesService.activeStrokeColor;
    this.fillColor = this.geometryPropertiesService.activeFillColor;
    let predefinedStylesList: any = predefinedStyles;
    this.predifinedFormats = predefinedStylesList.default;
  }

  updateParameter(value, parameterName: string) {
    this[parameterName] = value;
    if(this.selectionService.selectedText()) {
      this.geometryPropertiesService.updateTextParameterOfSelecton(value, parameterName);
    }
  }

  editTextContents() {
    let dialogRefPass = this.dialog.open(InputDialogComponent, {
      data: {
        inputType: "textarea",
        valueName: "Text Inhalt",
        buttonText: "Text speichern",
        value: this.selectionService.selectedText().text
      }
    });
    dialogRefPass.afterClosed().subscribe(result => {
      if(result && result.textValue) {
        this.geometryPropertiesService.updateTextOfSelection(result.textValue);
      }
    })
  }

  addText(position, scale) {
    let textItem = {
      text: "Hier klicken um den Text zu verändern",
      origin: {
        x: position.x/scale,
        y: position.y/scale
      },
      textAlign: this.textAlign,
      fontStyle: this.fontStyle,
      fontSize: this.fontSize,
      fontFamily: this.fontFamily,
      lineHeight: this.lineHeight,
      width: 300,
      height: this.lineHeight+4,
      geometryProperties: {
        strokeColor: this.strokeColor,
        fillColor: this.fillColor
      }
    };
    this.layerService.addGeometryToActiveLayer(textItem, 10).subscribe(geomName => {
      this.selectionService.resetSelection();
      this.selectionService.setActiveElement(geomName);
      this.selectionService.addElementToSelection();
      this.geometryEditingService.setLineControlsEditingMode(null);
    });
  }
}
