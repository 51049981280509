<div class="full-height" *ngIf="formFilled && isBrowser">
    <div class="p-2" fxLayout="column" fxLayoutAlign="end center">
        <div class="w-100" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start center" fxLayoutAlign.gt-sm="space-between start">
          <div class="ep-tabs-labels" fxFlex="20" fxLayout="row" fxLayout.gt-sm="column" fxLayoutAlign="center start">
            <div class="ep-label-wrapper">
              <button mat-button (click)="goToTAb(0)" [ngClass]="{'active-tab' : activeTab === 0}">{{ "project.basicData" | translate}}</button>
            </div>
            <div class="ep-label-wrapper">
              <button mat-button (click)="goToTAb(1)" [ngClass]="{'active-tab' : activeTab === 1}">{{ "project.detail" | translate}}</button>
            </div>
          </div>
          <form [formGroup]="projectDataForm" class="ep-tabs-content-container" fxFlex="80">
              <div *ngIf="activeTab === 0" class="ep-tab-content">
                <div *ngIf="projectDataForm" class="w-100"  fxLayout="column" fxLayout.gt-sm="column wrap" fxLayoutGap="20px">
                    <div *ngFor="let control of controls | keyvalue : originalOrder" class="ep-project-data-form-field">
                        <div class="ep-form-control-container" *ngIf="
                        !controls[control.key].controls &&
                        projectDataFormTemplate[control.key] !== 'date'
                        ">
                            <div class="ep-form-control">
                                <span>{{"project." + control.key | translate}}</span>
                                <input *ngIf="projectDataFormTemplate[control.key] !== 'textarea'" type="{{projectDataFormTemplate[control.key]}}" formControlName="{{control.key}}" [readonly]="projectId === 'sampleProject'">
                                <textarea *ngIf="projectDataFormTemplate[control.key] === 'textarea'" formControlName="{{control.key}}" [readonly]="projectId === 'sampleProject'"></textarea>
                            </div>
                            <mat-error *ngIf="projectDataForm?.get(control.key).touched && projectDataFormTemplate[control.key] !== 'textarea'">
                                <control-messages [control]="{controlProperties: projectDataForm?.get(control.key), controlName: control.key}">
                                </control-messages>
                            </mat-error>
                        </div>
                        <div class="ep-form-control-container" *ngIf="!controls[control.key].controls && projectDataFormTemplate[control.key] === 'date'" appearance="fill">
                            <div class="ep-form-control">
                                <span>{{"project." + control.key | translate}}</span>
                                <div fxLayout="row" fxLayoutAlign="end center" class="ep-datepicker">
                                    <input class="ep-datepicker-input" [matDatepicker]="picker" formControlName="{{control.key}}" [readonly]="projectId === 'sampleProject'">
                                    <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="projectId === 'sampleProject'">
                                        <mat-icon class="date-picker-icon" matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </div>
                            </div>
                            <mat-error *ngIf="projectDataForm?.get(control.key).touched && projectDataFormTemplate[control.key] !== 'textarea'">
                                <control-messages [control]="{controlProperties: projectDataForm?.get(control.key), controlName: control.key}">
                                </control-messages>
                            </mat-error>
                        </div>
                        <div *ngIf="controls[control.key].controls && control.key !== 'detail'" fxLayout="column" fxLayoutGap="20px">
                            <div formGroupName="{{control.key}}" *ngFor="let nestedControl of controls[control.key].controls | keyvalue : originalOrder">
                                <div class="ep-form-control-container" *ngIf="nestedControl.key !== 'address'">
                                    <div class="ep-form-control">
                                        <span>{{"project." + nestedControl.key | translate}}</span>
                                        <input *ngIf="projectDataFormTemplate[nestedControl.key] !== 'textarea'" type="{{projectDataFormTemplate[nestedControl.key]}}" formControlName="{{nestedControl.key}}" [readonly]="projectId === 'sampleProject'">
                                        <textarea *ngIf="projectDataFormTemplate[nestedControl.key] === 'textarea'" formControlName="{{nestedControl.key}}" [readonly]="projectId === 'sampleProject'"></textarea>
                                    </div>
                                    <mat-error *ngIf="projectDataForm?.get(control.key).get(nestedControl.key).touched && projectDataFormTemplate[nestedControl.key] !== 'textarea'">
                                        <control-messages [control]="{controlProperties: projectDataForm?.get(control.key).get(nestedControl.key), controlName: nestedControl.key}">
                                        </control-messages>
                                    </mat-error>
                                </div>
                                <div *ngIf="nestedControl.key === 'address'" formGroupName="{{nestedControl.key}}" fxLayout="column" fxLayoutGap="20px">
                                    <div class="ep-form-control-container" *ngFor="let nestedNestedControl of controls[control.key].controls[nestedControl.key].controls | keyvalue : originalOrder">
                                        <div class="ep-form-control">
                                            <span>{{"project." + nestedNestedControl.key | translate}}</span>
                                            <input *ngIf="projectDataFormTemplate[nestedNestedControl.key] !== 'textarea'" type="{{projectDataFormTemplate[nestedNestedControl.key]}}" formControlName="{{nestedNestedControl.key}}" [readonly]="projectId === 'sampleProject'">
                                            <textarea *ngIf="projectDataFormTemplate[nestedNestedControl.key] === 'textarea'" formControlName="{{nestedNestedControl.key}}" [readonly]="projectId === 'sampleProject'"></textarea>
                                        </div>
                                        <mat-error *ngIf="projectDataForm?.get(control.key).get(nestedControl.key).get(nestedNestedControl.key).touched && projectDataFormTemplate[nestedNestedControl.key] !== 'textarea'">
                                            <control-messages [control]="{controlProperties: projectDataForm?.get(control.key).get(nestedControl.key).get(nestedNestedControl.key), controlName: nestedNestedControl.key}">
                                            </control-messages>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            <div *ngIf="activeTab === 1" class="ep-tab-content">
                <div *ngIf="projectDataForm" formGroupName="detail" class="w-100"  fxLayout="column" fxLayout.gt-sm="column wrap" fxLayoutGap="20px">
                    <div *ngFor="let control of projectDataForm.get('detail').controls | keyvalue : originalOrder" class="ep-single-wide-form">
                        <div class="ep-form-control-container">
                            <div class="ep-form-control">
                                <span>{{"project." + control.key | translate}}</span>
                                <input *ngIf="projectDataFormTemplate[control.key] !== 'textarea'" type="{{projectDataFormTemplate[control.key]}}" formControlName="{{control.key}}" [readonly]="projectId === 'sampleProject'">
                                <textarea *ngIf="projectDataFormTemplate[control.key] === 'textarea'" formControlName="{{control.key}}" [readonly]="projectId === 'sampleProject'"></textarea>
                            </div>
                            <mat-error *ngIf="projectDataForm?.get('detail').get(control.key).touched && projectDataFormTemplate[control.key] !== 'textarea'">
                                <control-messages [control]="{controlProperties: projectDataForm?.get('detail').get(control.key), controlName: control.key}">
                                </control-messages>
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="w-58" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
        <button mat-flat-button class="ep-btn-call" [disabled]="!projectDataForm.valid" (click)="onSubmit()">{{ "save" | translate }}</button>
        <button mat-stroked-button (click)="fillForm(projectData)">{{ "reset" | translate }}</button>
        <button mat-stroked-button (click)="navigateToProjects()">{{ "back" | translate }}</button>
    </div>
</div>
</div>

