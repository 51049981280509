import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { jsPDF } from "jspdf";
import { PlanService } from '../../user/plan/plan.service';
import { saveAs } from 'file-saver';
import { PageOrientationEnum } from 'src/app/models/enums/page-orientation.enum';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  exportState: string;
  exportState$: Observable<string>;
  private _exportStateSource: BehaviorSubject<string>;
  options: any;
  preview: any;
  pdf: jsPDF;

  constructor(
    private planService: PlanService
  ) {
    this.preview = {
      image: "",
      width: null,
      height: null
    };
    this.setupObservables();
  }

  setupObservables() {
    this.exportState = "";
    this._exportStateSource = new BehaviorSubject(this.exportState);
    this.exportState$ = this._exportStateSource.asObservable();
  }

  emitExportEvent(state) {
    this.exportState = state;
    this._exportStateSource.next(this.exportState);
    this.exportState = "";
    this._exportStateSource.next(this.exportState);
  }


  saveForExport(canvas,result) {
    let obs: Observable<any> = new Observable(observer => {
      this.downloadFile(canvas,result).subscribe(res => {
        this.emitExportEvent("exported");
        observer.next(true);
      });
    })
    return obs;
  }

  exportPDF() {
    let obs: Observable<any> = new Observable(observer => {
      if(this.planService.activePlanId && this.planService.drawingName) {
        if(this.planService.drawingName) {
          this.pdf.save(this.planService.drawingName + ".pdf");
        }
        else {
          this.planService.getPlanById(this.planService.activePlanId).subscribe((plan: any) => {
            if(plan && plan.data && plan.data.drawingName) {
              this.pdf.save(plan.data.drawingName + ".pdf");
            }
            else {
              this.pdf.save("plan.pdf");
            }
            observer.next(true);
          })
        }
      }
      else {
        this.pdf.save("plan.pdf");
        observer.next(true); 
      }
    });
    return obs;
  }

  downloadFile(canvas, format) {
    let obs: Observable<any> = new Observable(observer => {
      canvas.toBlob(blob => {
        if(this.planService.activePlanId) {
          if(this.planService.drawingName) {
            saveAs(blob, this.planService.drawingName + "." + format);
            observer.next(true);
          }
          else {
            this.planService.getPlanById(this.planService.activePlanId).subscribe((plan: any) => {
              if(plan && plan.data && plan.data.drawingName && format !== "pdf") {
                saveAs(blob, plan.data.drawingName + "." + format);
                observer.next(true);
              }
              else if(format !== "pdf") {
                saveAs(blob, "plan." + format);
                observer.next(true);
              }
              else {
                this.savePDF(blob,canvas).subscribe(res => {
                  observer.next(true); 
                });
              }
            })
          }
        }
        else if(format !== "pdf") {
          saveAs(blob, "plan." + format);
          observer.next(true); 
        }
        else {
          this.savePDF(blob,canvas).subscribe(res => {
            observer.next(true); 
          });
        }
      }, 'image/'+ format === "png" ? 'png' : 'jpeg', 1);
    })
    return obs;
  }

  savePDF(blob,canvas) {
    let obs: Observable<any> = new Observable(observer => {
      var reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onload = () => {
        this.preview.image = reader.result;
        let orientation: any = canvas.width > canvas.height ? PageOrientationEnum.lanscape : PageOrientationEnum.portrait;
        this.pdf = new jsPDF(orientation, "mm", "a4",true);
        let pageWidth = this.pdf.internal.pageSize.getWidth();
        let pageHeight = this.pdf.internal.pageSize.getHeight();
        let mult = orientation === PageOrientationEnum.lanscape ? pageWidth/canvas.width : pageHeight/canvas.height;
        let width = canvas.width*mult;
        let height = canvas.height*mult;
        this.pdf.addImage(this.preview.image, 'SVG', (pageWidth - width)/2, (pageHeight - height)/2, width, height, '', 'FAST');
        this.preview.width = pageWidth;
        this.preview.height = pageHeight;
        this.exportPDF().subscribe(res => {
          observer.next(true);
        });
      }
    })
    return obs;
  }
}