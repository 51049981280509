export class Color {
    r: number;
    g: number;
    b: number;

    constructor(data) {
        if(typeof data === 'string' && data.indexOf("#") === 0 && data.length === 7) {
            data = this.hexToRgb(data);
        };
        this.b = data && data.b ? data.b : 0;
        this.g = data && data.g ? data.g : 0;
        this.r = data && data.r ? data.r : 0;
    }

    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    }

    rgbToHex(color) {
      if(typeof color === 'string' && color.indexOf("#") === 0 && color.length === 7) return color;
      else return "#" + this.colorChannelToHex(color.r) + this.colorChannelToHex(color.g) + this.colorChannelToHex(color.b);
    }

    colorChannelToHex(c) {
      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    }
}