import { GeometricType } from './enums/geometric-types.enum';
import { Geometry } from './geometries/geometry.model';
import { Point } from './point.model';

export class Layer {
    layerName: string;
    geometry: Array<Geometry>;
    isVisible: boolean;
    isLayerOn: boolean;

    constructor() {
        this.geometry = new Array<Geometry>();
        this.isVisible = true;
        this.isLayerOn = true;
    }

    findIntersectedGeometry(mousePosition: Point, translation: Point, context, canvas, scale) {
        let activeElement = "";
        let mousePositionBuff = {...mousePosition};
        let i = 0;
        while(i < this.geometry.length) {
            if(
                this.geometry[i].geometricType === GeometricType.Rectangle ||
                this.geometry[i].geometricType === GeometricType.Arrow ||
                this.geometry[i].geometricType === GeometricType.Image ||
                this.geometry[i].geometricType === GeometricType.Legend ||
                this.geometry[i].geometricType === GeometricType.Frame ||
                this.geometry[i].geometricType === GeometricType.Text
            ) {
                mousePositionBuff.x = mousePosition.x - translation.x;
                mousePositionBuff.y = mousePosition.y - translation.y;
            }
            else {
                mousePositionBuff = {...mousePosition};
            }
            let extrema = this.geometry[i].extrema();
            if(
                mousePosition.x > extrema.maxX ||
                mousePosition.x < extrema.minX ||
                mousePosition.y < extrema.minY ||
                mousePosition.y > extrema.maxY
            ) {
            }
            else {
                if(this.geometry[i].mouseIntersectsGeometry(mousePositionBuff, context, canvas, scale)) {
                    return this.geometry[i].entityName;
                }
            }
            i++;
        }
        return activeElement;
    }
}
