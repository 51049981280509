import { Component, OnInit } from '@angular/core';
import { SelectionService } from 'src/app/services/status-and-control-services/selection/selection.service';

@Component({
  selector: 'app-editing-tools',
  templateUrl: './editing-tools.component.html',
  styleUrls: ['./editing-tools.component.scss']
})
export class EditingToolsComponent implements OnInit {

  constructor(
    private selectionService: SelectionService
  ) { }

  ngOnInit(): void {
  }

  selectionExists() {
    return this.selectionService.selectedElementsExist();
  }

  elementsWithFillSelected() {
    return this.selectionService.elementsWithFillSelected();
  }

}
