export class LineTypeControl {
    type: number;
    patternSample: string;
    dashSetting?: Array<number>;
    
    constructor(data) {
        Object.keys(data).forEach(key => {
            this[key] = data[key];
        })
    }
}
