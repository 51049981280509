import { Component, OnInit } from '@angular/core';
import { ColorEvent } from 'ngx-color';
import { ColorService, ControlsService } from 'src/app/services';
import { LineTypeControl } from 'src/app/models/controls';
import { EventsPropagationService } from 'src/app/services/status-and-control-services/events-propagation/events-propagation.service';
import { GeometryPropertiesService } from 'src/app/services/operational-services/geometry-properties/geometry-properties.service';
import { MatSelectChange } from '@angular/material/select';
@Component({
  selector: 'app-common-properties',
  templateUrl: './common-properties.component.html',
  styleUrls: ['./common-properties.component.scss']
})
export class CommonPropertiesComponent implements OnInit {

  currentLineType: LineTypeControl;
  lineTypesList: Array<LineTypeControl>;
  width: number;
  currentColor: string;
  presetColors: Array<string>;

  constructor(
    private eventsPropagationService: EventsPropagationService,
    private controlsService: ControlsService,
    private geometryPropertiesService: GeometryPropertiesService,
    private colorService: ColorService
  ) {
    this.width = this.geometryPropertiesService.currentLineWidth;
    this.lineTypesList = this.controlsService.lineTypes;
    this.currentLineType = this.geometryPropertiesService.currentLineTypeSettings;
    this.currentColor = this.geometryPropertiesService.activeStrokeColor;
    this.presetColors = this.colorService.presetColors;

  }

  ngOnInit(): void {
  }

  onBlur() {
    this.eventsPropagationService.enableEvents();
  }

  onFocus() {
    this.eventsPropagationService.disableEvents();
  }

  ngOnDestroy() {
    this.onBlur();
  }

  updateActiveColor(event: ColorEvent){
    this.geometryPropertiesService.setActiveStrokeColor(event);
  }

  updateLineWidth(width: number) {
    this.geometryPropertiesService.updateCurrentLineWidth(width);
  }

  updateLineType(event: MatSelectChange) {
    this.geometryPropertiesService.updateCurrentLineType(event.value);
  }

}
