import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AppSettingsService } from '../../status-and-control-services/app-settings/app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class ViewerService {
  rulerOn: boolean;
  rulerOn$: Observable<boolean>;
  scale: number;
  scale$: Observable<number>;
  gridSubscription: Subscription;
  grid: boolean;
  grid$: Observable<boolean>;
  scaleWithMouse$: Observable<any>;
  loading: boolean = false;
  private _scaleWithMouseSource: BehaviorSubject<any>;
  private _scaleSource: BehaviorSubject<number>;
  private _gridSource: BehaviorSubject<boolean>;
  private _rulerOnSource: BehaviorSubject<boolean>;
  loading$: Observable<boolean>;
  _loadingSource: BehaviorSubject<boolean>;
  constructor(
    private appSettingsService: AppSettingsService
  ) {
    this.scale = 1;
    this.gridSubscription = this.appSettingsService.settingsChange$.subscribe(change => {
      if(change) {
        this.grid = this.appSettingsService.gridSettings.gridOn;
      }
    });
    this.rulerOn = false;
    this.loading = false;
    this.setupObservables();
  }

  setupObservables() {
    this._scaleSource = new BehaviorSubject(this.scale);
    this.scale$ = this._scaleSource.asObservable();
    this._scaleWithMouseSource = new BehaviorSubject(this.scale);
    this.scaleWithMouse$ = this._scaleWithMouseSource.asObservable();
    this._gridSource = new BehaviorSubject(this.grid);
    this.grid$ = this._gridSource.asObservable();
    this._rulerOnSource = new BehaviorSubject(this.rulerOn);
    this.rulerOn$ = this._rulerOnSource.asObservable();
    this._rulerOnSource = new BehaviorSubject(this.rulerOn);
    this.rulerOn$ = this._rulerOnSource.asObservable();
    this._loadingSource = new BehaviorSubject(this.loading);
    this.loading$ = this._loadingSource.asObservable();
  }

  toggleGrid() {
    this.grid = !this.grid;
    this._gridSource.next(this.grid);
  }

  toggleRuler() {
    this.rulerOn = !this.rulerOn;
    this._rulerOnSource.next(this.rulerOn);
  }

  disableGrid() {
    this.grid = false
    this._gridSource.next(this.grid);
  }

  enableGrid() {
    this.grid = true;
    this._gridSource.next(this.grid);
  }

  scaleUp() {
    this.scale = Number((this.scale + 0.25).toFixed(2));
    this.emitScale();
    this._rulerOnSource.next(this.rulerOn);
  }

  scaleDown() {
    if(this.scale > 0.25) {
      this.scale = Number((this.scale - 0.25).toFixed(2));
      this.emitScale();
    }
    this._rulerOnSource.next(this.rulerOn);
  }

  setScale(value:number) {
    this.scale = value;
    this.emitScale();
    this._rulerOnSource.next(this.rulerOn);
  }

  smoothScale(event,mousePos) {
    var wheel = event.wheelDelta/1000;//n or -n
    this.scale += wheel;
    if (this.scale > 20) this.scale = 20;
    if (this.scale < 0.01) this.scale = 0.01;
    this._scaleWithMouseSource.next({mousePos: mousePos, scale: this.scale});
    this._rulerOnSource.next(this.rulerOn);
  }

  emitScale() {
    this._scaleSource.next(this.scale);
  }

}