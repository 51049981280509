import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';



@Component({
  selector: 'app-styleguide',
  templateUrl: './styleguide.component.html',
  styleUrls: ['./styleguide.component.scss']
})
export class StyleguideComponent implements OnInit {
  emailFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email,
  ]);
  checked: boolean = false;
  thickness: number = 5;
  step = 0;
  progressvalue = 0;
  isBrowser: boolean = false;
  listFAIcons: Array <string> = [
    "align-center-solid",
    "bars-solid",
    "file-import-solid",
    "location-arrow-solid",
    "ruler-solid",
    "undo-alt-solid",
    "align-left-solid",
    "book-solid",
    "file-regular",
    "long-arrow-alt-right-solid",
    "save-regular",
    "upload-solid",
    "align-right-solid",
    "chess-knight-solid",
    "fill-drip-solid",
    "long-arrow-alt-up-solid",
    "scroll-solid",
    "user-solid",
    "arrow-down-solid",
    "chevron-down-solid",
    "folder-open-regular",
    "mouse-pointer-solid",
    "search-minus-solid",
    "window-close-regular",
    "arrow-left-solid",
    "chevron-up-solid",
    "hand-pointer-regular",
    "object-group-regular",
    "search-plus-solid",
    "arrow-right-solid",
    "cog-solid",
    "home-solid",
    "object-ungroup-regular",
    "slash-solid",
    "arrow-up-solid",
    "copy-regular",
    "images-regular",
    "pencil-alt-solid",
    "th-large-solid",
    "arrows-alt-solid",
    "draw-polygon-solid",
    "layer-group-solid",
    "plus-solid",
    "th-solid",
    "backward-solid",
    "file-export-solid",
    "link-solid",
    "print-solid",
    "trash-solid"
  ];
  listOwnIcons: Array <string> = [
    "am-raster-ausrichten",
    "fenster",
    "innenwand",
    "mehrfach-linien",
    "rechteck",
    "vertical-unten",
    "an-objekt-ausrichten",
    "ganz-in-den-hintergrund",
    "kreis",
    "moebel",
    "text",
    "vertikal-spiegeln",
    "aussendwand",
    "ganz-in-den-vordergrund",
    "kreisbogen",
    "orthogonalmodus",
    "tuer",
    "wandoeffnung",
    "einen-schritt-nach-hinten",
    "haltepunkt-hinzufuegen",
    "layer",
    "parallele-linien",
    "vertical-mittig",
    "einen-schritt-nach-vorne",
    "horizontal-spiegeln",
    "linien-trennen",
    "rahmen",
    "vertical-oben"
  ];
  activeTab: number = null;
  constructor(public dialog: MatDialog, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId)
  }

  goToTAb(tab: number) {
    this.activeTab = tab;
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  openDialog() {
    this.dialog.open(InfoDialogComponent);
  }

  animateProgressBar() {
    setTimeout(() => {
      this.progressvalue++;
      if (this.progressvalue < 100) {
        this.animateProgressBar();
      }
      else {
        this.progressvalue = 0;
        this.animateProgressBar();
      }
    }, 100)
  }



  ngOnInit() {
  }

}
