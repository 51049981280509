import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-export-setup-dialog',
  templateUrl: './export-setup-dialog.component.html',
  styleUrls: ['./export-setup-dialog.component.scss']
})
export class ExportSetupDialogComponent implements OnInit {
  exportFileFormats: Array<string>;
  selectedValue: string;
  constructor(
    public dialogRef: MatDialogRef<ExportSetupDialogComponent>,
  ) {
    this.exportFileFormats = ["png","jpg"];
    this.selectedValue = "png"
  }

  ngOnInit(): void {
  }

  updateExportFileFormat(value) {
    this.selectedValue = value;
  }

  closeDialog(value) {
    this.dialogRef.close(value);
  }
}
