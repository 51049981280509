import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subscription } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Extrema } from "src/app/models/extrema.model";
import { saveAs } from 'file-saver';
import { DataService } from "../data/data.service";
import { SignalrService } from "../../signalr/signalr.service";

interface FileEvent {
  data: {
    dxfFilePath: string,
    fileBase64: string,
    images?: Array<any>
  },
}
@Injectable({ providedIn: "root" })
export class FileService {
  public fileName: string;
  private _dxfFile: string;
  dxfFilePath: string;
  images: Array<any>;
  _fileSource: BehaviorSubject<FileEvent>;
  file$: Observable<FileEvent>;
  download: string;
  private _planId: string;
  private _planName: string;
  private _projectId: string;
  signalrLayerDetailSubscription: Subscription;

  constructor(
    private httpClient: HttpClient,
    private dataService: DataService,
    private signalrService: SignalrService
  ) {
    this.dxfFilePath = "";
    this.dxfFile = null;
    this._fileSource = new BehaviorSubject(null);
    this.file$ = this._fileSource.asObservable();
  }

  setFileName(fileName: string) {
    this.dxfFilePath = fileName;
  }

  resetFileName() {
    this.dxfFilePath = "";
  }

  get planId(): string {
    return this._planId;
  }

  set planId(value: string) {
    this._planId = value;
  }

  get dxfFile(): string {
    return this._dxfFile;
  }

  set dxfFile(value: string) {
    this._dxfFile = value;
  }

  get planName(): string {
    return this._planName;
  }

  set planName(value: string) {
    this._planName = value;
  }

  get projectId(): string {
    return this._projectId;
  }

  set projectId(value: string) {
    this._projectId = value;
  }

  emitFileInfo() {
    this._fileSource.next({ data: { dxfFilePath: this.dxfFilePath, fileBase64: this.dxfFile, images: this.images } })
  }

  requestLayers(fileData): Observable<any> {
    let obs: Observable<any> = new Observable(observer => {
      this.httpClient
        .post(
          environment.requestUrl + "/api/file/getlayers",
          fileData)
        .subscribe(res => {
          observer.next(res);
        }, error => {
          observer.next(error.error);
        });
    });
    return obs;
  }

  getPreviewByLayers(fileData, editing = false): Observable<any> {
    this.dxfFilePath = fileData.FileName;
    let obs: Observable<any> = new Observable(observer => {
      let dxfReqData =
      {
        "FileName": fileData.FileName,
        "LayerNames": fileData.LayerNames,
        "IsFirstLoad" : fileData.IsFirstLoad
      };
      this.httpClient.post(environment.requestUrl + "/api/file/GetPreviewByLayers", dxfReqData).subscribe((response: any) => {
        this.download = "data:image/vnd.dxf;base64," + response.data.fileBase64;
        if (!editing) {
          this._fileSource.next({ data: response.data });
        }
        observer.next(response);
      }, error => {
        console.log(error);
      })
    })
    return obs;
  }

  getDxfLayerDetailByLocation(coords: Extrema, layerNames: string[],toOverwrite = null) {

    let buff = [];
    let uniqueLayers = [...new Set(layerNames)]
    if(!toOverwrite) {
      this.dataService.initializeGeometryDataLists();
    }
    let obs: Observable<any> = new Observable(observer => {
      if (this.dxfFilePath && this.dxfFilePath !== "") {
        let fileLayerDetailRequest = {
          "FileName": this.dxfFilePath,
          "LayerNames": uniqueLayers,
          "Range":
            (!toOverwrite && (coords && coords.maxX)) ?
              {
                StartX: coords.maxX > coords.minX ? coords.minX : coords.maxX,
                StartY: coords.maxY > coords.minY ? coords.minY : coords.maxY,
                Width: coords.maxX > coords.minX ? coords.maxX - coords.minX : coords.minX - coords.maxX,
                Height: coords.maxY > coords.minY ? coords.maxY - coords.minY : coords.minY - coords.maxY
              }
              : null
        };
        if(fileLayerDetailRequest.Range){
          fileLayerDetailRequest.Range.StartX -= fileLayerDetailRequest.Range.Width/2;
          fileLayerDetailRequest.Range.StartY -= fileLayerDetailRequest.Range.Height/2;
          fileLayerDetailRequest.Range.Width += fileLayerDetailRequest.Range.Width;
          fileLayerDetailRequest.Range.Height += fileLayerDetailRequest.Range.Height;
        }
        this.signalrService.layerStream(localStorage.getItem("currentUser"), fileLayerDetailRequest.FileName, fileLayerDetailRequest.LayerNames, fileLayerDetailRequest.Range)
          .subscribe({
            next: (item) => {
              buff.push(item.data.details[0]);
            },
            complete: () => {
              observer.next(buff);
              console.log("Stream completed");
            },
            error: (err) => {
              console.log(err);
              observer.next(null);
            },
          });

      }
      else {
        observer.next(null)
      }
    });
    return obs;
  }

  getDxfFileName() {
    if (this.dxfFilePath) {
      return this.dxfFilePath.split('.')[0];
    }
    else {
      return null;
    }
  }

  downloadDxf() {
    saveAs(this.download, this.dxfFilePath);
  }

  updateProjectDrawingLayers(fileData, editing = false): Observable<any> {
    let obs: Observable<any> = new Observable(observer => {
      let dxfReqData =
      {
        drawingDxfPath: fileData.FileName,
        projectId: this.projectId,
        deletedEntityNames: [],
        deletedLayerNames: fileData.LayerNames,
        drawingName: this.planName,
        drawingJsonData: JSON.stringify(this.dataService.getGeometricalData())
      };

      this.httpClient.put(environment.requestUrl + "/api/projectdrawing/" + this.planId, dxfReqData).subscribe((response: any) => {
        this.download = "data:image/vnd.dxf;base64," + response.data.fileBase64;
        if (!editing) {
          this._fileSource.next({ data: response.data });
        }
        observer.next(response);
      }, error => {
        console.log(error);
      })
    })
    return obs;
  }

  deletePlanLayers(dxfFilePath: string, deletedLayerNames: string[]=null, editing = false): Observable<any> {
    let obs: Observable<any> = new Observable(observer => {
    let deleteLayerModel = {
      drawingDxfPath: dxfFilePath,
      deletedLayerNames: deletedLayerNames
    }

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: deleteLayerModel
    };

    return this.httpClient.delete(environment.requestUrl + "/api/projectdrawing/" + this.planId  + "/layer", options).subscribe((response: any) => {
      this.download = "data:image/vnd.dxf;base64," + response.data.fileBase64;
      if (!editing) {
        this._fileSource.next({ data: response.data });
      }
      observer.next(response);
    }, error => {
      console.log(error);
    });

  })
  return obs;

  }
}
