import { Component, OnInit } from '@angular/core';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { GeometryPropertiesService } from 'src/app/services/operational-services/geometry-properties/geometry-properties.service';
import { EventsPropagationService } from 'src/app/services/status-and-control-services/events-propagation/events-propagation.service';
import { ModesService } from 'src/app/services/status-and-control-services/modes/modes.service';

@Component({
  selector: 'app-wall-tools',
  templateUrl: './wall-tools.component.html',
  styleUrls: ['./wall-tools.component.scss']
})
export class WallToolsComponent implements OnInit {
  currentLineWidth: number;
  minWallWidth: number;
  selectedWallMode: number;
  constructor(
    private eventsPropagationService: EventsPropagationService,
    private modesService: ModesService,
    private geometryPropertiesService: GeometryPropertiesService
  ) {
    this.selectedWallMode = 1;
    this.minWallWidth = 1.6;
    this.geometryPropertiesService.updateCurrentLineWidth(2);
    this.currentLineWidth = this.geometryPropertiesService.currentLineWidth;
    this.geometryPropertiesService.updateCurrentLineType(null);
    this.geometryPropertiesService.resetActiveFillColor();
    this.geometryPropertiesService.resetActiveStrokeColor();
  }

  ngOnInit(): void {
  }

  isInsertMode(): boolean {
    return this.modesService.mode === ActiveMode.insertMode;
  }

  onBlur() {
    this.eventsPropagationService.enableEvents();
  }

  onFocus() {
    this.eventsPropagationService.disableEvents();
  }

  ngOnDestroy() {
    this.onBlur();
  }

  setWallMode(mode) {
    this.selectedWallMode = mode;
    switch(mode) {
      case 1: {
        this.updateLineWidth(2, 1.6);
        break;
      }
      case 2: {
        this.updateLineWidth(1, 0.6);
        break;
      }
      case 3: {
        this.updateLineWidth(0.5, 0.15);
        break;
      }
      default: return
    }
  }

  updateLineWidth(width: number, minWidth: number) {
    this.geometryPropertiesService.updateCurrentLineWidth(width);
    this.currentLineWidth = this.geometryPropertiesService.currentLineWidth;
    this.minWallWidth = minWidth;
  }

  updateLineWidthFromModel(value) {
    if(value < this.minWallWidth) {
      this.geometryPropertiesService.updateCurrentLineWidth(this.minWallWidth);
    }
    else {
      this.geometryPropertiesService.updateCurrentLineWidth(value);
    }
    this.currentLineWidth = this.geometryPropertiesService.currentLineWidth;
  }

}
