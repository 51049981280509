<div class="w-100" xLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
    <div ngClass.gt-sm="w-25"></div>
    <div ngClass.xs="p-lr" ngClass.sm="p-lr-2" class="p-t-3 full-height-container" fxFlex fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">
        <div class="w-100" *ngFor="let dataElement of data; let i = index">
            <h2 fxLayout="column" fxLayoutAlign="center center" *ngIf="dataElement.type === 'title'">{{dataElement.content}}</h2>
            <h3 fxLayout="row" fxLayoutAlign="start center" *ngIf="dataElement.type === 'heading'">{{dataElement.content}}</h3>
            <h4 fxLayout="row" fxLayoutAlign="start center" *ngIf="dataElement.type === 'subheading'">{{dataElement.content}}</h4>
            <p *ngIf="dataElement.type === 'passage'">{{dataElement.content}}</p>
            <span *ngIf="dataElement.type === 'span'">{{dataElement.content}}</span>
            <span class="underline" *ngIf="dataElement.type === 'span_underlined'">{{dataElement.content}}</span>
            <div class="m-l" *ngIf="dataElement.type == 'list'">
                <span *ngFor="let listItem of dataElement.elements" fxLayout="row" fxLayoutAlign="start center" fxlayoutGap="15px" class="w-100 p-b">
                    <mat-icon fxLayout="row" fxLayoutAlign="center center" class="list-mark small primary">remove</mat-icon>
                    <span class="w-90">
                        <span class="p-l">{{listItem}}</span>
                    </span>
                </span>
            </div>
            <div class="m-l-2"*ngIf="dataElement.type == 'list_2'">
                <span *ngFor="let listItem of dataElement.elements" fxLayout="row" fxLayoutAlign="start center" fxlayoutGap="15px" class="w-100 m-l p-b">
                    <mat-icon fxLayout="row" fxLayoutAlign="center center" class="list-mark small primary">radio_button_unchecked</mat-icon>
                    <span class="w-90 p-l">
                        <span>{{listItem}}</span>
                    </span>
                </span>
            </div>
            <div class="w-100" *ngIf="dataElement.type === 'full_image'">
                <img (click)="openPictureDialog(dataElement.path)" class="w-100 ep-link" [src]="dataElement.path" alt="" onerror="this.onerror=null; this.src='assets/images/placeholder.png'" draggable="false">
            </div>
            <div class="w-50" *ngIf="dataElement.type === 'half_image'">
                <img (click)="openPictureDialog(dataElement.path)" class="w-100 ep-link" [src]="dataElement.path" alt="" onerror="this.onerror=null; this.src='assets/images/placeholder.png'" draggable="false">
            </div>
        </div>
    </div>
    <div ngClass.gt-sm="w-25"></div>
</div>
