<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
    <div fxLayout="column" fxLayoutAlign="center center">
        <div *ngIf="this.passage.children">
            <p *ngFor="let p of passage.children">{{p}}</p>
        </div>
        <div *ngIf="!this.passage.children">
            <p>{{passage}}</p>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <button mat-flat-button class="ep-btn-call" (click)="submitValue(true)">Ja</button>    
        <button mat-flat-button (click)="submitValue(false)">Nein</button>    
    </div>
</div>