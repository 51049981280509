import { VerticalControls } from './vertical-controls.model';
import { HorizontalControls } from './horizontal-controls.model';

export class ApplicationControls {
    verticalControls: VerticalControls;
    horizontalControls: HorizontalControls;

    constructor(){
        this.verticalControls = new VerticalControls();
        this.horizontalControls = new HorizontalControls();
    }
}