import { Injectable } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Subject } from "rxjs";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { NotificationComponent } from "src/app/components/notification/notification.component";
import { TranslationService } from "../../operational-services/translation/translation.service";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(public snackBar: MatSnackBar, private translationService: TranslationService) {
  }

  openNotification(message: string, translate: boolean = true, duration = 3500, position: MatSnackBarConfig = { horizontalPosition: 'end', verticalPosition: 'top'}) {
    message = translate ? this.translationService.translateSimpleToken("notification."+message) : message;
    this.snackBar.openFromComponent(NotificationComponent, {
      data: {
        text: message,
      },
      duration: duration,
      horizontalPosition: position.horizontalPosition,
      verticalPosition: position.verticalPosition ? position.verticalPosition : 'top'
    });

    return this.snackBar;
  }

  dismiss() {
    this.snackBar.dismiss();
  }}
