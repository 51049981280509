<div class="w-100" xLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
    <div ngClass.gt-sm="w-25"></div>
    <div ngClass.xs="p-lr" ngClass.sm="p-lr-2" class="p-t-3 full-height-container" fxFlex fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">
        <div class="w-100" *ngFor="let dataElement of data; let i = index">
            <div *ngIf="dataElement.type === 'border-box'" class="padding faq-border-box">
                <h3 class="no-m text-center">{{dataElement.title}}</h3>
                <p class="text-center">{{dataElement.content}}</p>
            </div>
            <h2 fxLayout="column" fxLayoutAlign="center center" *ngIf="dataElement.type === 'title'">{{dataElement.content}}</h2>
            <h3 fxLayout="row" fxLayoutAlign="start center" *ngIf="dataElement.type === 'heading'">{{dataElement.content}}</h3>
            <p *ngIf="dataElement.type === 'passage'">{{dataElement.content}}</p>
            <mat-accordion *ngIf="dataElement.type === 'accordion'">
                <mat-expansion-panel class="p-tb">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div>
                            <span >{{dataElement.title}}</span>&nbsp;
                        </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div fxLayout="row" fxLayoutAlign="start center" class="m-b" *ngIf="dataElement.content && !dataElement.content.type">
                    <p>{{dataElement.content}}</p>
                  </div>
                  <div class="m-b" *ngIf="dataElement.content && dataElement.content.type" [class.inline]="dataElement.content.type === 'inline'">
                    <div class="w-100" *ngFor="let contentElement of dataElement.content.elements; let i = index" [class.inline-content]="dataElement.content.type === 'inline'">
                      <div *ngIf="contentElement.type === 'innerHTML'" [innerHTML]="contentElement.content"></div>
                      <a *ngIf="contentElement.type === 'link'" href="{{contentElement.path}}" target="_blank">{{contentElement.title}}</a>
                      <h2 fxLayout="column" fxLayoutAlign="center center" *ngIf="contentElement.type === 'title'">{{contentElement.content}}</h2>
                      <h3 fxLayout="row" fxLayoutAlign="start center" *ngIf="contentElement.type === 'heading'">{{contentElement.content}}</h3>
                      <h4 fxLayout="row" fxLayoutAlign="start center" *ngIf="contentElement.type === 'subheading'">{{contentElement.content}}</h4>
                      <p *ngIf="contentElement.type === 'passage'">{{contentElement.content}}</p>
                      <span *ngIf="contentElement.type === 'span'">{{contentElement.content}}</span>
                      <span class="underline" *ngIf="contentElement.type === 'span_underlined'">{{contentElement.content}}</span>
                      <div class="m-l" *ngIf="contentElement.type == 'list'">
                          <span *ngFor="let listItem of contentElement.elements" fxLayout="row" fxLayoutAlign="start center" fxlayoutGap="15px" class="w-100 p-b">
                              <mat-icon fxLayout="row" fxLayoutAlign="center center" class="list-mark small primary">remove</mat-icon>
                              <span class="w-90">
                                  <span class="p-l">{{listItem}}</span>
                              </span>
                          </span>
                      </div>
                      <div class="m-l-2"*ngIf="contentElement.type == 'list_2'">
                          <span *ngFor="let listItem of contentElement.elements" fxLayout="row" fxLayoutAlign="start center" fxlayoutGap="15px" class="w-100 m-l p-b">
                              <mat-icon fxLayout="row" fxLayoutAlign="center center" class="list-mark small primary">radio_button_unchecked</mat-icon>
                              <span class="w-90 p-l">
                                  <span>{{listItem}}</span>
                              </span>
                          </span>
                      </div>
                      <div class="w-100" *ngIf="contentElement.type === 'full_image'">
                          <img (click)="openPictureDialog(contentElement.path)" class="w-100 ep-link" [src]="contentElement.path" alt="" onerror="this.onerror=null; this.src='assets/images/placeholder.png'" draggable="false">
                      </div>
                      <div class="w-50" *ngIf="contentElement.type === 'half_image'">
                          <img (click)="openPictureDialog(contentElement.path)" class="w-100 ep-link" [src]="contentElement.path" alt="" onerror="this.onerror=null; this.src='assets/images/placeholder.png'" draggable="false">
                      </div>
                  </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
        </div>
    </div>
    <div ngClass.gt-sm="w-25"></div>
</div>
