<div class="p-lr-2">
    <h2>Typographie</h2>
    <div class="p-lr">
        <span>Fließtext</span>
        <h1>Überschrift 1</h1>
        <h2>Überschrift 2</h2>
        <h3>Überschrift 3</h3>
    </div>
    <mat-divider></mat-divider>
    
    <h2>Farben</h2>
    <div class="p-b" fxLayout="row wrap" fxLayoutAlign="start" fxLayoutAlign.gt-md="space-evenly">
        <div  class="bg-grey p-2 m-b" fxLayoutAlign="center center">
            <span class="font-white">grey</span>
        </div>
        <div  class="bg-dark p-2 m-b" fxLayoutAlign="center center">
            <span class="font-white">dark</span>
        </div>
        <div  class="bg-white p-2 m-b" fxLayoutAlign="center center">
            <span>white</span>
        </div>
        <div  class="bg-light p-2 m-b" fxLayoutAlign="center center">
            <span>light</span>
        </div>
        <div  class="bg-light-greyblue p-2 m-b" fxLayoutAlign="center center">
            <span class="font-white">light-greyblue</span>
        </div>
        <div  class="bg-normal-greyblue p-2 m-b" fxLayoutAlign="center center">
            <span class="font-white">normal-greyblue</span>
        </div>
        <div  class="bg-dark-greyblue p-2 m-b" fxLayoutAlign="center center">
            <span class="font-white">dark-greyblue</span>
        </div>
    </div>
    
    <mat-divider></mat-divider>
    <h2>Buttons</h2>
    <div class="padding bg-light" fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="15px">
      <button mat-flat-button>Basic</button>
      <button mat-flat-button class="ep-btn-call">call-to-action</button>
      <button mat-stroked-button class="ep-btn-system">system</button>
      <button mat-flat-button class="ep-btn" color="primary">Primary</button>
      <button mat-flat-button class="ep-btn" color="accent">Accent</button>
      <button mat-flat-button class="ep-btn" color="warn">Warn</button>
      <button mat-flat-button disabled>Disabled</button>
        <a mat-flat-button href="https://www.google.com/" target="_blank">Link</a>
    </div>
    <mat-divider></mat-divider>
    <h2>Navigation</h2>
    <app-nav></app-nav>
    <mat-divider></mat-divider>
    <h2>Icons</h2>
    <h3>font awesome</h3>
    <div *ngIf="isBrowser" class="p-2 bg-light" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-icon *ngFor="let icon of listFAIcons" class="p-b" svgIcon="{{icon}}"></mat-icon>
    </div>
    <h3>material icons mit eigenen SVG's</h3>
    <div *ngIf="isBrowser" class="p-2 bg-light" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-icon *ngFor="let ownIcon of listOwnIcons" svgIcon="{{ownIcon}}"></mat-icon>
    </div>
    <mat-divider></mat-divider>
    <h2>Text-links</h2>
    <div class="p-2">
        <a href="https://www.google.com/" class="ep-link" target="_blank">Link</a>
    </div>
    <mat-divider></mat-divider>
    <h2>Inputfelder</h2>
    <div class="">
        <form>
            <mat-form-field>
              <mat-label>Email</mat-label>
              <input type="email" matInput [formControl]="emailFormControl" placeholder="Ex. pat@example.com">
              <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                Bitte valide email Adresse eingeben
              </mat-error>
              <mat-error *ngIf="emailFormControl.hasError('required')">
                Email ist <strong>erforderlich</strong>
              </mat-error>
            </mat-form-field>
          </form>
    </div>
    <mat-divider></mat-divider>
    <h2>Dropdowns</h2>
    <div>
        <mat-form-field>
            <mat-label>Option auswählen</mat-label>
            <mat-select>
                <mat-option value="one">Erste 1</mat-option>
                <mat-option value="two">Zweite 2</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <mat-divider></mat-divider>
    <h2>Zähler</h2>
    <div>
        <mat-form-field>
            <mat-label>Stärke der Wand eingeben (mm)</mat-label>
            <input type="number" [(ngModel)]="thickness" matInput>
          </mat-form-field>
    </div>
    <mat-divider></mat-divider>
    <h2>Textareas</h2>
    <div>
        <mat-form-field>
            <mat-label>Beschriebung eingeben...</mat-label>
            <textarea matInput></textarea>
          </mat-form-field>
    </div>
    <mat-divider></mat-divider>
    <h2>Checkboxes</h2>
    <div class="padding">
        <mat-checkbox [(ngModel)]="checked">
          Bitte klicken
        </mat-checkbox>
    </div>
    <mat-divider></mat-divider>
    <h2>Radio button</h2>
    <div class="padding">
        <mat-radio-group fxLayoutGap="10px">
            <mat-radio-button value="1">Option 1</mat-radio-button>
            <mat-radio-button value="2">Option 2</mat-radio-button>
        </mat-radio-group>          
    </div>
    <mat-divider></mat-divider>
    <h2>Date picker</h2>
    <div class="padding">
        <mat-form-field appearance="fill">
            <mat-label>Datum auswählen</mat-label>
            <input matInput [matDatepicker]="picker">
            <mat-datepicker-toggle matSuffix [for]="picker">
              <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
    </div>
    <mat-divider></mat-divider>
    <h2>Accordions</h2>
    <div class="padding">
        <mat-accordion>
            <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
              <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-between center">
                <mat-panel-title>
                  Persönliche Daten
                </mat-panel-title>
                <mat-panel-description>
                  Name und Alter eingeben
                </mat-panel-description>
                <mat-icon>account_circle</mat-icon>
              </mat-expansion-panel-header>
              <div fxLayoutGap="8px">
                <mat-form-field class="lh-20">
                <mat-label>Vorname</mat-label>
                <input matInput>
                </mat-form-field>
                <mat-form-field class="lh-20">
                <mat-label>Alter</mat-label>
                <input matInput type="number" min="1">
                </mat-form-field>
                </div>
              <mat-action-row>
                <button mat-flat-button class="ep-btn-call" (click)="nextStep()">Weiter</button>
              </mat-action-row>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-panel-title>
                  Persönliche Daten
                </mat-panel-title>
                <mat-panel-description>
                  Name und Alter eingeben
                </mat-panel-description>
                <mat-icon>account_circle</mat-icon>
              </mat-expansion-panel-header>
              <div fxLayoutGap="8px">
                <mat-form-field class="lh-20">
                    <mat-label>Vorname</mat-label>
                    <input matInput>
                  </mat-form-field>
              
                  <mat-form-field class="lh-20">
                    <mat-label>Alter</mat-label>
                    <input matInput type="number" min="1">
                  </mat-form-field>
              </div>
              <mat-action-row>
                <button mat-flat-button class="ep-btn-call" (click)="nextStep()">Weiter</button>
              </mat-action-row>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <mat-divider></mat-divider>
    <h2>Tabs</h2>
    <div class="padding">
      <div class="ep-tabs-container" fxLayout="row" fxLayoutAlign="space-between start">
        <div class="ep-tabs-labels" fxFlex="20" fxLayout="column" fxLayoutAlign="center start">
          <div class="ep-label-wrapper">
            <button mat-button (click)="goToTAb(0)" [ngClass]="{'active-tab' : activeTab === 0}">Option 1</button>
          </div>
          <div class="ep-label-wrapper">
            <button mat-button (click)="goToTAb(1)" [ngClass]="{'active-tab' : activeTab === 1}">Option 2</button>
          </div>
          <div class="ep-label-wrapper">
            <button mat-button (click)="goToTAb(2)" [ngClass]="{'active-tab' : activeTab === 2}">Option 3</button>
          </div>

        </div>
        <div class="ep-tabs-content-container" fxFlex="80">
          <div *ngIf="activeTab === 0" class="ep-tab-content">
            Option 1
          </div>
          <div *ngIf="activeTab === 1" class="ep-tab-content">
            Option 2
          </div>
          <div *ngIf="activeTab === 2" class="ep-tab-content">
            Option 3
          </div>
        </div>
      </div>
      <div>

      </div>
    </div>
    <mat-divider></mat-divider>
    <h2>Popups</h2>
    <div class="padding">
        <button mat-button (click)="openDialog()">Popup öffnen</button>
    </div>
    <mat-divider></mat-divider>
    <h2>Progressbar</h2>
    <button mat-flat-button [disabled]="progressvalue > 0" [ngClass]="{'ep-btn-call' : progressvalue === 0}" (click)="animateProgressBar()">Progressbar starten</button>
    <div class="padding">
        <mat-progress-bar mode="determinate" value="{{progressvalue}}"></mat-progress-bar>
    </div>
</div>