import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of, Subscription } from 'rxjs';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { DataService, FileService, TranslationService, ViewerOperationsService, ViewerService } from 'src/app/services';
import { AdditionalToolsService } from 'src/app/services/status-and-control-services/additional-tools/additional-tools.service';
import { ModesService } from 'src/app/services/status-and-control-services/modes/modes.service';
import { NotificationService } from 'src/app/services/status-and-control-services/notification/notification.service';
import { PlanService } from 'src/app/services/user/plan/plan.service';
import { ProjectService } from 'src/app/services/user/project/project.service';
import { InfoDialogComponent } from '../../dialogs/info-dialog/info-dialog.component';
import { InputDialogComponent } from '../../dialogs/input-dialog/input-dialog.component';
import { ProjectChoiceComponent } from '../../dialogs/project-choice/project-choice.component';

@Component({
  selector: 'app-horizontal-controls',
  templateUrl: './horizontal-controls.component.html',
})
export class HorizontalControlsComponent implements OnInit {
  editing: boolean;
  editingSub: Subscription;
  constructor(
    private translationService: TranslationService,
    private dialog: MatDialog,
    private viewerService: ViewerService,
    private viewerOperationsService: ViewerOperationsService,
    private modesService: ModesService,
    private additionalToolsService: AdditionalToolsService,
    private projectService: ProjectService,
    private planService: PlanService,
    private notificationService: NotificationService,
    private fileService: FileService,
    private dataService: DataService
  ) {
    this.editingSub = this.viewerOperationsService.editting$.subscribe(status => {
      this.editing = status;
    })
  }

  ngOnInit(): void {
  }

  get isFrameMode(): boolean {
    return this.modesService.mode === ActiveMode.frameMode;
  }

  ngOnDestroy() {
    this.editingSub.unsubscribe();
  }

  stopEvent(e: MouseEvent) {
    e.stopPropagation();
  }

  toggleModes() {
    let mode = this.viewerOperationsService.editing ? ActiveMode.moveMode : ActiveMode.editMode;
    if(mode === ActiveMode.moveMode) {
      this.setMoveMode();
    }
    else if(mode === ActiveMode.editMode) {
      this.enableEditMode();
    }
  }

  toggleAdditionalTools(componentName) {
    this.additionalToolsService.toggleAdditionalTools(componentName);
  }

  setMoveMode() {
    this.enableMoveMode();
    this.closeAdditionalToolsByComponent('move');
  }

  closeAdditionalToolsByComponent(componentName) {
    this.additionalToolsService.closeAdditionalTools(componentName);
  }

  enableEditMode() {
    this.toggleAdditionalTools("edit");
    if(!this.modesService.editableMode) {
      this.modesService.setMode(ActiveMode.editMode);
      if(!this.viewerOperationsService.editing) {
        this.viewerOperationsService.toggleEdittingViewer(ActiveMode.editMode);
      }
    }
  }

  enableMoveMode() {
    if(this.modesService.mode === ActiveMode.editMode || this.modesService.mode === ActiveMode.insertMode) {
      this.modesService.setMode(ActiveMode.moveMode);
      this.savePlan().subscribe(
        () => {},
        error => {
          this.viewerService._loadingSource.next(false);
          this.notificationService.openNotification('500');
        }
      );
    }
    else if(this.modesService.mode === ActiveMode.frameMode) {
      this.viewerService._loadingSource.next(true);
      this.viewerOperationsService.editing = false;
      this.viewerOperationsService._edittingSource.next(this.viewerOperationsService.editing);
      this.modesService.setMode(ActiveMode.moveMode);
      this.dataService.initGeometricalData();
      this.planService.getPlanById(this.planService.activePlanId).subscribe((response: any) => {
        if(response.data.drawingBase64) {
          this.fileService.dxfFile = response.data.drawingBase64;
        }
        this.fileService.emitFileInfo();
      })
    }
    else {
      this.notificationService.openNotification('change_to_edit_warning');
    }
  }

  savePlan(): Observable<any> {
    const obs: Observable<any> = new Observable(observer => {
      this.viewerService._loadingSource.next(true);
      const projectId = this.projectService.activeProjectId;
      if(!projectId || projectId === 'sampleProject' ) {
        const dialogRefPass = this.dialog.open(ProjectChoiceComponent);
        dialogRefPass.afterClosed().subscribe(resultProjectId => {
          if(resultProjectId) {
            const dialogRefPass = this.dialog.open(InputDialogComponent, {
              data: {
                value: 'Musterplan',
                valueName: 'Plan-Name',
                buttonText: 'Name speichern'
              }
            });
            dialogRefPass.afterClosed().subscribe(resultPlanNameResponse => {
              if(resultPlanNameResponse) {
                this.createNewPlan(resultProjectId, resultPlanNameResponse.planName).subscribe(result => {
                  if(result) {
                    this.notificationService.openNotification('save_plan_success');
                    this.viewerService._loadingSource.next(false);
                    this.planService.recentlySaved = true;
                    observer.next(true)
                  }
                });
              }
            })
          }
          else {
            observer.next(false)
            this.viewerService._loadingSource.next(false);
          }
        })
      }
      else if(projectId && this.planService.activePlanId) {
        this.viewerOperationsService.toggleEdittingViewer(ActiveMode.moveMode);
        observer.next(true);
      }
      else {
        this.viewerService._loadingSource.next(false);
        let infoString = this.translationService.translateSimpleToken('NO_ACTIVE_DRAWING');
        let errorTitle = this.translationService.translateSimpleToken('ERROR');
        this.dialog.open(InfoDialogComponent, {data: {kind: errorTitle, serviceErrorCode: infoString}});
      }
    })
    return obs;
  }

  createNewPlan(projectId,planName) {
    let obs: Observable<any> = new Observable(observer => {
      this.viewerService._loadingSource.next(true);
      let planData = {
          drawingName: planName,
          projectId: projectId
        }
        this.planService.createPlan(planData).subscribe(response => {
          this.projectService.getMyProjects().subscribe((projectsResponse: any)  => {

            this.dataService.convertSpecial(this.planService.activePlanId).subscribe(() => {
              this.viewerOperationsService.scaleGeometry(this.dataService.geometricalData,1/this.viewerOperationsService.viewerMultiplier,false);
              this.dataService.geometricalData.forEach((layer, indexLayer: number) => {
                layer.geometry.forEach((geometryItem, indexGeometryItem: number) => {
                  if(geometryItem.title && geometryItem.title === 'previewimage') {
                    layer.geometry.splice(indexGeometryItem,1);
                  }
                });
              });
              this.dataService.filterSpecialGeometries(1/this.viewerOperationsService.viewerMultiplier).subscribe(() => {
                  this.dataService.mirrorAllToXAxis().subscribe(() => {
                    let createdPlan = projectsResponse.data.projects
                    .find(projectItem => projectItem.id === projectId).projectDrawings
                    .find(drawingItem => drawingItem.drawingName === planData.drawingName);
                    this.planService.activePlanId = createdPlan.id;
                    this.projectService.activeProjectId = projectId;
                    this.planService.drawingName = createdPlan.drawingName;
                    this.planService.savePlanAction(
                      projectId,
                      this.dataService.deletedEntityNames,
                      this.fileService.dxfFilePath,
                      this.dataService.newOrUpdatedEntities,
                      this.dataService.layerNames).subscribe(() => {
                        this.notificationService.openNotification("save_plan_success");
                        this.viewerService._loadingSource.next(false);
                    },
                    error => {
                      this.notificationService.openNotification("save_plan_error");
                      this.viewerService._loadingSource.next(false);
                    });
                    this.viewerOperationsService.editing = false;
                    this.viewerOperationsService._edittingSource.next(this.viewerOperationsService.editing);
                  })
                })

              })
          },
          error => {
            observer.error();
          })
        });
    })

    return obs;
  }

}
