import { Component, Inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";
import { PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import * as iconLists from "../assets/icons/icon-lists.json";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "Fluchtplan Manager";
  static isBrowser = new BehaviorSubject<boolean>(null);

  constructor(
    private translate: TranslateService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    if (isPlatformBrowser(platformId)) {
      translate.setDefaultLang("de");
      this.initIcons();
    }
  }

  initIcons() {
    let iconInfo:any = iconLists;
    iconInfo.default.forEach(element => {
      element.iconNames.forEach(icon => {
        this.iconRegistry.addSvgIcon(
          icon,
          this.sanitizer.bypassSecurityTrustResourceUrl(
            element.path + icon + ".svg"
          )
        );
      })
    });
  }
}
