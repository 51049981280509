import { Component, OnInit } from '@angular/core';
import { EventsPropagationService } from 'src/app/services/status-and-control-services/events-propagation/events-propagation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-additional-tools',
  templateUrl: './additional-tools.component.html',
  styleUrls: ['./additional-tools.component.scss']
})
export class AdditionalToolsComponent implements OnInit {
  constructor(
    private eventsPropagationService: EventsPropagationService,
  ) {}

  ngOnInit(): void {
  }

  stopEvent(e) {
    e.preventDefault();
    this.eventsPropagationService.disableEvents();
  }

  startEvent() {
    this.eventsPropagationService.enableEvents();
  }

}
