import { Geometry } from './geometry.model';
import { GeometryProperties } from './geometry-properties.model';
import { Extrema } from '../extrema.model';
import { VertexPosition } from '../position.model';
import { Point } from '../point.model';
import { ColorEnum } from '../enums/color.enum';

export class Rectangle implements Geometry {
    entityName: string;
    entityTag: string;
    geometricType: number;
    layerName: string;
    geometryProperties: GeometryProperties;
    selected?: boolean;

    //direction -> {x,y,z,isNormalized}, vector -> {x,y,z,isNormalized}
    origin: VertexPosition;
    width: number;
    height: number;
    intersectedControl: Point;
    rotation?: number

    roundUpCoords() {
        this.origin = new VertexPosition(this.origin,true);
    }
    
    constructor(data) {
        this.origin =  new VertexPosition(data.origin);
        this.width =  data.width;
        this.height =  data.height;
        this.geometryProperties =  new GeometryProperties({...data,...data.geometryProperties});
        this.entityName = data.entityName;
        this.entityTag = (data.entityTag && data.entityTag != data.entityName) ? data.entityTag : "tag_" + data.entityName;
        this.geometricType = data.geometricType;
        this.layerName = data.layerName;
        this.rotation = data.rotation ? data.rotation : 0;
    }
    
    extrema() {
        let extrema = new Extrema();
        let controls = this.getRectControls();
        extrema.maxX = controls[4].x;
        extrema.minX = controls[0].x;
        extrema.maxY = controls[4].y;
        extrema.minY = controls[0].y;
        return extrema;
    }

    rotatedCoordinate(coordinate: Point): Point {
        let centerX = this.origin.x + this.width/2
        let centerY = this.origin.y + this.height/2
        let tempX = coordinate.x - centerX;
        let tempY = coordinate.y - centerY;
        let radRotation = (this.rotation) * Math.PI / 180
        let rotatedX = tempX * Math.cos(radRotation) - tempY * Math.sin(radRotation) + centerX;
        let rotatedY = tempX * Math.sin(radRotation) + tempY * Math.cos(radRotation) + centerY;
        
        return new Point(rotatedX,rotatedY);
    }

    rotatedCoordinates(rotation = false) {
        let rectControls = rotation ? this.getSelectionRectControls() : this.getRectControls();
        rectControls.forEach(control => {
            let rotatedPos = this.rotatedCoordinate(control);
            control.x = rotatedPos.x;
            control.y = rotatedPos.y;
        });
        return rectControls;
    }

    scale(multiplier: Point,selectionRect: Rectangle=null, equalizeThicknessFactor: number = null) {
        this.origin.x *= selectionRect ? 1 : multiplier.x;
        this.origin.y *= selectionRect ? 1 : multiplier.y;
        this.width *= multiplier.x;
        this.height *= multiplier.y;
        if(equalizeThicknessFactor) {
            this.geometryProperties.thickness *= equalizeThicknessFactor;
        }
    }

    draw(context, scale, color, canvas, fillColor = null) {
        context.save();
        //context.setTransform(1,0,0,1,0,0);
        this.defineRectangle(context, scale);
        if(fillColor) {
            context.fillStyle = fillColor;
            context.fill();
        }
        context.strokeStyle = color ? color : ColorEnum.default;
        context.stroke();
        context.restore();
    }
    
    defineRectangle(context, scale) {
        context.translate((this.origin.x + this.width/2) * scale, (this.origin.y + this.height/2) * scale);
        if(this.rotation) {
            context.rotate((this.rotation) * Math.PI / 180);
        }
        context.beginPath();
        context.rect(-this.width/2 * scale, -this.height/2 * scale, this.width * scale, this.height * scale);
    }

    mouseIntersectsGeometry(mousePosition,context,canvas,scale) {
        context.save();
        //context.setTransform(1, 0, 0, -1, this.origin.x * scale + this.width * scale/2, this.origin.y * scale - this.height/2* scale);
        this.defineRectangle(context, scale);
        let intersected = context.isPointInPath(mousePosition.x, mousePosition.y);
        context.restore();
        if(
            intersected
        ) {
            return true;
        }
    }

    drawControls(mousePos,context,canvas,scale,singleRect = false) {
        if(!mousePos) {
            mousePos = new Point(0,0);
        }
        let rectControls = this.getSelectionRectControls();
        let controlDimensions = 10;
        context.save()
        //let vertivcalTranslation = singleRect ? canvas.clientHeight - (this.origin.y - this.height / 2)*scale : (canvas.clientHeight - (this.origin.y * scale));
        let transX = (this.origin.x + this.width / 2) * scale;
        let transY = (this.origin.y + this.height / 2) * scale;
        //context.setTransform(1,0,0,1,0,0);
        context.translate(transX, transY);
        if (this.rotation) {
            context.rotate(-(360 - this.rotation) * Math.PI / 180);
        }
        if(singleRect) {
            this.constructLineToRotationControl(context, scale, transX, transY);
        }
        rectControls.forEach(control => {
            if((control.name !== "rotation" && !singleRect) || singleRect) {
                context.fillStyle = "green";
                context.beginPath();
                context.rect(-transX + (control.x) * scale  - controlDimensions / 2, -transY + (control.y)*scale - controlDimensions / 2, controlDimensions, controlDimensions);
                let intersects = context.isPointInPath(mousePos.x, mousePos.y);
                context.fill();
                if (intersects) {
                    context.fillStyle = "yellow";
                    this.intersectedControl = control
                    context.fillRect(-transX + (control.x) * scale  - controlDimensions / 2, -transY + (control.y) * scale - controlDimensions / 2, controlDimensions, controlDimensions);
                }

            }
        })
        context.restore();
    }

    drawInformation(context,canvas,scale,visualisationMultiplier,unit,ratio) {
        context.save();
        context.fillStyle = ColorEnum.fontDefault;
        context.font = "18px Arial";
        context.beginPath();
        context.translate(this.origin.x * scale, this.origin.y * scale);
        context.fillText("Breite: " + Math.round(this.width * visualisationMultiplier * ratio * 1000) / 1000 + " " + unit, 0, this.height*scale + 20);
        context.fillText("Höhe: " + Math.round(this.height * visualisationMultiplier * ratio * 1000) / 1000  + " " + unit, 0, this.height*scale + 40);
        context.restore();
    }

    findControlToSnap(mousePos, context, canvas, scale, rotatable=false) {
        let rectControls = rotatable ? this.getSelectionRectControls() : this.getRectControls();
        if(this.rotation) {
            rectControls = this.rotatedCoordinates(true);
        }
        let pointRadius = 5;
        let controlPoint = null;
        rectControls.forEach(control => {
            if(this.intersectsControlPoint(control, pointRadius, mousePos, scale)) {
                controlPoint = control;
            }
        })
        return controlPoint;
    }

    getRectControls() {
        let rectControls = [
            {x: this.origin.x, y: this.origin.y, name: "leftTop", actions: ["originX", "originY", "height", "width"]},
            {x: this.origin.x + this.width/2, y: this.origin.y, name: "top", actions: ["originY", "height"]},
            {x: this.origin.x + this.width, y: this.origin.y, name: "rightTop", actions: ["originY", "height", "width"]},
            {x: this.origin.x + this.width, y: this.origin.y + this.height/2, name: "right", actions: ["width"]},
            {x: this.origin.x + this.width, y: this.origin.y + this.height, name: "rightBottom", actions: ["height", "width"] },
            {x: this.origin.x + this.width/2, y: this.origin.y + this.height, name: "bottom", actions: ["height"]},
            {x: this.origin.x, y: this.origin.y + this.height, name: "leftBottom", actions: ["originX", "height", "width"]},
            {x: this.origin.x, y: this.origin.y + this.height/2, name: "left", actions: ["originX", "width"]},
        ]
        return rectControls;
    }

    getSelectionRectControls() {
        let conrols = this.getRectControls();
        conrols.push({ x: this.origin.x + this.width / 2, y: this.origin.y - 50,name: "rotation", actions: ["rotation"] })
        return conrols;
    }

    constructLineToRotationControl(context, scale, transX, transY) {
        let rotationControl = this.getSelectionRectControls().find(element => element.name === "rotation");
        context.moveTo(-transX + (rotationControl.x) * scale,-transY + (rotationControl.y) * scale);
        context.lineTo(-transX + (this.origin.x + this.width/2 ) * scale, -transY + (this.origin.y) * scale);
        context.stroke();
    }

    intersectsControlPoint(position: Point, r, mousePos: Point, scale) {
        var distSqr = Math.pow(position.x * scale - mousePos.x, 2) + Math.pow(position.y *scale - mousePos.y, 2);
        if(distSqr < r * r) {
            return true;
        }
        else {
            return false;
        }
    }

    move(offset: Point) {
        this.origin.x -= offset.x;
        this.origin.y -= offset.y;
    }

    mirrorToYAxis(referenceX: number) {
        this.origin.x += (referenceX-this.origin.x)*2 - this.width;
        this.rotation = 360 - this.rotation;
    }

    mirrorToXAxis(referenceY: number, rotate: boolean = true) {
        this.origin.y += (referenceY-this.origin.y)*2 + this.height;
        if(rotate) {
            this.rotation = 180 - this.rotation;
            if(this.rotation < 0) {
                this.rotation = this.rotation + 360;
            }
        }
    }

}