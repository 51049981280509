import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AppSettingsService } from 'src/app/services/status-and-control-services/app-settings/app-settings.service';
import { EventsPropagationService } from 'src/app/services/status-and-control-services/events-propagation/events-propagation.service';

@Component({
  selector: 'app-grid-settings',
  templateUrl: './grid-settings.component.html',
  styleUrls: ['./grid-settings.component.scss']
})
export class GridSettingsComponent implements OnInit {
  private gridSettings: any;
  units: string;
  settingsForm: UntypedFormGroup
  constructor(
    private appSettingsService: AppSettingsService,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<GridSettingsComponent>,
    private eventsPropagationService: EventsPropagationService
  ) {
    this.units = this.appSettingsService.activeUnitTypeName;
    this.gridSettings = this.appSettingsService.gridSettings;
    this.initForm();
  }

  ngOnInit(): void {
  }

  onBlur() {
    this.eventsPropagationService.enableEvents();
  }

  onFocus() {
    this.eventsPropagationService.disableEvents();
  }

  resetSettings() {
    this.appSettingsService.initGridSettings();
    this.initForm();
  }

  resetColor(colorName) {
    this.settingsForm.get(colorName).setValue(this.appSettingsService.gridSettings[colorName]);
  }

  setColor(color,colorName) {
    this.settingsForm.get(colorName).setValue(color);
  }

  initForm() {
    this.settingsForm = this.formBuilder.group({
      gridOn: new UntypedFormControl(this.appSettingsService.gridSettings.gridOn),
      gridOnForeground: new UntypedFormControl(this.appSettingsService.gridSettings.gridOnForeground),
      cellSize: new UntypedFormControl(this.appSettingsService.dimensionToDisplay(this.appSettingsService.gridSettings.cellSize)),
      snapping: new UntypedFormControl(this.appSettingsService.gridSettings.snapping),
      gridColor: new UntypedFormControl(this.appSettingsService.gridSettings.gridColor),
      backgroundColor: new UntypedFormControl(this.appSettingsService.gridSettings.backgroundColor),
    });
  }

  onSubmit() {
    if(this.settingsForm.valid) {
      let visualisationMultiplier = this.appSettingsService.getVisualisationMultiplier();
      let params = {
        gridOn: this.settingsForm.value.gridOn,
        gridOnForeground: this.settingsForm.value.gridOnForeground,
        cellSize: parseFloat(this.settingsForm.value.cellSize)/visualisationMultiplier,
        snapping: this.settingsForm.value.snapping,
        gridColor: this.settingsForm.value.gridColor,
        backgroundColor: this.settingsForm.value.backgroundColor,
      }
      this.appSettingsService.saveGridSettings(params);
      this.closeDialog(true)
    }
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  closeDialog(value) {
    this.dialogRef.close(value);
  }

}
