import { Component, OnInit } from '@angular/core';
import { Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AuthenticationService } from 'src/app/services/user/authentication/authentication.service';
import { Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { PlanCloseDialogComponent } from '../dialogs/plan-close-dialog/plan-close-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DataService, FileService, LayerService, TranslationService, ViewerOperationsService, ViewerService } from 'src/app/services';
import { NotificationService } from 'src/app/services/status-and-control-services/notification/notification.service';
import { ProjectService } from 'src/app/services/user/project/project.service';
import { PlanService } from 'src/app/services/user/plan/plan.service';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { Observable } from 'rxjs';
import { ProjectChoiceComponent } from '../dialogs/project-choice/project-choice.component';
import { InputDialogComponent } from '../dialogs/input-dialog/input-dialog.component';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { Geometry } from 'src/app/models/geometries/geometry.model';
import { Layer } from 'src/app/models';
import { ModesService } from 'src/app/services/status-and-control-services/modes/modes.service';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';


@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  isBrowser: boolean;
  userDataSubscription: Subscription;
  isAuthenticated: boolean;
  loading: boolean;
  loadingSubscription: Subscription;
  editing: boolean;

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private authenticationService: AuthenticationService,
    private router: Router,
    private dialog: MatDialog,
    private layerService: LayerService,
    private translationService: TranslationService,
    private notificationService: NotificationService,
    private dataService: DataService,
    private projectService: ProjectService,
    private planService: PlanService,
    private viewerOperationsService: ViewerOperationsService,
    private viewerService: ViewerService,
    private fileService: FileService,
    private modesService: ModesService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if(this.isBrowser) {
      this.userDataSubscription = this.authenticationService.currentUser$
      .subscribe(userData => {
        this.isAuthenticated = userData ? true : false;
      });
      this.loadingSubscription = this.viewerService.loading$
      .subscribe(status => {
        this.loading = status;
      });
    }
    this.editing = this.viewerOperationsService.editing;

  }

  ngOnInit(): void {
  }

  isLoggedIn() {
    return this.authenticationService.isAuthenticated();
  }

  logOut() {
    let defaultGeometricalData = this.dataService.getGeometricalData().length === 1 && this.dataService.getGeometricalData()[0].layerName === "Background" && !this.dataService.getGeometricalData()[0].geometry.length;
    if((!defaultGeometricalData && this.dataService.getGeometricalData().length) && !this.planService.recentlySaved) {
      let dialogRefPass = this.dialog.open(ConfirmationDialogComponent,{
        data: {
          question: "logout"
        }
      });
      dialogRefPass.afterClosed().subscribe(result => {
        if(result) {
          let dialogRefPass = this.dialog.open(PlanCloseDialogComponent);
          dialogRefPass.afterClosed().subscribe(result => {
            if(result) {
              if(result === "save") {
                this.savePlan().subscribe(res => {
                  if(res) {
                    this.layerService.initLayersFromDataBase(null).subscribe(res=>{});
                    this.authenticationService.logout().subscribe(loggedOut => {
                      if(loggedOut) {
                        this.router.navigate(["authorization/login"])
                      }
                    })
                  }
                });
              }
              else if(result === "dismiss")  {
                this.layerService.initLayersFromDataBase(null).subscribe(res=>{});
                this.authenticationService.logout().subscribe(loggedOut => {
                  if(loggedOut) {
                    this.router.navigate(["authorization/login"])
                  }
                })
              }
            }
          })
        }
      })
      }
      else {
        let dialogRefPass = this.dialog.open(ConfirmationDialogComponent,{
          data: {
            question: "logout"
          }
        });
        dialogRefPass.afterClosed().subscribe(result => {
          if(result) {
            this.layerService.initLayersFromDataBase(null).subscribe(res=>{});
            this.authenticationService.logout().subscribe(loggedOut => {
              if(loggedOut) {
                this.router.navigate(["authorization/login"])
              }
            })
          }
        })
      }
  }

  createNewPlan(projectId,planName) {
    let obs: Observable<any> = new Observable(observer => {
      this.viewerService._loadingSource.next(true);
        let planData = {
          drawingName: planName,
          projectId: projectId
        }
        this.planService.createPlan(planData).subscribe(response => {
          this.projectService.getMyProjects().subscribe((projectsResponse: any)  => {
            let createdPlan = projectsResponse.data.projects.find(projectItem => projectItem.id === projectId).projectDrawings.find(drawingItem => drawingItem.drawingName === planData.drawingName);
            let copiedPlanData = {
              drawingName: createdPlan.drawingName,
              drawingDxfPath: createdPlan.drawingDxfPath,
              newAndUpdatedEntitiesJson: JSON.stringify(this.dataService.newOrUpdatedEntities),
              drawingJsonPath: createdPlan.drawingJsonPath,
            }
            this.planService.editPlan(copiedPlanData,createdPlan.id).subscribe(editResp => {
              this.viewerService._loadingSource.next(false);
              this.planService.recentlySaved = true;
              observer.next(true);
            },
            error => {
              observer.error();
            })
          },
          error => {
            this.viewerService._loadingSource.next(false);
            observer.error();
          })
        },
        error => {
          this.viewerService._loadingSource.next(false);
          observer.error();
        });
    })

    return obs;
  }

  savePlan(): Observable<any> {
    let obs: Observable<any> = new Observable(observer => {
      this.viewerService._loadingSource.next(true);
      let projectId = this.projectService.activeProjectId;
      if(!projectId || projectId === "sampleProject" ) {
        let dialogRefPass = this.dialog.open(ProjectChoiceComponent);
        dialogRefPass.afterClosed().subscribe(resultProjectId => {
          if(resultProjectId) {
            let dialogRefPass = this.dialog.open(InputDialogComponent, {
              data: {
                value: "Musterplan",
                valueName: "Plan-Name",
                buttonText: "Name speichern"
              }
            });
            dialogRefPass.afterClosed().subscribe(resultPlanNameResponse => {
              if(resultPlanNameResponse) {
                this.createNewPlan(resultProjectId, resultPlanNameResponse.planName).subscribe(result => {
                  if(result) {
                    this.notificationService.openNotification("save_plan_success");
                    this.viewerService._loadingSource.next(false);
                    this.planService.recentlySaved = true;
                    observer.next(true);
                    this.goToProjects();
                  }
                });
              }
            })
          }
          else {
            observer.next(false)
            this.viewerService._loadingSource.next(false);
          }
        })
      }
      else if(projectId && this.planService.activePlanId) {
        this.dataService.convertSpecial(this.planService.activePlanId).subscribe(res => {
        this.viewerOperationsService.scaleGeometry(this.dataService.geometricalData,1/this.viewerOperationsService.viewerMultiplier,false);
        this.dataService.geometricalData.forEach((layer: Layer, indexLayer: number) => {
          layer.geometry.forEach((geometryItem: Geometry, indexGeometryItem: number) => {
            if(geometryItem.title && geometryItem.title === 'previewimage') {
              layer.geometry.splice(indexGeometryItem,1);
            }
          });
        });
        this.dataService.filterSpecialGeometries(1/this.viewerOperationsService.viewerMultiplier).subscribe(res => {
            this.dataService.mirrorAllToXAxis().subscribe(res => {
              this.planService.savePlanAction
              (this.projectService.activeProjectId,
                this.dataService.deletedEntityNames,
                this.fileService.dxfFilePath,
                this.dataService.newOrUpdatedEntities,
                this.dataService.layerNames,
                true).subscribe(() => {
                this.notificationService.openNotification("save_plan_success");
                this.viewerService._loadingSource.next(false);
                this.goToProjects();
              });
              this.viewerOperationsService._edittingSource.next(this.editing);
            })
          })

        },
        error => {
          this.notificationService.openNotification("save_plan_error");
          this.viewerService._loadingSource.next(false);
        });
      }
      else {
        this.viewerService._loadingSource.next(false);
        let infoString = this.translationService.translateSimpleToken("NO_ACTIVE_DRAWING");
        let errorTitle = this.translationService.translateSimpleToken("ERROR");
        this.dialog.open(InfoDialogComponent, {data: {kind: errorTitle, serviceErrorCode: infoString}});
      }
    })
    return obs;
  }

  navigateTo(route: string) {
    let defaultGeometricalData = this.dataService.getGeometricalData().length === 1 && this.dataService.getGeometricalData()[0].layerName === "Background" && !this.dataService.getGeometricalData()[0].geometry.length;
    if((!defaultGeometricalData && this.dataService.getGeometricalData().length) && this.modesService.mode !== ActiveMode.frameMode && !this.planService.recentlySaved) {
      let dialogRefPass = this.dialog.open(PlanCloseDialogComponent);
      dialogRefPass.afterClosed().subscribe(result => {
        if(result) {
          if(result === "save") {
            this.viewerService._loadingSource.next(true);
            let projectId = this.projectService.activeProjectId;
            if(projectId && this.planService.activePlanId) {
              if(result === "save") {
                this.savePlan().subscribe(res => {
                  if(res) {
                    this.goToProjects();
                  }
                });
              }
            }
            else {
              this.viewerService._loadingSource.next(false);
              let infoString = this.translationService.translateSimpleToken("NO_ACTIVE_DRAWING");
              let errorTitle = this.translationService.translateSimpleToken("ERROR");
              this.dialog.open(InfoDialogComponent, {data: {kind: errorTitle, serviceErrorCode: infoString}});
            }
          }
            else {
              this.goToProjects();
            }
          }
        },
        error => {
          console.log('Navigation Component Error: ' , error);
          this.viewerService._loadingSource.next(false);
        })
    }
    else {
      this.goToProjects();
    }
  }

  goToProjects() {
    this.layerService.initLayersFromDataBase(null).subscribe(()=>{
      this.modesService.setMode(ActiveMode.moveMode);
      this.viewerOperationsService.editing = false;
      this.viewerOperationsService._edittingSource.next(this.viewerOperationsService.editing);
      this.router.navigate(['../projects']);
    });
  }

  getPlanName(): string {
    return this.planService.drawingName ? this.planService.drawingName : "";
  }

}
