import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { DataService, RenderService, ViewerService, MouseInteractionsService, TranslationService, ViewerOperationsService, FileService, SignalrService, LayerService } from '../../services';
import { Subscription } from 'rxjs';
import { Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { FitAreaData, Layer, Point } from 'src/app/models';
import { ModesService } from '../../services/status-and-control-services/modes/modes.service';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { DrawingService } from 'src/app/services/operational-services/drawing/drawing.service';
import { AdditionalToolsService } from 'src/app/services/status-and-control-services/additional-tools/additional-tools.service';
import { GeometryEditingService } from 'src/app/services/operational-services/geometry-editing/geometry-editing.service';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { DrawingMode } from 'src/app/models/enums/drawing-mode.enum';
import { EventsPropagationService } from 'src/app/services/status-and-control-services/events-propagation/events-propagation.service';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { SelectionService } from 'src/app/services/status-and-control-services/selection/selection.service';
import { CanvasDimensionsService } from 'src/app/services/status-and-control-services/canvas-dimensions/canvas-dimensions.service';
import { PrintService } from 'src/app/services/operational-services/print/print.service';
import { PrintSetupDialogComponent } from '../dialogs/print-setup-dialog/print-setup-dialog.component';
import { ProfileService } from 'src/app/services/user/profile/profile.service';
import { Router } from '@angular/router';
import { ExportService } from 'src/app/services/operational-services/export/export.service';
import { ExportSetupDialogComponent } from '../dialogs/export-setup-dialog/export-setup-dialog.component';
import { PlanService } from 'src/app/services/user/plan/plan.service';
import { DxfViewerService } from 'src/app/services/operational-services/dxf-viewer/dxf-viewer.service';
import { Rectangle } from 'src/app/models/geometries/rectangle.model';
import { EPImage } from 'src/app/models/geometries/ep-image.model';
import { GeometricType } from 'src/app/models/enums/geometric-types.enum';
import { ProjectService } from 'src/app/services/user/project/project.service';
import { NotificationService } from 'src/app/services/status-and-control-services/notification/notification.service';
@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit, AfterViewInit {
  @ViewChild('canvas') canvasRef: ElementRef;
  @ViewChild('gridCanvas') gridCanvasRef: ElementRef;
  @ViewChild("cadCanvas")
  private cadCanvasRef: ElementRef = new ElementRef(null);
  private previewCanvas;
  @ViewChild('horizontalRuler') horRulerRef: ElementRef;
  @ViewChild('verticalRuler') vertRulerRef: ElementRef;
  @ViewChild('viewer') viewerRef: ElementRef;
  @ViewChild('horizontalRulerContainer') horizontalRulerContainerRef: ElementRef;
  @ViewChild('verticalRulerContainer') verticalRulerContainerRef: ElementRef;
  private ctx: CanvasRenderingContext2D;
  cRect: any = {};
  isBrowser: boolean;
  geometricalData : Array<Layer>;
  geometricalDataSubscription: Subscription;
  mousePositionSubscription: Subscription;
  gridSubscription: Subscription;
  cameraOffsetSubscription: Subscription;
  modeSubscription: Subscription;
  scale: number;
  additionalToolsOpenSubscription: Subscription;
  additionalToolsOpen: boolean;
  eventsDisabled: boolean;
  eventsDisabledsubscription: Subscription;
  printSubscription: Subscription;
  exportSubscription: Subscription;
  rulerSubscription: Subscription;
  fitSubscription: Subscription;
  rulerOn: boolean;
  loading: boolean;
  demoVersion: boolean;
  grid: boolean = true;

  lastFrameTime: number = 0;
  editing: boolean;
  editingSubscription: Subscription;
  loadingSubscription: Subscription;
  planErrorSubscription: Subscription;
  updateDrawingSubscription : Subscription;
  private previewSubscription: Subscription;

  //modes
  mode: ActiveMode;

  //modesSubscriptions
  selectionModeSubscription: Subscription;
  shiftKey: boolean;
  projectDrawingUpdateLogs : any = "lädt...";

  constructor(
    private dataService: DataService,
    @Inject(PLATFORM_ID) platformId: Object,
    private renderService: RenderService,
    private dxfViewerService: DxfViewerService,
    private viewerService: ViewerService,
    private viewerOperationsService: ViewerOperationsService,
    private mouseInteractionsService: MouseInteractionsService,
    private modesService: ModesService,
    private drawingService: DrawingService,
    private eventsPropagationService: EventsPropagationService,
    private geometryEditingService: GeometryEditingService,
    private dialog: MatDialog,
    private selectionService: SelectionService,
    private cdRef: ChangeDetectorRef,
    private canvasDimensionsService: CanvasDimensionsService,
    private printService: PrintService,
    private planService: PlanService,
    private profileService: ProfileService,
    private translationService: TranslationService,
    private router: Router,
    private exportService: ExportService,
    private additionalToolsService: AdditionalToolsService,
    private fileService: FileService,
    private signalRService: SignalrService,
    private layerService: LayerService,
    private projectService: ProjectService,
    private notificationService: NotificationService
  ) {
    this.rulerOn = false;
    this.additionalToolsOpen = false;
    this.scale = 1;
    this.isBrowser = isPlatformBrowser(platformId);

    this.profileService.requestMyProfile().subscribe((profileResponse: any) => {
      if(profileResponse && profileResponse.data && !profileResponse.data.licenseStatus) {
        let response = this.translationService.translateAPIResponse({serviceErrorCode: 23, kind: "ERROR"})
        this.dialog.open(InfoDialogComponent,{data: response});
        this.router.navigate(['/projects']);
      }
      else if(profileResponse.data.licenseStatus == 2){
        this.demoVersion = true;
      }
      if(!this.projectService.activeProjectId) {
        this.router.navigate(['/projects']);
        this.notificationService.openNotification("no_project_active");
      }
    })

    this.updateDrawingSubscription = this.signalRService.updateLogs.subscribe((res : any) => {
      this.projectDrawingUpdateLogs = res;
    })


  }


  ngOnInit() {}

  subscribeToGeometricalData() {
    this.geometricalDataSubscription = this.dataService.geometricalData$.subscribe(
      geomData => {
        if(this.geometricalData && this.geometricalData.length && geomData && geomData.length) {
          this.planService.recentlySaved = false;
        }
        this.geometricalData = geomData;
        if(this.geometricalData) {
          this.drawingService.drawingError = false;
          this.animate();
          this.renderService.animate(this.geometricalData,this.ctx,this.canvas,this.viewerService.scale,new Point(0,0),true,null);
        }
      }
    );
  }

  subscribeToLoading() {
    this.loadingSubscription = this.viewerService.loading$.subscribe(
      laodingStatus => {
        this.loading = laodingStatus;
      }
    );
  }

  subscribeToViewers() {
    this.editingSubscription = this.viewerOperationsService.editting$.subscribe(
      editingStatus => {
        this.editing = editingStatus;
        if(this.editing && this.dxfViewerService.scene && this.dxfViewerService.scene.children) {
          this.dxfViewerService.clearScene();
          this.dxfViewerService.renderWithoutUpdate();
        }
      }
    )
  }

  subscribeToAdditionalTools() {
    this.additionalToolsOpenSubscription = this.additionalToolsService.additionalToolsOpen$.subscribe(
      status => {
        this.additionalToolsOpen = status;
      }
    );
  }

  subscribeToModes() {
    this.modeSubscription = this.modesService.activeMode$.subscribe(
      mode => {
        this.mode = mode;
        if(mode === ActiveMode.moveMode) {
          this.editing = false;
        }
      }
    )
  }

  subscribeToGrid() {
    this.gridSubscription = this.viewerService.grid$.subscribe(
      grid => {
        this.grid = grid;
      }
    );
  }

  subscribeToFitOperations() {
    this.fitSubscription = this.viewerOperationsService.fitAreaOperation$.subscribe(
      (fittingData: FitAreaData) => {
        if(fittingData.point) {
          let params = {
            fitArea: true,
            offset: {
              x: fittingData.point.x,
              y: -fittingData.point.y
            }
          }
          this.mouseInteractionsService._cameraOffsetSource.next(params);
        }
      }
    );
  }

  get boundingClientRect() {
    return this.canvas.getBoundingClientRect();
  }

  subscribeToPanning() {
    this.cameraOffsetSubscription = this.mouseInteractionsService.cameraOffset$.subscribe(
      panningData => {
        if(panningData.wheel) {
          this.viewerOperationsService.translation.x += Math.round(panningData.offset.x);
          this.viewerOperationsService.translation.y += Math.round(panningData.offset.y);
          this.viewerOperationsService.translated = false;
          this.animate();
        }
        else if(this.modesService.editableMode && !panningData.move) {
          let dimensions = {width: window.innerWidth - 60, height: window.innerHeight - 130};
          this.geometryEditingService.editSelection(panningData, this.ctx, dimensions, this.viewerService.scale);
        }
        else if(this.modesService.editableMode && panningData.move) {
          this.viewerOperationsService.translation.x += Math.round(panningData.offset.x);
          this.viewerOperationsService.translation.y += Math.round(panningData.offset.y);
          this.viewerOperationsService.translated = false;
          this.animate();
        }
      }
    )
  }

  subscribeToMousePosition() {
    this.mousePositionSubscription = this.mouseInteractionsService.mousePosition$.subscribe(
      mousePos => {
        if(this.modesService.editableMode) {
          this.renderService.animate(this.geometricalData,this.ctx,this.canvas,this.viewerService.scale,mousePos,true,null);
        }
        else if(this.modesService.mode === ActiveMode.insertMode) {
          if(!this.drawingService.drawingError) {
            this.drawingService.drawActiveGeometry(mousePos,this.ctx,this.canvas,this.viewerService.scale, (this.mouseInteractionsService.isDragging || ((this.drawingService.drawingMode === DrawingMode.arc || this.drawingService.multipleLineMode()) && this.drawingService.activeGeometry && this.drawingService.activeGeometry.step === 1 && this.drawingService.drawingStep === 1))? true : false, this.shiftKey);
            this.animate();
          }
          else {
            let errorInfo = {
              kind: "Keine Ebene ist Aktiv",
              serviceErrorCode: "Bitte wählen Sie eine Ebene aus für eine weitere Bearbeitung"
            }
            this.dialog.open(InfoDialogComponent, {data: errorInfo});
          }
        }
      }
    )
  }

  subscribeToEventsPropagation() {
    this.eventsDisabledsubscription = this.eventsPropagationService.eventsDisabled$.subscribe(
      eventPropagationChange => {
        if(eventPropagationChange !== this.eventsDisabled) {
          this.eventsDisabled = eventPropagationChange;
        }
      }
    )
  }

  subscribeToRuler() {
    this.rulerSubscription = this.viewerService.rulerOn$.subscribe(
      rulerOn => {
        if(rulerOn) {
          this.rulerOn = rulerOn;
          this.renderService.renderRuler(this.horRulerRef.nativeElement,this.vertRulerRef.nativeElement,this.canvas);
        }
        else {
          this.rulerOn = false;
        }
      }
    )
  }

  subscribeToPrint() {
    this.printSubscription = this.printService.printState$.subscribe(
      printEvent => {
        if(printEvent) {
          this.performSaveForPrintActions(printEvent);
        }
      }
    )
  }

  subscribeToExport() {
    this.exportSubscription = this.exportService.exportState$.subscribe(
      exportEvent => {
        if(exportEvent === "exportsetup") {
          this.exportService.exportState = null;
          this.viewerService._loadingSource.next(true);
          this.selectionService.resetSelection();
          this.viewerService.disableGrid();
          let geometriesExist = this.dataService.geometryExists();
          this.performExportActions();
        }
      }
    )
  }

  subscribeToPreview() {
    this.previewSubscription = this.viewerOperationsService.previewEvent.subscribe(event => {
      if(event && this.modesService.mode === ActiveMode.frameMode) {
        this.generatePreviewForFrame();
      }
    })

  }

  generatePreviewForFrame() {
    let scaleMult = 2;
    this.viewerService._loadingSource.next(true);
    let transBuff = this.viewerOperationsService.translation;
    this.viewerOperationsService.translation = new Point();
    this.viewerOperationsService.scaleGeometry(this.dataService.geometricalData,scaleMult,false,true);
    let ext = this.viewerOperationsService.findGlobalExtrema(this.dataService.geometricalData,null,true);
    this.viewerOperationsService.moveGeometryToOrigin(this.dataService.geometricalData, ext);
    ext = this.viewerOperationsService.findGlobalExtrema(this.dataService.geometricalData,null,true);
    let exc = ["Legende","Rahmen","PlanPreview","Grafiken"];
    let geom = [];
    let imgs = [];
    this.dataService.geometricalData.forEach(layer => {
      if(!exc.includes(layer.layerName)) {
        for(let i = 0; i < layer.geometry.length; i++ ) {
          if(layer.geometry[i].geometricType === GeometricType.Image) {
            layer.geometry[i].layerName = "Grafiken";
            layer.geometry[i].scale({x:1/scaleMult,y:1/scaleMult})
            imgs.push(layer.geometry[i]);
            layer.geometry.splice(i,1)
            i--;
          }
        }
        geom.push(layer);
      };
    })
    let scaledCanvas = document.createElement('canvas');
    let ctx = scaledCanvas.getContext('2d');
    scaledCanvas.width = this.canvas.width * scaleMult;
    scaledCanvas.height = this.canvas.height * scaleMult;
    this.renderService.renderGeometries(null,geom,ctx,scaledCanvas,1,null,true);
    const img = new Image();

    img.src = scaledCanvas.toDataURL();
    img.title = "previewimage";

    img.onload = () => {
      this.viewerOperationsService.scaleGeometry(this.dataService.geometricalData,1/scaleMult,false,true);
      this.dataService.geometricalData = this.dataService.geometricalData.filter(e => exc.includes(e.layerName));
      this.layerService.createLayer("Grafiken");
      this.layerService.createLayer("PlanPreview");
      let ImgLayerIndex = this.dataService.findLayerIndex("Grafiken");
      this.dataService.geometricalData[ImgLayerIndex].geometry = this.dataService.geometricalData[ImgLayerIndex].geometry.concat(imgs);
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');
      let d = 5;

      canvas.width = ext.maxX - ext.minX + d * 2;
      canvas.height = ext.maxY - ext.minY + d * 2;
      ctx.drawImage(img, ext.minX-d, ext.minY-d, ext.maxX - ext.minX + d * 2, ext.maxY - ext.minY + d * 2, 0, 0, canvas.width, canvas.height);
      const img2 = new Image();

      img2.src = canvas.toDataURL();
      img2.title = "previewimage";
      img2.onload = () => {
        this.layerService.importImageToActiveLayer(img2,origin).subscribe(geomName => {
          if(geomName) {
            let index = this.dataService.findGeometryIndexInLayerByName(this.layerService.activeLayer, geomName);
            if(index > -1) {
              let layerIndex = this.dataService.findLayerIndex(this.layerService.activeLayer);
              if(layerIndex > -1) {
                let img = this.dataService.geometricalData[layerIndex].geometry[index] as EPImage;
                img.scale({x:1/scaleMult,y:1/scaleMult});
                img.origin.x = this.viewerOperationsService.translation.x + this.canvasDimensionsService.dimensions.width/2 - img.width/2;
                img.origin.y = this.viewerOperationsService.translation.y + this.canvasDimensionsService.dimensions.height/2 - img.height/2;
                this.viewerOperationsService.moveGeometry(this.dataService.geometricalData,{x: -img.origin.x+d, y: -img.origin.y});
                img.origin.x = this.viewerOperationsService.translation.x + this.canvasDimensionsService.dimensions.width/2 - img.width/2;
                img.origin.y = this.viewerOperationsService.translation.y + this.canvasDimensionsService.dimensions.height/2 - img.height/2;
              }
            }
            this.viewerOperationsService.editing = true;
            this.dataService.emitChanges();
            this.viewerService._loadingSource.next(false);
            this.viewerOperationsService._edittingSource.next(this.viewerOperationsService.editing);
          }
        });
        },
        error => {
          console.log('Error while gathering SVG response.');
          this.viewerService._loadingSource.next(false);
        };
    }
  }

  performSaveForPrintActions(printEvent: string) {
    if(printEvent === "setup") {
      if(this.modesService.editableMode) {
        this.printService.printState = "";

        this.selectionService.resetSelection();
        this.viewerService.disableGrid();
        this.viewerOperationsService.scaleGeometry(this.dataService.geometricalData,3,false,true);
        let scaledCanvas = document.createElement('canvas');
        let ctx = scaledCanvas.getContext('2d');
        let d = 20;
        let ext = this.viewerOperationsService.findGlobalExtrema(this.dataService.geometricalData,null);
        scaledCanvas.width = ext.maxX - ext.minX + d*2;
        scaledCanvas.height = ext.maxY - ext.minY + d*2;
        ctx.translate(-ext.minX+2,-ext.minY+2);
        this.renderService.renderGeometries(null,this.dataService.geometricalData,ctx,scaledCanvas,1,null,true);

        const img = new Image();

        img.src = scaledCanvas.toDataURL();
        img.title = "previewimage";
        img.onload = () => {
          let canvas = document.createElement('canvas');
          let d = 5;
          canvas.width = scaledCanvas.width * 1.1;
          canvas.height = scaledCanvas.height * 1.1;
          let ctx = canvas.getContext('2d')
          ctx.fillStyle = "#fff";
          ctx.fillRect(0,0,canvas.width,canvas.height);
          ctx.drawImage(img, -d, -d, ext.maxX - ext.minX+d*2, ext.maxY - ext.minY+d*2, canvas.width/2-(scaledCanvas.width)/2, canvas.height/2-(scaledCanvas.height)/2, scaledCanvas.width, scaledCanvas.height);
          this.printService.generatePreview(canvas);
          this.previewCanvas = canvas;
          if(!this.dialog.openDialogs.length) {
            let dialogRefPass = this.dialog.open(PrintSetupDialogComponent);
            this.viewerService._loadingSource.next(false);
            dialogRefPass.afterClosed().subscribe(result => {
              if(result) {
                this.printService.saveForPrint();
              }
              if(!this.modesService.editableMode) {
                this.dxfViewerService.loadPreviewWithParameters(0xaaaaaa).subscribe(res => {
                  this.dxfViewerService.rendering();
                });
              }
              this.viewerService._loadingSource.next(false);
              this.viewerOperationsService.scaleGeometry(this.dataService.geometricalData,1/3,false,true);
              this.animate();
            });
          }

        }
      }
      else {
        this.dxfViewerService.loadPreviewWithParameters(0xffffff).subscribe(res => {
          this.dxfViewerService.rendering();
          this.printService.generatePreview(this.cadCanvasRef.nativeElement);
          if(!this.dialog.openDialogs.length) {
            let dialogRefPass = this.dialog.open(PrintSetupDialogComponent);
            this.viewerService._loadingSource.next(false);
            dialogRefPass.afterClosed().subscribe(result => {
              if(result) {
                this.printService.saveForPrint();
              }
              if(!this.modesService.editableMode) {
                this.dxfViewerService.loadPreviewWithParameters(0xaaaaaa).subscribe(res => {
                  this.dxfViewerService.rendering();
                });
              }
              this.viewerService._loadingSource.next(false);
              this.animate();
            });
          }
        });
      }

    }
    else if(printEvent === "update") {
      if(this.modesService.editableMode) {
        this.printService.generatePreview(this.previewCanvas);
      }
      else {
        this.printService.generatePreview(this.cadCanvasRef.nativeElement);
      }
    }
    else if(printEvent === "printing" && this.modesService.editableMode) {
      this.printService.printState = "";
      this.selectionService.resetSelection();
      this.viewerService.disableGrid();
      this.viewerService.enableGrid();
      this.printService.resetPrintSettings();
    }
  }

  performExportActions() {
    let scaleMult = 2;
    this.animate(3);
    if(!this.dialog.openDialogs.length) {
      let dialogRefPass = this.dialog.open(ExportSetupDialogComponent);
      dialogRefPass.afterClosed().subscribe(result => {
        if(result) {
          if(this.modesService.editableMode) {
            this.selectionService.resetSelection();
            this.viewerService.disableGrid();
            this.viewerOperationsService.scaleGeometry(this.dataService.geometricalData,scaleMult,false,true);
            let scaledCanvas = document.createElement('canvas');
            let ctx = scaledCanvas.getContext('2d');
            let d = 20;
            let ext = this.viewerOperationsService.findGlobalExtrema(this.dataService.geometricalData,null);
            scaledCanvas.width = ext.maxX - ext.minX + d*2;
            scaledCanvas.height = ext.maxY - ext.minY + d*2;
            ctx.translate(-ext.minX,-ext.minY);
            this.renderService.renderGeometries(null,this.dataService.geometricalData,ctx,scaledCanvas,1,null,true);

            const img = new Image();
            img.src = scaledCanvas.toDataURL("image/png", 1.0);
            img.onload = () => {
              let canvas = document.createElement('canvas');
              let ctx = canvas.getContext('2d')
              canvas.width = ext.maxX - ext.minX + d*2;
              canvas.height = ext.maxY - ext.minY + d*2;
              ctx.fillStyle = "#fff";
              ctx.fillRect(0,0,canvas.width,canvas.height);
              ctx.drawImage(img, -d, -d, ext.maxX - ext.minX+d*2, ext.maxY - ext.minY+d*2, 0, 0, canvas.width, canvas.height);
              this.exportService.saveForExport(canvas,result).subscribe(res => {
                this.viewerService._loadingSource.next(false);
                this.viewerOperationsService.scaleGeometry(this.dataService.geometricalData,1/scaleMult,false,true);
                this.animate();
                this.viewerService.enableGrid();
              });
            }
          }
          else {
            this.dxfViewerService.loadPreviewWithParameters(0xffffff).subscribe(res => {
              this.dxfViewerService.rendering();
              this.exportService.saveForExport(this.cadCanvasRef.nativeElement,result).subscribe(res => {
                this.viewerService._loadingSource.next(false);
                this.dxfViewerService.loadPreviewWithParameters(0xaaaaaa).subscribe(res => {
                  this.dxfViewerService.rendering();
                });
              });
            })
          }
        }
        else {
          this.selectionService.resetSelection();
          this.viewerService.disableGrid();
          this.animate(2);
          this.viewerService.enableGrid();
          this.viewerService._loadingSource.next(false);
        }
      });
    }
  }

  subscribeToPlanError() {
    //TODO: Code unclear, to be discussed and a error behaviour should be defined later on!
    this.planErrorSubscription = this.planService.planError$.subscribe(error => {
      if(error && error.short === "TOO_MUCH_ELEMENTS") {
        if(!this.dialog.openDialogs.length) {
          let dialogRefPass = this.dialog.open(ConfirmationDialogComponent,{
            data: {
              question: "to_much_elements_warning"
            }
          });
          dialogRefPass.afterClosed().subscribe(result => {
            if(result) {
              this.fileService.downloadDxf();
            }
          });
        }
      }
      else if (error && (!error.short || error.short !== "TOO_MUCH_ELEMENTS")) {
        this.dialog.open(InfoDialogComponent, {data: error});
      }
    })
  }

  ngAfterViewInit() {
    if(this.isBrowser) {
      this.ctx = this.canvas.getContext('2d');
      this.canvas.width  = window.innerWidth - 60;
      this.canvas.height = window.innerHeight - 130;
      this.subscribeToGeometricalData();
      this.subscribeToModes();
      this.subscribeToGrid();
      this.subscribeToPanning();
      this.subscribeToMousePosition();
      this.subscribeToAdditionalTools();
      this.subscribeToEventsPropagation();
      this.subscribeToPrint();
      this.subscribeToRuler();
      this.subscribeToExport();
      this.subscribeToPreview();
      this.subscribeToFitOperations();
      this.subscribeToViewers();
      this.subscribeToLoading();
      this.subscribeToPlanError();
      let gridCanvas = this.gridCanvasRef.nativeElement;
      this.renderService.setCanvasInitialScale(gridCanvas, 1);
      this.renderService.createCanvasGrid(gridCanvas, gridCanvas.getContext('2d'), 1);
      try {
        if(this.planService.activePlanId === "samplePlan") {
          this.editing = true;
        }
        this.dxfViewerService.initThree(this.cadCanvasRef.nativeElement);
      } catch (error) {
        this.dxfViewerService.cleanViewerCache();
        this.router.navigate(['/projects']).then(() => {
          window.location.reload();
        })
      }
      this.canvasDimensionsService.setCanvasDimensions();
      this.onChange();

    }
  }

  onChange(): void {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    if(this.isBrowser) {
      this.geometricalDataSubscription.unsubscribe();
      this.modeSubscription.unsubscribe();
      this.gridSubscription.unsubscribe();
      this.cameraOffsetSubscription.unsubscribe();
      this.mousePositionSubscription.unsubscribe();
      this.additionalToolsOpenSubscription.unsubscribe();
      this.eventsDisabledsubscription.unsubscribe();
      this.printSubscription.unsubscribe();
      this.rulerSubscription.unsubscribe();
      this.exportSubscription.unsubscribe();
      this.fitSubscription.unsubscribe();
      this.editingSubscription.unsubscribe();
      this.loadingSubscription.unsubscribe();
      this.updateDrawingSubscription.unsubscribe();
      this.dxfViewerService.onDestroy();
      this.viewerService._loadingSource.next(false);
      this.previewSubscription.unsubscribe();
      this.onChange();
    }
  }

  animate(forPrint=null) {
    if(forPrint || this.editing) {
      this.renderService.animate(this.geometricalData,this.ctx,this.canvas,this.viewerService.scale,null,null,null,forPrint);
    }
  }

  private get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }

  private get viewer(): HTMLCanvasElement {
    return this.viewerRef.nativeElement;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.canvasDimensionsService.setCanvasDimensions();
    this.dxfViewerService.onResize().subscribe(()=>{});
  }


  @HostListener("mousemove", ["$event"])
  onMouseMove(event) {
    if(!this.eventsDisabled && event && event.target.classList.contains('ep-canvas')) {
      if(this.modesService.mode !== ActiveMode.moveMode) {
        this.shiftKey = false;
        if(event.shiftKey)this.shiftKey = true;
        this.mouseInteractionsService.onMouseMove(event,this.boundingClientRect,this.ctx,this.canvas,this.viewerService.scale);
      }
    }
  }

  @HostListener("click", ["$event"])
  onMouseClick(event) {
    if(!this.eventsDisabled && event && event.target.classList.contains('ep-canvas')) {
      if(this.modesService.mode !== ActiveMode.moveMode)
      this.mouseInteractionsService.onMouseClick(event,this.boundingClientRect,this.ctx,this.canvas);
    }
  }
  //@HostListener("doubleclick", ["$event"])
  onMouseDoubleClick(event) {
    if(!this.eventsDisabled && event && event.target.classList.contains('ep-canvas')) {
      if(this.modesService.mode !== ActiveMode.moveMode)
      this.mouseInteractionsService.onMouseDoubleClick(event,this.boundingClientRect,this.ctx,this.canvas,this.viewerService.scale);
    }
  }

  @HostListener("mousedown", ["$event"])
  onMouseDown(event) {
    if(!this.eventsDisabled && event && event.target.classList.contains('ep-canvas') || event.target.classList.contains('ep-symbol'))
    {
      if(this.modesService.mode !== ActiveMode.moveMode) {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.mouseInteractionsService.onMouseDown(event,this.boundingClientRect,this.canvas,this.ctx);
      }
    }
  }
  @HostListener("contextmenu", ["$event"])
  contextMenu(event) {
    if(!this.eventsDisabled && event && event.target.classList.contains('ep-canvas') || event.target.classList.contains('ep-symbol'))
    {
      if(this.modesService.mode !== ActiveMode.moveMode) {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.mouseInteractionsService.onMouseDown(event,this.boundingClientRect,this.canvas,this.ctx);
      }
    }
  }
  @HostListener("mouseup", ["$event"])
  onMouseUp(event) {
    if(!this.eventsDisabled && event && event.target.classList.contains('ep-canvas'))
    {
      if(this.modesService.mode !== ActiveMode.moveMode)
      this.mouseInteractionsService.onMouseUp(event,this.boundingClientRect,this.canvas);
    }
  }
  @HostListener('document:keydown', ['$event'])
  geometryRemoveEventHandler(event: KeyboardEvent) {
    let keyboardAction = this.performKeyboardAction(event);
    if(keyboardAction === "escape" && this.modesService.mode === ActiveMode.insertMode && this.drawingService.multipleLineMode() && this.drawingService.drawingStep && this.drawingService.isDrawing && this.drawingService.activeGeometry) {
      this.mouseInteractionsService.isDragging = false;
      this.drawingService.setDrawingStep(3);
      this.drawingService.drawActiveGeometry(null,this.ctx,this.canvas,this.viewerService.scale,true,false);
    }
    else if(this.selectionService.selectedElementsExist() && !this.eventsDisabled) {
      let selectionRect: Rectangle = this.selectionService.getSelectionRect();
      switch(keyboardAction) {
        case "remove": {
            let dialogRefPass = this.dialog.open(ConfirmationDialogComponent,{
              data: {
                question: "delete_selection"
              }
            });
            dialogRefPass.afterClosed().subscribe(result => {
              if(result) {
                this.geometryEditingService.removeSelection();
              }
            });
            break;
        }
        case "copy": {
          this.geometryEditingService.copySelectionToBuffer();
          break;
        }
        case "cut": {
          this.geometryEditingService.cutSelectionToBuffer();
          this.selectionService.resetSelection();
          break;
        }
        case "paste": {
          this.selectionService.resetSelection();
          this.geometryEditingService.pasteBuffer();
          break;
        }
        case "up": {
          this.geometryEditingService.moveSelectionWithArrows(this.canvas,selectionRect,event, {x: 0, y: event.shiftKey ? 5 : 1},this.viewerService.scale);
          break;
        }
        case "down": {
          this.geometryEditingService.moveSelectionWithArrows(this.canvas,selectionRect,event, {x:0, y: event.shiftKey ? -5 : -1}, this.viewerService.scale);
          break;
        }
        case "left": {
          this.geometryEditingService.moveSelectionWithArrows(this.canvas,selectionRect,event, {x: event.shiftKey ? 5 : 1, y: 0}, this.viewerService.scale);
          break;
        }
        case "right": {
          this.geometryEditingService.moveSelectionWithArrows(this.canvas,selectionRect,event, {x: event.shiftKey ? -5 : -1, y: 0}, this.viewerService.scale);
          break;
        }
      }
    }
    else if(this.dataService.geometryBuffer && this.dataService.geometryBuffer.length && !this.eventsDisabled && keyboardAction === "paste") {
      this.geometryEditingService.pasteBuffer();
    }
  }

  performKeyboardAction(event) {
    let platformMac = navigator.platform.match('Mac');
    let controlKey = false;
    if(platformMac && event) {
      controlKey = event.metaKey;
    }
    else {
      controlKey = event.ctrlKey;
    }
    switch(true) {
      //copy
      case (controlKey && event.key === "c"):
        return "copy";
      //copy
      case (controlKey && event.key === "x"):
        return "cut";
      //copy
      case (controlKey && event.key === "v"):
        return "paste";
      //backspace
      case (event.keyCode === 8):
        return "remove";
      //delete
      case (event.keyCode === 46):
        return "remove";
      case (event.key === "ArrowRight"):
        return "right";
      case (event.key === "ArrowLeft"):
        return "left";
      case (event.key === "ArrowUp"):
        return "up";
      case (event.key === "ArrowDown"):
        return "down";
      case (event.key === "Escape"):
        return "escape";
      default:
      return;
    }
  }

  onImageDragEnter(event) {
    if(this.modesService.mode === ActiveMode.insertMode && this.drawingService.drawingMode === DrawingMode.image) {
      this.mouseInteractionsService.onImageDragEnter(event, this.boundingClientRect,this.canvas);
    }
  }

  onImageDrag(event) {
    this.mouseInteractionsService.onImageDrag(event, this.boundingClientRect, this.ctx, this.canvas,this.viewerService.scale);
  }

  onImageDrop(event) {
    this.mouseInteractionsService.onImageDrop(event, this.boundingClientRect,this.canvas);
  }

  toggleAdditionalTools() {
    this.additionalToolsService.toggleAdditionalTools("editor");
  }

  stopEvent(e) {
    e.preventDefault();
    this.eventsPropagationService.disableEvents();
  }

  startEvent() {
    this.eventsPropagationService.enableEvents();
  }

  resetThreecamera() {
    this.viewerService.setScale(1);
  }

  clearRect() {
    this.canvas.width = this.canvas.width;
  }
}
