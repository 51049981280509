import { Component, OnInit } from '@angular/core';
import { LineModeControl } from 'src/app/models/controls/line-mode.model';
import { ControlsService } from 'src/app/services';
import { DrawingService } from 'src/app/services/operational-services/drawing/drawing.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-line-tools',
  templateUrl: './line-tools.component.html',
  styleUrls: ['./line-tools.component.scss']
})

export class LineToolsComponent implements OnInit {

  lineModesList: Array<LineModeControl>;
  currentLineMode: number;
  constructor(
    private controlsService: ControlsService,
    private drawingService: DrawingService,
  ) {
    this.lineModesList = this.controlsService.lineModes;
    this.currentLineMode = new LineModeControl(this.lineModesList[0]).type;
  }

  ngOnInit(): void {
  }

  updateLineMode(event: MatSelectChange) {
    this.drawingService.setDrawingMode(event.value);
    this.currentLineMode = event.value;
  }

}
