import { Point } from '../point.model';
import { LineIconEnum } from '../enums/line-icon.enum';

export class LineIcon {
    type: number;
    size: number;
    color: string;

    constructor(data) {
        this.type = data.type;
        this.size = data.size;
        this.color = data.color;
    }

    draw(context, angle, position: Point, scale) {
        switch(this.type) {
            case LineIconEnum.arrow: this.drawArrow(context, angle, position, scale); break;
            case LineIconEnum.rect: this.drawRect(context, angle, position, scale); break;
            case LineIconEnum.square: this.drawSquare(context, angle, position, scale); break;
            case LineIconEnum.diamond: this.drawDiamond(context, angle, position, scale); break;
            case LineIconEnum.circle: this.drawCircle(context, angle, position, scale); break;
            default: return
        }        
    }

    drawArrow(context, angle, position: Point, scale) {
        var headlen = 10*scale*this.size; // length of head in pixels
        context.beginPath();
        context.setLineDash([]);
        context.moveTo(position.x*scale, position.y*scale);
        context.lineTo(position.x*scale - headlen * Math.cos(angle - Math.PI / 6), position.y*scale - headlen * Math.sin(angle - Math.PI / 6));
        context.moveTo(position.x*scale, position.y*scale);
        context.lineTo(position.x*scale - headlen * Math.cos(angle + Math.PI / 6), position.y*scale - headlen * Math.sin(angle + Math.PI / 6));
        context.stroke();
    }
    drawRect(context, angle, position: Point, scale) {
        context.save();
        context.translate(position.x*scale, position.y*scale);
        context.rotate(angle);
        context.fillStyle = this.color ? this.color : "black";
        context.fillRect(-5*scale*this.size,-10*scale*this.size,10*scale*this.size,20*scale*this.size);
        context.restore();
    }
    drawSquare(context, angle, position: Point, scale) {
        context.save();
        context.translate(position.x*scale, position.y*scale);
        context.rotate(angle);
        context.fillStyle = this.color ? this.color : "black";
        context.fillRect(-5*scale*this.size,-5*scale*this.size,10*scale*this.size,10*scale*this.size);
        context.restore();
    }
    drawDiamond(context, angle, position: Point, scale) {
        context.save();
        context.translate(position.x*scale, position.y*scale);
        context.rotate(angle+Math.PI/4);
        context.fillStyle = this.color ? this.color : "black";
        context.fillRect(-5*scale*this.size,-5*scale*this.size,10*scale*this.size,10*scale*this.size);
        context.restore();
    }
    drawCircle(context, angle, position: Point, scale) {
        context.save();
        context.fillStyle = this.color ? this.color : "black";
        context.beginPath();
        context.arc(position.x*scale, position.y*scale, 6*scale*this.size, 0, 2 * Math.PI);
        context.fill();
        context.restore();
    }

}