import { Component, OnInit } from '@angular/core';
import { EditorControl } from 'src/app/models/controls';
import { ControlsService, LayerService, ViewerOperationsService } from 'src/app/services';
import { DrawingMode } from 'src/app/models/enums/drawing-mode.enum';
import { DrawingService } from 'src/app/services/operational-services/drawing/drawing.service';
import { AdditionalToolsService } from 'src/app/services/status-and-control-services/additional-tools/additional-tools.service';
import { ModesService } from 'src/app/services/status-and-control-services/modes/modes.service';
import { ActiveMode } from 'src/app/models/enums/active-mode.enum';
import { ControlActiveSettingEnum } from 'src/app/models/enums/control-active-setting.enum';

@Component({
  selector: 'app-geom-primitives-controls',
  templateUrl: './geom-primitives-controls.component.html',
  styleUrls: ['./geom-primitives-controls.component.scss']
})
export class GeomPrimitivesControlsComponent implements OnInit {
  controlItems: Array<EditorControl>;
  constructor(
    private controlsService: ControlsService,
    private drawingService: DrawingService,
    private additionalToolsService: AdditionalToolsService,
    private viewerOperationsService: ViewerOperationsService,
    private LayerService: LayerService,
    private modeService: ModesService
  ) {
    this.controlItems = this.controlsService.controls.verticalControls.geomPrimitivesControlsList;
  }

  ngOnInit(): void {
  }

  onButtonClick(controlsItem: EditorControl) {
    switch(controlsItem.id) {
      case 19: {
        this.setDrawingMode(6);
        this.toggleAdditionalTools("line");
        break;
      } 
      case 20: {
        this.setDrawingMode(7);
        this.toggleAdditionalTools("rect");
        break;
      } 
      case 21: {
        this.setDrawingMode(4);
        this.toggleAdditionalTools("circle");
        break;
      } 
      case 22: {
        this.setDrawingMode(5);
        this.toggleAdditionalTools("arc");
        break;
      } 
      default: return
    }
  }

  get specialCondition(): boolean {
    return this.LayerService.specialLayerActive();
  }

  setDrawingMode(value) {
    if(this.modeService.mode !== ActiveMode.insertMode) {
      this.modeService.setMode(ActiveMode.insertMode);
    }
    this.drawingService.setDrawingMode(value);
  }

  variableValueFromComponent(controlItem: EditorControl) {
    if(controlItem.varName) {
      let varValue = controlItem.varName === "line" ? (this.drawingService.drawingMode === 6 || this.drawingService.drawingMode === 9) && this.modeService.mode == ActiveMode.insertMode : this.drawingService.drawingMode === DrawingMode[controlItem.varName] && this.modeService.mode == ActiveMode.insertMode;
      return varValue;
    }
  }

  toggleAdditionalTools(componentName) {
    this.additionalToolsService.toggleAdditionalTools(componentName);
  }

  closeAdditionalToolsByComponent(componentName) {
    this.additionalToolsService.closeAdditionalTools(componentName);
  }

  controlActive(activeOn) {
    let result = this.viewerOperationsService.controlActive(activeOn);
    return result;
  }

}
