export class ProjectDataFormTemplate {
    planNumber= "number";
    type= "text";
    object= "text";
    floor= "text";
    room= "text";
    creationDate= "date";
    lastChangedDate= "date";
    nextReviewDate= "date";
    responsibleName= "text";
    creatorName= "text";
    streetWithNumber= "text";
    postalCode= "number";
    city= "text";
    phoneNumber= "number";
    mobilePhoneNumber= "number";
    fax= "number";
    email= "email";
    comment= "textarea";
    clientName= "text";
    builderName= "text";
    fireSensorsNr= "text";
    normId= "text";
    buildingUsage= "textarea";
    partnerInCase= "textarea";
    contentsList= "textarea";
    distributor= "textarea";
    personel= "textarea";
    workingHours= "textarea";
    fireKeys= "textarea";
    energySupplyTips= "textarea";
    hazardAndTechnicsTips= "textarea";
    dangerousSubstancesTip= "textarea";
    technicalBuildingEquipment= "textarea";
    buildingDescription= "textarea";
    otherInformation= "textarea";
}