import { Injectable } from '@angular/core';
import { ColorEnum } from '../../../models/enums/color.enum';
import { ColorEvent } from 'ngx-color';
import { Color } from 'src/app/models';

interface ColorObject {
  colorName:string;
  colorHex:string;
  colorInt?:number
}

@Injectable({
  providedIn: 'root'
})
export class ColorService {
  activeStrokeColor: string;
  activeFillColor: string;
  presetColors: Array<string>;
  presetArrowColors: Array<string>;
  defaultColors: Array<ColorObject>;
  arrowColors: Array<ColorObject>;
  legendFrameColors: Array<ColorObject>;
  constructor() {
    this.activeStrokeColor = "";
    this.activeFillColor = "";
    this.defaultColors = [
      { colorName: "fireEscape", colorHex: "#9DBD7E"},
      { colorName: "starecase", colorHex: "#008100"},
      { colorName: "specialDangers", colorHex: "#BD2E00"},
      { colorName: "noDrive", colorHex: "#FFFF00"},
      { colorName: "neighbour", colorHex: "#000000"},
      { colorName: "houseTech", colorHex: "#005EBD"},
      { colorName: "moveFireFighters", colorHex: "#FFFFFF"},
      { colorName: "moveubild", colorHex: "#828282"},
      { colorName: "1", colorHex: "#808080"},
      { colorName: "2", colorHex: "#BD0000"},
      { colorName: "3", colorHex: "#680000"},
      { colorName: "4", colorHex: "#FF3F00"},
      { colorName: "5", colorHex: "#BD5E0"},
      { colorName: "6", colorHex: "#683400"},
      { colorName: "7", colorHex: "#FFBF00"},
      { colorName: "8", colorHex: "#FFFF00"},
      { colorName: "9", colorHex: "#686800"},
      { colorName: "10", colorHex: "#8DBD00"},
      { colorName: "11", colorHex: "#5EBD00"},
      { colorName: "12", colorHex: "#004F00"},
      { colorName: "13", colorHex: "#00BFFF"},
      { colorName: "14", colorHex: "#AAAAFF"},
      { colorName: "15", colorHex: "#7F00FF"},
      { colorName: "16", colorHex: "#BF00FF"},
      { colorName: "17", colorHex: "#FF00BF"},
      { colorName: "18", colorHex: "#FF003F"},
    ];
    this.arrowColors = [
      { colorName: "mainEntrance", colorHex: "#000000", colorInt: 4278190080},
      { colorName: "sideEntrance", colorHex: "#000000", colorInt: 4278190080},
      { colorName: "fireFightersEntrance", colorHex: "#2EBD00"},
    ];
    this.legendFrameColors = [
      { colorName: "green", colorHex: "#008140"},
      { colorName: "red", colorHex: "#810000"},
    ]
    this.presetColors = this.defaultColors.map(a => a.colorHex);
    this.presetArrowColors = this.arrowColors.map(a => a.colorHex);
  }

  setActiveStrokeColor(event: ColorEvent) {
    this.activeStrokeColor = event.color.hex;
  }

  setActiveFillColor(event: ColorEvent) {
    this.activeFillColor = event.color.hex;
  }

  resetActiveFillColor() {
    this.activeFillColor = "";
  }

  resetActiveStrokeColor() {
    this.activeStrokeColor = "";
  }

  get activeGeometryStrokeColor(): string {
    return this.activeStrokeColor ? this.activeStrokeColor : ColorEnum.default;
  }

  get activeGeometryFillColor(): string {
    return this.activeFillColor ? this.activeFillColor : null;
  }

  colorChannelToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }
  
  rgbToHex(color) {
    if(typeof color === 'string' && color.indexOf("#") === 0 && color.length === 7) return color;
    else return "#" + this.colorChannelToHex(color.r) + this.colorChannelToHex(color.g) + this.colorChannelToHex(color.b);
  }

  activeStrokeColorHexToRgb() {
      return this.hexToRgb(this.activeGeometryStrokeColor);
  }

  activeFillColorHexToRgb() {
    return this.hexToRgb(this.activeGeometryFillColor);
  }

  increaseBrightness(color){
    return '#' +
       ((0|(1<<8) + color.r + (256 - color.r) * 0.70).toString(16)).substr(1) +
       ((0|(1<<8) + color.g + (256 - color.g) * 0.70).toString(16)).substr(1) +
       ((0|(1<<8) + color.b + (256 - color.b) * 0.70).toString(16)).substr(1);
  }

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  intToHex(integerColor: number) {
    if(integerColor) {
      let hexString = integerColor.toString(16);
      return this.normalizeHexString(hexString);
    }
    else {
      return ColorEnum.default;
    }
  }

  normalizeHexString(hexString: string) {
    if(hexString.length < 6) {
      return this.normalizeHexString("00" + hexString)
    }
    else {
      return "#" + hexString;
    }
  }

  isEqualRGB(rgb1: Color, rgb2: Color) {
    return rgb1.b === rgb2.b && rgb1.g === rgb2.g && rgb1.r === rgb2.r;
  }
  

}
