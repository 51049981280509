import { Vertex } from '../vertex.model';
import { Geometry } from './geometry.model';
import { GeometryProperties } from './geometry-properties.model';
import { Extrema } from '../extrema.model';
import { Point } from '../point.model';
import { VertexPosition } from '../position.model';
import { ColorEnum } from '../enums/color.enum';
import { WallOpening } from './wall-opening.model';
import { WallOpeningMode } from '../enums/wall-opening-mode.enum';
import { Rectangle } from './rectangle.model';

export class LwPolyline implements Geometry {
    entityName: string;
    entityTag: string;
    geometricType: number;
    layerName: string;
    geometryProperties: GeometryProperties;
    isClosed?: boolean;

    vertexes: Array<Vertex>;
    selected?: boolean;
    intersectedPoint?: Point;
    intersectedAfterVertexIndex?: number;
    openings?: Array<WallOpening>;
    fixedWidthOpenignBuff?: any;

    constructor(data) {
        this.vertexes = new Array<Vertex>();
        data.vertexes.forEach(element => {
            this.vertexes.push(new Vertex(element));
        });

        this.geometryProperties =  new GeometryProperties({...data,...data.geometryProperties});
        this.entityName = data.entityName;
        this.entityTag = (data.entityTag && data.entityTag != data.entityName) ? data.entityTag : "tag_" + data.entityName;
        this.geometricType = data.geometricType;
        this.layerName = data.layerName;
        this.isClosed = data.isClosed;
        this.selected = data.selected;
        this.intersectedPoint = data.intersectedPoint;
        this.intersectedAfterVertexIndex = data.intersectedAfterVertexIndex;
        if(data.openings && data.openings.length) {
            this.openings = new Array<WallOpening>();
            data.openings.forEach(element => {
                let wallOpening = new WallOpening();
                Object.keys(element).forEach(key => {
                    wallOpening[key] = element[key];
                });      
                this.openings.push(wallOpening);
            });
        }
        this.fixedWidthOpenignBuff = data.fixedWidthOpenignBuff;
    }

    roundUpCoords() {
        this.vertexes.forEach(vertex => {
            vertex.position = new VertexPosition(vertex.position,true);
        })
    }

    extrema() {
        let extrema = new Extrema();
        extrema.maxX = this.vertexes[0].position.x;
        extrema.minX = this.vertexes[0].position.x;
        extrema.maxY = this.vertexes[0].position.y;
        extrema.minY = this.vertexes[0].position.y;
        this.vertexes.forEach(vertex => {
            if (extrema.minX > vertex.position.x) extrema.minX = vertex.position.x;
            if (extrema.minY > vertex.position.y) extrema.minY = vertex.position.y;
            if (extrema.maxX < vertex.position.x) extrema.maxX = vertex.position.x;
            if (extrema.maxY < vertex.position.y) extrema.maxY = vertex.position.y;
        })
        extrema.minY -= 15;
        extrema.maxY += 15;
        extrema.minX -= 15;
        extrema.maxX += 15;
        return extrema;
    }

    scale(multiplier: Point, selectionRect: Rectangle=null, equalizeThicknessFactor: number = null) {
        this.vertexes.forEach(vertex => {
            vertex.position.x *= multiplier.x
            vertex.position.y *= multiplier.y
        })        
        if(equalizeThicknessFactor) {
            this.geometryProperties.thickness *= equalizeThicknessFactor;
        }
    }

    draw(context, scale, color, canvas, fillColor = null, mode=null) {
        context.save();
        context.beginPath();
        context.moveTo(this.vertexes[0].position.x * scale, this.vertexes[0].position.y * scale);
        this.vertexes.forEach((vertex, index) => {
            context.strokeStyle = color ? color : ColorEnum.default;
            if(index < this.vertexes.length-1 && (this.intersectedAfterVertexIndex || this.intersectedAfterVertexIndex === 0) && (mode && mode !== 6)) {
                if(index === this.intersectedAfterVertexIndex) {
                    context.beginPath();
                    context.moveTo(this.vertexes[index].position.x * scale, this.vertexes[index].position.y * scale);
                    context.setLineDash([10,10]);
                    context.strokeStyle = "yellow";
                    context.lineTo(this.vertexes[index+1].position.x * scale, this.vertexes[index+1].position.y * scale);
                    context.stroke();
                }
                else {
                    if(!this.openings || !this.openings.length) {
                        context.beginPath();
                        context.moveTo(this.vertexes[index].position.x * scale, this.vertexes[index].position.y * scale);
                        context.setLineDash([]);
                        context.lineTo(this.vertexes[index+1].position.x * scale, this.vertexes[index+1].position.y * scale);
                        context.stroke();
                    }
                    else {
                        this.renderLinePartWithOpening(context, canvas, index, scale)
                    }
                }
            }
            else if(index < this.vertexes.length-1) {
                if(!this.openings || !this.openings.length) {
                    context.lineTo(this.vertexes[index+1].position.x * scale, this.vertexes[index+1].position.y * scale);
                }
                else {
                    this.renderLinePartWithOpening(context, canvas, index, scale)
                } 
            }
            if (index === this.vertexes.length-1 && this.isClosed) {
                context.lineTo(this.vertexes[0].position.x * scale, this.vertexes[0].position.y * scale);
                context.stroke();
            }
        })
        context.stroke();
        context.restore();
    }

    renderLinePartWithOpening(context, canvas, index, scale) {
        context.beginPath();
        context.setLineDash([]);
        let opening = this.openings.find(opening => opening.afterVertexIndex === index);
        if(!opening) {
            context.moveTo(this.vertexes[index].position.x * scale, this.vertexes[index].position.y * scale);
            context.lineTo(this.vertexes[index+1].position.x * scale, this.vertexes[index+1].position.y * scale);
            context.stroke();
        }
        else {
            context.beginPath();
            let distance = 0;
            if(opening.type !== WallOpeningMode.opening) {
                distance =  this.calculateDistance(this.vertexes[opening.afterVertexIndex].position,this.vertexes[opening.afterVertexIndex + 1].position);
            }
            this.renderOpening(opening, context, canvas, scale, distance);
            context.moveTo(this.vertexes[index+1].position.x * scale, this.vertexes[index+1].position.y * scale);
        }
    }

    calculateDistance(start, end) {
        let radius = Math.round(Math.abs(Math.sqrt(Math.pow(start.x - end.x, 2) + Math.pow(start.y - end.y, 2))));
        return radius;
    }

    renderOpening(opening: WallOpening, context, canvas, scale, distance) {
        let angle = this.getLineAngle(this.vertexes[opening.afterVertexIndex].position, this.vertexes[opening.afterVertexIndex+1].position);
        opening.draw(angle, this.vertexes[opening.afterVertexIndex].position, this.vertexes[opening.afterVertexIndex+1].position, context, canvas, scale, distance, this.geometryProperties.thickness ? this.geometryProperties.thickness : 1);
    }

    mouseIntersectsGeometry(mousePosition,context,canvas,scale) {
        let intersects = false;
        this.vertexes.forEach((vertex,index) => {
            if(index < this.vertexes.length - 1) {
                /* context.save();

                let angle = this.getLineAngle(vertex.position,this.vertexes[index+1].position);
                let lineLength = this.lineLength(vertex.position,this.vertexes[index+1].position,scale);
                context.beginPath();
                context.translate(this.vertexes[index+1].position.x*scale, this.vertexes[index+1].position.y*scale);
                context.rotate(angle);
                context.rect(-3,-3,lineLength,6);
                context.restore(); */
                let startPoint = vertex.position;
                let endPoint = this.vertexes[index+1].position;
                if(
                    startPoint.x === endPoint.x &&
                    Math.abs(mousePosition.x - startPoint.x) < 2 &&
                    mousePosition.y > ((startPoint.y > endPoint.y) ? endPoint.y : startPoint.y) && 
                    mousePosition.y < ((startPoint.y < endPoint.y) ? endPoint.y : startPoint.y)
                ) {
                    this.defineIntersectedPosition(mousePosition,vertex.position,this.vertexes[index+1].position,scale,index);
                    intersects = true;
                }
                else if(
                    startPoint.y === endPoint.y &&
                    Math.abs(mousePosition.y - startPoint.y) < 2 &&
                    mousePosition.x > ((startPoint.x > endPoint.x) ? endPoint.x : startPoint.x) && 
                    mousePosition.x < ((startPoint.x < endPoint.x) ? endPoint.x : startPoint.x)
                ) {
                    this.defineIntersectedPosition(mousePosition,vertex.position,this.vertexes[index+1].position,scale,index);
                    intersects = true;
                }
                else if(
                    mousePosition.x > ((startPoint.x > endPoint.x) ? endPoint.x : startPoint.x) && 
                    mousePosition.x < ((startPoint.x < endPoint.x) ? endPoint.x : startPoint.x) && 
                    mousePosition.y > ((startPoint.y > endPoint.y) ? endPoint.y : startPoint.y) && 
                    mousePosition.y < ((startPoint.y < endPoint.y) ? endPoint.y : startPoint.y)
                ) {
                    this.defineIntersectedPosition(mousePosition,vertex.position,this.vertexes[index+1].position,scale,index);
                    var dx = mousePosition.x - this.intersectedPoint.x;
                    var dy = mousePosition.y - this.intersectedPoint.y;
                    var distance = Math.abs(Math.sqrt(dx*dx+dy*dy));
                    if(distance < 5) {
                        intersects = true;
                    }
                }
            }
        })
        return intersects;
    }

    defineIntersectedPosition(mousePosition, startPoint, endPoint, scale, index) {
        let linePoint = this.findLinePointNearestToMouse({x0:startPoint.x*scale,x1:endPoint.x*scale,y0:startPoint.y*scale,y1:endPoint.y*scale}, mousePosition);
        this.intersectedAfterVertexIndex = index;
        this.intersectedPoint = linePoint;
    }

    findLinePointNearestToMouse(line,mousePosition) {
        let lerp=function(a,b,x){ return(a+x*(b-a)); };
        var dx=line.x1-line.x0;
        var dy=line.y1-line.y0;
        var t=((mousePosition.x-line.x0)*dx+(mousePosition.y-line.y0)*dy)/(dx*dx+dy*dy);
        var lineX=lerp(line.x0, line.x1, t);
        var lineY=lerp(line.y0, line.y1, t);
        return({x:lineX,y:lineY});
    };

    lineLength(startPoint,endPoint,scale): number {
        let lineLength = Math.round(Math.sqrt(Math.pow((startPoint.x - endPoint.x)*scale,2) + Math.pow((startPoint.y - endPoint.y)*scale,2)));
        return lineLength;
    }

    getLineAngle(startPoint, endPoint) {
        var dx = startPoint.x - endPoint.x;
        var dy = startPoint.y - endPoint.y;
        var angle = Math.atan2(dy, dx);
        return angle;
    }

    findControlToSnap(mousePos, context, canvas, scale) {
        let pointRadius = 5;
        let controlPoint = null
        this.vertexes.forEach((vertex,index) => {
            if(this.intersectsControlPoint(vertex.position, pointRadius, mousePos, scale)) {
                controlPoint = {...vertex.position, name: index};
            }
        })
        return controlPoint;
    }

    drawControls(mousePosition,context,canvas,scale,mode) {
        let intersectsControl = false;
        context.save();
        this.vertexes.forEach((vertex,index) => {
            context.beginPath();
            context.fillStyle = "green";
            let pointRadius = 5;
            intersectsControl = this.intersectsControlPoint(vertex.position, pointRadius, mousePosition, scale);
            if(intersectsControl) {
                context.fillStyle = "yellow";
            }
            context.arc(vertex.position.x * scale, vertex.position.y * scale, pointRadius, 0 * Math.PI, 2 * Math.PI);
            context.fill();
        })
        context.restore();
        if(!intersectsControl && mode && this.mouseIntersectsGeometry(mousePosition,context,canvas,scale)) {
            let withOpenings = (mode === 6 || mode.value);
            let index = this.intersectedAfterVertexIndex;
            if(this.intersectedPoint && this.intersectedPoint.x && this.intersectedPoint.y && (index || index === 0) && (mode<3 || withOpenings)) {
                let color = mode === 1 ? "green" : (withOpenings ? "orange" : "red");
                context.save();
                context.beginPath();
                context.fillStyle = color;
                if(!mode.value) {
                    context.arc(this.intersectedPoint.x, this.intersectedPoint.y, 5, 0, Math.PI*2);
                }
                else if(mode.value) {
                    if(index < this.vertexes.length-1) {
                        this.generateFixedWidthOpeningBuffer(mode.value/2,context,canvas,scale,index);
                        context.beginPath();
                        if(this.fixedWidthOpenignBuff) {
                            context.arc(this.fixedWidthOpenignBuff.startPoint.x*scale, this.fixedWidthOpenignBuff.startPoint.y*scale, 5, 0, Math.PI*2);
                            context.arc(this.fixedWidthOpenignBuff.endPoint.x*scale, this.fixedWidthOpenignBuff.endPoint.y*scale, 5, 0, Math.PI*2);
                        }
                    }
                }
                context.fill();
                context.restore();
            }
        }
        else if(!this.mouseIntersectsGeometry(mousePosition,context,canvas,scale) && this.intersectedPoint) {
            this.resetIntersectedPoints()
        }
    }

    drawInformation(context,canvas,scale) {

    }

    resetIntersectedPoints() {
        delete this.intersectedPoint;
        delete this.intersectedAfterVertexIndex;
        delete this.fixedWidthOpenignBuff;
    }

    intersectsControlPoint(position: VertexPosition, r, mousePos: Point, scale) {
        var distSqr = Math.pow(position.x - mousePos.x/scale, 2) + Math.pow(position.y - mousePos.y/scale, 2);
        if(distSqr < r * r) {
            return true;
        }
        return false;
    }

    generateFixedWidthOpeningBuffer(distance,context,canvas,scale,index) {
        context.save();
        let angle = this.getLineAngle(this.vertexes[index].position,this.vertexes[index+1].position);
        let cos = Math.cos(angle);
        let sin = Math.sin(angle);
        let x0 = -distance;
        let y0 = 0;
        let xnew = x0 * cos - y0 * sin;
        let ynew = x0 * sin + y0 * cos;
        let y1 = ynew + this.intersectedPoint.y/scale;
        let x1 = xnew + this.intersectedPoint.x/scale;
        let endPoint = new Point(x1,y1);
        x0 = distance;
        xnew = x0 * cos - y0 * sin;
        ynew = x0 * sin + y0 * cos;
        y1 = ynew + this.intersectedPoint.y/scale;
        x1 = xnew + this.intersectedPoint.x/scale;
        let startPoint = new Point(x1,y1);
        angle = -this.getLineAngle(this.vertexes[index].position,this.vertexes[index+1].position);
        let lineLength = this.lineLength(this.vertexes[index].position,this.vertexes[index+1].position,scale);
        context.beginPath();
        context.setTransform(1,0,0,1,this.vertexes[index+1].position.x*scale, this.vertexes[index+1].position.y*scale);
        context.rotate(angle);
        context.rect(-3,-3,lineLength,6);
        context.restore();
        if(
            context.isPointInPath(startPoint.x*scale,startPoint.y*scale) &&
            context.isPointInPath(endPoint.x*scale,endPoint.y*scale)
        
            ) {
                this.fixedWidthOpenignBuff = {
                    startPoint: startPoint,
                    endPoint: endPoint
                }
        }
        else {
            delete this.fixedWidthOpenignBuff;
        }
    }

    move(offset) {
        this.vertexes.forEach(vertex => {
            vertex.position.x -= offset.x;
            vertex.position.y -= offset.y;
        })
    }

    mirrorToYAxis(referenceX: number) {
        this.vertexes.forEach(vertex => {
            vertex.position.x += (referenceX-vertex.position.x)*2;
        })
    }

    mirrorToXAxis(referenceY: number) {
        this.vertexes.forEach(vertex => {
            vertex.position.y += (referenceY-vertex.position.y)*2;
        })
    }

}