import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as knowContents from "../../../assets/text-contents/knowledge.json";
import { ImageDialogComponent } from '../dialogs/image-dialog/image-dialog.component';

@Component({
  selector: 'app-knowledge',
  templateUrl: './knowledge.component.html',
  styleUrls: ['./knowledge.component.scss']
})
export class KnowledgeComponent implements OnInit {

  data: any;
  constructor(
    public dialog: MatDialog,

  ) {
    if(!this.data) {
      let faqData: any = knowContents;
      Object.keys(faqData.default).forEach(key => {
        this.data = faqData.default.contents;
      });
    }
  }
  ngOnInit(): void {
  }

  openPictureDialog(pic): void {
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      width: "80vw",
      data: { src: pic }
    });
  }


}
