import { SymbolItem } from "./symbol-item.model";

export class OwnSymbolItem extends SymbolItem {
    id: string;
    folderId: number;
    imageName: string;
    imageBase64: string

    constructor() {
        super();
    }
}