<div mat-dialog-content fxLayout="column" *ngIf="projectSummary">
    <h2>{{ "selectProject" | translate }}</h2>
    <span class="m-tb">{{ "chooseProject" | translate }}</span>
    <div class="p-b-2">
        <mat-radio-group fxLayout="column" fxLayoutAlign="center start"  fxLayoutGap="10px" [(ngModel)]="selectedProject">
            <mat-radio-button *ngFor="let project of projectSummary" value="{{project.id}}">{{project.name}}</mat-radio-button>
        </mat-radio-group>   
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <button mat-flat-button class="ep-btn-call" [disabled]="!selectedProject" (click)="submitValue(selectedProject)">{{ "save" | translate }}</button>
        <button mat-stroked-button (click)="submitValue(false)">{{ "cancel" | translate }}</button> 
    </div>
</div>