import {TranslationService} from '../../operational-services/translation/translation.service';


export class ValidationService {

  constructor() {
  }

  static getValidatorErrorMessage(translationService: TranslationService, validatorName: string, validatorValue?: any, controlName?: string) {

    let config = {
      required: translationService.translateSimpleToken("validationErrors." + (controlName ? "requiredSpecial." + controlName : "required")),
      pattern: translationService.translateSimpleToken("validationErrors.pattern"),
      minlength: translationService.translateSimpleToken("validationErrors.minlength") + `${validatorValue.requiredLength}`,
      maxlength: translationService.translateSimpleToken("validationErrors.maxlength") + `${validatorValue.requiredLength}`,
      invalidJson: translationService.translateSimpleToken("validationErrors.invalidJson"),
      invalidUri: translationService.translateSimpleToken("validationErrors.invalidUri"),
      invalidChar: translationService.translateSimpleToken("validationErrors.invalidChar"),
      invalidStreetNumber: translationService.translateSimpleToken("validationErrors.invalidStreetNumber"),
      invalidZipCode: translationService.translateSimpleToken("validationErrors.invalidZipCode"),
      invalidPhoneNumber: translationService.translateSimpleToken("validationErrors.invalidPhoneNumber"),
      email: translationService.translateSimpleToken("validationErrors.email"),
      invalidAmount: translationService.translateSimpleToken("validationErrors.invalidAmount"),
      invalidPassword: translationService.translateSimpleToken("validationErrors.invalidPassword"),
      invalidNumeric: translationService.translateSimpleToken("validationErrors.invalidNumeric"),
      invalidCharacterCombo: translationService.translateSimpleToken("validationErrors.invalidCharacterCombo"),
      invalidSpecialChar: translationService.translateSimpleToken("validationErrors.invalidSpecialChar"),
      min: translationService.translateSimpleToken("validationErrors.min") + `${validatorValue.min}`,
      max: translationService.translateSimpleToken("validationErrors.max") + `${validatorValue.max}`,
    };

    return config[validatorName];
  }

  static minValue(control) {
    if (control.value && control.value > ValidationService.minValue) {
      return null;
    }  else {
      return { minValue: true };
    }
  }

  static maxValue(control) {
    if (control.value && control.value < ValidationService.maxValue) {
      return null;
    }  else {
      return { minValue: true };
    }
  }

  static containsNumeric(control) {
    if (control.value && control.value.match(/[0-9]/)) {
      return null;
    } else if (!control || !control.value) {
      return null;
    } else if (control.value && control.value.length === 0) {
      return null;
    } else {
      return { invalidNumeric: true };
    }
  }

  static containsUpperAndLowerCase(control) {
    if (control.value && control.value.match(/(.*[a-z])(?=.*[A-Z])/)) {
      return null;
    } else if (!control || !control.value) {
      return null;
    } else if (control.value && control.value.length === 0) {
      return null;
    } else {
      return { invalidCharacterCombo: true };
    }
  }

  static containsSpecialChar(control) {
    if (
      control.value &&
      control.value.match(/(.*[!"#$%&')(*+,\-\]\[./:;><?=@^_`|}{~])/)
    ) {
      return null;
    } else if (!control || !control.value) {
      return null;
    } else if (control.value && control.value.length === 0) {
      return null;
    } else {
      return { invalidSpecialChar: true };
    }
  }

  static matchSpecialChar(control) {
    if (
      control.value &&
      control.value.match(/(.*[!"#$%&')(*+,\-\]\[./:;><?=@^_`|}{~])/)
    ) {
      return true;
    } else {
      return false;
    }
  }

  static matchChars(control) {
    if (
      control.value &&
      control.value.match(/[A-Z]/) &&
      control.value.match(/[a-z]/)
    ) {
      return true;
    } else {
      return false;
    }
  }

  static matchNumber(control) {
    if (control.value && control.value.match(/[0-9]/)) {
      return true;
    } else {
      return false;
    }
  }

  static passwordValidator(control) {
    let matchNumeric: boolean = false;
    let matchCases: boolean = false;
    let matchSpecial: boolean = false;
    let passwordValue = control.value;
    if (passwordValue != undefined && passwordValue.length > 1) {
      // validate numeric
      if (ValidationService.matchNumber(control)) {
        matchNumeric = true;
      }
      // validate case sensitivity
      if (ValidationService.matchChars(control)) {
        matchCases = true;
      }
      // validate special char
      if (ValidationService.matchSpecialChar(control)) {
        matchSpecial = true;
      }

      if (
        (matchNumeric && matchCases) ||
        (matchNumeric && matchSpecial) ||
        (matchCases && matchSpecial)
      ) {
        return null;
      } else {
        return { invalidPassword: true };
      }
    } else return null;
  }

  static jsonValidator(control) {
    if (
      control.value.match(
        /[{\[]{1}([,:{}\[\]0-9.\-+Eaeflnr-u \n\r\t]|".*?")+[}\]]{1}/
      )
    ) {
      return null;
    } else {
      return { invalidJson: true };
    }
  }

  static redirectUriValidator(control) {
    if (
      control.value &&
      control.value.match(
        /^(?:https:\/\/)(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::\d+)?(?:\/?|[\/?]\S+)$/i
      ) &&
      control.value.indexOf("#") <= 0
    ) {
      return null;
    } else if (!control || !control.value) {
      return null;
    } else if (control.value && control.value.length === 0) {
      return null;
    } else {
      return { invalidHttpsUri: true };
    }
  }

  static uriValidator(control) {
    if (
      control.value &&
      control.value.match(
        /^((?:http)s?:\/\/)(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::\d+)?(?:\/?|[\/?]\S+)$/i
      )
    ) {
      return null;
    } else if (!control || !control.value) {
      return null;
    } else if (control.value && control.value.length === 0) {
      return null;
    } else {
      return { invalidUri: true };
    }
  }

  static nameValidator(control) {
    if (
      control.value &&
      control.value.match(
        /^[a-zA-Z0-9 ß ä ö ü ß Ä Ö Ü À Â Ç È É Ê Ë Í Î Ï Ó Ù Ô Û Ñ Ã Ÿ à â ç è é ê ë í î ï ó ô ù û ñ ã ÿ ü\-\\s &\/()+@._]+$/
      )
    ) {
      return null;
    } else if (!control || !control.value) {
      return null;
    } else if (control.value && control.value.length === 0) {
      return null;
    } else {
      return { invalidChar: true };
    }
  }

  static textValidator(control) {
    if (
      control.value &&
      control.value.match(
        /^([a-zA-Z0-9 ^°'²§³$%&\/(){}[=?\´`+\*~#'@<>|,;.:\-_ ]+)$/i
      )
    ) {
      return null;
    } else if (!control || !control.value) {
      return null;
    } else if (control.value && control.value.length === 0) {
      return null;
    } else {
      return { invalidChar: true };
    }
  }

  static streetNumberValidator(control) {
    if (control.value && control.value.match(/^[a-zA-Z0-9\/\.\-]*$/)) {
      return null;
    } else if (!control || !control.value) {
      return null;
    } else if (control.value && control.value.length === 0) {
      return null;
    } else {
      return { invalidStreetNumber: true };
    }
  }

  static zipCodeValidator(control) {
    if (control.value && control.value.match(/^[0-9]*$/)) {
      return null;
    } else if (!control || !control.value) {
      return null;
    } else if (control.value && control.value.length === 0) {
      return null;
    } else {
      return { invalidZipCode: true };
    }
  }

  static phoneNumberValidator(control) {
    if (control.value && control.value.match(/^\+?\d+$/)) {
      return null;
    } else if (!control || !control.value) {
      return null;
    } else if (control.value && control.value.length === 0) {
      return null;
    } else {
      return { invalidPhoneNumber: true };
    }
  }

  static amountValidator(control) {
    if (
      control.value &&
      control.value.match(/^(?:[+-])?\d{0,9}(?:[.]\d{1,2})?$/)
    ) {
      return null;
    } else if (!control || !control.value) {
      return null;
    } else if (control.value && control.value.length === 0) {
      return null;
    } else {
      return { invalidAmount: true };
    }
  }

  static validateNumeric(modelValue) {
    if (/[0-9]/.test(modelValue)) {
      return true;
    } else {
      return false;
    }
  }

  static bulkUploadValidator(dataSet, originIban) {
    let brokenTransactions = [];
    if (Array.isArray(dataSet) && dataSet.length >= 1) {
      dataSet.forEach(element => {
        if (
          (element && element.amount && element.counterPartyIban) ||
          (element.counterPartyIban === "" && element.currencyCode) ||
          (element.currencyCode === "" && element.creditorId) ||
          (element.creditorId === "" && element.bookingDate) ||
          (element.bookingDate === "" && element.mandateReference) ||
          (element.mandateReference === "" && element.counterPartyName) ||
          (element.counterPartyName === "" &&
            element.externalBankTransactionDomainCode) ||
          (element.externalBankTransactionDomainCode === "" &&
            element.externalBankTransactionFamilyCode) ||
          (element.externalBankTransactionFamilyCode === "" &&
            element.externalBankTransactionSubFamilyCode) ||
          (element.externalBankTransactionSubFamilyCode === "" &&
            element.transactionCode &&
            element.paymentReference) ||
          (element.paymentReference === "" && element.valueDate) ||
          element.valueDate === ""
        ) {
          element.originIban = originIban;
        } else {
          brokenTransactions.push(element.index);
        }
      });
      return brokenTransactions;
    }
  }

  static matchValidator(group) {
    let values = [];
    for (let name in group.controls) {
      values.push(group.controls[name].value);
    }

    if (values[0] === values[1]) {
      return true;
    } else {
      group.setErrors({ matchValidator: false });
      group.controls['confirmNewPassword'].setErrors({ matchValidator: false });
    }
  }

}
