import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Plan } from 'src/app/models/project/plan.model';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { Layer } from 'src/app/models';
import { TranslationService } from '../../operational-services/translation/translation.service';
import { FileService } from '../../operational-services/file/file.service';
import { ViewerService } from '../../operational-services/viewer/viewer.service';
import { NotificationService } from '../../status-and-control-services/notification/notification.service';
import { SignalrService } from '../../signalr/signalr.service';
import { Geometry } from 'src/app/models/geometries/geometry.model';
import { DataService } from '../../operational-services/data/data.service';

@Injectable({
  providedIn: 'root'
})
export class PlanService {
  activePlanId: string;
  private _activeProjectId: string;
  drawingName: string;
  recentlySaved: boolean;

  planError: any;
  _planErrorSource: BehaviorSubject<any>;
  planError$: Observable<any>;

  constructor(
    private httpClient: HttpClient,
    private translationService: TranslationService,
    private fileService: FileService,
    private viewerService: ViewerService,
    private notificationService: NotificationService,
    private signalRService: SignalrService,
    private dataService:DataService
  ) {
    this.recentlySaved = false;
    this.resetObservers();
  }

  get activeProjectId(): string {
    return this._activeProjectId;
  }

  set activeProjectId(value: string) {
    this._activeProjectId = value;
  }

  resetObservers() {
    this.planError = null;
    this._planErrorSource = new BehaviorSubject(null);
    this.planError$ = this._planErrorSource.asObservable();
  }

  emitError(description: string) {
    const infoString = this.translationService.translateSimpleToken(description);
    const errorTitle = this.translationService.translateSimpleToken("ERROR");
    this.planError = {kind: errorTitle, serviceErrorCode: infoString, short: description}
    this._planErrorSource.next(this.planError);
    this.planError = null;
    this._planErrorSource.next(this.planError);
  }

  getPlansByProjectId(id:string) {
    return this.httpClient.get<Array<Plan>>(
      environment.requestUrl + "/api/plans/"+id
    );
  }

  setActivePlanData(plan) {
    this.activePlanId = plan.id;
    this.fileService.planId = plan.id;
    this.fileService.planName = plan.drawingName;
    this.fileService.projectId = this._activeProjectId;
    this.drawingName = plan.drawingName;
    this.recentlySaved = true;
  }

  getPlanById(id) {
    return this.httpClient.get<Plan>(environment.requestUrl + "/api/projectdrawing/"+id);
  }

  createPlan(planData) {
    return this.httpClient.post(
      environment.requestUrl + "/api/projectdrawing", planData
    );
  }

  editPlan(editData, id, layerNames: string[] = null) {
    let drawings = JSON.parse(localStorage.getItem("drawings"));
    if(!!drawings) {
      const drawingsIndex = drawings.findIndex(dr => dr.id == this.activePlanId);
      if(drawingsIndex > -1) {
        drawings[drawingsIndex].layerOrder = layerNames;
        localStorage.setItem("drawings",JSON.stringify(drawings));
      }
    }
    return this.httpClient.put(
      environment.requestUrl + "/api/projectdrawing/"+ id, editData
    );
  }

  deletePlan(id) {
    return this.httpClient.delete(
      environment.requestUrl + "/api/projectdrawing/" + id
    );
  }

  savePlanAction(projectId: string, deletedEntityNames: string[], dxfFilePath: string, newAndUpdatedEntitiesJson: Geometry[], layerNames: string[], editMode = false): Observable<any> {
    let initScale = this.getActiveDrawingInitScale();
    if(!initScale) {
      this.setActiveDrawingScale(1);
    }
    let obs: Observable<any> = new Observable(observer => {
      if(projectId && this.activePlanId) {
        let editData = {
          drawingDxfPath: dxfFilePath,
          projectId: projectId,
          deletedEntityNames: deletedEntityNames,
          drawingName: this.drawingName,
          connectionid: this.signalRService.connectionId,
          newAndUpdatedEntitiesJson: JSON.stringify(newAndUpdatedEntitiesJson)
        }
        this.savePlan(editData, layerNames, editMode, this.activePlanId).subscribe(response => {
          observer.next(true)
        },
        error => {
          observer.error();
        });
      }
      else {
        this.emitError("NO_ACTIVE_DRAWING");
        this.viewerService._loadingSource.next(false);
        this.resetObservers;
      }
    })
    return obs;
  }

  savePlan(data, layerNames: string[], editMode:boolean, id): Observable<any> {
    let obs: Observable<any> = new Observable(observer => {
      let count = 1;
      let subscription = timer(5000, 30000).subscribe(() => this.notificationService.openNotification("loading_progress_" + (count++%2), true,50000,{horizontalPosition: 'center'}));

      this.editPlan(data, id, layerNames).subscribe(
        (response: any) => {
          subscription.unsubscribe();
          this.recentlySaved = true;
          this.fileService.dxfFile = response.data.fileBase64;
          this.fileService.dxfFilePath = response.data.dxfFilePath;
          this.fileService.images = response.data.images;
          if(!editMode) {
            this.fileService.emitFileInfo();
          }
          observer.next(true);
          if(response && response.status === 1)
          {
            this.dataService.cleanNewOrUpdatedEntitiesArray();
          }
        },
        error => {
          subscription.unsubscribe();
          observer.error();
        }
      )
    });
    return obs;
  }

  getActiveDrawingInitScale() {
    return this.getInitScale(this.activePlanId);
  }

  getInitScale(id: string) {
    let drawings = JSON.parse(localStorage.getItem("drawings"));
    if(drawings) {
      let drawingIndex = drawings.findIndex(d => d.id === id);
      if(drawingIndex > -1) {
        return drawings[drawingIndex].initScale;
      }
      else {
        return null;
      }
    }
    else {
      return null
    }
  }

  setDrawingScale(id: string, scale: number) {
    let drawings = JSON.parse(localStorage.getItem("drawings"));
    if(drawings && drawings.length) {
      let drawingIndex = drawings.findIndex(d => d.id === id);
      if(drawingIndex > -1 && drawings[drawingIndex].initScale) {
        drawings[drawingIndex].lastScale = scale;
        drawings[drawingIndex].fileName = drawings[drawingIndex].fileName ? drawings[drawingIndex].fileName : this.fileService.getDxfFileName();
      }
      else {
        let drawing = {id: id, initScale: scale, drawingName: this.drawingName, fileName: this.fileService.getDxfFileName()};
        drawings.push(drawing);
      }
      localStorage.setItem("drawings",JSON.stringify(drawings));
    }
    else {
      let drawing = {id: id, initScale: scale, drawingName: this.drawingName, fileName: this.fileService.getDxfFileName()};
      drawings = [drawing];
      localStorage.setItem("drawings",JSON.stringify(drawings));
    }

  }

  setActiveDrawingScale(scale: number) {
    this.setDrawingScale(this.activePlanId, scale);
  }

  getDrawingAsSvgById(drawingId: string) {
    if(drawingId) {
      return this.httpClient.get(
        environment.requestUrl + '/api/projectdrawing/preview/' + drawingId
      );
    }
  }

  editPlanName(editData,id) {
    return this.httpClient.put(
      environment.requestUrl + "/api/projectdrawing/"+ id+ "/name", editData
    );
  }

  copyPlan(copyData){
    return this.httpClient.post(environment.requestUrl + "/api/projectdrawing/copy", copyData);
  }

}
