import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Project } from 'src/app/models/project/project.model';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { KeyValue } from '@angular/common';
import { ProjectDataFormTemplate } from 'src/app/models/form-templates/project-data-form-template';
import { ProjectService } from 'src/app/services/user/project/project.service';
import { MatDialog } from '@angular/material/dialog';
import { InputDialogComponent } from '../../dialogs/input-dialog/input-dialog.component';
import { Plan } from 'src/app/models/project/plan.model';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';
import * as sampleProjectData from "../../../../assets/projects/sample-project.json";
import { NotificationService } from 'src/app/services/status-and-control-services/notification/notification.service';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { InfoDialogComponent } from '../../dialogs/info-dialog/info-dialog.component';
import { TranslationService } from '../../../services/operational-services/translation/translation.service';

@Component({
  selector: 'app-project-data',
  templateUrl: './project-data.component.html',
  styleUrls: ['./project-data.component.scss']
})
export class ProjectDataComponent implements OnInit {
  projectDataForm: any;
  projectDataFormTemplate: ProjectDataFormTemplate;
  projectData: Project;
  activeTab: number;
  formFilled: boolean;
  isBrowser: boolean;
  projectId: string;
  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private projectService: ProjectService,
    private cdRef: ChangeDetectorRef,
    private notificationService: NotificationService,
    private translationService: TranslationService,

    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if(this.isBrowser) {
      this.formFilled = false;
      this.activeTab = 0;
    }
  }

  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

  get controls() {
    return this.projectDataForm.controls;
  }

  get additionalDataControls() {
    return this.projectDataForm.get('detail').controls;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if(this.isBrowser) {
      this.initForm();
      this.projectDataFormTemplate = new ProjectDataFormTemplate();
      this.route.queryParams.subscribe(params => {
        this.projectId = this.route.snapshot.params['id'];
        if(this.projectId !== "newProject" && this.projectId !== "sampleProject") {
          this.projectService.getProjectById(this.route.snapshot.params['id']).subscribe((response: any) => {
            this.projectData = response.data;
            this.fillForm(this.projectData);
          });
        }
        else if(this.projectId === "sampleProject") {
          let projectData: any = sampleProjectData;
          this.projectData = projectData.default.projectDetails;
          this.fillForm(this.projectData);
          let message = this.translationService.translateSimpleToken("project.sampleProjectMessage");
          this.dialog.open(InfoDialogComponent,{data: {serviceErrorCode: message}});
        }
        else {
          this.formFilled = true;
        }
      })
      this.onChange();
    }
  }

  onChange(): void {
    this.cdRef.detectChanges();
  }

  goToTAb(tab: number) {
    this.activeTab = tab;
  }

  fillForm(data) {
    if(data) {
      this.projectDataForm.reset();
      Object.keys(this.controls).forEach(key => {
        if(key !== "creator" && key !== "client" && key !== "detail") {
          if( data[key]) {
            this.projectDataForm.get(key).setValue( data[key]);
            this.projectDataForm.value[key] =  data[key];
          }
        }
        else {
          Object.keys(this.projectDataForm.get(key)['controls']).forEach(nestedControlKey => {
            if( data[key] &&  data[key][nestedControlKey] && nestedControlKey !== 'address') {
              this.projectDataForm.get(key).get(nestedControlKey).setValue( data[key][nestedControlKey])
            }
            else if( data[key][nestedControlKey] && nestedControlKey === 'address') {
              Object.keys(this.projectDataForm.get(key)['controls'][nestedControlKey].controls).forEach(nestedNestedControlKey => {
                if( data[key] &&  data[key][nestedControlKey] &&  data[key][nestedControlKey][nestedNestedControlKey]) {

                  this.projectDataForm.get(key).get(nestedControlKey).get(nestedNestedControlKey).setValue( data[key][nestedControlKey][nestedNestedControlKey])
                }
              })
            }
          })
        }
      });
    }
    else {
      this.projectDataForm.reset();
    }
    this.projectDataForm.markAsUntouched();
    this.formFilled = true;
  }

  initForm() {
    this.projectDataForm = this.formBuilder.group({
      projectNo: new UntypedFormControl(null, Validators.required),
      type: new UntypedFormControl(""),
      object: new UntypedFormControl(""),
      floor: new UntypedFormControl(""),
      room: new UntypedFormControl(""),
      creationDate: new UntypedFormControl(null),
      lastChangedDate: new UntypedFormControl(null),
      nextReviewDate: new UntypedFormControl(null),
      creator: new UntypedFormGroup({
        creatorName: new UntypedFormControl(""),
        address: new UntypedFormGroup({
          postCode: new UntypedFormControl(null),
          streetWithNumber: new UntypedFormControl(""),
          city: new UntypedFormControl(""),
          phone: new UntypedFormControl(null),
          mobile: new UntypedFormControl(null),
          fax: new UntypedFormControl(""),
          email: new UntypedFormControl("", Validators.email),
        })
      }),
      comment: new UntypedFormControl(null),
      client: new UntypedFormGroup({
        clientName: new UntypedFormControl(""),
        responsibleName: new UntypedFormControl(""),
        builderName: new UntypedFormControl(null),
        address: new UntypedFormGroup({
          postCode: new UntypedFormControl(null),
          streetWithNumber: new UntypedFormControl(""),
          city: new UntypedFormControl(""),
          phone: new UntypedFormControl(null),
          mobile: new UntypedFormControl(null),
          fax: new UntypedFormControl(""),
          email: new UntypedFormControl("", Validators.email),
        })
      }),
      detail: new UntypedFormGroup({
        fireSensorsNr: new UntypedFormControl(null),
        normId: new UntypedFormControl(null),
        buildingUsage: new UntypedFormControl(null),
        partnerInCase: new UntypedFormControl(null),
        contentsList: new UntypedFormControl(null),
        distributor: new UntypedFormControl(null),
        personel: new UntypedFormControl(null),
        workingHours: new UntypedFormControl(null),
        fireKeys: new UntypedFormControl(null),
        energySupplyTips: new UntypedFormControl(null),
        hazardAndTechnicsTips: new UntypedFormControl(null),
        dangerousSubstancesTip: new UntypedFormControl(null),
        technicalBuildingEquipment: new UntypedFormControl(null),
        buildingDescription: new UntypedFormControl(null),
        otherInformation: new UntypedFormControl(null),
      })
    });
  }

  onSubmit() {
    if(this.projectData) {
      let dialogRefPass = this.dialog.open(InputDialogComponent, {
        data: {
          value: this.projectData.name,
          valueName: "Projekt-Name",
          buttonText: "Projektdaten speichern"
        }
      },);
      dialogRefPass.afterClosed().subscribe(result => {
        Object.keys(this.projectDataForm.value).forEach(key => {
          this.projectData[key] = this.projectDataForm.value[key];
        })
        if(result && result.projectName) {
          this.projectData.name = result.projectName;
          this.projectService.editProject(this.projectData).subscribe((response :Project) => {
            this.projectService.updateProjectsState();
            this.notificationService.openNotification("save_project_success")
            this.router.navigate(["../projects"]);
          });
        }
      })
    }
    else {
      this.createProject();
    }
  }

  createProject() {
    let dialogRefPass = this.dialog.open(InputDialogComponent, {
      data: {
        valueName: "Projekt-Name",
        buttonText: "Projekt anlegen"
      }
    },);
    dialogRefPass.afterClosed().subscribe(result => {
      if(result && result.projectName) {
        let project = new Project();
        project = {...this.projectDataForm.value};
        project.name = result.projectName;
        project.projectDrawings = new Array<Plan>();
        this.projectService.createNewProject(project).subscribe(response => {
          this.projectService.updateProjectsState();
          this.notificationService.openNotification("create_project_success")
          this.router.navigate(["../projects"]);
        });
      }
    });
  }

  navigateToProjects() {
    let dialogRefPass = this.dialog.open(ConfirmationDialogComponent,{
      data: {
        question: "back_to_projects"
      }
    });
    dialogRefPass.afterClosed().subscribe(result => {
      if(result) {
        this.router.navigate(["../projects"]);
      }
    });
  }

}
