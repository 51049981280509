import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventsPropagationService } from 'src/app/services/status-and-control-services/events-propagation/events-propagation.service';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss']
})
export class InputDialogComponent implements OnInit {
  value: string
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InputDialogComponent>,
    private eventsPropagationService: EventsPropagationService
  ) {
    this.value = data.value ? data.value : "";
  }

  ngOnInit(): void {
  }

  onBlur() {
    this.eventsPropagationService.enableEvents();
  }

  onFocus() {
    this.eventsPropagationService.disableEvents();
  }

  ngOnDestroy() {
    this.onBlur();
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  submitValue() {
    if(this.data && this.data.valueName === "Plan-Name") {
      this.dialogRef.close({ planName: this.value });
    }
    else if(this.data && this.data.valueName === "Projekt-Name") {
      this.dialogRef.close({ projectName: this.value });
    }
    else if(this.data && this.data.valueName === "Symbol Name") {
      this.dialogRef.close({ symbolName: this.value });
    }
    else if(this.data && this.data.valueName === "Element name") {
      this.dialogRef.close({ elementName: this.value });
    }
    else if(this.data && this.data.valueName === "Text Inhalt") {
      this.dialogRef.close({ textValue: this.value });
    }
    else if(this.data && this.data.valueName === "Email") {
      this.dialogRef.close({ email: this.value });
    }
    else {
      this.dialogRef.close({ layerName: this.value });
    }
  }

}
