<div class="additional-tools-element" fxFlex fxLayout="column" fxLayoutAlign="start" fxLayoutGap="5px" >
    <mat-label class="p-l">Symbole</mat-label>
    <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <button class="symbol-category-selection-trigger" [matTooltip]="selectedCategory.name+ ' ' + selectedList.name" mat-button [matMenuTriggerFor]="levelOne" #levelOneTrigger="matMenuTrigger" (click)="levelOneTrigger.openMenu()">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="symbol-category-selection-trigger-text-container" fxLayout="column" fxLayoutAlign="start start">
                    <span class="symbol-category-selection-trigger-text" *ngIf="selectedCategory">{{selectedCategory.name}}</span>
                    <span class="symbol-category-selection-trigger-text" *ngIf="selectedList">{{selectedList.name}}</span>
                </div>
                <mat-icon>expand_more</mat-icon>
            </div>
        </button>
        <mat-menu #levelOne="matMenu" direction="down" yPosition="below">
            <ng-container *ngFor="let symbolCategory of symbolCategories; let categoryIndex = index">   
                <span (mouseleave)="levelOneTrigger.closeMenu()"></span>
                <ng-container>
                    <li *ngIf="symbolCategory.symbolLists && symbolCategory.symbolLists.length" mat-menu-item [matMenuTriggerFor]="levelTwo">
                        <span>{{symbolCategory.name}}</span>
                    </li>
                    <mat-menu #levelTwo="matMenu">
                        <ng-container *ngFor="let symbolList of symbolCategory.symbolLists; let listIndex = index">
                            <button mat-menu-item (click)="selectList(categoryIndex,listIndex)">
                            {{symbolList.name}}
                            </button>
                        </ng-container>
                    </mat-menu>
                </ng-container>
            </ng-container>
        </mat-menu>
        <button mat-icon-button (click)="uploadSymbol()" matTooltip="Eigene Grafik hochladen">
            <mat-icon svgIcon="upload-solid"></mat-icon>
        </button>
    </div>
    <div fxFlex fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px">
        <div *ngFor="let icon of selectedList.items" fxLayout="column" fxLayoutAlign="start center">
            <div class="ep-symbol-container" fxLayout="column" fxLayoutAlign="center center">
                <img class="ep-symbol" [matTooltip]="icon.title" [src]="symbolsFolderPath + icon.imageFileName" alt="" onerror="this.onerror=null; this.src='assets/images/placeholder.png'" draggable="false" (mousedown)="onDragStart($event,icon)">
            </div>
            <span class="ep-symbol-title" [matTooltip]="icon.title">{{icon.title}}</span>
        </div>
        <div *ngFor="let icon of ownSymbolsList.items" fxLayout="column" fxLayoutAlign="start center">
            <div class="ep-symbol-container" fxLayout="column" fxLayoutAlign="center center">
                <img class="ep-symbol" [matTooltip]="icon.imageName" [src]="icon.imageBase64" alt="" onerror="this.onerror=null; this.src='assets/images/placeholder.png'" draggable="false" (mousedown)="onDragStart($event,icon)">
                <button class="symbol-delete-button" mat-icon-button (click)="deleteSymbol(icon.id)" matTooltip="Symbol entfernen">
                    <mat-icon svgIcon="trash-solid"></mat-icon>
                </button>
            </div>
            <span class="ep-symbol-title" [matTooltip]="icon.imageName">{{icon.imageName}}</span>
        </div>
    </div>
</div>