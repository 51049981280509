<div class="full-height-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="40px">
    <div></div>
    <form class="ep-single-form" [formGroup]="loginForm" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <h3>Login</h3>
        <div class="ep-form-control-container">
            <div class="ep-form-control">
                <span>{{ "project.email" | translate }}</span>
                <input type="text" formControlName="email">
            </div>
            <mat-error *ngIf="loginForm?.get('email').touched">
                <control-messages [control]="{controlProperties: loginForm?.get('email'), controlName: 'email'}">
                </control-messages>
            </mat-error>
        </div>
        <div class="ep-form-control-container">
            <div class="ep-form-control">
                <span>{{ "authentication.password" | translate }}</span>
                <input type="password" formControlName="password">
            </div>
            <mat-error *ngIf="loginForm?.get('password').touched">
                <control-messages [control]="{controlProperties: loginForm?.get('password'), controlName: 'password'}">
                </control-messages>
            </mat-error>
        </div>
    </form>
    <div *ngIf="!passed" class="w-50" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="50px">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            <h2 class="font-warning">Achtung!</h2>
            <p class="font-warning text-center">Im Zeitraum zwischen dem <b>23.01.2023</b> und <b>27.01.2023</b> spielen wir die neue Version vom Fluchtplan Manager ein. Eine Arbeit an Ihren Plänen ist in dieser Zeit nicht möglich. Nach der Umstellung können Sie wieder an Ihren Plänen arbeiten.</p>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="50px">
        <button mat-flat-button class="ep-btn-call" [disabled]="loginForm.invalid" (click)="login()">Login</button>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            <!-- <a [routerLink]="['../forgot_password']" class="disabled">{{ "authentication.forgotPassword" | translate }}</a> -->
            <p>Sie haben noch kein Konto?</p>
            <a [routerLink]="['../registration']">{{ "authentication.register_now" | translate }}</a>
            <p>Haben Sie Ihr Passwort vergessen?</p>
            <a class="ep-link" (click)="openForgotPasswordDialog()">{{ "authentication.forgot_my_password" | translate }}</a>
        </div>
    </div>
    <div fxFlex="20px">
    </div>
</div>