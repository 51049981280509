<div class="additional-tools-element">
    <p>{{ "frame.frame" | translate }}</p>
    <div class="p-tb" fxLayout="column">
       <!--  <mat-checkbox (click)="togglePaperFormatLock()" [checked]="paperFormatLocked">
            {{"frame.paperFormatLocked" | translate }}
        </mat-checkbox> -->
        <mat-divider></mat-divider>
        <div class="m-tb ep-form-control-container">
            <div class="ep-form-control" fxLayoutGap="15px">
                <span>{{ "frame.text" | translate }}</span>
                <input type="text" [(ngModel)]="title" (blur)="onBlur()" (focus)="onFocus()">
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="ep-form-control-container">
            <div class="ep-form-control">
                <span>Textgröße</span>
                <input type="number" [(ngModel)]="fontSize" (blur)="onBlur()" (focus)="onFocus()">
            </div>
        </div>
        <div class="ep-form-control-container">
            <div class="ep-form-control">
                <span>Linienstärke</span>
                <input type="number" [ngModel]="geometryProperties.thickness" (blur)="onBlur()" (focus)="onFocus()"
                (ngModelChange)="updateLineWidth($event)">
            </div>
        </div>
        <div class="w-100 p-b">
            <mat-form-field class="w-100">
                <mat-label>Typ</mat-label>
                <mat-select (selectionChange)="updateLineType($event)" [(value)]="geometryProperties.lineType">
                    <mat-option *ngFor="let lineType of lineTypesList" [value]="lineType.type">{{lineType.patternSample}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <mat-label class="font-grey">Linienfarbe</mat-label>
        <color-sketch [presetColors]="frameColors" [color]="strokeColor ? strokeColor : '#008e5e'" (onChangeComplete)="updateCurrentFrameColor($event)"></color-sketch>
        <mat-divider></mat-divider>
        <button mat-flat-button class="ep-btn-call" (click)="generateFrame()">{{ "frame.generateNew" | translate }}</button>

    </div>
</div>