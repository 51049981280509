import { Component, OnInit } from '@angular/core';
import { SelectionService } from 'src/app/services/status-and-control-services/selection/selection.service';
import { GeometryEditingService } from 'src/app/services/operational-services/geometry-editing/geometry-editing.service';
import { Geometry } from 'src/app/models/geometries/geometry.model';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import { GeometricType } from 'src/app/models/enums/geometric-types.enum';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services';
import { AppSettingsService } from 'src/app/services/status-and-control-services/app-settings/app-settings.service';

@Component({
  selector: 'app-objects-order-tools',
  templateUrl: './objects-order-tools.component.html',
  styleUrls: ['./objects-order-tools.component.scss']
})
export class ObjectsOrderToolsComponent implements OnInit {
  geometries: Array<Geometry>;
  geometrySubscription: Subscription;
  constructor(
    private selectionService: SelectionService,
    private geometryEditingService: GeometryEditingService,
    private dataService: DataService,
    private appSettingsService: AppSettingsService

  ) {
    this.geometries = new Array<Geometry>();
    this.geometrySubscription = this.dataService.geometricalData$.subscribe(data => {
      this.updateActiveGeometricalData();
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.geometrySubscription.unsubscribe();
  }

  getGeometricTypeName(type: number) {
    let parsedType: string = GeometricType[type.toString()];
    return parsedType;
  }

  updateActiveGeometricalData() {
    this.geometries = this.selectionService.getGeometricalDataFromActiveLayer(this.appSettingsService.variableSettings.elementsPerLayerSideBarLimit);
    if(this.geometries){
      this.geometries = this.geometries.slice().reverse();
    }
  }

  selectionExists() {
    return this.selectionService.selectedElementsExist();
  }

  multipleObjectsSelected() {
    return this.selectionService.multipleObjectsSelected();
  }

  moveSelectionToForeground() {
    this.geometryEditingService.moveSelectionToForeground();
  }

  isSelected(name: string) {
    return this.selectionService.selectionIncludesGeometry(name);
  }

  moveSelectionToBackground() {
    this.geometryEditingService.moveSelectionToBackground();
  }

  rearrangeGeometries(event: CdkDragDrop<Array<Geometry>>) {
    this.geometryEditingService.rearrangeGeometries(event);
  }

  selectionOneStepToBackground() {
    this.geometryEditingService.moveSelectionOneStepToBackground();
  }

  selectionOneStepToForeground() {
    this.geometryEditingService.moveSelectionOneStepToForeground();
  }

  selectElement(name: string) {
    this.selectionService.resetSelection();
    this.selectionService.setActiveElement(name);
    this.selectionService.addElementToSelection();
  }
}
