import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { UnitEnum } from 'src/app/models/enums/unit-enum';
import { BehaviorSubject, Observable } from 'rxjs';
import * as appVariablesConfigs from "../../../../assets/appVariablesConfigs.json";
import { AppVariableSettings } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  dimensionsSettings: any;
  isBrowser: boolean;
  gridSettings: any;
  private _settingsChangeSource: BehaviorSubject<boolean>;
  settingsChange$: Observable<boolean>;
  variableSettings: AppVariableSettings;

  constructor(
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if(this.isBrowser) {
      let configsContent: any = appVariablesConfigs
      this.variableSettings = configsContent.default;
      let settingsBuff = JSON.parse(localStorage.getItem("set"));
      if(settingsBuff) {
        if(settingsBuff.dimensionsSettings) {
          this.dimensionsSettings = settingsBuff.dimensionsSettings;
          this.dimensionsSettings.ratio = settingsBuff.dimensionsSettings.ratio ? settingsBuff.dimensionsSettings.ratio : 1;
        }
        else {
          this.initDimensionsSettings();
        }
        if(settingsBuff.gridSettings) {
          this.gridSettings = settingsBuff.gridSettings;
        }
        else {
          this.initGridSettings();
        }
      }
      else {
        this.initDimensionsSettings();
        this.initGridSettings();
      }
      this._settingsChangeSource = new BehaviorSubject(true);
      this.settingsChange$ = this._settingsChangeSource.asObservable();
    }
  }

  initDimensionsSettings() {
    this.dimensionsSettings = {
      units: 1,
      pixelInMeter: 100,
      ratio: 1
    }
  }

  initGridSettings() {
    this.gridSettings = {
      gridOn: true,
      gridOnForeground: false,
      cellSize: 10,
      snapping: false,
      gridColor: "",
      backgroundColor: "#aaa"
    }
  }

  setGridBackgroundColor(color: string) {
    this.gridSettings.backgroundColor = color;
  }

  resetGridBackgroundColor() {
    this.setGridBackgroundColor("#aaa");
  }

  gridCellSize() {
    return this.gridSettings.cellSize
  }

  saveDimensionsSettings(params) {
    this.dimensionsSettings = {...params};
    this.saveSettings();
  }

  saveGridSettings(params) {
    this.gridSettings = {...params};
    this.saveSettings();
  }
  
  saveSettings() {
    localStorage.setItem("set", JSON.stringify({dimensionsSettings:this.dimensionsSettings, gridSettings: this.gridSettings}));
    this._settingsChangeSource.next(true);
  }

  get activeUnitTypeName(): string {
    let parsedType: string = UnitEnum[this.dimensionsSettings.units.toString()];
    return parsedType;
  }

  dimensionToDisplay(realValue) {
    switch(this.dimensionsSettings.units) {
      case 0: {
        return Math.round(realValue/this.dimensionsSettings.pixelInMeter * 1000 * 1000) / 1000;
      }
      case 1: {
        return Math.round(realValue/this.dimensionsSettings.pixelInMeter * 100 * 1000) / 1000;
      }
      case 2: {
        return Math.round(realValue/this.dimensionsSettings.pixelInMeter * 1000) / 1000;
      }
      default: return Math.round(realValue * 1000) / 1000
    }
  }

  getVisualisationMultiplier() {
    return this.dimensionToDisplay(1);
  }
}
