import { Injectable, Inject, PLATFORM_ID, EventEmitter, Output, Directive } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Directive()
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    currentUser: String;
    currentUserSubject: BehaviorSubject<String>;
    currentUser$: Observable<String>;
    isBrowser: boolean;
    @Output() logoutEvent = new EventEmitter();
    constructor(
        private http: HttpClient,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        this.isBrowser = isPlatformBrowser(this.platformId);
        if(this.isBrowser) {
            this.currentUser = localStorage.getItem('currentUser');
            this.currentUserSubject = new BehaviorSubject(this.currentUser);
            this.currentUser$ = this.currentUserSubject.asObservable(); 
        }
    }

    login(email: string, password: string) {
        return this.http.post<any>(`${environment.requestUrl}/api/auth/signin`, { email, password })
            .pipe(
            map(response => {
                // login successful if there's a jwt token in the response
                if (response && response.data && response.data.token != '') {
                    let token = response.data.token;
                    localStorage.setItem('currentUser', token);
                    this.currentUserSubject.next(token);
                    return response.data;
                }
            }),
            catchError(
                (error: any) =>
                    Observable.throw(error || 'Server Error'))
            )
    }

    register(userData) {
        return this.http.post<any>(`${environment.requestUrl}/api/auth/signup`, userData)
            .pipe(
            map(response => {
                if (response && response.status === 1) {
                    return true;
                }
            }),
            catchError(
                (error: any) =>
                    Observable.throw(error || 'Server Error'))
            )
    }

    logout(): Observable<any> {
      let obs: Observable<any> = new Observable(observer => {
            localStorage.removeItem('currentUser');
            localStorage.clear();
              this.currentUserSubject.next(null);
              observer.next(true);
            });
      return obs;
    }

    isAuthenticated(): boolean {
        if(this.isBrowser) {
            const authData = localStorage.getItem("currentUser");
            if (authData && authData !== "invalid" && authData !== "undefined") {
                this.currentUserSubject.next(authData);
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    sendForgotPasswordRequest(data) : Observable<boolean> {
        let requestData = {
                'Email': data
            }
        return this.http.post<any>(`${environment.requestUrl}/api/auth/forgotpassword`, requestData)
            .pipe(
            map(response => {
                if (response && response.status === 1) {
                    return true;
                }
            }),
            catchError(
                (error: any) =>
                    Observable.throw(error || 'Server Error'))
            )
    }

    resetPassword(data) : Observable<boolean> {
        return this.http.post<any>(`${environment.requestUrl}/api/auth/resetpassword`, data)
            .pipe(
            map(response => {
                if (response && response.status === 1) {
                    return true;
                }
            }),
            catchError(
                (error: any) =>
                    Observable.throw(error || 'Server Error'))
            )
    }

    sendActivationRequest() : Observable<boolean> {
        return this.http.post<any>(`${environment.requestUrl}/api/auth/sendactivation`,{})
            .pipe(
            map(response => {
                if (response && response.status === 1) {
                    return true;
                }
            }),
            catchError(
                (error: any) =>
                    Observable.throw(error || 'Server Error'))
            )
    }
}
