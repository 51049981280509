import { Component, OnInit } from '@angular/core';
import * as videoContents from "../../../assets/text-contents/videos.json";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  data: any;
  constructor(
    private sanitizer : DomSanitizer
  ) {
    if(!this.data) {
      let video: any = videoContents;
      Object.keys(video.default).forEach(key => {
        this.data = video.default.contents;
      });
    }
  }
  ngOnInit(): void {
  }


  getSanitizedURL(url) {
    let san = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    return san;
  }

}
