<div class="additional-tools-element">
    <div *ngIf="isEditMode() && selectedArc()" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
        <div class="ep-form-control-container">
            <div class="w-100">
                <div class="ep-form-control">
                    <span>Radius ({{units}})</span>
                    <input type="number" [(ngModel)]="arc.radius" (blur)="onBlur()" (focus)="onFocus()"
                    (ngModelChange)="updateRadius()">
                </div>
            </div>
            <div fxFlex="40px"></div>
        </div>
        <div class="ep-form-control-container">
            <div class="w-100">
                <div class="ep-form-control">
                    <span>startWinkel °</span>
                    <input type="number" [(ngModel)]="arc.startAngle" (blur)="onBlur()" (focus)="onFocus()"
                    (ngModelChange)="updateAngle('start')">
                </div>
            </div>
            <div fxFlex="40px"></div>
        </div>
        <div class="ep-form-control-container">
            <div class="w-100">
                <div class="ep-form-control">
                    <span>endWinkel °</span>
                    <input type="number" [(ngModel)]="arc.endAngle" (blur)="onBlur()" (focus)="onFocus()"
                    (ngModelChange)="updateAngle('end')">
                </div>
            </div>
            <div fxFlex="40px"></div>
        </div>
        <div fxFlex="5px"></div>
    </div>
    <app-common-properties *ngIf="isInsertMode()"></app-common-properties>
</div>