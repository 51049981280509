<div class="additional-tools-element">
    <div class="w-100">
        <mat-form-field class="w-100">
            <mat-label>Modus</mat-label>
            <mat-select (selectionChange)="updateLineMode($event)" [(value)]="currentLineMode">
                <mat-option *ngFor="let lineMode of lineModesList" [value]="lineMode.type" [matTooltip]="lineMode.name">
                    <mat-icon svgIcon="{{lineMode.icon}}"></mat-icon>
                    <span>{{lineMode.name}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <app-line-icon-tools></app-line-icon-tools>
    <app-common-properties></app-common-properties>
    <app-fill-tools *ngIf="currentLineMode === 9"></app-fill-tools>
</div>